import { inject, Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { IReminderItem } from "../app/settings/components/reminders-page/reminders-page.component";
import {
  ReminderSendingMethods
} from "../app/settings/components/reminders-page/components/ag-reminder-sending-method-label/ag-reminder-sending-method-label.component";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ReminderRecord } from "../models/reminderRecord";
import { AdHocDebtReminderBody } from "../models/adHocDebtReminderBody";
import { ReminderBody } from "../models/reminderBody";
import { AdHocSigningReminderBody } from "../models/adHocSigningReminderBody";
import { AdHocPaymentReminderBody } from "../models/adHocPaymentReminderBody";

@Injectable({
  providedIn: 'root'
})
export class RemindersService {
  private api = inject(HttpClient);

  public loadReminders(): Observable<IReminderItem[]> {
    return of([
      {
        name: 'Annual Statement Payment Reminder',
        id: '0',
        dates: [],
        sendingMethod: null,
        autoSending: false,
        time: null,
      },
      {
        name: 'Company Debt Reminder',
        id: '1',
        dates: [],
        sendingMethod: null,
        autoSending: false,
        time: null,
      },
      {
        name: 'Signing Reminder for Annual Statement',
        id: '2',
        dates: [],
        sendingMethod: null,
        autoSending: false,
        time: null,
      },
      {
        name: 'Signing Reminder for ASIC Forms',
        id: '3',
        dates: [2, 3, 4],
        sendingMethod: ReminderSendingMethods.smsAndEmail,
        autoSending: true,
        time: '07:00 AM',
      },
    ]);
  }

  public saveReminderChanges(reminder: IReminderItem): Observable<IReminderItem> {
    return of(reminder);
  }

  public sendSigningAdHocReminder(body: AdHocSigningReminderBody): Observable<ReminderRecord> {
    return this.api.post<ReminderRecord>(`${ environment.api_url }/reminders/ah-hoc/signing`, body);
  }

  public sendPaymentAdHocReminder(body: AdHocPaymentReminderBody): Observable<ReminderRecord> {
    return this.api.post<ReminderRecord>(`${ environment.api_url }/reminders/ah-hoc/payment`, body);
  }

  public sendDebtAdHocReminder(body: AdHocDebtReminderBody): Observable<ReminderRecord> {
    return this.api.post<ReminderRecord>(`${ environment.api_url }/reminders/ah-hoc/debt`, body);
  }

  public addReminder(body: ReminderBody): Observable<void> {
    return this.api.post<void>(`${ environment.api_url }/reminders`, body);
  }

  public deleteReminder(reminderId: string): Observable<void> {
    return this.api.delete<void>(`${ environment.api_url }/reminders/${reminderId}`);
  }
}
