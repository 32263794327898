<div class="card settings-page">
  <app-page-title
    header="Agent Details"
    iconClass="ic-asic-black-white"
    backButtonPath="settings"
  >
    <app-button
      id="edit-agent-dd"
      text="Edit Agent Details"
      [iconClass]="'icon ic-xs ic-edit'"
      [isDisabled]="isLoading || editAgentDetailsModalOpened"
      (clickBtn)="editAgent()"
      *appHasRole="['Admin', 'Manager']"
    ></app-button>

    <div
      ngbDropdown
      [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
      class="dropdown"
      *appHasRole="['Admin', 'Manager']"
    >
      <button
        ngbDropdownToggle
        id="company-actions-dd-3"
        class="btn btn-primary d-flex align-items-center gap-2"
        [disabled]="modalOpened()"
      >
        <span>Form Actions</span>
        <i class="icon ic-xs ic-select-icon"></i>
      </button>

      <div ngbDropdownMenu aria-labelledby="company-actions-dd-3" class="dropdown-menu dropdown-menu-right">
        @for(item of agentSettingsFormMenu; track $index) {
          <div ngbDropdownItem class="dropdown-item">
            <a class="dropdown-button p-2" (click)="item.action()">
              <i class="ic-form ic-md icon"></i>
              <div class="document">
                <div class="title">{{ item.title }}</div>
                <div class="asic-form">{{ item.formName }}</div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>

<!--    <app-button-->
<!--      id="reset-edge-password-dd"-->
<!--      text="Reset EDGE Password"-->
<!--      [isDisabled]="resetEdgePasswordButtonDisabled"-->
<!--      [iconClass]="'icon ic-xs ic-key'"-->
<!--      (clickBtn)="resetEdgePassword()"-->
<!--    ></app-button>-->
  </app-page-title>

  <div class="card-body">
    @if (!isLoading) {
      <div class="agent-info">
        <div class="form-group">
          <label class="title mb-1">Agent Name</label>
          <span class="property" appCopy>{{ agent.firmName | defaultValue }}</span>
        </div>

        <div class="form-group">
          <label class="title mb-1">Agent Number</label>
          <span class="property" appCopy>{{ agent.agentNumber | defaultValue }}</span>
        </div>

        <div class="form-group">
          <label class="title mb-1">ASIC Contact</label>
          <span class="property" appCopy>{{ agent.contactPerson | defaultValue }}</span>
        </div>

        <div class="form-group">
          <label class="title mb-1">Address</label>
          <span class="property" appCopy>{{ agent.postalAddress.normalizedFullAddress | defaultValue }}</span>
        </div>

        <div class="form-group">
          <label class="title mb-1">Phone Number</label>
          <span class="property" appCopy>{{ agent.phone | phone | defaultValue }}</span>
        </div>

        <div class="form-group">
          <label class="title mb-1">Email Address</label>
          <span class="property" appCopy>{{ agent.email | defaultValue }}</span>
        </div>
      </div>

      <app-divider margin="24px 0"></app-divider>

      <div class="edge-info">
        <div class="form-group">
          <label class="title mb-1">EDGE Login</label>
          <span class="property" appCopy>{{ agent.edgeLogin | defaultValue }}</span>
        </div>

        <div class="form-group">
          <label class="title mb-1">EDGE Password</label>
          <span class="property asic-password">
            @if (agent.edgePassword) {
              @if (edgePasswordVisible) {
                {{ agent.edgePassword | defaultValue }}
              } @else {
                ********
              }

              <app-ag-action-icon-button
                [iconClass]="edgePasswordVisible ? 'ic-eye-off' : 'ic-eye'"
                (action)="edgePasswordVisible = !edgePasswordVisible"
              ></app-ag-action-icon-button>
            } @else {
              {{ '' | defaultValue }}
            }
          </span>
        </div>
      </div>

      <app-divider margin="24px 0"></app-divider>

      <div class="asic-info">
        <div class="form-group">
          <label class="title mb-1">ASIC Mailbox</label>
          <span class="property">{{ ASICMailbox[mailbox] }}</span>
        </div>
      </div>
    }

    <app-loader [loading]="isLoading"></app-loader>
  </div>
</div>
