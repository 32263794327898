<div [ngClass]="{overlay: $showDetails()}" (click)="closeDetails()"></div>
<div class="card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="ic-alert-triangle ic-md icon"></i>
        <div class="d-flex flex-column ml-3">
          <div class="alerts-title">Alerts</div>
          <div class="alerts-subtitle">{{pageTitle}}</div>
        </div>
      </div>

      <div class="button-container" *appHasRole="['Admin', 'Manager', 'Preparer']">
        @if (checkedInboxAlerts.length) {
          <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
            <button
              ngbDropdownToggle
              id="bulk-actions-dd"
              class="btn btn-primary d-flex align-items-center gap-2"
              [disabled]="modalOpened()"
            >
              <span>Actions</span>
              <i class="icon ic-xs ic-select-icon"></i>
            </button>

            <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
              <div class="dropdown-item" (click)="bulkMarkRead()">
                <a class="dropdown-button">
                  <i class="icon ic-circle-check"></i>
                  <span>Mark as Read</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="bulkMarkRequireAttention()">
                <a class="dropdown-button">
                  <i class="icon ic-circle-info"></i>
                  <span>Mark as Require Attention</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="bulkArchive()">
                <a class="dropdown-button">
                  <i class="icon ic-archive"></i>
                  <span>Archive</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="bulkIgnore()">
                <a class="dropdown-button">
                  <i class="icon ic-ignore"></i>
                  <span>Ignore</span>
                </a>
              </div>
              <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
                <a ngbDropdownToggle class="dropdown-button pad-32">
                  <i class="icon ic-clock"></i>
                  <span>Postpone</span>
                  <i class="icon ic-xs ic-chevron-down"></i>
                </a>

                <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
                  @for(item of postponeMenu; track $index) {
                    <div class="dropdown-item" (click)="bulkPostpone(item.value)">
                      <a class="dropdown-button">
                        <span>{{item.label}}</span>
                      </a>
                    </div>
                  }
                </div>
              </div>
              <div class="dropdown-item" (click)="exportSelectedAlertsToXls()">
                <a class="dropdown-button">
                  <i class="icon ic-xs ic-download-file"></i>
                  <span>Export to xlsx</span>
                </a>
              </div>
            </div>
          </div>
        } @else {
          <app-button
            [text]="'Mark All as Read'"
            [iconClass]="'icon ic-circle-check'"
            [isDisabled]="modalOpened()"
            (clickBtn)="markAllRead()"
          ></app-button>

          <app-button
            style="width: 100px"
            [text]="'Export'"
            [iconClass]="'icon ic-xs ic-download-file'"
            [isDisabled]="!totalRows || modalOpened()"
            (clickBtn)="exportAlertsList(false, ExportTypeEnum.EXCEL)"
          ></app-button>
        }
      </div>
    </div>
  </div>

  <app-grid
    storageKey="all-alerts"
    [defaultExcelExportParams]="excelExportParams"
    [excelStyles]="excelStyles"
    [colDefs]="$any(colDefs)"
    [rowData]="[]"
    [totalRows]="totalRows"
    [maxBlocksInCache]="maxBlocksInCache"
    [gridOptions]="gridOptions"
    [pageTitle]="pageTitle"
    [suppressRowClickSelection]="true"
    [rowModelType]="serverRowModelType"
    [serverSideDatasource]="dataSource"
    [getRowId]="getRowId"
    [pagination]="true"
    [paginationPageSize]="defaultPageSize"
    [cacheBlockSize]="cacheBlockSize"
    (gridReady)="onGridReady($event)"
    (itemSelect)="selectInboxAlerts($event)"
    (search)="onSearch($event)"
  ></app-grid>
</div>

<div class="alert-details" [@expandDetail]="$showDetails() ? 'alert-expanded' : 'alert-collapsed'">
  <div class="alert-details-header d-flex align-items-center">
    <div class="close-icon-container d-flex justify-content-center align-items-center">
      <span class="icon ic ic-close cursor-pointer" (click)="closeDetails()"></span>
    </div>
    <div class="info-container">
      <div class="header-title">{{$selectedAlert()?.model?.title}}</div>
      <div class="alert-pagination-container">
        <div class="pages">{{currentAlertIndex + 1}} of {{totalRows}}</div>
        <div class="arrow-btn-container">
          <i
            class="icon ic-arrow-left"
            [ngClass]="{'disabled': !currentAlertIndex || modalOpened()}"
            (click)="previousAlert()"
          ></i>
          <i
            class="icon ic-arrow-right"
            [ngClass]="{'disabled': currentAlertIndex + 1 === totalRows || modalOpened()}"
            (click)="nextAlert()"
          ></i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="alert-details-content" [@expandDetail]="$showDetails() ? 'alert-expanded' : 'alert-collapsed'">
  <app-alert-details
    [alert]="$selectedAlert()!"
    (updateAlert)="updateAlertGridRow($event)"
  ></app-alert-details>
</div>
