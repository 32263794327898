import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from "../../components/common/button/button.component";
import { IServerSideDatasource, ValueFormatterParams } from "ag-grid-community";
import { GridComponent } from "../../components/common/grid/components/grid/grid.component";
import { BaseAnnualStatements } from "../base-annual-statements";
import {
  AnnualStatementPageFilter,
  AnnualStatementStatus,
  SigningStatus
} from "../../../models/enums/annualStatementEnums";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import {
  AgAnnualNameAcnComponent
} from "../../components/common/grid/components/annual/ag-annual-name-acn/ag-annual-name-acn.component";
import {
  AgAnnualStatusComponent
} from "../../components/common/grid/components/annual/ag-annual-status/ag-annual-status.component";
import { dateComparator } from "../../components/common/grid/functions/date-comparator";
import { AnnualRecord } from "../../../models/annualRecord";
import { formatDate } from "@angular/common";
import { CellDataType } from "../../../models/enums/agGridEnums";
import {
  AgCompanyDebtComponent
} from "../../components/common/grid/components/ag-company-debt/ag-company-debt.component";
import { AgEntityTagsComponent } from "../../components/common/grid/components/ag-company-tag/ag-entity-tags.component";
import { Tag} from "../../../models/tag";
import {
  AgAnnualSigningStatusComponent
} from "../../components/common/grid/components/annual/ag-annual-signing-status/ag-annual-signing-status.component";
import {
  AgAnnualActionsComponent
} from "../../components/common/grid/components/annual/ag-annual-actions/ag-annual-actions.component";
import { FilterOption } from "../../../models/gridFilter";
import { uniqueArrayToFilterOptionHelper } from "../../helpers/unique-array-to-filter-option.helper";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { HasRoleDirective } from '../../../directives/has-role.directive';

@Component({
  selector: 'app-annual-ready-to-send',
  standalone: true,
  imports: [
    ButtonComponent,
    GridComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    HasRoleDirective
  ],
  templateUrl: './annual-ready-to-send.component.html',
  styleUrls: ['./annual-ready-to-send.component.scss', '../base-annual-statements.scss']
})
export class AnnualReadyToSendComponent extends BaseAnnualStatements implements OnInit {
  readonly pageTitle = 'Ready to Send';
  override pageFilter = AnnualStatementPageFilter.ReadyToSend;
  tagsList: string[] = [];
  tagsFilterOption: FilterOption[] = [];
  tagsLoading = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.tagsLoading = true;
    this.tagsService.getTags().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(tags => {
      this.tagsList = Array.from(new Set(tags?.map(tag => tag.name)));
      this.tagsFilterOption = uniqueArrayToFilterOptionHelper(this.tagsList);
      this.setGridConfig();
      this.tagsLoading = false;
    });
  }

  override dataSource: IServerSideDatasource = this.getDataSource(this.pageFilter, '');

  override onSearch(searchText: string): void {
    this.dataSource = this.getDataSource(this.pageFilter, searchText);
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Company Name & ACN',
        exportColumnName: 'Company Name',
        field: 'companyName',
        width: 200,
        flex: 1,
        editable: false,
        sort: 'asc',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: AgAnnualNameAcnComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: '',
        exportColumnName: 'ACN',
        field: 'acn',
        width: 100,
        flex: 0.5,
        menuTabs: [],
        hide: true
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.statusFilterOptions,
          values: Object.values(AnnualStatementStatus).filter((v) => !isNaN(Number(v))),
        },
        cellRenderer: AgAnnualStatusComponent,
        menuTabs: [],
        hide: true
      },
      {
        headerName: 'Partner',
        field: 'partnerManagerFullName',
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.partnerManagerFilterOptions,
          values: this.profileUserNameList,
        },
        menuTabs: [],
      },
      {
        headerName: 'Review Date',
        field: 'reviewDate',
        width: 100,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
        },
        valueFormatter: (params: ValueFormatterParams<AnnualRecord, Date>) => {
          return formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US');
        },
        cellDataType: CellDataType.DATE,
        menuTabs: [],
        hide: true
      },
      {
        headerName: 'Payment Deadline',
        field: 'paymentDeadline',
        width: 120,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
        },
        valueFormatter: (params: ValueFormatterParams<AnnualRecord, Date>) => {
          return formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US');
        },
        cellDataType: CellDataType.DATE,
        menuTabs: [],
      },
      {
        headerName: 'Debt',
        field: 'debt',
        flex: 0.5,
        cellRenderer: AgCompanyDebtComponent,
        menuTabs: [],
      },
      {
        headerName: 'Tags',
        field: 'tags',
        flex: 1,
        sortable: false,
        cellRendererParams: { allowEdit: false },
        cellRenderer: AgEntityTagsComponent,
        valueFormatter: (params: { value: Tag }) => params.value?.name ?? '',
        menuTabs: [],
        hide: true
      },
      {
        headerName: 'Tags',
        field: 'tagsName',
        suppressColumnsToolPanel: true,
        lockVisible: true,
        sortable: false,
        hide: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.tagsFilterOption,
          values: this.tagsList,
        },
        cellRendererParams: { allowEdit: false },
        menuTabs: [],
      },
      {
        headerName: 'Reminders',
        field: 'reminders',
        flex: 0.5,
        menuTabs: [],
        hide: true
      },
      {
        headerName: 'Signing Status',
        field: 'signingStatus',
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.signingStatusFilterOption,
          values: Object.values(SigningStatus).filter((v) => !isNaN(Number(v))),
        },
        cellRenderer: AgAnnualSigningStatusComponent,
        menuTabs: [],
        hide: true
      },
      // {
      //   headerName: 'Signing Date',
      //   field: 'signingDate',
      //   flex: 0.5,
      //   filterParams: {
      //     comparator: dateComparator,
      //   },
      //   valueFormatter: (params: ValueFormatterParams<AnnualRecord, Date>) => {
      //     return formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US');
      //   },
      //   menuTabs: [],
      //   hide: true
      // },
      {
        headerName: 'Account Manager',
        field: 'accountManagerFullName',
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.accountManagerFilterOptions,
          values: this.profileUserNameList,
        },
        menuTabs: [],
        hide: true
      },
      {
        width: 150,
        sortable: false,
        suppressHeaderMenuButton: true,
        cellRenderer: AgAnnualActionsComponent,
        cellRendererParams: {
          view: this.view.bind(this),
          send: this.send.bind(this),
          edit: this.edit.bind(this),
          downloadDocuments: this.downloadDocuments.bind(this),
          archive: this.archive.bind(this),
          sendReminder: this.sendReminder.bind(this),
          sendPaymentReminder: this.sendPaymentReminder.bind(this),
          regenerate: this.regenerate.bind(this),
          markSent: this.markSent.bind(this),
          markSigned: this.markSigned.bind(this),
        },
        menuTabs: []
      }
    ];
  }
}
