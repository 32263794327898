import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IndividualService } from '../../services/individual.service';
import { firstValueFrom, take } from 'rxjs';
import { IndividualRecord } from '../../models/IndividualRecord';
import { Router } from '@angular/router';
import { Tag } from '../../models/tag';
import { ToastrService } from 'ngx-toastr';
import {
  CsvExportParams,
  ExcelExportParams,
  GetContextMenuItems,
  GridApi,
  ProcessCellForExportParams, ProcessHeaderForExportParams
} from "ag-grid-community";
import {
  AgIndividualActionsComponent
} from '../components/common/grid/components/ag-individual-actions/ag-individual-actions.component';
import { formatDate } from "@angular/common";
import { ManageTagsModalComponent } from '../settings/components/tags/manage-tags-modal/manage-tags-modal.component';
import { EntityType } from '../../models/enums/entityType';
import { Alert } from "../../models/alerts/alert";
import { ColumnWithExportName } from "../../models/columnWithExportName";
import { ExportTypeEnum } from "../../models/enums/exportTypeEnum";
import { ExportCompanyListComponent } from "../modals/export-company-list/export-company-list.component";
import { ModalFormsService } from "../../services/modal-forms.service";
import { AlertStatus } from "../../models/enums/alertEnums";
import { deepClone } from "fast-json-patch/commonjs/core";
import { ButtonComponent } from "../components/common/button/button.component";
import { GridComponent } from "../components/common/grid/components/grid/grid.component";
import { NotesComponent } from "../components/notes/notes.component";
import { AlertsListComponent } from "../components/alerts-list/alerts-list.component";
import { HasRoleDirective } from '../../directives/has-role.directive';

@Component({
  selector: 'app-individuals',
  standalone: true,
  templateUrl: './individuals.component.html',
  styleUrl: './individuals.component.scss',
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    ButtonComponent,
    GridComponent,
    NotesComponent,
    AlertsListComponent,
    HasRoleDirective
  ]
})
export class IndividualsComponent implements OnInit {
  private individualService = inject(IndividualService);
  private modalFormsService = inject(ModalFormsService);
  private router: Router = inject(Router);
  private toastr = inject(ToastrService);
  private modalService = inject(NgbModal);
  #destroyRef: DestroyRef = inject(DestroyRef);

  isMerging = false;
  loading = false;
  readonly pageTitle = 'Individuals';
  readonly EntityType = EntityType;

  gridApi!: GridApi;
  colDefs = this.individualService.colDefs;
  individualList: IndividualRecord[] = [];
  selectedIndividuals: IndividualRecord[] = [];
  actionColumn!: ColumnWithExportName;

  excelExportParams = this.exportParamsXls() as ExcelExportParams;
  csvExportParams = this.exportParamsCsv() as CsvExportParams;
  excelStyles = this.individualService.excelStyles;

  alerts: Alert[] = [];
  alertsVisible = false;
  entityForAlerts: IndividualRecord | undefined | null = null;

  notesVisible = false;
  entityForNotes: IndividualRecord | undefined | null = null;

  async ngOnInit() {
    this.actionColumn = {
      field: '',
      width: 120,
      sortable: false,
      cellClass: 'actions-button-cell',
      cellRenderer: AgIndividualActionsComponent,
      cellRendererParams: {
        open: this.openIndividual.bind(this),
        openAlerts: this.openAlerts.bind(this),
        openNotes: this.openNotes.bind(this),
      },
      suppressHeaderMenuButton: true,
    }

    await this.loadIndividualList();
    this.individualService.individualsListUpdated$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.individualService.setIndividualOptions(this.individualList, this.actionColumn))
  }

  async loadIndividualList() {
    this.loading = true;
    this.individualList = await firstValueFrom(this.individualService.list());
    this.individualService.setIndividualOptions(this.individualList, this.actionColumn);
    this.loading = false;
  }

  async mergeIndividuals(){
    const individualToMerge = this.selectedIndividuals;

    if(individualToMerge.length < 2){
      this.toastr.warning("Please select at least 2 individuals to merge")
      this.isMerging = false;
      return;
    }

    if(individualToMerge.some(x => x.fullName != individualToMerge[0].fullName)){
      this.toastr.warning("You can merge individuals only with the same name")
      this.isMerging = false;
      return;
    }

    this.isMerging = true;
    try {
      await firstValueFrom(this.individualService.merge(individualToMerge[0].individualId, individualToMerge.slice(1).map(x => x.individualId)));
      this.toastr.success("Individuals merged successfully");
      await this.loadIndividualList()
    }catch(e){
      this.toastr.error("Error while merging individuals")
    }
    this.isMerging = false;
  }

  exportSelectedIndividualsToXls(): void {
    this.exportIndividualList(true, ExportTypeEnum.EXCEL);
  }

  exportSelectedIndividualsToCsv(): void {
    this.exportIndividualList(true, ExportTypeEnum.CSV);
  }

  exportIndividualList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const componentInstance = this.modalFormsService.openModal(ExportCompanyListComponent, {
      modalDialogClass: 'export-company-list'
    }).componentInstance as ExportCompanyListComponent;

    componentInstance.colDefs = deepClone(this.colDefs()) as ColumnWithExportName[];
    componentInstance.title = 'Export Individual List';
    componentInstance.subTitle = 'individual selected';
    componentInstance.numberOfCompanies = isBulkExport ? this.selectedIndividuals.length : this.individualList.length;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((columnForExport: string[]) => {
      const tagIndex = columnForExport.findIndex(field => field === 'tags');
      if(tagIndex >= 0) {
        columnForExport.splice(tagIndex + 1, 0, 'systemTags');
      }

      this.individualService.numberColumnForExport.set(columnForExport.length - 1);

      const fileName = 'Individuals ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US');
      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        const exportParamsXls = this.exportParamsXls() as ExcelExportParams;
        this.gridApi.exportDataAsExcel({ ...exportParamsXls, ...params, fileName });
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsCsv({ ...params, fileName });
      }
    });
  }

  manageTags(): void {
    if (this.modalOpened()) return;
    const modalRef = this.modalService.open(ManageTagsModalComponent,
      {
        windowClass: 'manage-tags-modal',
        size: 'xl',
        centered: true
      });
    const instance = modalRef.componentInstance as ManageTagsModalComponent;

    instance.entities = this.selectedIndividuals;
    instance.confirm
      .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        void this.loadIndividualList();
        this.selectIndividuals([]);
      });
  }

  async openIndividual(individual: IndividualRecord): Promise<void> {
    await this.router.navigate(['/individual', individual.individualId]);
  }

  selectIndividuals(individuals: IndividualRecord[]): void {
    this.selectedIndividuals = individuals;
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
    this.individualService.gridApi = gridApi;
  }

  exportParamsXls() {
    return {
      ...this.exportParams(),
      prependContent: this.individualService.getExcelTitleRows(this.individualService.numberColumnForExport())
    };
  }

  exportParamsCsv() {
    return this.exportParams();
  }

  exportParams() {
    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const individualData = params.node?.data as IndividualRecord;
        if (params.column.getColId() === 'tags') {
          return (value as Tag[]).map((tag: Tag) => tag.name).join(', ');
        } else if (params.column.getColId() === 'systemTags') {
          return (value as Tag[] ?? []).map((tag: Tag) => tag.name).join(', ');
        } else if (params.column.getColId() === 'dob') {
          return individualData.dob ? formatDate(individualData.dob ?? '','dd MMM yyyy', 'en') : '';
        }
        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      sheetName: 'Individual'
    }
  }

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {individualId: string}}}): GetContextMenuItems {
    const items = gridApi.defaultItems.slice();
    return ([
      {
        name: 'Open Individual Detail in New Tab',
        action: () => {
          const individualId = gridApi.node.data.individualId;
          const url = `/individual/${individualId}`;
          window.open(url, '_blank');
        }
      },
      'separator',
      ...items,
    ]) as unknown as GetContextMenuItems
  }

  openAlerts(individual: IndividualRecord): void {
    if (this.modalOpened()) return;
    this.alertsVisible = true;
    this.alerts = individual?.alerts?.filter(alert => {
      return alert.status !== AlertStatus.Archived && alert.status !== AlertStatus.Ignored
    }) ?? [];
    this.entityForAlerts = individual;
  }

  openNotes(individual: IndividualRecord): void {
    if (this.modalOpened()) return;
    this.notesVisible = true;
    this.entityForNotes = individual;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
