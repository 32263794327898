<app-common-modal-form
  closeText="Cancel"
  [confirmText]="saveButton"
  [header]="header"
  [isLoading]="isLoading"
  (close)="close()"
  (confirm)="confirm()"
>
  <div class="create-edit-user-modal" [formGroup]="form">
    <div class="two-piece-input-wrapper">
      <app-input
        label="User Name"
        formControlName="fullName"
      />
      <app-input-phone-number
        label="Phone Number"
        formControlName="phoneNumber"
      />
    </div>

    <div class="form-group">
      <app-input
        label="Email"
        formControlName="email"
      />
    </div>

    <div class="form-group">
      <app-select
        label="Access Permissions"
        formControlName="roles"
        [options]="userRolesSelectOptions"
        [helperText]="userRoleTip"
        [valuePrimitive]="true"
      />
    </div>
  </div>
</app-common-modal-form>
