import { Component, DestroyRef, inject, Input, OnInit, signal } from '@angular/core';
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputComponent } from "../../../../../components/common/input/input.component";
import { NgbActiveModal, NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailTemplate } from "../../../../../../models/email-templates/emailTemplate";
import { OrganisationService } from "../../../../../../services/organisation.service";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { catchError, debounceTime, map, Observable, of, switchMap, tap } from "rxjs";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TemplateSettingsBase } from "../../../../../../models/email-templates/templateSettingsBase";
import { TextareaComponent } from "../../../../../components/common/textarea/textarea.component";
import { CustomFormValidators } from "../../../../../../custom-form-validators/custom-form-validators";
import { EmailTemplatesService } from "../../../../../../services/email-templates.service";
import { EmailTemplatesHelper } from "../../../../../../models/email-templates/emailTemplatesHelper";
import { SendEmailModalComponent } from "../send-email-modal/send-email-modal.component";
import { ButtonComponent } from "../../../../../components/common/button/button.component";
import { ToastrService } from "ngx-toastr";
import { IEmailPreviewResponse } from "../../../../../../models/email-templates/IEmailPreviewResponse";
import {
  ShadowDomViewerComponent
} from "../../../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";
import {
  AgActionIconButtonComponent
} from "../../../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import {
  TemplatesLegendTableComponent
} from "../../../text-messages-templates-page/components/sms-templates-keys-table/templates-legend-table.component";
import { TemplateCodePreview } from "../../../../../../models/text-messages/textMessagesHelper";
import { EmailPayloadBase } from "../../../../../../models/email-templates/emailPayloadBase";
import { HasRoleDirective } from '../../../../../../directives/has-role.directive';

@Component({
  selector: 'app-edit-email-template-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    TextareaComponent,
    ButtonComponent,
    CommonModalFormComponent,
    ShadowDomViewerComponent,
    AgActionIconButtonComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    TemplatesLegendTableComponent,
    HasRoleDirective
  ],
  providers: [EmailTemplatesService],
  templateUrl: './edit-email-template-modal.component.html',
  styleUrl: './edit-email-template-modal.component.scss',
})
export class EditEmailTemplateModalComponent implements OnInit {
  private emailTemplatesService = inject(EmailTemplatesService);
  private modalService = inject(NgbModal);
  private activeModal = inject(NgbActiveModal);
  private organisationService = inject(OrganisationService);
  private destroyRef = inject(DestroyRef);
  private sanitizer = inject(DomSanitizer);
  private toastr = inject(ToastrService);

  @Input() template!: EmailTemplate;
  @Input() defaultTemplate: TemplateSettingsBase | null = null;

  readonly subjectTemplateLengthLimit = 100;
  readonly bodyTemplateLengthLimit = 5000;
  readonly footerTemplateLengthLimit = 1000;
  readonly subjectTemplateCustomErrors = this.getCustomErrors(this.subjectTemplateLengthLimit);
  readonly bodyTemplateCustomErrors = this.getCustomErrors(this.bodyTemplateLengthLimit);
  readonly footerTemplateCustomErrors = this.getCustomErrors(this.footerTemplateLengthLimit);
  readonly PREVIEW_LOADING_DEBOUNCE_TIME = 2000;
  readonly confirmResetToDefaultTemplateMessage = 'Are you sure you want to reset to default? All changes will be lost, and the template will revert to the original state.';
  readonly customStyle = `.container {margin: 0 !important;}`;
  header = '';
  isLoading = false;
  formWasEdited = false;
  legendPreview: TemplateCodePreview = [];
  templatePayload!: EmailPayloadBase;
  keysMatchErrorMessages = {
    subjectTemplate: '',
    bodyTemplate: '',
    footerTemplate: '',
  };

  htmlContent = signal<SafeHtml | null>(null);
  logo = toSignal(this.organisationService.getSmallLogo()
    .pipe(map(b => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(b))))
    .pipe(catchError(() => of('assets/svg/bolt-logo-icon.svg'))));

  form = new FormGroup({
    subjectTemplate: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.minLength(1), CustomFormValidators.maxLength(this.subjectTemplateLengthLimit)]),
    bodyTemplate: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.minLength(1), CustomFormValidators.maxLength(this.bodyTemplateLengthLimit)]),
    footerTemplate: new FormControl<string | null>(null, [CustomFormValidators.maxLength(this.footerTemplateLengthLimit)]),
  });

  ngOnInit(): void {
    this.header = EmailTemplatesHelper.TemplateHeadersRecord[this.template.code] ?? '';

    this.form.patchValue({
      subjectTemplate: this.template.subjectTemplate,
      bodyTemplate: EmailTemplatesHelper.prepareTemplateString(this.template.bodyTemplate),
      footerTemplate: EmailTemplatesHelper.prepareTemplateString(this.template.footerTemplate),
    });

    this.loadPreview().subscribe();

    this.form.valueChanges
      .pipe(
        tap(() => this.formWasEdited = true),
        debounceTime(this.PREVIEW_LOADING_DEBOUNCE_TIME),
        switchMap(() => this.loadPreview()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.templatePayload = EmailTemplatesHelper.buildEmailTemplatePayload(this.template.code);
    this.legendPreview = EmailTemplatesHelper.buildPreview(this.templatePayload);
  }

  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.clearKeysMatchErrors();
    const templateKeysFit = Object.entries(this.form.controls)
      .map(([key, control]) => this.keysMatch(control.value ?? '', key as 'subjectTemplate' | 'bodyTemplate' | 'footerTemplate'))
      .every(Boolean);
    if (!templateKeysFit)
      return;

    const editedTemplate = this.buildTemplate();

    this.isLoading = true;
    this.form.disable();

    this.emailTemplatesService.saveEmailTemplate(editedTemplate)
      .subscribe({
        next: () => {
          this.activeModal.close();
          this.toastr.success('Data saved successfully', 'Success');
        },
        error: () => {
          this.form.enable();
          this.isLoading = false;
        }
      });
  }

  close(): void {
    this.clearKeysMatchErrors();
    this.activeModal.dismiss();
  }

  resetTemplate(): void {
    if (!this.defaultTemplate || !confirm(this.confirmResetToDefaultTemplateMessage)) return;

    this.clearKeysMatchErrors();
    this.form.patchValue({
      subjectTemplate: this.defaultTemplate.defaultSubject,
      bodyTemplate: EmailTemplatesHelper.prepareTemplateString(this.defaultTemplate.defaultTemplate),
      footerTemplate: EmailTemplatesHelper.prepareTemplateString(this.defaultTemplate.defaultFooterTemplate),
    });

    this.loadPreview().subscribe();
  }

  loadPreview(): Observable<IEmailPreviewResponse> {
    const errorFormResponse = of({ subject: '', htmlContent: '' });

    return this.emailTemplatesService.loadPreview(EmailTemplatesHelper.buildEmailPreviewRequest(this.buildTemplate()))
      .pipe(
        tap({
          next: (res) => this.htmlContent.set(this.sanitizer.bypassSecurityTrustHtml(res.htmlContent) ?? ''),
          error: (error) => {
            console.warn('[loadPreview] error: ', error);
            this.toastr.error("Something happened while loading preview", "Error");
          }
        }),
        catchError(() => errorFormResponse),
      );
  }

  testTemplate(): void {
    const modalRef = this.modalService.open(SendEmailModalComponent, { size: 'sm', centered: true });
    const instance = modalRef.componentInstance as SendEmailModalComponent;

    instance.emailByDefault = this.organisationService.getCurrentUser()?.email ?? '';
    instance.emailSendRequest = EmailTemplatesHelper.buildEmailSendRequest(this.buildTemplate());

    modalRef.result.then(
      () => this.toastr.success('Test email was sent', 'Success'),
      () => {
      }
    );
  }

  private buildTemplate(): EmailTemplate {
    return new EmailTemplate({
      ...this.template,
      subjectTemplate: this.form.controls.subjectTemplate.value!,
      bodyTemplate: this.bodyTemplate,
      footerTemplate: this.footerTemplate,
    });
  }

  private getCustomErrors(limit: number): Record<string, string> {
    return { maxLength: `You've exceeded the ${ limit } character limit` };
  }

  private keysMatch(text: string, propertyKey: 'subjectTemplate' | 'bodyTemplate' | 'footerTemplate'): boolean {
    const obj = this.templatePayload;
    const regex = /{{(.*?)}}/g; // Регулярное выражение для поиска шаблонов
    const matches = text.matchAll(regex);
    const nonMatchingKeys: string[] = [];

    for (const match of matches) {
      const keyFromText = match[1];
      const matchPascalCase = keyFromText.charAt(0) === keyFromText.charAt(0).toUpperCase();
      const keyInCamelCase = keyFromText.charAt(0).toLowerCase() + keyFromText.slice(1);

      if (!(matchPascalCase && keyInCamelCase in obj)) {
        nonMatchingKeys.push(keyFromText);
      }
    }

    if (nonMatchingKeys.length) {
      this.keysMatchErrorMessages[propertyKey] = `Keys <b>${ nonMatchingKeys.map((key) => `{{${ key }}}`).join(', ') }</b> can't be used in this template.`;
      return false;
    } else {
      this.keysMatchErrorMessages[propertyKey] = '';
      return true;
    }
  }

  private clearKeysMatchErrors(): void {
    Object.keys(this.keysMatchErrorMessages).forEach((key) => this.keysMatchErrorMessages[key] = '');
  }

  get bodyTemplate(): string {
    return EmailTemplatesHelper.replaceNewLine(this.form.controls.bodyTemplate.value);
  }

  get footerTemplate(): string {
    return EmailTemplatesHelper.replaceNewLine(this.form.controls.footerTemplate.value);
  }
}
