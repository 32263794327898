import { Component, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { CommonOffcanvasComponent } from "../../../components/common/common-offcanvas/common-offcanvas.component";
import { ToastrService } from "ngx-toastr";
import { SafeHtml } from "@angular/platform-browser";
import { finalize } from "rxjs";
import { TextMessageTemplatesService } from "../../../../services/sms-templates.service";
import { TextMessageRecord } from "../../../../models/textMessageRecord";
import { TextMessageStatus } from "../../../../models/enums/textMessageStatus";
import {
  AgActionIconButtonComponent
} from "../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import {
  AgOutgoingEmailMessageStatusComponent
} from "../ag-outgoing-message-status/ag-outgoing-email-message-status.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { DatePipe } from "@angular/common";
import { DisclaimerComponent } from "../../../components/common/disclaimer/disclaimer.component";
import { EmailRecipientsComponent } from "../ag-email-recipients/email-recipients.component";
import { LoaderStandaloneComponent } from "../../../components/common/loader-standalone/loader-standalone.component";
import { ShadowDomViewerComponent } from "../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";
import {
  AgOutgoingTextMessageStatusComponent
} from "../ag-outgoing-text-message-status/ag-outgoing-text-message-status.component";
import { TextMessageModel } from "../../../../models/textMessageModel";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { HasRoleDirective } from '../../../../directives/has-role.directive';

@Component({
  selector: 'app-outgoing-text-message-details',
  standalone: true,
  imports: [
    AgActionIconButtonComponent,
    AgOutgoingEmailMessageStatusComponent,
    ButtonComponent,
    CommonOffcanvasComponent,
    DatePipe,
    DisclaimerComponent,
    EmailRecipientsComponent,
    LoaderStandaloneComponent,
    ShadowDomViewerComponent,
    AgOutgoingTextMessageStatusComponent,
    NgxSkeletonLoaderModule,
    HasRoleDirective
  ],
  providers: [TextMessageTemplatesService],
  templateUrl: './outgoing-text-message-details.component.html',
  styleUrls: [
    'outgoing-text-message-details.component.scss',
    '../outgoing-email-message-details/outgoing-email-message-details.component.scss',
    '../../components/ag-email-recipients/email-recipients.component.scss'
  ]
})
export class OutgoingTextMessageDetailsComponent extends CommonOffcanvasComponent {
  private toastr = inject(ToastrService);
  private textMessagesTemplatesService = inject(TextMessageTemplatesService);

  @Input({ alias: 'textMessageRecord', required: true }) set textMessageRecordSetter(record: TextMessageRecord | null) {
    this.textMessageRecord = record;
    this.textMessageModel = null;

    if (record) {
      this.loadTextMessageModel(record);
    }
  }

  @Input() totalRows = 0;
  @Input() currentEmailMessageIndex = 0;
  @Input() currentEmailMessageItemNumber = 0;
  @Input() isOuterLoading = false;

  @Output() getNextEmailMessage = new EventEmitter<void>();
  @Output() getPreviousEmailMessage = new EventEmitter<void>();
  @Output() refreshData = new EventEmitter<void>();

  readonly customStyle = `.container {margin: 0 !important;}`;
  readonly TextMessageStatus = TextMessageStatus;
  htmlContent = signal<SafeHtml | null>(null);
  textMessageRecord: TextMessageRecord | null = null;
  textMessageModel: TextMessageModel | null = null;
  isTextMessageModelLoading = false;
  isMessageActionLoading = false;
  resentEmailDate: Date | null = null;

  openNextEmail(): void {
    this.getNextEmailMessage.emit();
  }

  openPreviousEmail(): void {
    this.getPreviousEmailMessage.emit();
  }

  deleteEmail(): void {
    // available only for admins
    if (!this.textMessageRecord || !confirm('Deleting the SMS will not recall sent emails nor cancel pending eSigning envelopes. If you wish to cancel an eSigning request, you must void the envelope. Do you want to continue?')) return;
    this.isMessageActionLoading = true;
    this.textMessagesTemplatesService.deleteOutgoingTextMessage(this.textMessageRecord.textMessageId)
      .pipe(finalize(() => this.isMessageActionLoading = false))
      .subscribe({
        next: () => {
          this.refreshData.emit();
          this.closeOffcanvas();
        },
        error: () => this.toastr.error('Something happened while deleting SMS message', 'Error')
      });
  }

  resendEmail(): void {
    if (this.textMessageRecord?.status !== TextMessageStatus.Failed || !this.textMessageModel) return;
    this.isMessageActionLoading = true;

    this.textMessagesTemplatesService.resendOutgoingTextMessage(this.textMessageRecord.textMessageId)
      .pipe(finalize(() => this.isMessageActionLoading = false))
      .subscribe({
        next: () => {
          this.refreshData.emit();
          this.resentEmailDate = new Date();
        },
        error: () => this.toastr.error('Something happened while sending SMS message', 'Error')
      });
  }

  private loadTextMessageModel(record: TextMessageRecord): void {
    this.isTextMessageModelLoading = true;
    this.textMessagesTemplatesService.getOutgoingTextMessageDetails(record.textMessageId)
      .pipe(finalize(() => this.isTextMessageModelLoading = false))
      .subscribe({
        next: (textMessageModel) => {
          if (textMessageModel.textMessageId !== this.textMessageRecord?.textMessageId) return;
          this.textMessageModel = new TextMessageModel({
            ...textMessageModel,
            message: textMessageModel.message.replaceAll('\n', '<br>')
          });
        },
        error: () => this.toastr.error('Something happened while loading SMS', 'Error')
      });
  }
}
