<div class="sidebar-container">
  <div class="section">
    @if(!loadCompany) {
      @if (entity !== null && entity.entityNumber && _document?.type !== 'c:362a') {
        <div>
          <app-ag-tag-entity-title
            [isLink]="true"
            [entity]="entity"
            [entityType]="entityType"
          ></app-ag-tag-entity-title>
        </div>
      }

      <div class="document-info-grid">
        <div class="info-item">
          <div class="info-item-title">Status</div>
          <div class="status-wrapper">
            @if(_document?.documentStatus !== undefined) {
              <div class="status" [ngClass]="DocumentStatusEnum[_document?.documentStatus ?? 0] | lowercase">
                {{ _document?.documentStatus | documentStatus }}
              </div>
            }
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-title">Late fee</div>
          <div class="info-item-content" [ngClass]="{'pink': lodgement?.lateFee}">
            {{lodgement?.lateFee ? (lodgement?.lateFee | currency : "$" : 'code' : '1.2-2') : 'N/A'}}
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-title">Date Created</div>
          <div class="info-item-content">{{_document?.createdOn | date: 'dd MMM yyyy'}}</div>
        </div>

        @if(currentStepIndex && currentStepIndex >= DocumentStepEnum.SignDocuments) {
          <div class="info-item">
            <div class="info-item-title">Date of Signature</div>
            <div class="info-item-content">{{_document?.documentSigning?.signedOn ? (_document?.documentSigning?.signedOn | date: 'dd MMM yyyy') : 'N/A'}}</div>
          </div>
        }

        <div class="info-item">
          <div class="info-item-title">Lodgement Deadline</div>
          <div class="info-item-content">
            {{lodgement?.lodgementDeadline ? (lodgement?.lodgementDeadline | date : 'dd MMM yyyy') : 'N/A'}}
          </div>
        </div>
      </div>

      <div class="tag-container">
        <div class="info-item-title">Tags</div>
        <div class="info-item-tag">
          @for (tag of entity?.tags; track $index) {
            <app-tag [tag]="tag"></app-tag>
          } @empty {
            N/A
          }
        </div>
      </div>
    } @else {
      <div class="skeleton-container">
        <ngx-skeleton-loader count="2" appearance="line" />
        <div class="d-flex gap-12">
          <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
          <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
        </div>
      </div>
    }
  </div>

  <app-divider></app-divider>

  <div class="section">
    @if(!loading) {
      @if(currentStepIndex && currentStepIndex >= DocumentStepEnum.SignDocuments) {
        <div class="documents-container">
          <div class="header">
            <div class="title-wrapper">
              <div><i class="icon ic-documents"></i></div>
              <div class="title">Documents</div>
            </div>

            <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
              <app-button
                ngbDropdownToggle
                [btnColor]="'white'"
                [iconClass]="'icon ic-download-file'"
              ></app-button>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item" (click)="downloadCombinedPDF(DocumentSubFolderName.Final)">
                  <a class="dropdown-button">
                    <span>Download combined PDF</span>
                  </a>
                </div>
                <div class="dropdown-item" (click)="downloadZIP(DocumentSubFolderName.Final)">
                  <a class="dropdown-button">
                    <span>Download ZIP</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="document-uploaded-container">
            <div class="uploaded-container">
              <div class="sub-title">Generated</div>
              <div class="uploaded-list">
                @for (finalDocument of (documentsList$ | async)?.final; track finalDocument.name) {
                  <div class="uploaded-row">
                    <div class="name-wrapper">
                      <i class="icon ic-xs ic-file"></i>
                      <div class="file-name" (click)="downloadFile(finalDocument.name, DocumentSubFolderName.Final)">{{finalDocument.name }}</div>
                    </div>
                    <div class="action-wrapper">
                      <i class="icon ic-xs ic-download-file" (click)="downloadFile(finalDocument.name, DocumentSubFolderName.Final)"></i>
                    </div>
                  </div>
                } @empty {
                  <div>No generated documents</div>
                }
              </div>
            </div>
          </div>

          @if((documentsList$ | async)?.signed?.length) {
            <div class="uploaded-container">
              <div class="sub-title">Signed</div>
              <div class="uploaded-list">
                @for (finalDocument of (documentsList$ | async)?.signed; track finalDocument.name) {
                  <div class="uploaded-row">
                    <div class="name-wrapper">
                      <i class="icon ic-xs ic-file"></i>
                      <div class="file-name" (click)="downloadFile(finalDocument.name, DocumentSubFolderName.Signed)">{{finalDocument.name }}</div>
                    </div>
                    <div class="action-wrapper">
                      <i class="icon ic-xs ic-download-file" (click)="downloadFile(finalDocument.name, DocumentSubFolderName.Signed)"></i>
                    </div>
                  </div>
                }
              </div>
            </div>
          }

          @if(currentStepIndex === DocumentStepEnum.SignDocuments) {
            <div class="uploaded-container">
              <div class="sub-title">Uploaded</div>
              <div class="uploaded-list">
                @for (uploadedDocument of (documentsList$ | async)?.upload; track uploadedDocument.name) {
                  <div class="uploaded-row uploaded-name">
                    <div class="name-wrapper">
                      <div><i class="icon ic-xs ic-file"></i></div>
                      <div class="file-name">{{ uploadedDocument.name }}</div>
                    </div>
                    <div class="action-wrapper">
                      <div><i class="icon ic-xs ic-download-file" (click)="downloadFile(uploadedDocument.name, DocumentSubFolderName.Upload)"></i></div>
                      <div><i class="icon ic-xs ic-close" (click)="deleteFile(uploadedDocument.name)"></i></div>
                    </div>
                  </div>
                } @empty {
                  <div>No uploaded files</div>
                }
              </div>

              <input
                style="display: none"
                #fileUploadInput
                type="file"
                (change)="handleFileUpload($event)"
              >

              <app-button
                class="upload-file"
                [iconClass]="'icon ic-upload-file'"
                [text]="'Upload Documents'"
                [btnColor]="'white'"
                (clickBtn)="uploadFile(fileUploadInput)"
              ></app-button>
            </div>
          }
        </div>
      }
    } @else {
      @if(currentStepIndex) {
        <div class="skeleton-container">
          <ngx-skeleton-loader count="6" appearance="line" />
        </div>
      }
    }
  </div>
</div>
