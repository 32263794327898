@if(maxBlocksInCache() !== undefined) {
  <ag-grid-angular
    class="custom-ag-grid"
    [class]="themeClass()"
    [rowData]="rowData()"
    [maxBlocksInCache]="1"
    [columnDefs]="columnDefs"
    [rowClassRules]="rowClassRules()"
    [detailRowHeight]="detailRowHeight()"
    [detailRowAutoHeight]="detailRowAutoHeight()"
    [autoSizeStrategy]="autoSizeStrategy()"
    [getContextMenuItems]="getContextMenuItems()"
    [gridOptions]="$any(gridOptions())"
    [groupDefaultExpanded]="groupDefaultExpanded()"
    [rowSelection]="rowSelection()"
    [quickFilterText]="quickFilterText()"
    [statusBar]="statusBar()"
    [suppressCellFocus]="suppressCellFocus()"
    [suppressRowClickSelection]="suppressRowClickSelection()"
    [pagination]="pagination()"
    [paginationPageSize]="paginationPageSize()"
    [domLayout]="'autoHeight'"
    [paginationPageSizeSelector]="paginationPageSizeSelector()"
    [rowModelType]="rowModelType()"
    [enableAdvancedFilter]="enableAdvancedFilter()"
    [cacheBlockSize]="cacheBlockSize()"
    [serverSideDatasource]="$any(serverSideDatasource())"
    [getRowId]="$any(getRowId())"
    [suppressPaginationPanel]="suppressPaginationPanel()"
    [suppressScrollOnNewData]="suppressScrollOnNewData()"
    [defaultExcelExportParams]="defaultExcelExportParams()"
    [defaultCsvExportParams]="defaultCsvExportParams()"
    [excelStyles]="excelStyles()"
    [masterDetail]="masterDetail()"
    [detailCellRenderer]="detailCellRenderer()"
    [detailCellRendererParams]="detailCellRendererParams()"
    [groupDisplayType]="groupDisplayType()"
    (selectionChanged)="onSelectionChanged($event)"
    (paginationChanged)="onPaginationChanged($event)"
    (filterChanged)="onFilterChanged($event)"
    (rowValueChanged)="onCellValueChanged($event)"
    (gridReady)="onGridReady($event)"
    (stateUpdated)="onStateUpdated($event)"
  ></ag-grid-angular>
} @else {
  <ag-grid-angular
    class="custom-ag-grid"
    [class]="themeClass()"
    [rowData]="rowData()"
    [columnDefs]="columnDefs"
    [getRowHeight]="getRowHeight"
    [rowClassRules]="rowClassRules()"
    [detailRowHeight]="detailRowHeight()"
    [detailRowAutoHeight]="detailRowAutoHeight()"
    [autoSizeStrategy]="autoSizeStrategy()"
    [getContextMenuItems]="getContextMenuItems()"
    [gridOptions]="$any(gridOptions())"
    [groupDefaultExpanded]="groupDefaultExpanded()"
    [rowSelection]="rowSelection()"
    [quickFilterText]="quickFilterText()"
    [statusBar]="statusBar()"
    [suppressCellFocus]="suppressCellFocus()"
    [suppressRowClickSelection]="suppressRowClickSelection()"
    [pagination]="pagination()"
    [paginationPageSize]="paginationPageSize()"
    [domLayout]="'autoHeight'"
    [paginationPageSizeSelector]="paginationPageSizeSelector()"
    [rowModelType]="rowModelType()"
    [enableAdvancedFilter]="enableAdvancedFilter()"
    [cacheBlockSize]="cacheBlockSize()"
    [serverSideDatasource]="$any(serverSideDatasource())"
    [getRowId]="$any(getRowId())"
    [suppressPaginationPanel]="suppressPaginationPanel()"
    [suppressScrollOnNewData]="suppressScrollOnNewData()"
    [defaultExcelExportParams]="defaultExcelExportParams()"
    [defaultCsvExportParams]="defaultCsvExportParams()"
    [excelStyles]="excelStyles()"
    [masterDetail]="masterDetail()"
    [detailCellRenderer]="detailCellRenderer()"
    [detailCellRendererParams]="detailCellRendererParams()"
    [groupDisplayType]="groupDisplayType()"
    (selectionChanged)="onSelectionChanged($event)"
    (paginationChanged)="onPaginationChanged($event)"
    (filterChanged)="onFilterChanged($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    (gridReady)="onGridReady($event)"
    (stateUpdated)="onStateUpdated($event)"
  ></ag-grid-angular>
}

@if (pagination()) {
  <app-ag-custom-pagination
    [currentPage]="currentPage"
    [pageNumbers]="pageNumbers"
    [totalPages]="totalPages"
    [paginationPageSize]="paginationPageSize()"
    [paginationPageSizeSelector]="pageSizeList()"
    [showPageSizeSelector]="paginationPageSizeSelector()"
    (previousPage)="goToPreviousPage()"
    (nextPage)="goToNextPage()"
    (selectPage)="goToPage($event)"
  ></app-ag-custom-pagination>
}
