import {
  Component,
  DestroyRef, ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output, Renderer2,
  SimpleChanges, ViewChild
} from '@angular/core';
import { DocumentTypePipe } from '../../../pipes/document-type.pipe';
import { ButtonComponent } from "../common/button/button.component";
import { DividerComponent } from "../common/divider/divider.component";
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbTooltip
} from "@ng-bootstrap/ng-bootstrap";
import { ToggleButtonComponent } from "../common/toggle-button/toggle-button.component";
import { DocumentRecord } from "../../../models/documentRecord";
import { DocumentStatusPipe } from "../../../pipes/enumsPipes/documentStatusPipe";
import { LoaderStandaloneComponent } from "../common/loader-standalone/loader-standalone.component";
import { DocumentStatusEnum } from "../../../models/enums/documentStatusEnum";
import { LowerCasePipe, NgClass } from "@angular/common";
import { CheckboxComponent } from "../common/checkbox/checkbox.component";
import { Router } from "@angular/router";
import { DocumentsService } from "../../../services/documents.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ToastrService } from "ngx-toastr";
import { catchError, of, switchMap, tap, timer } from "rxjs";
import { documentStatusToStep } from "../../helpers/document-status-to-step";
import { TooltipComponent } from "../common/tooltip/tooltip.component";
import { HasRoleDirective } from '../../../directives/has-role.directive';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-company-pending-changes',
  standalone: true,
  imports: [
    ButtonComponent,
    DividerComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    ToggleButtonComponent,
    DocumentStatusPipe,
    LoaderStandaloneComponent,
    NgClass,
    CheckboxComponent,
    LowerCasePipe,
    NgbTooltip,
    DocumentTypePipe,
    TooltipComponent,
    HasRoleDirective
  ],
  templateUrl: './company-pending-changes.component.html',
  styleUrl: './company-pending-changes.component.scss'
})
export class CompanyPendingChangesComponent implements OnChanges {
  @ViewChild('profileCard') private profileCardElRef!: ElementRef<HTMLLabelElement>;
  @Input() trustView = false;
  @Input() pendingButtonState = false;
  @Input() loading = false;
  @Input() disableControls = false;
  @Input() pendingDocuments!: DocumentRecord[];
  @Input() highlightCurrentChanges = false;
  @Output() switchPendingState = new EventEmitter<boolean>();
  @Output() updatePendingDocuments=  new EventEmitter();
  @Output() deletePendingDocuments=  new EventEmitter<string>();
  @Output() openEditForm = new EventEmitter<DocumentRecord>();

  readonly #router: Router = inject(Router);
  readonly #authService = inject(AuthService);
  readonly #documentsService = inject(DocumentsService);
  readonly #toastr = inject(ToastrService);
  readonly #renderer = inject(Renderer2);
  readonly #destroyRef = inject(DestroyRef);

  DocumentStatusEnum = DocumentStatusEnum;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['highlightCurrentChanges']?.currentValue) {
      this.pendingDocuments[0].checked = true;
      this.addDocumentItemAnimation();
    }
  }

  get isAllNotChecked(): boolean {
    return !this.pendingDocuments.some(d => d.checked);
  }

  get isOneMoreChecked(): boolean {
    return this.pendingDocuments.filter(d => d.checked)?.length > 1;
  }

  get isFirst484FormSelected(): boolean {
    return this.highlightCurrentChanges && this.pendingDocuments[0].checked && this.pendingDocuments[0].type.includes('484');
  }

  onSwitchButton(state: boolean): void {
    this.switchPendingState.emit(state);
  }

  deleteChanges(documentId: string): void {
    this.deletePendingDocuments.emit(documentId);
  }

  generateDocuments(): void {
    const checkedPendingDocuments = this.pendingDocuments.filter(d => d.checked);
    const documentIds = checkedPendingDocuments.map(({ documentId }) => documentId);
    const documentStatus = checkedPendingDocuments[0].status ?? 0;

    if(documentIds.length === 1) {
      this.openDocument(documentIds[0], documentStatus);
    } else if(documentIds.length > 1) {
      const isPossibleMerge = checkedPendingDocuments.every(d => (d.type === 'c:484'));
      if(isPossibleMerge) {
        this.#documentsService.mergeDocuments(documentIds).pipe(
          catchError(() => {
            this.#toastr.error('Documents can\'t be merged!');
            return of();
          }),
          takeUntilDestroyed(this.#destroyRef),
        ).subscribe((response) => {
          if (response?.documentId) {
            this.openDocument(response.documentId, 0);
          }
        });
      } else {
        this.#toastr.error('Documents can\'t be merged!');
      }
    }
  }

  addDocumentItemAnimation(): void {
    timer(500).pipe(
      switchMap(() => {
        if(this.profileCardElRef.nativeElement) {
          this.#renderer.addClass(this.profileCardElRef.nativeElement, 'grow');

          return timer(1000).pipe(
            tap( ()=> {
              this.#renderer.removeClass(this.profileCardElRef.nativeElement, 'grow');
              this.#renderer.removeClass(this.profileCardElRef.nativeElement, 'highlight-border');
            })
          )
        }

        return of();
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }

  checkRolesAndOpen(document: DocumentRecord): void {
    const allowedRoles = ['Admin', 'Manager', 'Preparer'];
    const canBeOpened = this.#authService.hasRole(allowedRoles);

    if(!canBeOpened) return;

    if(document.status !== DocumentStatusEnum.Draft){
      this.openDocument(document.documentId, document.status);
    } else {
      this.onOpenEditForm(document);
    }
  }

  openDocument(documentId: string, documentStatus: DocumentStatusEnum | undefined): void {
    const step = documentStatusToStep(documentStatus);
    void this.#router.navigate(['/document', documentId],  { queryParams: { step } });
  }

  onOpenEditForm(document: DocumentRecord): void {
    this.openEditForm.emit(document);
  }
}
