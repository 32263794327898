import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { bytesToMegabytes } from '../../../../../functions/bytes-to-megabytes';
import { downloadBase64File } from '../../../../../functions/download-base64-file';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { toBase64 } from '../../../../../functions/to-base64';
import { FileCreateDto } from '../../../../../models/files';
import { Note, NoteRecord } from '../../../../../models/note';
import { Guid } from '../../../../helpers/guid.helper';
import { ButtonComponent } from '../../../common/button/button.component';
import { DisclaimerComponent } from '../../../common/disclaimer/disclaimer.component';
import { TextareaComponent } from '../../../common/textarea/textarea.component';
import { ValidationErrorComponent } from '../../../common/validation-error/validation-error.component';
import { AttachedFileLabelComponent } from '../attached-file-label/attached-file-label.component';
import { HasRoleDirective } from '../../../../../directives/has-role.directive';

export interface FileCreateDtoWithSize extends FileCreateDto {
  size: number; // in megabytes
}

@Component({
  selector: 'app-create-edit-note-control',
  standalone: true,
  imports: [
    TextareaComponent,
    ReactiveFormsModule,
    ButtonComponent,
    AttachedFileLabelComponent,
    ValidationErrorComponent,
    DisclaimerComponent,
    FormsModule,
    HasRoleDirective
  ],
  templateUrl: './create-edit-note-control.component.html',
  styleUrl: './create-edit-note-control.component.scss'
})
export class CreateEditNoteControlComponent implements OnInit {
  @ViewChild('fileUploadInput') fileUploadInput!: ElementRef<HTMLInputElement>;
  @ViewChild(TextareaComponent) textareaComponent!: TextareaComponent;

  @Input() set note(value: Note | null) {
    this.noteForEdit = value;
    this.form.controls.noteControl.setValue(value?.text ?? '');
    this.attachmentsName = value ? [...value.attachemntsName] : [];
    this.attachmentsToUpload = [];

    if (!value) {
      this.clearForm(true);
    }
  }

  @Input('isLoading') set _isLoading(value: boolean) {
    this.isLoading = value;
    setControlDisabled(this.form, value);
  }

  @Output() downloadFile = new EventEmitter<{ filename: string, note: Note }>();
  @Output() submitNote = new EventEmitter<NoteRecord>();
  @Output() clear = new EventEmitter<void>();

  readonly allowedExtensions = ['.docx', '.pdf', '.xls', '.xlxs', '.txt'].join(', ');
  readonly allowedFilesSummarySizeMb = 10;
  readonly allowedFilesQuantity = 10;
  readonly allowedSingleFileSizeMb = 5;
  readonly clearNotesFormConfirmMessage = 'Clearing the message will permanently delete it. Do you want to continue?';
  readonly cancelEditingConfirmMessage = 'Do you want to proceed?';
  readonly deleteNoteConfirmationMessage = 'Are you sure you would like to delete this file?';
  readonly emptyNoteAlertMessage = 'To save the note, you must enter a text';
  readonly fileSizeOverflowAlertMessage = (filename: string) => `It seems that you have uploaded a file "${ filename }" that exceeds the allowed size. Please ensure that the file is not larger than ${ this.allowedSingleFileSizeMb }MB`;
  readonly filesSizeOverflowAlertMessage = (filesNames: string) => `It seems that you have uploaded a files "${ filesNames }" that exceeds the allowed size. Please ensure that the file is not larger than ${ this.allowedSingleFileSizeMb }MB`;
  readonly filesSummarySizeOverflowAlertMessage = () => `Users can't upload files with a total size greater than ${ this.allowedFilesSummarySizeMb }MB`;
  readonly filesSummaryQuantityOverflowAlertMessage = () => `The maximum number of files to upload is ${ this.allowedFilesQuantity }`;
  readonly fileDuplicateAlertMessage = (filename: string) => `File with name "${ filename }" already attached to the note.`;
  readonly filesDuplicateAlertMessage = (filename: string) => `Files with names "${ filename }" already attached to the note.`;
  readonly customTextareaErrors = { maxLength: 'You\'ve exceeded the 1000 character limit.' };


  noteForEdit: Note | null = null;
  showControl = false;
  isLoading = false;
  attachmentsName: string[] = [];
  attachmentsToUpload: FileCreateDtoWithSize[] = [];
  errorMessage = '';

  form = new FormGroup({
    noteControl: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.maxLength(1000)]
    })
  });

  ngOnInit(): void {
    this.addFileSizeValidator();
    this.addFilesQuantityValidator();
    this.setErrorMessage();
  }

  onSubmitNote(): void {
    const noteText = this.form.controls.noteControl.value?.trim();
    if (!noteText) {
      this.setErrorMessage(this.emptyNoteAlertMessage);
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const noteRecord = new NoteRecord({
      text: this.form.controls.noteControl.value || '',
      attachmentsName: this.attachmentsName,
      attachmentsToUpload: this.attachmentsToUpload,
      id: this.noteForEdit?.id ?? Guid.EmptyGuid,
      entityId: this.noteForEdit?.entityId ?? null,
      individualId: this.noteForEdit?.individualId ?? null,
    });

    this.submitNote.emit(noteRecord);
  }

  tryHandleFileUpload(fileUploadInput: HTMLInputElement): void {
    this.form.markAsTouched();
    this.setErrorMessage();

    if (this.attachmentsToUpload.reduce((sum, file) => sum += file.size, 0) >= this.allowedFilesSummarySizeMb) {
      this.setErrorMessage(this.filesSummarySizeOverflowAlertMessage());
      return;
    }

    this.fileUploadInput.nativeElement.value = '';
    fileUploadInput.click();
  }

  onDownloadFile(index: number): void {
    const attachedFile = this.attachmentsToUpload.find(file => file.fileName === this.attachmentsName[index]);

    if (attachedFile && attachedFile.base64FileContent.length !== 0) {
      downloadBase64File(attachedFile.base64FileContent, attachedFile.fileName);
    } else if (this.noteForEdit) {
      this.downloadFile.emit({ filename: this.attachmentsName[index], note: this.noteForEdit });
    }

    this.setErrorMessage();
  }

  deleteFile(index: number): void {
    if (this.noteForEdit && !confirm(this.deleteNoteConfirmationMessage))
      return;

    this.attachmentsToUpload = this.attachmentsToUpload.filter((_, i) => i !== index);
    this.attachmentsName = this.attachmentsName.filter((_, i) => i !== index);
    this.setErrorMessage();
  }

  setShowControl(state: boolean): void {
    this.showControl = state || !!this.form.controls.noteControl.value;
    this.setErrorMessage();
  }

  clearForm(ignoreConfirmation = false): void {
    if (ignoreConfirmation || confirm(this.noteForEdit ? this.cancelEditingConfirmMessage : this.clearNotesFormConfirmMessage)) {
      this.form.controls.noteControl.setValue('');
      this.form.reset();
      this.form.updateValueAndValidity();
      this.attachmentsName = [];
      this.attachmentsToUpload = [];
      this.setShowControl(false);
      this.setErrorMessage();

      try {
        // can be called before identified
        this.textareaComponent.resetHeight();
      } catch {
      }

      if (!ignoreConfirmation) {
        this.clear.emit();
      }
    }
  }

  setErrorMessage(errorMessage = ''): void {
    this.errorMessage = errorMessage;
  }

  async handleFileUpload(event: Event) {
    const files = Array.from((event.target as unknown as { files: File[] }).files);
    const duplicatedFileNames = files
      .filter(file => this.attachmentsName.includes(file.name))
      .map(file => file.name);

    if (files.length + this.attachmentsName.length > this.allowedFilesQuantity) {
      this.setErrorMessage(this.filesSummaryQuantityOverflowAlertMessage());
      return;
    }

    if (duplicatedFileNames.length) {
      if (duplicatedFileNames.length === 1) {
        this.setErrorMessage(this.fileDuplicateAlertMessage(duplicatedFileNames[0]));
      } else {
        this.setErrorMessage(this.filesDuplicateAlertMessage(duplicatedFileNames.join('", "')));
      }
      return;
    }

    const invalidSizeFiles = files.filter(file => !this.isValidFileSize(file.size));

    if (invalidSizeFiles.length) {
      if (invalidSizeFiles.length === 1) {
        this.setErrorMessage(this.fileSizeOverflowAlertMessage(invalidSizeFiles[0].name));
      } else {
        this.setErrorMessage(this.filesSizeOverflowAlertMessage(invalidSizeFiles.join('", "')));
      }
      return;
    }

    const formattedFiles: FileCreateDtoWithSize[] = await Promise.all(files.map(async (file) => ({
      fileName: file.name,
      base64FileContent: await toBase64(file),
      size: bytesToMegabytes(file.size)
    })));

    const uploadedFilesSummarySize = formattedFiles.reduce((sum, file) => sum += file.size, 0);
    const existingFilesSummarySize = this.attachmentsToUpload.reduce((sum, file) => sum += file.size, 0);
    const summarySizeOfExistingFilesAndNewFiles = uploadedFilesSummarySize + existingFilesSummarySize;

    if (summarySizeOfExistingFilesAndNewFiles >= this.allowedFilesSummarySizeMb) {
      this.setErrorMessage(this.filesSummarySizeOverflowAlertMessage());
      return;
    }

    this.attachmentsToUpload.push(...formattedFiles);
    this.attachmentsName.push(...formattedFiles.map(file => file.fileName));
    this.setErrorMessage();
  }

  private isValidFileSize(fileSize: number): boolean {
    return bytesToMegabytes(fileSize) <= this.allowedSingleFileSizeMb;
  }

  private addFileSizeValidator(): void {
    this.form.addValidators([() => {
      return this.attachmentsToUpload.reduce((sum, file) => sum += file.size, 0) >= this.allowedFilesSummarySizeMb
        ? { overlimitOfFilesSize: true }
        : null;
    }]);
  }

  private addFilesQuantityValidator(): void {
    this.form.addValidators([() => {
      return this.attachmentsName.length > this.allowedFilesQuantity
        ? { tooManyFiles: true }
        : null;
    }]);
  }

  get errorMessageVisible(): boolean {
    return this.form.invalid && this.form.errors !== null;
  }

  get isFormEmpty(): boolean {
    return this.noteForEdit === null
      && this.form.controls.noteControl.value == ''
      && this.attachmentsName.length == 0;
  }

  get textareaRows(): number {
    return this.noteForEdit || this.showControl || this.attachmentsName.length ? 3 : 1;
  }

  get submitButtonDisabled(): boolean {
    return this.isLoading || !this.form.controls.noteControl.value?.trim() || this.form.invalid;
  }
}
