import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appHasRole]',
  standalone: true
})
export class HasRoleDirective {
  private currentRoles: string[] = [];
  private requiredRoles: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {
    this.authService.roles$.subscribe(roles => {
      this.currentRoles = roles;
      this.updateView();
    });
  }

  @Input() set appHasRole(roles: string | string[] | undefined) {
    if (!roles) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.requiredRoles = Array.isArray(roles) ? roles : [roles];
    this.updateView();
  }

  private updateView(): void {
    if (this.requiredRoles.some(role => this.currentRoles.includes(role))) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
