{
  "106": [
    { "key": "Purpose", "value": "Used to withdraw a previously lodged document. Only some documents can be withdrawn and only under particular circumstances" },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "201": [
    { "key": "Declarations", "value": "By completing this form I assent to the following declarations:<br><br>I apply for the registration of a company on the basis of the information in this form and any attachments. I have the necessary written consents and agreements referred to in this application concerning the members and officeholders and I shall give the consents and agreements to the company after the company becomes registered. The information provided in this application and in any annexures is true and correct at the time of signing.<br><br>I declare that I own, or am registering the company for the owner(s) of the identical business name(s), the registration details of which are listed." }
  ],
  "205A": [
    {
      "key": "Purpose",
      "value": "Used by company to notify ASIC that a resolution has been passed or agreed to change the company name."
    },
    { "key": "Applicable Fees", "value": "$474" },
    { "key": "Lodging Period", "value": "Within 14 days after the resolution was passed" },
    { "key": "Late Fees", "value": "Standard late fees apply" }
  ],
  "206": [
    {
      "key": "Purpose",
      "value": "Lodged in conjunction with a Form 205 when a resolution is passed to change company type. The change takes effect 1 month after ASIC publishes a notice, in the gazette, of intention to alter details of the company's registration."
    },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "280": [
    {
      "key": "Purpose",
      "value": "Used to notify details of an intended share buy back. It must be lodged with ASIC before the notice of meeting and proposed resolution and associated documentation is sent to the members."
    },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "281": [
    { "key": "Purpose", "value": "This document is lodged to notify of the intention of a company to carry out a share buy back. It must be lodged at least 14 days prior to passing the resolution if the buy back is conditional on passing the resolution, or, if not, entering the buy back agreement." },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "361": [
    { "key": "Purpose", "value": "Lodged by Registered Agent to notify ASIC that they have ceased to act for a company or companies." },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."},
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "362": [
    { "key": "Purpose", "value": "Used by companies to appoint a registered agent within Australia to receive and manage ASIC correspondence on their behalf, or cease such an appointment. The paper form is combined with Form 486 Notification by a company to nominate or cease a registered agent or contact address." },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."},
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "370": [
    { "key": "Purpose", "value": "Used by officeholder (director or secretary) to notify ASIC of resignation from the company." },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Standard late fees apply" }
  ],
  "388": [
    { "key": "Purpose", "value": "Public, large proprietary, small proprietary foreign-controlled companies, registered schemes and trusts must lodge a copy of financial statements and reports annually accompanied by this form. The form is also used to notify appointment of an auditor to the company (but not a scheme)." },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "410B": [
    { "key": "Purpose", "value": "Reserve a proposed company name" },
    { "key": "Applicable Fees", "value": "New name on change of name $59" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "410F": [
    { "key": "Purpose", "value": "To extend the company name reservation" },
    { "key": "Applicable Fees", "value": "Extension of reservation (all types) $59" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "484 A1": [
    { "key": "Purpose", "value": "Used by company to notify ASIC of the change of address of the registered office, principal place of business, officeholder/s and or shareholder/s." },
    { "key": "Applicable Fees", "value": "Not applicable" },
    { "key": "Lodging Period", "value": "28 days from the date of change" },
    { "key": "Late Fees", "value": "Standard late fees apply" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "484 A2": [
    { "key": "Purpose", "value": "Used by company to notify ASIC of the change of name of an officeholder and/or shareholder. This form is not intended to be used to correct misspelt name of officeholder and/or shareholder. To correct misspelt name, use Form 492 instead." },
    { "key": "Applicable Fees", "value": "Not applicable" },
    { "key": "Lodging Period", "value": "28 days from the date of change" },
    { "key": "Late Fees", "value": "Standard late fees apply" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "484 A3": [
    { "key": "Purpose", "value": "Use this section if there is a change to the ultimate holding company. " },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "28 days from the date change" },
    { "key": "Late Fees", "value": "Standard late fees apply" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "484 B1": [
    { "key": "Purpose", "value": "Used by company to notify ASIC of the cessation of a company officeholder." },
    { "key": "Applicable Fees", "value": "Not applicable" },
    { "key": "Lodging Period", "value": "28 days from the date of change" },
    { "key": "Late Fees", "value": "Standard late fees apply" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "484 B2": [
    { "key": "Purpose", "value": "Use this section to notify if a company officeholder has ceased to be a company officeholder. You need to notify details separately for each ceased officeholder. " },
    { "key": "Applicable Fees", "value": "Not applicable" },
    { "key": "Lodging Period", "value": "28 days from the date of change" },
    { "key": "Late Fees", "value": "Standard late fees apply" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "484 B3": [
    { "key": "Purpose", "value": "Use this section to notify if the company has commenced or ceased status as one of the special purpose company designations." },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "6010": [
    { "key": "Purpose", "value": "Used to apply to ASIC to voluntarily deregister a company" },
    { "key": "Applicable Fees", "value": "$47" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "RA01": [
    { "key": "Purpose", "value": "Used to register as, change details or cease as an ASIC registered agent" },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "RA04": [
    { "key": "Purpose", "value": "Used by Registered Agents to set up direct debit with ASIC" },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "RA 71": [],
  "RA 63": [],
  "492": [
    { "key": "Purpose", "value": "Notify a correction to a previously lodged document that has been fully processed by ASIC." },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "902": [
    { "key": "Purpose", "value": "This document is submitted as supplementary information to an already lodged document that has not been accepted by us." },
    { "key": "Applicable Fees", "value": "No fee" },
    { "key": "Lodging Period", "value": "Not applicable" },
    { "key": "Late Fees", "value": "Not applicable" }
  ],
  "DIVIDEND_STATEMENT": [
    { "key": "Purpose", "value": "If an Australian company distributes profits then the company is obliged to provide a dividend statement or distribution statement detailing the distribution made." }
  ],
  "484C1": [
    { "key": "Purpose", "value": "Used by company to cancel shares" },
    { "key": "Applicable Fees", "value": "Not applicable" },
    { "key": "Lodging Period", "value": "28 days from the date of change" },
    { "key": "Late Fees", "value": "Standard late fees apply" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "484C2": [
    { "key": "Purpose", "value": "Used by company to issue shares" },
    { "key": "Applicable Fees", "value": "Not applicable" },
    { "key": "Lodging Period", "value": "28 days from the date of change" },
    { "key": "Late Fees", "value": "Standard late fees apply" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "484C3": [
    { "key": "Purpose", "value": "Used by company to transfer shares" },
    { "key": "Applicable Fees", "value": "Not applicable" },
    { "key": "Lodging Period", "value": "28 days from the date of change" },
    { "key": "Late Fees", "value": "Standard late fees apply" },
    { "key": "Privacy", "value" : "The information provided to ASIC in this form may include personal information. Please refer to privacy policy (www.asic.gov.au/privacy) for more information about handling your personal information, your rights to seek access to and correct personal information, and to complain about breaches of privacy."}
  ],
  "2205": [
    { "key": "Purpose", "value": "Used by company to convert shares into larger number" },
    { "key": "Applicable Fees", "value": "Not applicable" },
    { "key": "Lodging Period", "value": "Within 1 month of the date on which the resolution was passed" },
    { "key": "Late Fees", "value": "Standard late fees apply" }
  ],
  "Officeholders": [],
  "Shareholders": [],
  "AddTrust": []
}
