import { Injectable, inject, signal } from '@angular/core';
import { environment } from '../environments/environment';
import createKindeClient, { KindeClient, KindeUser } from "@kinde-oss/kinde-auth-pkce-js";
import { OrganisationService } from './organisation.service';
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs';
import { UIProfile } from '../models/uiProfile';
import { KindeProfile } from '../models/kindeProfile';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from "@sentry/angular-ivy";

interface AppState {
  redirectTo?: Location
}

interface KindeRoleClaim {
  id: string;
  key: string;
  name: string;
}

interface Flags {
  SomeBooleanFlag: FeatureFlag<boolean>;
  StringValuedFlag: FeatureFlag<string>;
  TestFlag: FeatureFlag<string>;
}

interface FeatureFlag<T> {
  v: T;
}

interface Role {
  id: string;
  key: string;
  name: string;
}

interface RolesClaim {
  name: string;
  value: Role[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private client!: KindeClient;
  private organisationService = inject(OrganisationService);
  private userProfile!: UIProfile;
  private rolesSubject = new BehaviorSubject<string[]>([]);
  roles$ = this.rolesSubject.asObservable();
  currentUserProfile = signal<UIProfile | null>(null);

  public async init() {
    this.client = await createKindeClient({
      client_id: environment.kinde_client_id,
      domain: environment.kinde_url,
      audience: environment.api_url,
      redirect_uri: window.location.origin,
      on_redirect_callback: this.onUserAuthenticated,
      // in production Custom domain feature should be used to securely store jwt in cookies
      is_dangerously_use_local_storage: !environment.production
    });

    if (this.isAuthenticated) {
      const user = this.kindeUser;
      const profile: KindeProfile = {
        email: user.email!,
        externalUserId: user.id!,
        firstName: user.given_name!,
        lastName: user.family_name!,
        availableOrganisations: this.client.getUserOrganizations().orgCodes
      };

      //can be moved to onUserAuthenticated to speedup load
      await firstValueFrom(this.organisationService.sync(profile).pipe(tap({
        error: error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              void this.logOut();
            }
          }
        }
      })));

      this.userProfile = await firstValueFrom(this.organisationService.getProfile().pipe(tap({
        error: error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              void this.logOut();
            }
          }
        }
      })));

      this.rolesSubject.next(this.roles);

      this.currentUserProfile.set(this.userProfile);

      Sentry.setUser({ email: this.userProfile.email, username: this.userProfile.fullName, organisationId: this.userProfile.organisationId, organisationName: this.userProfile.organisationName });
    }
  }

  public get isSuperuser(): boolean {
    var superUserFlag = this.flags['superuser'];

    if (superUserFlag === undefined) {
      return false;
    }

    return superUserFlag.v as boolean;
  }

  public get profile() {
    return this.userProfile;
  }

  public setOrganisationSetupDone() {
    this.userProfile.organisationSetupRequired = false;
  }

  public async login(orgCode: string | undefined = undefined) {
    await this.client.login({
      org_code: orgCode,
      app_state: {
        redirectTo: window.location.href
      }
    });
  }

  public async register() {
    await this.client.register({
      app_state: {
        redirectTo: window.location.href
      }
    });
  }

  public async logOut() {
    localStorage.clear();
    await this.client.logout();
  }

  private onUserAuthenticated = (user: KindeUser, appState?: AppState) => {
    if (appState?.redirectTo) {
      window.location = appState?.redirectTo;
    }
  }

  private get kindeUser() {
    return this.client.getUser();
  }

  public get flags() {
    return this.client.getClaim('feature_flags')?.value as Flags;
  }

  public get roles(): string[] {

    if (!this.client.getClaim('roles')?.value) {
      return [];
    }

    const roleClaims = (this.client.getClaim('roles')!.value as KindeRoleClaim[]);

    var roles = roleClaims.map(r => r.key);

    if (this.isSuperuser) {
      roles.push('superuser');
    }

    return roles;
  }

  public hasRole(roles: string | string[]): boolean {
    if(this.isSuperuser)
      return true;

    const requiredRoles = Array.isArray(roles) ? roles : [roles];
    return this.roles.some(role => requiredRoles.includes(role));
  }

  public async getToken() {
    return await this.client.getToken();
  }

  public get isAuthenticated() {
    return !!this.client.getUser();
  }

  public get permissions() {
    return this.client.getPermissions().permissions;
  }

  public get featureFlags() {
    const flagClaim = this.client.getClaim('feature_flags');

    if (!flagClaim) {
      return [];
    }

    return flagClaim.value as Flags;
  }

  updateUserProfile(): Observable<UIProfile> {
    return this.organisationService.getProfile().pipe(
      tap(userProfile => this.currentUserProfile.set(userProfile)),
    );
  }
}
