<app-common-offcanvas [opened]="opened()" (close)="closeOffcanvas()">
  <div class="header">
    <h2 class="h2-heading m-0 p-0">
      @if (!isOuterLoading) {
        {{ textMessageRecord?.header }}
      }
    </h2>
    <div class="alert-pagination-container">
      <div class="pages">{{ currentEmailMessageItemNumber }} of {{ totalRows }}</div>
      <div class="arrow-btn-container">
        <app-ag-action-icon-button
          iconClass="ic-arrow-left"
          tooltipText="Previous Email"
          [disabled]="currentEmailMessageItemNumber <= 1 || isMessageActionLoading || isOuterLoading"
          (action)="openPreviousEmail()"
        />

        <app-ag-action-icon-button
          iconClass="ic-arrow-right"
          tooltipText="Next Email"
          [disabled]="currentEmailMessageItemNumber >= totalRows || isMessageActionLoading || isOuterLoading"
          (action)="openNextEmail()"
        />
      </div>
    </div>
  </div>

  @if (textMessageRecord && !isOuterLoading) {
    <div class="content-wrapper">
      <div class="content">
        @if (textMessageRecord.errorMessage) {
          <div class="error-message-wrapper">
            <app-disclaimer color="red" icon="ic-circle-cancel-20">
              {{ textMessageRecord.errorMessage }}
            </app-disclaimer>
          </div>
        }
        <div class="email-date-status">
          <div>
            <div class="title">Date & Time</div>
            <div class="text-gray900 font-weight-bold">
              {{ textMessageRecord.createdOn | date : 'dd MMM yyyy HH:mm' : 'en-US' }}
            </div>
          </div>

          <div>
            <div class="title">Status</div>
            <div>
              <app-ag-outgoing-text-message-status [status]="textMessageRecord.status"/>
            </div>
          </div>
        </div>

        <ul class="recipients-list list-unstyled mb-0">
          @if (textMessageRecord.to) {
            <li class="recipients-group">
              <div class="recipients-type">To:</div>
              <div class="recipients-addresses">{{ textMessageRecord.to }}</div>
            </li>
          }
        </ul>

        <div class="email-preview-wrapper">
          @if (isTextMessageModelLoading) {
            <div class="text-message-content loader">
              <ngx-skeleton-loader style="width: 100%" count="4" appearance="line"/>
            </div>
          } @else if (textMessageModel?.message) {
            <div class="text-message-content" [innerHtml]="textMessageModel?.message"></div>
          }
        </div>
      </div>

      <div class="controls">
        @if (resentEmailDate) {
          <div>
            <div class="title">Email was resent on</div>
            <div>
              {{ resentEmailDate | date : 'dd MMM yyyy HH:mm' : 'en-US' }}
            </div>
          </div>
        }

        @if (textMessageRecord.status === TextMessageStatus.Failed) {
          <app-button
            text="Resend"
            iconClass="icon ic-sent-mail"
            [isDisabled]="isMessageActionLoading"
            (clickBtn)="resendEmail()"
            *appHasRole="['Admin']"
          />
        }

        <app-button
          btnColor="white"
          text="Delete"
          iconClass="icon ic-trash"
          [isDisabled]="isMessageActionLoading"
          (clickBtn)="deleteEmail()"
          *appHasRole="['Admin']"
        />
      </div>
    </div>
  } @else if (isOuterLoading) {
    <app-loader-standalone [loading]="true"/>
  }
</app-common-offcanvas>
