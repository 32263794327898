import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  OnChanges,
  SimpleChanges,
  DestroyRef
} from '@angular/core';
import { ColDef, ValueGetterParams } from "ag-grid-enterprise";
import {
  AgYesNoStatusComponent
} from "../common/grid/components/ag-yes-no-status/ag-yes-no-status.component";
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from '../../../models/securityRegistryRecord';
import { AgHolderComponent } from '../common/grid/components/ag-holder/ag-holder.component';
import { AgHolderBeneficialOwnerComponent } from '../common/grid/components/ag-holder-beneficial-owner/ag-holder-beneficial-owner.component';
import { AgHolderBeneficialOwnerStatusComponent } from '../common/grid/components/ag-holder-beneficial-owner-status/ag-holder-beneficial-owner-status.component';
import { AgOpenActionComponent } from '../common/grid/components/ag-open-action/ag-open-action.component';
import { SecurityService } from '../../../services/security.service';
import { ToastrService } from 'ngx-toastr';
import { ListGridComponent } from '../common/grid/components/list-grid/list-grid.component';
import { SecurityholderProfileComponent } from '../secutityholder-profile/securityholder-profile.component';
import { Relationship } from "../../../models/relationship";
import { IndividualData } from "../../../models/individualData";
import { forkJoin } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  AgHolderNumberOfSharesComponent
} from "../common/grid/components/ag-holder-number-of-shares/ag-holder-number-of-shares.component";

@Component({
  selector: 'app-securityholders',
  templateUrl: './securityholders.component.html',
  styleUrl: './securityholders.component.scss',
  standalone: true,
  imports: [
    SecurityholderProfileComponent,
    ListGridComponent,
  ]
})
export class SecurityholdersComponent implements OnInit, OnChanges {
  @Input({ required: true }) entityId!: string;
  @Input() pendingButtonState: boolean | undefined;
  @Input() previousOfficeholders!: Relationship[];
  @Output() profileViewChange = new EventEmitter<boolean>();
  @Output() selectShareholders = new EventEmitter<Relationship[]>();

  securityService = inject(SecurityService);
  toastr = inject(ToastrService);
  destroyRef = inject(DestroyRef);

  openedSecurityholder?: SecurityRegistryRecord;
  securityRegistry!: SecurityRegistryRecord[];
  previousSecurityRegistry!: SecurityRegistryRecord[];
  readonly defaultPageSize = 20;
  rowSelection: 'single' | 'multiple' = 'multiple';
  openedIndex = -1;

  ngOnChanges(changes: SimpleChanges):void {
    if(changes['pendingButtonState']?.currentValue !== null && changes['pendingButtonState']?.currentValue !== undefined) {
      const pendingButtonState = changes['pendingButtonState'].currentValue as boolean;
      this.updateSecurityRegistryRows(pendingButtonState);
    }
  }

  ngOnInit() {
    this.loadSecurities();

    this.profileViewChange.subscribe((isProfileView: boolean) => {
      if (!isProfileView) {
        this.openedSecurityholder = undefined;
      }
    });
  }

  loadSecurities() {
    const previousData$ = this.securityService.getSecurityRegistry(this.entityId, true, '', false);
    const data$ = this.securityService.getSecurityRegistry(this.entityId);

    forkJoin({ previousData: previousData$, data: data$ }).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: ({ previousData, data }) => {
        this.previousSecurityRegistry = previousData;
        this.securityRegistry = this.getSecurityRegistryRows(data);
        this.updateSecurityRegistryRows(!!this.pendingButtonState);
      },
      error: (error) => {
        this.toastr.error('Error loading securities', 'Error');
        console.error('Error loading securities:', error);
      }
    });
  }

  getSecurityRegistryRows(data: SecurityRegistryRecord[]): SecurityRegistryRecord[] {
    return data.map(item => {
      const updatedHolders = item.holders.map((holder: IndividualHolderModel | CorporateHolderModel) => {
        this.previousOfficeholders.forEach(el => {
          if(el.individualId === (holder as { individualId: string }).individualId) {
            (holder as { previousIndividualData: IndividualData | null }).previousIndividualData = el.individualDataOverride;
            (holder as { pendingButtonState: boolean }).pendingButtonState = !!this.pendingButtonState;
          }
        });
        return holder;
      });

      const updatedHoldings = item.holdings.map(holding => {
        this.previousSecurityRegistry.forEach(el => {
          el.holdings.forEach(prevHolding => {
            if(prevHolding.securityHoldingId === holding.securityHoldingId) {
              holding.previousNumber = prevHolding.number;
            }
          })
        })

        return holding;
      });

      return new SecurityRegistryRecord({
        holders: updatedHolders,
        holdings: updatedHoldings
      });
    });
  }

  updateSecurityRegistryRows(pendingButtonState: boolean): void{
    if(this.securityRegistry?.length) {
      this.securityRegistry.forEach(item => {
        item.holders.forEach((holder: IndividualHolderModel | CorporateHolderModel) => {
          (holder as { pendingButtonState: boolean }).pendingButtonState = pendingButtonState;
        });
      });

      this.securityRegistry = [...this.securityRegistry];

      if (this.openedSecurityholder) {
        const openedIndex = this.securityRegistry.indexOf(this.openedSecurityholder);

        if(openedIndex > -1) {
          this.openedIndex = openedIndex;
        }

        const openedSecurityholder = openedIndex > -1 ? this.securityRegistry[openedIndex] : this.securityRegistry[this.openedIndex];

        this.openedSecurityholder = openedSecurityholder
          ? new SecurityRegistryRecord({ ...openedSecurityholder } as SecurityRegistryRecord)
          : undefined;
      }
    }
  }

  colDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'fullName',
      flex: 3,
      cellRenderer: AgHolderComponent,
      sort: 'asc',
      suppressHeaderMenuButton: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      minWidth: 200
    },
    {
      headerName: 'Beneficial Owner',
      field: 'beneficialOwner',
      flex: 3,
      cellClass: 'spanned-cell p-0',
      cellRenderer: AgHolderBeneficialOwnerComponent,
      suppressHeaderMenuButton: true,
      minWidth: 200
    },
    {
      headerName: 'Beneficially Owned',
      field: 'isBeneficialOwner',
      flex: 1,
      cellClass: 'spanned-cell p-0',
      cellRenderer: AgHolderBeneficialOwnerStatusComponent,
      suppressHeaderMenuButton: true,
      minWidth: 50
    },
    {
      headerName: 'Share Class',
      valueGetter: (params: ValueGetterParams<SecurityRegistryRecord>) => params.data!.holdings[0].securityType.class,
      flex: 1,
      suppressHeaderMenuButton: true,
      minWidth: 50
    },
    {
      headerName: '# of Shares',
      flex: 1,
      cellRenderer: AgHolderNumberOfSharesComponent,
      suppressHeaderMenuButton: true,
      minWidth: 50
    },
    {
      headerName: 'Fully Paid',
      valueGetter: (params: ValueGetterParams<SecurityRegistryRecord>) => params.data!.holdings.every(h => h.fullyPaid),
      flex: 1,
      cellRenderer: AgYesNoStatusComponent,
      suppressHeaderMenuButton: true,
      minWidth: 50
    },
    {
      field: '',
      width: 50,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      resizable: false,
      sortable: false,

      cellClass: 'actions-button-cell',
      cellRenderer: AgOpenActionComponent,
      cellRendererParams: {
        open: this.openSecurityholdersProfile.bind(this)
      },
    }
  ];

  openSecurityholdersProfile(securityholder: SecurityRegistryRecord): void {
    this.openedSecurityholder = securityholder;
    this.profileViewChange.emit(true);
  }

  onSelectShareholders(shareholders: SecurityRegistryRecord[]): void {
    const holders: Relationship[] = shareholders.map(item => {
      const relationship = new Relationship();
      relationship.relationshipId = item.holders[0].relationshipId;
      return relationship;
    });
    this.selectShareholders.emit(holders);
  }
}
