import { IUserRole } from "./userRecord";

export class UserInvitationRecord {
    userInvitationId: string;
    firstName: string;
    middleName: string | undefined;
    lastName: string;
    email: string;
    phoneNumber: string | undefined;
    roles: IUserRole[];
    
    get fullName() {
        return [this.firstName, this.middleName, this.lastName].filter(v => v).join(' ');
    }

    constructor(data: Partial<UserInvitationRecord> = {}) {
      this.userInvitationId = data.userInvitationId!;
      this.firstName = data.firstName!;
      this.middleName = data.middleName;
      this.lastName = data.lastName!;
      this.email = data.email!;
      this.phoneNumber = data.phoneNumber;
      this.roles = data.roles ?? [];
    }
  }