import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyAppointChange } from '../../models/companyAppointChange';
import { CompaniesService } from '../../services/companies.service';
import {
  CsvExportParams,
  ExcelExportParams,
  GetContextMenuItems,
  GridApi,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams
} from "ag-grid-community";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Company } from "../../models/company";
import { ModalFormsService } from '../../services/modal-forms.service';
import { catchError, filter, switchMap, take, throwError } from 'rxjs';
import { ButtonComponent } from '../components/common/button/button.component';
import { GridComponent } from '../components/common/grid/components/grid/grid.component';
import { NotesComponent } from '../components/notes/notes.component';
import { CompanyStatus } from "../../models/enums/companyEnums";
import { Tag } from "../../models/tag";
import { formatDate, NgClass } from "@angular/common";
import { ExportCompanyListComponent } from "../modals/export-company-list/export-company-list.component";
import { ColumnWithExportName } from "../../models/columnWithExportName";
import { ExportTypeEnum } from "../../models/enums/exportTypeEnum";
import { OrganisationService } from "../../services/organisation.service";
import { User } from "../../models/user";
import { FilterOption, FilterParams } from "../../models/gridFilter";
import { ToastrService } from "ngx-toastr";
import { ManageTagsModalComponent } from '../settings/components/tags/manage-tags-modal/manage-tags-modal.component';
import { EntityType } from '../../models/enums/entityType';
import { Alert } from "../../models/alerts/alert";
import {
  AgCompanyActionsComponent
} from "../components/common/grid/components/ag-company-actions/ag-company-actions.component";
import { Router } from "@angular/router";
import { CompanyNameLinkComponent } from "../components/common/company-name-link/company-name-link.component";
import { CompanyAlertDetailsComponent } from "./company-alert-details/company-alert-details.component";
import { AlertsListComponent } from "../components/alerts-list/alerts-list.component";
import { ACNPipe } from "../../pipes/acnPipe";
import {
  BulkCompanyChangeOfficer
} from "../modals/bulk-changes/bulk-appointment-officeholder/BulkCompanyChangeOfficer.model";
import { CompanyChangeOfficerType } from "../../models/enums/companyChangeOfficerType";
import {
  BulkCompanyCeaseRegisteredAgent
} from "../modals/bulk-changes/bulk-cease-registered-agent/BulkCompanyCeaseRegisteredAgent";
import { CompanyRegistration } from "../../models/company-registration";
import { ChangeAddressCompanyList } from "../modals/bulk-changes/bulk-change-address-form/BulkCompanyChangeAddress.model";
import { deepClone } from "fast-json-patch/commonjs/core";
import { HasRoleDirective } from '../../directives/has-role.directive';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrl: './companies.component.scss',
  imports: [
    GridComponent,
    ButtonComponent,
    NotesComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgClass,
    CompanyNameLinkComponent,
    CompanyAlertDetailsComponent,
    AlertsListComponent,
    HasRoleDirective
  ],
  providers: [ACNPipe],
  standalone: true
})
export class CompaniesComponent implements OnInit {
  private companiesService: CompaniesService = inject(CompaniesService);
  private modalFormsService = inject(ModalFormsService);
  private organisationService = inject(OrganisationService);
  private toastr = inject(ToastrService);
  private modalService = inject(NgbModal);
  private router = inject(Router);
  private acnPipe = inject(ACNPipe);
  #destroyRef: DestroyRef = inject(DestroyRef);

  actionColumn!: ColumnWithExportName;
  colDefs = this.companiesService.colDefs;
  $showDetails: WritableSignal<boolean> = signal(false);

  alerts: Alert[] = [];
  currentCompanyIndex = 0;
  currentAlertIndex = 0;
  readonly pageTitle = 'Companies';
  readonly defaultPageSize = 20;
  readonly EntityType = EntityType;
  pageSizeList = [20, 50];
  companiesList: Company[] = [];
  loading = false;
  checkedCompanies: Company[] = [];
  excelExportParams = this.exportParamsXls() as ExcelExportParams;
  csvExportParams = this.exportParamsCsv() as unknown as CsvExportParams;
  excelStyles = this.companiesService.excelStyles;
  ExportTypeEnum = ExportTypeEnum;
  notesVisible = false;
  alertsVisible = false;
  entityForNotes: Company | undefined | null = null;
  entityForAlerts: Company | undefined | null = null;

  ngOnInit() {
    this.actionColumn = {
      field: '',
      width: 150,
      sortable: false,
      cellClass: 'actions-button-cell',
      cellRenderer: AgCompanyActionsComponent,
      cellRendererParams: {
        openCompanyProfile: this.openCompanyProfile.bind(this),
        appointAccountManager: this.appointAccountManager.bind(this),
        exportCompanyProfile: this.exportCompanyProfile.bind(this),
        openAlerts: this.openAlerts.bind(this),
        onOpenNotes: this.openNote.bind(this)
      },
      suppressHeaderMenuButton: true,
    }

    this.loadCompanyList();
    this.companiesService.companyListUpdated$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.companiesService.setCompanyOptions(this.companiesList, this.actionColumn));
  }

  loadCompanyList(): void {
    this.loading = true;
    this.companiesService.list().pipe(
      takeUntilDestroyed(this.#destroyRef),
      catchError((error) => {
        this.loading = false;
        const errorMsg = 'Failed to load companies';
        this.toastr.error(errorMsg);
        console.error(error);
        return throwError(() => new Error(errorMsg));
      })
    ).subscribe(companies => {
      const users = this.organisationService.getCachedProfileUsers();
      this.companiesList = companies.map(c => {
        users.forEach(u => {
          if (u.id === c.accountManagerId) {
            c.accountManager = (u as unknown as User);
          }

          if (u.id === c.partnerManagerId) {
            c.partnerManager = (u as unknown as User);
          }
        })

        return c;
      });

      this.companiesService.setCompanyOptions(this.companiesList, this.actionColumn);
      this.loading = false;
    });
  }

  selectCompanies(companies: Company[]): void {
    this.checkedCompanies = companies;
  }

  resetSelectedRows(): void {
    this.companiesService.gridApi.deselectAll();
  }

  onGridReady(gridApi: GridApi): void {
    this.companiesService.gridApi = gridApi;
  }

  addCompany(): void {
    if (this.modalOpened()) return;
    const modalRef = this.modalFormsService.openModalWithCompany(new CompanyAppointChange(), new Company());

    modalRef.closed.pipe(
      filter((data) => !!data),
      switchMap(() => {
        this.loading = true;
        return this.companiesService.list();
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((res) => {
      this.companiesList = res;
      this.loading = false;
    });
  }

  registerCompany(): void {
    if (this.modalOpened()) return;
    const modalRef = this.modalFormsService.openModalWithCompany(new CompanyRegistration(), new Company());

    modalRef.closed.pipe(
      filter((data) => !!data),
      switchMap(() => {
        this.loading = true;
        return this.companiesService.list();
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((res) => {
      this.companiesList = res;
      this.loading = false;
    });
  }

  manageTags(): void {
    const modalRef = this.modalService.open(ManageTagsModalComponent,
      {
        windowClass: 'manage-tags-modal',
        size: 'xl',
        centered: true
      });
    const instance = modalRef.componentInstance as ManageTagsModalComponent;

    instance.entities = this.checkedCompanies;
    instance.confirm
      .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.loadCompanyList();
        this.selectCompanies([]);
      });
  }

  changeOfAddress(): void {
    const companiesList: ChangeAddressCompanyList[] = this.checkedCompanies.map(company => ({
      entityId: company.entityId,
      entityNumber: company.acn,
      name: company.name,
      dateOfEstablishment: company.dateOfEstablishment
    }));

    void this.router.navigate(['/bulk-changes'], {
      queryParams: { companiesList: JSON.stringify(companiesList) }
    });
  }

  appointOfficeholder(): void {
    if (this.modalOpened()) return;
    const companiesList = this.checkedCompanies.map(company => ({
      acn: company.acn,
      entityId: company.entityId,
      organisationId: company.organisationId,
      name: company.name,
    }));

    const modalRef = this.modalFormsService.openModalWithBulkCompany(new BulkCompanyChangeOfficer({ companiesList }), new Company(), true, {}, {});
    modalRef.result.then(() => { this.resetSelectedRows(); this.selectCompanies([]); }, () => {});
  }

  ceaseOfficeholder(): void {
    if (this.modalOpened()) return;
    const companiesList = this.checkedCompanies.map(company => ({
      acn: company.acn,
      entityId: company.entityId,
      organisationId: company.organisationId,
      name: company.name,
    }));

    const modalRef = this.modalFormsService.openModalWithBulkCompany(
      new BulkCompanyChangeOfficer({ companiesList, actionType: CompanyChangeOfficerType.Removal }),
      new Company(),
      true,
      {},
      {}
    );
    modalRef.result.then(() => { this.resetSelectedRows(); this.selectCompanies([]); }, () => {});
  }

  ceaseRegisteredAgent(): void {
    if (this.modalOpened()) return;
    const companiesList = this.checkedCompanies.map(company => ({
      acn: company.acn,
      entityId: company.entityId,
      organisationId: company.organisationId,
      name: company.name,
    }));

    const modalRef = this.modalFormsService.openModalWithBulkCompany(new BulkCompanyCeaseRegisteredAgent({ companiesList }), new Company(), true, {}, {});
    modalRef.result.then(() => { this.resetSelectedRows(); this.selectCompanies([]); }, () => {});
  }

  exportSelectedCompaniesToXls(): void {
    this.exportCompanyList(true, ExportTypeEnum.EXCEL);
  }

  exportSelectedCompaniesToCsv(): void {
    this.exportCompanyList(true, ExportTypeEnum.CSV);
  }

  getSelectedFilterOptions(columnId: string): string {
    if(!this.companiesService.gridApi) { return ''; }
    const filterParams = this.companiesService.gridApi.getColumn(columnId)?.getColDef().filterParams as FilterParams;
    const filterOptions = filterParams?.filterOptions ?? [];
    const selectedFilterOptions = filterOptions.filter(o => o.active).map(o => o.label).join(', ');
    return selectedFilterOptions;
  }

  exportCompanyList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const componentInstance = this.modalFormsService.openModal(ExportCompanyListComponent, {
      modalDialogClass: 'export-company-list'
    }).componentInstance as ExportCompanyListComponent;

    componentInstance.colDefs = deepClone(this.colDefs()) as ColumnWithExportName[];
    componentInstance.numberOfCompanies = isBulkExport ? this.checkedCompanies.length : this.companiesList.length;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((columnForExport: string[]) => {
      const nameIndex = columnForExport.findIndex(field => field === 'name');
      if(nameIndex >= 0) {
        columnForExport.splice(nameIndex + 1, 0, 'acn');
      }
      const tagIndex = columnForExport.findIndex(field => field === 'tags');
      if(tagIndex >= 0) {
        columnForExport.splice(tagIndex + 1, 0, 'systemTags');
      }

      this.companiesService.numberColumnForExport.set(columnForExport.length - 1);

      const fileName = this.getSelectedFilterOptions('status')
        ? this.getSelectedFilterOptions('status') + ' Company ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
        : 'Company ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US');
      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        const exportParamsXls = this.exportParamsXls() as ExcelExportParams;
        this.companiesService.gridApi.exportDataAsExcel({ ...exportParamsXls, ...params, fileName });
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        this.companiesService.gridApi.exportDataAsCsv({ ...params, fileName });
      }
    });
  }

  exportParamsXls() {
    return {
      ...this.getExportParams(),
      prependContent: this.companiesService.getExcelTitleRows(this.companiesService.numberColumnForExport())
    };
  }

  exportParamsCsv() {
    return this.getExportParams();
  }

  getExportParams() {
    const acnPipe = this.acnPipe;

    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const companyData = params.node?.data as Company;

        if (params.column.getColId() === 'name') {
          return companyData.name;
        } if (params.column.getColId() === 'acn') {
          return acnPipe.transform(companyData.acn);
        } else if (params.column.getColId() === 'status') {
          return CompanyStatus[value as CompanyStatus];
        } else if (params.column.getColId() === 'tags') {
          return (value as Tag[])?.map((tag: Tag) => tag.name)?.join(', ')
        } else if (params.column.getColId() === 'systemTags') {
          return (value as Tag[])?.map((tag: Tag) => tag.name)?.join(', ')
        } else if (params.column.getColId() === 'dateOfEstablishment' || params.column.getColId() === 'nextAnnualReviewDate') {
          return formatDate(value as string ?? '', 'dd MMM yyyy', 'en-US');
        } else if (params.column.getColId() === 'jurisdiction') {
          const filterParams = params.column.getColDef()?.filterParams as { filterOptions?: FilterOption[] };
          const options: FilterOption[] = filterParams?.filterOptions ?? [];
          return options.find(o => o.value == value)?.label ?? ''
        }

        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      sheetName: 'Company',
    }
  }

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {entityId: string}}}): GetContextMenuItems {
    const items = gridApi.defaultItems.slice();

    return ([
      {
        name: 'Open Company Detail in New Tab',
        action: () => {
          const companyId = gridApi.node.data.entityId;
          const url = `/company-profile/${companyId}`;
          window.open(url, '_blank');
        }
      },
      'separator',
      ...items,
    ]) as unknown as GetContextMenuItems
  }

  async openCompanyProfile(company: Company): Promise<void> {
    await this.router.navigate(['/company-profile', company.entityId]);
  }

  appointAccountManager(company: Company): void {
    console.log(company)
  }

  exportCompanyProfile(company: Company): void {
    const columnForExport = this.colDefs()?.filter(c => !c.hide && c.headerName)
      .map(c => c.field) as string[];

    const nameIndex = columnForExport.findIndex(field => field === 'name');
    if(nameIndex >= 0) {
      columnForExport.splice(nameIndex + 1, 0, 'acn');
    }
    const tagIndex = columnForExport.findIndex(field => field === 'tags');
    if(tagIndex >= 0) {
      columnForExport.splice(tagIndex + 1, 0, 'systemTags');
    }
    this.companiesService.numberColumnForExport.set(columnForExport.length - 1);
    const exportParamsXls = this.exportParamsXls() as ExcelExportParams;
    this.companiesService.gridApi.exportDataAsExcel({ ...exportParamsXls, columnKeys: columnForExport, onlySelected: true });
  }

  openAlerts(data: Company): void {
    if (this.modalOpened()) return;
    this.alertsVisible = true;
    this.alerts = data.alerts;
    this.entityForAlerts = data;
  }

  openNote(data: Company): void {
    if (this.modalOpened()) return;
    this.notesVisible = true;
    this.entityForNotes = data;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
