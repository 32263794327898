<div class="action-container" *appHasRole="['Admin', 'Manager']">
  <app-ag-action-icon-button
    iconClass="ic-edit"
    (action)="edit()"
  />

  <div
    ngbDropdown
    class="dropdown d-inline-flex"
    container="body"
    [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
  >
    <app-ag-action-icon-button
      ngbDropdownToggle
      iconClass="ic-download-file"
    />

    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      <div ngbDropdownItem class="dropdown-item" (click)="downloadTemplate()">
        <a class="menu-action-button">
          <span>Download Template</span>
        </a>
      </div>

      <div ngbDropdownItem class="dropdown-item" (click)="downloadPreview()">
        <a class="menu-action-button">
          <span>Download Preview</span>
        </a>
      </div>
    </div>
  </div>
</div>
