import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { environment } from "../environments/environment";
import { IndividualRecord } from "../models/IndividualRecord";
import { Individual } from "../models/individual";
import { map, Subject } from "rxjs";
import { createFilterOptions } from "../app/helpers/create-filter-options.helper";
import { Tag } from "../models/tag";
import { mergeUniqueValues } from "../app/helpers/unique-merge.helper";
import { formatDate } from "@angular/common";
import {
  AgIndividualNameComponent
} from "../app/components/common/grid/components/ag-individual-name/ag-individual-name.component";
import {
  AgCompanyRegistrationDateComponent
} from "../app/components/common/grid/components/ag-company-registration-date/ag-company-registration-date.component";
import { CellDataType } from "../models/enums/agGridEnums";
import { AgEntityTagsComponent } from "../app/components/common/grid/components/ag-company-tag/ag-entity-tags.component";
import { ColumnWithExportName } from "../models/columnWithExportName";
import { ExcelRow, ExcelStyle, GridApi } from "ag-grid-community";
import { FilterOption } from "../models/gridFilter";
import { IndividualData } from "../models/individualData";
import { CountryPipe } from "../pipes/countryPipe";
import { excelReportTitleStyles, excelTitleForReport } from "../app/helpers/excel-title-for-report";

@Injectable({
  providedIn: 'root'
})
export class IndividualService {
  colDefs = signal<ColumnWithExportName[]>([]);
  numberColumnForExport = signal(0);
  individualsTagFilterOptions: FilterOption[] = [];
  individualsSystemTagFilterOptions: FilterOption[] = [];
  individualsBirthdayFilterOptions: FilterOption[] = [];
  gridApi!: GridApi;
  _individualsListUpdated$ = new Subject<void>();
  individualsListUpdated$ = this._individualsListUpdated$.asObservable();

  constructor(private api: HttpClient, private countryPipe: CountryPipe) { }

  public list() {
    return this.api.get<IndividualRecord[]>(`${environment.api_url}/individuals`);
  }

  public get(individualId: string) {
    return this.api.get<Individual>(`${environment.api_url}/individuals/${individualId}`)
      .pipe(map((data) => new Individual(data)));
  }

  public merge(individualIdMergeTo: string, individualsId: string[]) {
    return this.api.post<void>(`${environment.api_url}/individuals/merge/${individualIdMergeTo}`, individualsId);
  }

  public split(individualIdSplitFrom: string, individualsId: string[], individualIdSplitTo?: string ) {
    let params = new HttpParams();
    if (individualIdSplitTo !== undefined) {
      params = params.append('individualIdSplitTo', individualIdSplitTo);
    }
    return this.api.post<void>(`${environment.api_url}/individuals/split/${individualIdSplitFrom}`, individualsId,  {params: params});
  }

  setIndividualOptions(individualList: IndividualRecord[], actionColumn: ColumnWithExportName): void {
    this.individualsTagFilterOptions = createFilterOptions<Tag>(mergeUniqueValues<Tag[]>(individualList.map(c => c.tags), 'tagId'), 'name', 'name');
    this.individualsSystemTagFilterOptions = createFilterOptions<Tag>(mergeUniqueValues<Tag[]>(individualList.map(c => c.systemTags), 'tagId'), 'name', 'name');

    const dob = individualList.filter(individual => individual.dob).map(individual => individual.dob);
    this.individualsBirthdayFilterOptions = [...new Set(dob)].map(date => {
      return {
        label: formatDate(date ?? '','dd MMM yyyy', 'en'),
        value: date ?? '',
        active: false
      }
    });

    this.setGridConfig(actionColumn);
  }

  setGridConfig(actionColumn: ColumnWithExportName): void {
    this.colDefs.set([
      {
        headerName: 'Name',
        field: 'fullName',
        width: 150,
        flex: 1,
        editable: false,
        sort: 'asc',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: AgIndividualNameComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Email address',
        field: 'email',
        width: 150,
        flex: 1,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Mobile number',
        field: 'phone',
        width: 150,
        flex: 1,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Director ID',
        field: 'din',
        width: 150,
        flex: 1,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Date of Birth',
        field: 'dob',
        width: 90,
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.individualsBirthdayFilterOptions,
          valueFormatter: (params: { value: string }) => formatDate(params.value,'dd MMM yyyy', 'en'),
        },
        cellRenderer: AgCompanyRegistrationDateComponent,
        cellDataType: CellDataType.DATE_STRING,
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Place of Birth',
        field: 'birthCity',
        width: 90,
        flex: 1,
        valueFormatter: (params: { data: IndividualData }) => {
          return params.data.birthCity + ', ' + (this.countryPipe.transform(params.data.birthCountry)
            || this.countryPipe.transform('AU-' + params.data.birthCountry));
        },
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Tags',
        field: 'tags',
        width: 150,
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.individualsTagFilterOptions,
        },
        cellRendererParams: {
          allowEdit: true,
          handleAddNewTag: (() => this._individualsListUpdated$.next())
        },
        cellRenderer: AgEntityTagsComponent,
        valueFormatter: (params: { value: Tag }) => params.value?.name ?? '',
        comparator: (tagListA: Tag[], tagListB: Tag[]) => {
          const a = tagListA?.length ?? 0;
          const b = tagListB?.length ?? 0;
          return a - b;
        },
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: '',
        exportColumnName: 'System tags',
        field: 'systemTags',
        width: 180,
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.individualsSystemTagFilterOptions,
        },
        valueFormatter: (params: { value: Tag }) => params.value?.name ?? '',
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        ...actionColumn
      }
    ]);
  }

  getColDefs(): ColumnWithExportName[] {
    return this.colDefs();
  }

  setColDefs(colDefs: ColumnWithExportName[]): void {
    this.colDefs.set(colDefs);
  }

  public getExcelTitleRows: (numberColumnForExport: number) => ExcelRow[] = (numberColumnForExport) =>
    excelTitleForReport(numberColumnForExport, 'Individuals Report');

  public readonly excelStyles: ExcelStyle[] = excelReportTitleStyles;
}
