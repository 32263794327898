import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from "../../components/common/button/button.component";
import { GridComponent } from "../../components/common/grid/components/grid/grid.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import {
  AgAlertsTitleComponent
} from "../../components/common/grid/components/alerts/ag-alerts-title/ag-alerts-title.component";
import {
  AgAlertsCategoryComponent
} from "../../components/common/grid/components/alerts/ag-alerts-category/ag-alerts-category.component";
import { dateTimeComparator } from "../../components/common/grid/functions/date-time-comparator";
import {
  ValueFormatterParams
} from "ag-grid-community";
import { Alert } from "../../../models/alerts/alert";
import { formatDate, NgClass } from "@angular/common";
import { CellDataType } from "../../../models/enums/agGridEnums";
import {
  AgAlertsActionsComponent
} from "../../components/common/grid/components/alerts/ag-alerts-actions/ag-alerts-actions.component";
import { AlertStatus, AlertType, ResolvedStatus } from "../../../models/enums/alertEnums";
import { AlertDetailsComponent } from "../alert-details/alert-details.component";
import {
  AgAlertsStatusComponent
} from "../../components/common/grid/components/alerts/ag-alerts-status/ag-alerts-status.component";
import {
  AgYesNoStatusComponent
} from "../../components/common/grid/components/ag-yes-no-status/ag-yes-no-status.component";
import { BaseAlerts } from "../base-alerts";
import { rightSideExpandDetails } from "../../../animations/right-side-expand-details";
import {
  AgAlertsResolvedStatusComponent
} from "../../components/common/grid/components/alerts/ag-alerts-resolved-status/ag-alerts-resolved-status.component";
import { Tag } from "../../../models/tag";
import { AgEntityTagsComponent } from "../../components/common/grid/components/ag-company-tag/ag-entity-tags.component";
import { AlertRecord } from '../../../models/alerts/alertRecord';
import { HasRoleDirective } from '../../../directives/has-role.directive';

@Component({
  selector: 'app-all-alerts',
  standalone: true,
  imports: [
    ButtonComponent,
    GridComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgClass,
    AlertDetailsComponent,
    HasRoleDirective
  ],
  templateUrl: './all-alerts.component.html',
  styleUrl: './all-alerts.component.scss',
  animations: [rightSideExpandDetails]
})
export class AllAlertsComponent extends BaseAlerts implements OnInit {
  readonly pageTitle = 'All';

  constructor() {
    super();
  }

  async ngOnInit() {
    this.setGridConfig();
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Date',
        field: 'dateCreated',
        sort: 'desc',
        flex: 1.1,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateTimeComparator,
        },
        valueFormatter: (params: ValueFormatterParams<AlertRecord, Date>) => {
          return formatDate(params.value ?? '', 'dd MMM yyyy HH:mm', 'en-US');
        },
        cellDataType: CellDataType.DATE,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Description',
        field: 'modelDetailTitle',
        flex: 2,
        cellRenderer: AgAlertsTitleComponent,
        cellRendererParams: {
          openAlert: this.openAlert.bind(this),
        },
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Type of Alert',
        field: 'type',
        flex: 1,
        cellRenderer: AgAlertsCategoryComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.alertTypeFilterOptions,
          values: Object.values(AlertType).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'State',
        field: 'status',
        flex: 0.7,
        cellRenderer: AgAlertsStatusComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.alertStateFilterOptions,
          values: Object.values(AlertStatus).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Status',
        field: 'resolvedStatus',
        flex: 0.7,
        cellRenderer: AgAlertsResolvedStatusComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.alertStatusFilterOptions,
          values: Object.values(ResolvedStatus).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Entity name',
        field: 'entityName',
        flex: 0.5,
        hide: true,
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Require Attention',
        field: 'needAttention',
        flex: 0.5,
        hide: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.requireAttentionFilterOptions,
          values: [true, false]
        },
        cellRenderer: AgYesNoStatusComponent,
        menuTabs: ['generalMenuTab']
      },
      {
        width: 90,
        sortable: false,
        cellRenderer: AgAlertsActionsComponent,
        cellRendererParams: {
          openAlert: this.openAlert.bind(this),
        },
        suppressHeaderMenuButton: true,
        menuTabs: []
      }
    ];
  }
}
