import {
  booleanAttribute,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  numberAttribute,
  OnInit,
  Output
} from '@angular/core';
import { ButtonComponent } from "../../../button/button.component";
import { ListGridComponent } from "../list-grid/list-grid.component";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { SearchInputComponent } from "../../../search-input/search-input.component";
import { GridColumnsFilterComponent } from "../../../../grid-columns-filter/grid-columns-filter.component";
import {
  CsvExportParams,
  ExcelExportParams,
  GetContextMenuItems,
  GetRowIdFunc,
  IServerSideDatasource,
  GridApi,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  SizeColumnsToContentStrategy,
  ExcelStyle, RowClassRules,
} from "ag-grid-community";
import { ColDef, GridOptions } from "ag-grid-enterprise";
import {
  GridColumnSearchFilterComponent
} from "../../../../grid-column-search-filter/grid-column-search-filter.component";
import {
  ColDefCommunityExtended,
  FilterOption,
  GridFilterModel,
  GroupByOption
} from "../../../../../../models/gridFilter";
import {
  GridServerColumnSearchFilterComponent
} from "../../../../grid-server-column-search-filter/grid-server-column-search-filter.component";
import { PageSizeService } from "../../services/page-size.service";
import { CompaniesService } from "../../../../../../services/companies.service";
import { RowGroupingDisplayType } from "ag-grid-community/dist/lib/entities/gridOptions";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { GridService } from "../../../../../../services/grid.service";

interface DetailRendererParams {
  detailGridOptions: { columnDefs: ColDefCommunityExtended[] }
}

@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [
    ButtonComponent,
    ListGridComponent,
    ReactiveFormsModule,
    SearchInputComponent,
    GridColumnsFilterComponent,
    GridColumnSearchFilterComponent,
    GridServerColumnSearchFilterComponent
  ],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss'
})
export class GridComponent implements OnInit {
  @Input({required: true}) colDefs: ColDefCommunityExtended[] = [];
  @Input({required: true}) rowData: unknown[] = [];
  @Input() totalRows = 0;
  @Input({transform: numberAttribute}) getRowHeight: number | undefined | null;
  @Input() rowClassRules: RowClassRules<unknown> | undefined;
  @Input() gridOptions: GridOptions | undefined;
  @Input() detailRowAutoHeight = false;
  @Input() masterDetail = false;
  @Input() autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy | undefined;
  @Input() pageTitle = '';
  @Input() getContextMenuItems: GetContextMenuItems | undefined;
  @Input() defaultExcelExportParams: ExcelExportParams | undefined;
  @Input() defaultCsvExportParams: CsvExportParams | undefined;
  @Input() excelStyles: ExcelStyle[] = [];
  @Input({ transform: booleanAttribute }) suppressRowClickSelection = true;
  @Input({ transform: booleanAttribute }) suppressCellFocus = true;
  @Input({ transform: booleanAttribute }) pagination = true;
  @Input({ transform: booleanAttribute }) paginationPageSizeSelector = true;
  @Input({ transform: booleanAttribute }) suppressPaginationPanel = true;
  @Input({ transform: booleanAttribute }) suppressScrollOnNewData = true;
  @Input() paginationPageSize = 20;
  @Input() pageSizeList: number[] = [20, 50, 100, 250, 500];
  @Input() enableAdvancedFilter = false;
  @Input() cacheBlockSize = 20;
  @Input() maxBlocksInCache: number | undefined;
  @Input() loading = false;
  @Input() rowModelType: 'serverSide' | 'infinite' | 'clientSide' = 'clientSide';
  @Input() serverSideDatasource: IServerSideDatasource | undefined;
  @Input() getRowId: GetRowIdFunc<unknown> | undefined;
  @Input() groupDisplayType: RowGroupingDisplayType = 'singleColumn';
  @Input() groupByOptions: GroupByOption[] = [];
  @Input() detailCellRenderer: unknown;
  @Input() detailCellRendererParams: unknown;
  @Input() storageKey: string | undefined;

  @Output() itemSelect = new EventEmitter();
  @Output() search = new EventEmitter<string>();
  @Output() selectGroupByOption = new EventEmitter<number>();
  @Output() clearGroupByOption = new EventEmitter<boolean>();
  @Output() gridReady = new EventEmitter();

  pageSizeService = inject(PageSizeService);
  companiesService = inject(CompaniesService);
  gridService = inject(GridService);
  #fb = inject(FormBuilder);
  #destroyRef: DestroyRef = inject(DestroyRef);

  gridApi!: GridApi;
  rowSelection: 'single' | 'multiple' = 'multiple';
  readonly defaultPageSize = 20;
  detailColDefs: ColDefCommunityExtended[] = [];
  rowDataCopy: unknown[] = [];

  form = this.#fb.group({
    search: [''],
  });

  ngOnInit(): void {
    if(this.rowModelType === 'serverSide') {
      this.form.get('search')?.valueChanges
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(search => this.search.emit(search ?? ''));
    }

    if((this.detailCellRendererParams as DetailRendererParams)?.detailGridOptions?.columnDefs) {
      this.detailColDefs = (this.detailCellRendererParams as DetailRendererParams).detailGridOptions.columnDefs;
      if (!this.rowDataCopy.length) {
        this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData)) as unknown[];
      }
    }
  }

  getSavedColumnState(): any[] | null {
    const columnState = this.storageKey ? localStorage.getItem(this.storageKey) : null;
    return columnState ? JSON.parse(columnState) : null;
  }

  onCheckboxChange(colDef: ColDef): void {
    this.colDefs = this.colDefs.map((c) => ({ ...c, hide: c.field === colDef.field ? !c.hide : c.hide }));
    this.companiesService.setColDefs(this.colDefs);
    this.gridApi?.setColumnsVisible([colDef.field as unknown as string], !colDef.hide);
  }

  onGroupCheckboxChange(colDef: ColDefCommunityExtended): void {
    this.colDefs = this.colDefs.map((c) => {
      return c?.groupId === colDef.groupId ? colDef : c;
    });

    colDef?.children?.forEach(c => {
      if(c.field) {
        this.gridApi?.setColumnsVisible([c.field], !colDef.hide);
      }
    });
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
    this.initFilter();
    this.gridReady.emit(this.gridApi);
  }

  get quickFilterText(): string {
    return this.form.get('search')!.value!;
  }

  onSelect(value: unknown): void {
    this.itemSelect.emit(value);
  }

  applyGroupFilter(): void {
    this.rowData = JSON.parse(JSON.stringify(this.rowDataCopy)) as unknown[];

    const filterModel: GridFilterModel = this.gridService.getGridFilterModelState();
    this.rowData = (this.rowData as { callRecords: Record<string, unknown>[] }[]).map(data => {
      Object.keys(filterModel).forEach(key => {
        const keys = key.split('.');
        const filteredCallRecords = data.callRecords.filter(record => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const callRecordValue = keys.reduce((obj, prop) => obj[prop], record);
          return filterModel[key].values?.includes(callRecordValue);
        });
        data = { ...data, callRecords: filteredCallRecords };
      })

      return data;
    });

    this.rowData = (this.rowData as { callRecords: Record<string, unknown>[] }[]).filter(row => row.callRecords.length);
  }

  private initFilter(): void {
    const initialFilterModel: GridFilterModel = {};
    const savedState = this.getSavedColumnState();

    this.colDefs.forEach((c: ColDefCommunityExtended) => {
      const field = c.field ?? '';
      const options: FilterOption[] = c.filterParams?.filterOptions;
      const activeOptions = options?.filter(option => option?.active);

      if(savedState) {
        const columnState = savedState.find((state: { colId: string }) => state.colId === field);
        if(columnState) {
          c.hide = columnState.hide;
        }
      }

      if (activeOptions?.length) {
        c.filterTagShowed = true;
        initialFilterModel[field] = { filterType: 'set', values: activeOptions.map(option => option.value) };
      } else {
        c.filterTagShowed = false;
      }
    });

    this.gridApi?.setFilterModel(initialFilterModel);
  }
}
