<p>First Name: {{this.authService.profile.firstName}}</p>
<p>Last Name: {{this.authService.profile.lastName}}</p>
<p>Email: {{this.authService.profile.email}}</p>
<p>Phone Number: {{this.authService.profile.phoneNumber}}</p>
<p>User Id: {{this.authService.profile.userId}}</p>
<p>Organisation Name: {{this.authService.profile.organisationName}}</p>
<p>External User Id: {{this.authService.profile.externalUserId}}</p>
<p>Organisation Id: {{this.authService.profile.organisationId}}</p>
<p>External Organisation Id: {{this.authService.profile.externalOrganisationId}}</p>
<p>Organisation Address: {{this.authService.profile.address?.normalizedFullAddress}}</p>


<h5>Can switch to:</h5>
<p *ngFor="let org of this.authService.profile.availableOrganisations">{{org.organisationName}} | {{org.organisationId}} | {{org.organisationExternalId}}</p>

<h5>Users on current organisation:</h5>
<p *ngFor="let user of this.authService.currentUserProfile()?.users">{{user.id}} | {{user.fullName}} | {{user.email}}</p>

<h5>Roles:</h5>
<p *ngFor="let role of this.authService.roles">{{role}}</p>

<h5>Permissions:</h5>
<p *ngFor="let role of this.authService.permissions">{{role}}</p>

<h5>Feature Flags:</h5>
@if (this.authService.featureFlags)
{
    <p *ngFor="let flag of Object.keys(this.authService.featureFlags)">{{flag}}: {{this.authService.featureFlags[flag].v}}</p>
}
