<app-stepper-form
  header="Change of Address"
  subheader="Form 484A1"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [isLoading]="isLoading"
  [showSteps]="false"
>
  <div class="step-content">
    <app-stepper-form-description
      formKey="484 A1"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroup]="form"
      [hidden]="currentStep !== StepsEnum.NewAddress"
    >
      <h1 class="h1-heading">New Address</h1>

      <div class="form-group">
        <label class="sub-heading">This new address is for either</label>

        <div class="mb-2">
          <app-checkbox
            label="Registered Address"
            formControlName="applyToRegistered"
            (change)="checkValidity()"
          ></app-checkbox>
        </div>

        <div class="mb-2">
          <app-checkbox
            label="Principal Place of Business"
            formControlName="applyToPrincipal"
            (change)="checkValidity()"
          ></app-checkbox>
        </div>
      </div>

      <div
        class="form-group"
        formArrayName="relationshipIds"
        [hidden]="!officersSelectOptions.length"
      >
        <label class="sub-heading">Select officeholder for address changing</label>

        <ul class="form-group select-officer-form-group">
          @for (officerOption of officersSelectOptions; track officerOption.value; let index = $index) {
            <li class="officer-item">
              <app-checkbox
                [label]="officerOption.label"
                [formControlName]="index"
                (change)="checkValidity()"
              ></app-checkbox>
            </li>
          }
        </ul>
      </div>

      <div
        class="form-group"
        formArrayName="relationshipIds"
        [hidden]="isCheckboxErrorHidden || isLoading || (!form.errors?.['required'] && !form.errors?.['careOfResidential'])"
      >
        <app-validation-error
          [control]="form"
          [customErrors]="customErrors"
        ></app-validation-error>
      </div>

      @if (isOfficeholderWasSelected) {
        <div class="annotation">
          <div><i class="ic-alert-triangle ic-md icon"></i></div>
          <div>
            Note that if the officeholder or shareholder you have selected is associated with another company, you can
            use the Bulk Change feature to apply any address changes.
          </div>
        </div>
      }

      <app-address-and-occupier-form-group
        [formGroup]="form"
        [minDateOfAddressChange]="minDate"
        [maxDateOfAddressChange]="maxDate"
        [useInternationalAddresses]="true"
        [occupierPartHidden]="occupierPartHidden"
        [customDatepickerErrors]="customDatepickerErrors"
        [careOfAllowed]="true"
      >
        <div [hidden]="isCheckboxErrorHidden || isLoading || form.errors?.['required'] || form.errors?.['careOfResidential']">
          <app-validation-error
            [control]="form"
            [customErrors]="customErrors"
          ></app-validation-error>
        </div>
      </app-address-and-occupier-form-group>
    </section>
  </div>
</app-stepper-form>
