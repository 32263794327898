import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import { InputComponent } from "../../../../../components/common/input/input.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OrganisationService } from "../../../../../../services/organisation.service";
import { CustomFormValidators } from "../../../../../../custom-form-validators/custom-form-validators";
import {
  InputPhoneNumberComponent
} from "../../../../../components/common/input-phone-number/input-phone-number.component";
import {
  AddressFormGroupComponent
} from "../../../../../components/reusable-form-groups/address-form-group/address-form-group.component";
import { SelectComponent } from "../../../../../components/common/select/select.component";
import { SelectOption } from "../../../../../../models/selectOptions";
import { DividerComponent } from "../../../../../components/common/divider/divider.component";
import { OrganisationModel } from "../../../../../../models/organisationModel";
import { ToastrService } from "ngx-toastr";
import { AuthService } from '../../../../../../services/auth.service';
import { Address } from "../../../../../../models/address";
import { DisclaimerComponent } from "../../../../../components/common/disclaimer/disclaimer.component";
import { UsersService } from '../../../../../../services/users.service';

@Component({
  selector: 'app-edit-organisation-details-page-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    SelectComponent,
    DividerComponent,
    InputPhoneNumberComponent,
    AddressFormGroupComponent,
    CommonModalFormComponent,
    DisclaimerComponent,
  ],
  templateUrl: './edit-organisation-profile-modal.component.html',
  styleUrl: './edit-organisation-profile-modal.component.scss'
})
export class EditOrganisationProfileModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private organisationService = inject(OrganisationService);
  private usersService = inject(UsersService);
  private cdr = inject(ChangeDetectorRef);
  private toastr = inject(ToastrService);
  private authService = inject(AuthService);

  @Input() organisation!: OrganisationModel;

  isLoading = false;
  accountsSelectOptions: SelectOption[] = [];
  urlPatternValidatorCustomError = { urlPattern: `The website URL format is incorrect. Please include 'http://' or 'https://` };

  form = new FormGroup({
    name: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.maxLength(50)]),
    email: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.maxLength(50)]),
    phone: new FormControl<string | null>(''),
    address: AddressFormGroupComponent.defineForm(),
    website: new FormControl<string | null>(null, [CustomFormValidators.url, CustomFormValidators.maxLength(50)]),
    primaryContactId: new FormControl<string | null>(null, [CustomFormValidators.maxLength(50)]), //todo: make required if not first setup
    billingContactId: new FormControl<string | null>(null, [CustomFormValidators.maxLength(50)]), //todo: make required if not first setup
  });

  ngOnInit(): void {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog overflow-y-auto' });
    this.setupProfile();
    this.loadAccounts();
  }

  setupProfile(): void {
    this.form.patchValue({ ...this.organisation, address: this.organisation.address ?? new Address() });
  }

  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const payload = new OrganisationModel({
      ...this.form.value as Partial<OrganisationModel>,
      primaryContactId: this.organisationSetupRequired ? this.authService.currentUserProfile()?.userId : this.form.value.primaryContactId,
      billingContactId: this.organisationSetupRequired ? this.authService.currentUserProfile()?.userId : this.form.value.billingContactId,
    });

    this.isLoading = true;
    this.form.disable();

    this.organisationService.save(payload)
      .subscribe({
        next: (externalOrganizationId: string) => {
          this.activeModal.close();
          this.toastr.success('Data saved successfully', 'Success');

          if (this.authService.profile.organisationSetupRequired) {
            void this.authService.login(externalOrganizationId);
          }
        },  
        error: () => {
          this.isLoading = false;
          this.form.enable();
        }
      });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  private loadAccounts(): void {
    if (this.organisationSetupRequired && this.authService.currentUserProfile()) {
      const userId = this.authService.currentUserProfile()?.userId ?? '';

      this.accountsSelectOptions = [{
        label: [this.authService.currentUserProfile()?.firstName, this.authService.currentUserProfile()?.middleName, this.authService.currentUserProfile()?.lastName].filter(Boolean).join(' ') ?? '',
        value: userId
      }];

      this.form.patchValue({
        billingContactId: userId ?? null,
        primaryContactId: userId ?? null,
      });
    } else {
      this.usersService.getAssignableUsersCached()
        .subscribe((users) => {
          this.accountsSelectOptions = users.map((user) => ({
            label: user.fullName,
            value: user.id
          })) ?? [];
          this.cdr.detectChanges();
          this.form.patchValue({
            primaryContactId: this.form.value.primaryContactId,
            billingContactId: this.form.value.billingContactId,
          });
      });
    }
  }

  get organisationSetupRequired(): boolean {
    return this.authService.currentUserProfile()?.organisationSetupRequired ?? false;
  }
}
