import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, distinctUntilChanged, startWith } from 'rxjs';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { Address } from '../../../../../models/address';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { RelationshipType, RelationshipTypeLabels } from '../../../../../models/enums/relationshipTypeEnum';
import { IndividualData } from '../../../../../models/individualData';
import { Relationship } from '../../../../../models/relationship';
import { SelectOption } from '../../../../../models/selectOptions';
import { ACNPipe } from '../../../../../pipes/acnPipe';
import { DatePickerComponent } from '../../../../components/common/date-picker/date-picker.component';
import { SelectComponent } from '../../../../components/common/select/select.component';
import { TextareaComponent } from '../../../../components/common/textarea/textarea.component';
import { YesNoControlComponent } from '../../../../components/common/yes-no-control-component/yes-no-control.component';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../../../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../../../stepper-form/stepper-form.component';
import { CompanyChangeOfficerResignation } from './CompanyChangeOfficerResignation.model';
import {
  IndividualDataFormGroup,
  IndividualDataFormGroupComponent
} from "../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import { parseFullName } from "../../../../../functions/parse-fullname";
import { CheckboxComponent } from "../../../../components/common/checkbox/checkbox.component";
import { clearFormArray } from "../../../../../functions/clear-form-array";
import { ValidationErrorComponent } from "../../../../components/common/validation-error/validation-error.component";
import { NotificationComponent } from "../../../../components/common/notification/notification.component";
import { ParsedName } from "../../../../../models/parsedName";
import { positionTypesOptions } from "../../../officer-appointment/officer-appointment.component";

export enum ResignationOrRetirementStepsEnum {
  FormDescription = 0,
  ResignationDetails = 1
}

export interface ResignationOrRetirementControls {
  resignationLetterGiven: FormControl<Date | null>;          // date the letter handed in
  changeDate: FormControl<Date | null>;                      // date of cessation
  isFormerOfficerResignation: FormControl<boolean | null>;
  resignationLetterText: FormControl<string | null>;
  appointedOfficerId: FormControl<string | null>;
  formerOfficeholder: IndividualDataFormGroup;
  types: FormArray<FormControl<boolean>>;
}

export type ResignationOrRetirementFormGroup = FormGroup<ResignationOrRetirementControls>;

@Component({
  selector: 'app-form-370-officeholder-resignation-or-retirement',
  standalone: true,
  imports: [
    StepperFormComponent,
    StepperFormDescriptionComponent,
    ReactiveFormsModule,
    DatePickerComponent,
    SelectComponent,
    TextareaComponent,
    YesNoControlComponent,
    IndividualDataFormGroupComponent,
    CheckboxComponent,
    ValidationErrorComponent,
    NotificationComponent,
  ],
  templateUrl: './form370-officeholder-resignation-or-retirement.component.html',
  styleUrl: '../../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
})
export class Form370OfficeholderResignationOrRetirementComponent extends BaseStepperFormComponent<ResignationOrRetirementStepsEnum, CompanyChangeOfficerResignation> implements OnInit, AfterViewInit {
  cdr = inject(ChangeDetectorRef);

  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officersSelectOptions: SelectOption[] = [];
  @Input() moreThenOneDirectorExist: boolean = false;

  override readonly StepsEnum = ResignationOrRetirementStepsEnum;
  readonly ceaseWarningMsg = 'ASIC does not allow a sole director to resign without appointing a successor first.';

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  protected readonly positionTypesOptions = positionTypesOptions;
  officerPositionTypesOptions: SelectOption[] = [];

  form: ResignationOrRetirementFormGroup = new FormGroup({
    resignationLetterGiven: new FormControl<Date | null>(null),
    changeDate: new FormControl<Date | null>(null),
    isFormerOfficerResignation: new FormControl<boolean | null>(null, [Validators.required]),
    appointedOfficerId: new FormControl<string | null>(null, [Validators.required]),
    formerOfficeholder: IndividualDataFormGroupComponent.defineForm(),

    resignationLetterText: new FormControl<string | null>(null, [Validators.required]),

    types: new FormArray<FormControl<boolean>>([])
  }) as ResignationOrRetirementFormGroup;

  override stepperForm = new FormGroup({
    [ResignationOrRetirementStepsEnum.FormDescription]: new FormGroup({}),
    [ResignationOrRetirementStepsEnum.ResignationDetails]: this.form,
  });

  constructor() {
    super();
    this.setupSteps(ResignationOrRetirementStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());
    this.form.controls.resignationLetterGiven.setValue(DatepickerHelper.getToday());
    this.setTypeControls(this.positionTypesOptions);

    this.listenSelectedExistingOfficerChanges();
    this.listenAppointedOfficerChanges();
    this.listenResignationLetterTextPartsChanges();

    this.setTypesValidators();
    this.setTheOnlyDirectorResignationValidator();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes.find((change) => change.$type === CompanyChangeOfficerResignation.$type) as CompanyChangeOfficerResignation);
  }

  override setupChange(change: CompanyChangeOfficerResignation = this.formModel): void {
    if (!this.isEdit && change === this.formModel)
      return;

    this.form.patchValue(change);
    if (change.isFormerOfficerResignation) {

      this.form.patchValue({
        isFormerOfficerResignation: change.isFormerOfficerResignation,
        formerOfficeholder: {
          ...change.officerDataIfFormerOfficer,
        },
      });
    } else if (!change.isFormerOfficerResignation) {
      this.form.patchValue({
        ...change as any,
        appointedOfficerId: change.relationships[0].key,
      });
    }

    this.updateOfficeholderPositionTypesOptions();
    let types: RelationshipType[] = [];
    if (change.isFormerOfficerResignation) {
      types = change.relationshipTypesIfFormerOfficer as RelationshipType[];
      this.setPositionTypesOptionsFromChanges(this.positionTypesOptions, types);
    } else if (!change.isFormerOfficerResignation) {
      types = change.relationships.map((r) => r.type);
      this.setPositionTypesOptionsFromChanges(this.officerPositionTypesOptions, types);
    }

    this.form.controls.resignationLetterText.setValue(change.resignationLetterText);
  }

  private setPositionTypesOptionsFromChanges(positionTypesOptions: SelectOption[], types:RelationshipType[]): void {
    positionTypesOptions.forEach((positionOption, index) => {
      this.form.controls.types.at(index).setValue(types.includes(positionOption.value as RelationshipType));
    });
  }

  override buildDocument(): Document | null {
    const formValue = this.form.value;
    const change = new CompanyChangeOfficerResignation(formValue as Partial<CompanyChangeOfficerResignation>);

    if (!formValue.isFormerOfficerResignation && formValue.appointedOfficerId) {
      change.relationships = this.officers[formValue.appointedOfficerId].filter((relationship) => this.selectedTypes.includes(relationship.type));
    } else if (formValue.isFormerOfficerResignation) {
      const formerOfficeholderNames = parseFullName(this.form.controls.formerOfficeholder.controls.fullName.value ?? '');
      const formerName = parseFullName(this.form.controls.formerOfficeholder.controls.formerFullName.value ?? '') as ParsedName;
      change.officerDataIfFormerOfficer = new IndividualData({
        ...formValue.formerOfficeholder,
        ...formerOfficeholderNames,
        formerName,
        dob: formValue.formerOfficeholder?.dob,
      } as Partial<IndividualData>);
      change.relationshipTypesIfFormerOfficer = this.selectedTypes;
    }

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData.entityId,
        type: 'c:370',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private listenSelectedExistingOfficerChanges(): void {
    this.form.controls.isFormerOfficerResignation.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((isFormerOfficerResignation) => {
        setControlDisabled(this.form.controls.formerOfficeholder, isFormerOfficerResignation === false);
        setControlDisabled(this.form.controls.appointedOfficerId, isFormerOfficerResignation === true);
        this.form.controls.resignationLetterText.reset();
        this.cdr.detectChanges();

        this.updateOfficeholderPositionTypesOptions();
      });

    this.form.controls.appointedOfficerId.valueChanges.subscribe(() => {
      this.updateOfficeholderPositionTypesOptions();
    });
  }

  private listenAppointedOfficerChanges(): void {
    combineLatest([
      this.form.controls.appointedOfficerId.valueChanges,
      this.form.controls.changeDate.valueChanges.pipe(startWith(this.form.value.changeDate)),
      this.form.controls.types.valueChanges,
    ])
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(([officerId, changeDate]) => {
        if (this.form.controls.isFormerOfficerResignation.value)
          return;

        if (officerId) {
          const officer = new Relationship({ ...this.officers[officerId][0], end: changeDate! });
          officer.key = this.officers[officerId][0].key;
          this.form.controls.resignationLetterText.patchValue(this.fillResignationLetter(officer));
        } else {
          this.form.controls.resignationLetterText.reset();
        }
      });
  }

  private listenResignationLetterTextPartsChanges(): void {
    this.cdr.detectChanges();
    combineLatest(
      this.form.controls.formerOfficeholder.controls.fullName.valueChanges,
      this.form.controls.formerOfficeholder.controls.address.valueChanges,
      this.form.controls.types.valueChanges,
      this.form.controls.changeDate.valueChanges.pipe(startWith(this.form.value.changeDate)),
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([fullName, address, types, changeDate]) => {
        if (!this.form.controls.isFormerOfficerResignation.value)
          return;

        const parsedName = parseFullName(fullName ?? '');
        const relationship = new Relationship({
          type: types[0] as unknown as RelationshipType,
          end: changeDate!,
          individualDataOverride: new IndividualData({
            firstName: parsedName.firstName,
            lastName: parsedName.lastName,
            address: new Address(this.form.controls.formerOfficeholder.controls.address.value as Partial<Address>),
          }),
        });
        this.form.controls.resignationLetterText.setValue(this.fillResignationLetter(relationship));
        this.cdr.detectChanges();
      });
  }

  private fillResignationLetter(officer: Relationship): string {
    // 'I, [Name of the officeholder resigning] of [Address] hereby resign as [Role] of [Name of company and ACN] effective from [Date of resignation].'
    let result = '';

    const name = `I, ${ officer.individualDataOverride?.fullName?.trim() }`;
    const address = officer.individualDataOverride?.address?.normalizedFullAddress?.trim() ?? '';
    const roles = this.selectedTypes.map((role) => RelationshipTypeLabels[role]);
    const nameOfCompanyAndACN = this.companyChangeData.companyName + (this.companyChangeData.companyAcn ? ` - ${ new ACNPipe().transform(this.companyChangeData.companyAcn) }` : '');
    const dateOfResignation = DatepickerHelper.toString(officer.end ?? this.form.value.changeDate!);

    result += name;
    result += address ? ` of ${ address }` : '';
    result += roles.length ? `, hereby resign as ${ roles.join(', ') }` : '';
    result += ` of ${ nameOfCompanyAndACN }`;
    result += dateOfResignation ? `, effective from ${ dateOfResignation }` : '';
    result += '.';

    return result;
  }

  private setTypesValidators(): void {
    this.form.controls.types.addValidators((control) => {
      const controlsValue = (control.value ?? []) as boolean[];

      return controlsValue.some((value) => value)
        ? null
        : { requiredAtLeastOne: true };
    });
  }

  private setTheOnlyDirectorResignationValidator(): void {
    this.form.addValidators((control: AbstractControl) => {
      return this.isTheOnlyDirectorSelected
        ? { isTheOnlyDirectorSelected: true }
        : null;
    });
  }

  private updateOfficeholderPositionTypesOptions(): void {
   if (this.form.controls.appointedOfficerId.value) {
     const typeSet = new Set<RelationshipType>();
     this.officerPositionTypesOptions = (this.officers[this.form.controls.appointedOfficerId.value] ?? [])
       .filter((relationship) => {
         if (typeSet.has(relationship.type)) return false;
         typeSet.add(relationship.type);
         return true;
       })
       .map((relationship) => ({
         label: RelationshipTypeLabels[relationship.type],
         value: relationship.type
       }));
   } else if(!this.form.controls.isFormerOfficerResignation.value) {
     this.officerPositionTypesOptions = [];
   }

   if(!this.form.controls.isFormerOfficerResignation.value) {
     this.setTypeControls(this.officerPositionTypesOptions);
   } else {
     this.setTypeControls(this.positionTypesOptions);
   }
  }

  private setTypeControls(positionTypesOptions: SelectOption[]): void {
    const allowedPositionsControls = positionTypesOptions.map(() => new FormControl<boolean>(false, { nonNullable: true }));
    clearFormArray(this.form.controls.types);
    allowedPositionsControls.forEach((positionControl) => this.form.controls.types.push(positionControl));
  }

  private selectTypes(positionTypesOptions: SelectOption[]): RelationshipType[] {
    return positionTypesOptions
      .map((positionOptions, index) => {
        return this.form.controls.types.at(index)?.value
          ? positionOptions.value
          : null;
      })
      .filter((relationshipType) => relationshipType !== null) as RelationshipType[];
  }

  get selectedTypes(): RelationshipType[] {
    if(!this.form.controls.isFormerOfficerResignation.value) {
      return this.selectTypes(this.officerPositionTypesOptions);
    } else {
      return this.selectTypes(this.positionTypesOptions);
    }
  }

  get isTheOnlyDirectorSelected(): boolean {
    return Boolean(!this.moreThenOneDirectorExist
      && this.form.controls.appointedOfficerId.value
      && this.selectedTypes.includes(RelationshipType.Director));
  }
}
