import { ExcelRow, ExcelStyle } from "ag-grid-community";
import { formatDate } from "@angular/common";

export function excelTitleForReport(numberColumnForExport: number, title: string): ExcelRow[] {
  const currentDate = formatDate(new Date(), 'dd/MM/yyyy', 'en-US');

  return [
    {
      cells: [
        {
          data: {
            value: title,
            type: 'String',
          },
          mergeAcross: numberColumnForExport,
          styleId: 'titleStyle',
        },
      ],
    },
    {
      cells: [
        {
          data: {
            value: `As of ${currentDate}`,
            type: 'String',
          },
          mergeAcross: numberColumnForExport,
          styleId: 'dateStyle',
        },
      ],
    },
  ];
}

export const excelReportTitleStyles: ExcelStyle[] = [
  {
    id: 'titleStyle',
    font: {
      size: 18,
      color: '#EA6E24',
      bold: true,
    },
    alignment: {
      horizontal: 'Center',
    },
  },
  {
    id: 'dateStyle',
    font: {
      size: 14,
      color: '#EA6E24',
    },
    alignment: {
      horizontal: 'Center',
    },
  },
  {
    id: "header",
    interior: {
      color: "#aaaaaa",
      pattern: "Solid",
    },
    font: {
      size: 11,
      bold: true,
    },
  },
];
