import { Component, DestroyRef, inject, signal } from '@angular/core';
import { ListGridComponent } from "../../list-grid/list-grid.component";
import {
  ColGroupDef, CsvExportParams,
  ExcelExportParams,
  GridApi,
  ICellRendererParams, RowClassRules,
} from "ag-grid-community";
import { ColDef, GridOptions } from "ag-grid-enterprise";
import { SharesService } from "../../../../../../../services/shares.service";
import { AgShareGroupButtonsComponent } from "../ag-share-group-buttons/ag-share-group-buttons.component";
import { ColumnWithExportName, ExtendedColGroupDef } from "../../../../../../../models/columnWithExportName";
import { ColumnForExportComponent } from "../../../../../../modals/column-for-export/column-for-export.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ModalFormsService } from "../../../../../../../services/modal-forms.service";
import { ExportTypeEnum } from "../../../../../../../models/enums/exportTypeEnum";
import { GridComponent } from "../../grid/grid.component";
import { NgClass } from "@angular/common";
import {GroupedShare} from "../../../../../../shares/shares.component";
import {ClientSecurityTransaction} from "../../../../../../../models/clientSecurityTransaction";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<unknown> & {
  exportShares: ActionEvent,
  resolveIssue: ActionEvent,
  issueTransaction: ActionEvent,
  transferTransaction: ActionEvent,
  cancelTransaction: ActionEvent,
  subdivisionOrConsolidationTransaction: ActionEvent,
  conversionTransaction: ActionEvent,
  changeAmountPaidTransaction: ActionEvent,
  addHistoryTransaction: ActionEvent,
  hasIssue: boolean,
  detailGridOptions: GridOptions
};

@Component({
  selector: 'app-ag-share-group',
  standalone: true,
  imports: [
    ListGridComponent,
    GridComponent,
    NgClass
  ],
  templateUrl: './ag-share-group.component.html',
  styleUrl: './ag-share-group.component.scss'
})
export class AgShareGroupComponent {
  private sharesService = inject(SharesService);
  private modalFormsService = inject(ModalFormsService);
  #destroyRef = inject(DestroyRef);
  readonly paginationPageSize = 20;
  excelExportParams = this.sharesService.exportParams() as ExcelExportParams;
  csvExportParams = this.sharesService.exportParams() as CsvExportParams;
  public rowClassRules: RowClassRules = {
    'non-lodged-data': (params: { data: ClientSecurityTransaction }) => !params.data.isPosted,
  };

  params!: CellRendererParams;
  hasIssue = false;
  columnDefs: ColDef[] = [];
  rowData: unknown[] = [];
  colDefs = signal<(ColumnWithExportName | ExtendedColGroupDef)[]>([]);

  statusBar = {
    statusPanels: [
      {
        statusPanel: AgShareGroupButtonsComponent,
        statusPanelParams: {
          hasIssue: false,
          exportShares: this.exportShares.bind(this),
          resolveIssue: this.resolveIssue.bind(this),
          issueTransaction: this.issueTransaction.bind(this),
          transferTransaction: this.transferTransaction.bind(this),
          cancelTransaction: this.cancelTransaction.bind(this),
          subdivisionOrConsolidationTransaction: this.subdivisionOrConsolidationTransaction.bind(this),
          conversionTransaction: this.conversionTransaction.bind(this),
          changeAmountPaidTransaction: this.changeAmountPaidTransaction.bind(this),
          addHistoryTransaction: this.addHistoryTransaction.bind(this),
        },
        align: 'left'
      },
    ]
  };

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.hasIssue = (params.data as { hasIssue?: boolean })?.hasIssue ?? false;
    this.statusBar.statusPanels[0].statusPanelParams.hasIssue = this.hasIssue;
    this.columnDefs = this.params.detailGridOptions.columnDefs as ColDef[];
    const columnDef: ColDef | undefined = this.columnDefs.find((col :ColDef) => col.field === 'transactionDate');
    if (columnDef) {
      columnDef.checkboxSelection = false;
      columnDef.headerCheckboxSelection = false;
    }
    this.colDefs.set(this.columnDefs);
    this.rowData = (params.data as { callRecords: unknown[] }).callRecords;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return false;
  }

  onGridReady(gridApi: GridApi): void {
    this.sharesService.gridApi = gridApi;
    const autoSizeColumnList = this.sharesService.autoSizeColumnList;
    gridApi.autoSizeColumns(autoSizeColumnList);
  }

  exportShares(exportType: ExportTypeEnum): void {
    this.exportSharesList(false, exportType);
  }

  resolveIssue(): void {
  }

  exportSharesList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    const componentInstance = this.modalFormsService.openModal(ColumnForExportComponent, {
      modalDialogClass: 'export-company-list'
    }).componentInstance as ColumnForExportComponent;

    componentInstance.title = 'Export Share List';
    componentInstance.subTitle = 'shares selected';
    componentInstance.colDefs = this.colDefs() as ColGroupDef[];
    componentInstance.numberOfCompanies = this.rowData.length;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((columnForExport: string[]) => {
      if (exportType === ExportTypeEnum.EXCEL) {
        this.sharesService.gridApi.exportDataAsExcel({ columnKeys: columnForExport });
      } else if (exportType === ExportTypeEnum.CSV) {
        this.sharesService.gridApi.exportDataAsCsv({ columnKeys: columnForExport });
      }
    });
  }

  issueTransaction(): void {
    this.params.issueTransaction(true);
  }

  transferTransaction(): void {
    this.params.transferTransaction(true);
  }

  cancelTransaction(): void {
    this.params.cancelTransaction(true);
  }

  subdivisionOrConsolidationTransaction(): void {
    this.params.subdivisionOrConsolidationTransaction(true);
  }

  conversionTransaction(): void {
    this.params.conversionTransaction(true);
  }

  changeAmountPaidTransaction(): void {
    this.params.changeAmountPaidTransaction(true);
  }

  addHistoryTransaction(): void {
    this.params.addHistoryTransaction(true);
  }
}
