<app-stepper-form
  header="Notification by Officeholder of Resignation or Retirement"
  subheader="Form 370"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [isLoading]="isLoading"
  [showSteps]="false"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="370"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ResignationDetails"
      [hidden]="currentStep !== StepsEnum.ResignationDetails"
    >
      <h1 class="h1-heading">Resignation Details</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Resignation"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Date the letter handed in"
          formControlName="resignationLetterGiven"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Select Existing Officer?"
          formControlName="isFormerOfficerResignation"
          [reversedValues]="true"
        ></app-yes-no-control>
      </div>

      <div class="form-group" [hidden]="form.controls.isFormerOfficerResignation.value !== false">
        <app-select
          label="Resigning Officer"
          formControlName="appointedOfficerId"
          [options]="officersSelectOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group" [hidden]="form.controls.isFormerOfficerResignation.value !== true">
        <app-individual-data-form-group [formGroup]="form.controls.formerOfficeholder"/>
      </div>

      <div formArrayName="types" [hidden]="!officerPositionTypesOptions.length || form.controls.isFormerOfficerResignation.value">
        <div class="label">Positions</div>

        <ul class="positions-list list-unstyled my-3 pl-3">
          @for (officerPositionTypesOption of officerPositionTypesOptions; track officerPositionTypesOption) {
            <li class="positions-list-item">
              @if (form.controls.types.at($index)) {
                <app-checkbox
                  [label]="officerPositionTypesOption.label"
                  [formControlName]="$index"
                ></app-checkbox>
              }
            </li>
          }
        </ul>

        @if (form.controls.types.length && form.controls.types.touched) {
          <app-validation-error
            [control]="form.controls.types"
            [customErrors]="{requiredAtLeastOne: 'At least one officer position type must be selected'}"
          ></app-validation-error>
        }

        @if (isTheOnlyDirectorSelected && form.value.appointedOfficerId) {
          <app-notification
            class='mr-3'
            color="red"
            [text]="ceaseWarningMsg"
          />
        }
      </div>

      <div formArrayName="types" [hidden]="!positionTypesOptions.length || !form.controls.isFormerOfficerResignation.value">
        <div class="label">Positions</div>

        <ul class="positions-list list-unstyled my-3 pl-3">
          @for (officerPositionTypesOption of positionTypesOptions; track officerPositionTypesOption) {
            <li class="positions-list-item">
              @if (form.controls.types.at($index)) {
                <app-checkbox
                  [label]="officerPositionTypesOption.label"
                  [formControlName]="$index"
                ></app-checkbox>
              }
            </li>
          }
        </ul>
      </div>

      <div class="form-group" [hidden]="form.controls.isFormerOfficerResignation.value === null">
        <app-textarea
          label="Resignation Letter"
          formControlName="resignationLetterText"
          [rows]="4"
        ></app-textarea>
      </div>
    </section>
  </div>
</app-stepper-form>
