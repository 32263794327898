<div class="action-container" *appHasRole="['Admin', 'Manager', 'Preparer']">
  <app-ag-action-icon-button
    iconClass="ic-alert-triangle"
    [numberOfAlerts]="numberOfAlerts"
    [disabled]="!numberOfAlerts"
    (action)="numberOfAlerts && onOpenAlerts()"
  ></app-ag-action-icon-button>

  <app-ag-action-icon-button
    iconClass="ic-notes"
    (action)="onOpenNotes()"
  ></app-ag-action-icon-button>

  <app-ag-action-icon-button
    iconClass="ic-eye"
    (action)="onOpenTrustProfile()"
  ></app-ag-action-icon-button>

  <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown d-inline-flex">
    <app-ag-action-icon-button ngbDropdownToggle iconClass="ic-three-dots"></app-ag-action-icon-button>

    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      <div ngbDropdownItem class="dropdown-item" (click)="onAppointAccountManager()">
        <a class="menu-action-button">
          <span>Appoint Account Manager</span>
        </a>
      </div>
      <div ngbDropdownItem class="dropdown-item" (click)="onExportTrustProfile()">
        <a class="menu-action-button">
          <span>Download Trust Profile</span>
        </a>
      </div>
    </div>
  </div>
</div>
