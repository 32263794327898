import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { Alert } from "../models/alerts/alert";
import { environment } from "../environments/environment";
import { FilterModel, IServerSideGetRowsRequest } from "ag-grid-enterprise";
import { PageResult } from "../models/pageResult";
import { formatDate } from "@angular/common";
import { AlertStatus, AlertType } from "../models/enums/alertEnums";
import { concatMap, delay, forkJoin, from, map, Observable, of, Subject, toArray } from "rxjs";
import { AlertTypeIndicator } from "../models/alerts/alertTypeIndicator";
import { ExcelRow, ExcelStyle, ProcessCellForExportParams, ProcessHeaderForExportParams } from "ag-grid-community";
import { FilterOption } from "../models/gridFilter";
import { Tag } from "../models/tag";
import { ColumnWithExportName } from "../models/columnWithExportName";
import { AlertAttachment } from "../models/alerts/alertAttachment";
import { generate, Observer, Operation } from "fast-json-patch";
import { reorderGridFilterModel } from "../app/helpers/reorder-grid-filter";
import { AlertRecord } from "../models/alerts/alertRecord";
import { excelReportTitleStyles, excelTitleForReport } from "../app/helpers/excel-title-for-report";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private readStatusUpdatedSource = new Subject<void>();
  public readStatusUpdated$ = this.readStatusUpdatedSource.asObservable();
  numberColumnForExport = signal(0);

  constructor(private api: HttpClient) { }

  public resolve(alertId: string, actionId: string) {
    return this.api.post<Alert>(`${environment.api_url}/alerts/${alertId}/resolve?actionId=${actionId}`, {});
  }

  public list(entityId: string) {
    return this.api.get<AlertRecord[]>(`${environment.api_url}/alerts/${entityId}/list`);
  }

  public getAlert(alertId: string) {
    return this.api.get<Alert>(`${environment.api_url}/alerts/${alertId}`);
  }

  public notifyReadStatusUpdated() {
    this.readStatusUpdatedSource.next();
  }

  public getInboxAlertList(request: IServerSideGetRowsRequest, search?: string): Observable<PageResult<AlertRecord>> {
    request = reorderGridFilterModel(request);

    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }

    return this.api.post<PageResult<AlertRecord>>(`${environment.api_url}/alerts/grid`, request, { params: params });
  }

  public getAttachments(alertId: string): Observable<AlertAttachment[]> {
    return this.api.get<AlertAttachment[]>(`${environment.api_url}/alerts/${alertId}/attachments`);
  }

  public getDownload(alertId: string, filename?: string): Observable<string> {
    let params = new HttpParams();
    if (filename) {
      params = params.append('filename', filename);
    }

    return this.api.get<string>(`${environment.api_url}/alerts/${alertId}/attachments`, { params: params });
  }

  public updateAlert(alertId: string, observer: Observer<Alert[]>): Observable<Alert> {
    const patchOperations = observer ? generate(observer) : [];
    return this.api.patch<Alert>(`${environment.api_url}/alerts/${alertId}`, patchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } });
  }

  public updateAlertWithPatchOperation(alertId: string, patchOperations: Operation[]): Observable<Alert> {
    return this.api.patch<Alert>(`${environment.api_url}/alerts/${alertId}`, patchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } });
  }

  public bulkUpdateAlert(alertIds: string[], observer: Observer<Alert[]>): Observable<Alert[]> {
    const patchOperations = observer ? generate(observer) : [];
    const batches = this.chunkArray(alertIds, 10);
    return from(batches).pipe(
      concatMap(batch => forkJoin(batch.map(alertId => {
        return this.api.patch<Alert>(`${environment.api_url}/alerts/${alertId}`, patchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } });
      })).pipe(
        delay(1000)
      )),
      toArray(),
      concatMap(responses => of(...responses).pipe(concatMap(res => res), toArray()))
    );
  }

  private chunkArray<T>(array: T[], size: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  public getBellInboxAlert(request?: IServerSideGetRowsRequest) {
    if(!request) {
      request = {
        startRow: 0,
        endRow: 6,
        filterModel: this.getInboundFilterModel(),
        groupKeys: [],
        pivotCols: [],
        pivotMode: false,
        rowGroupCols: [],
        sortModel: [
          {
            sort: 'desc',
            colId: 'dateCreated'
          }
        ],
        valueCols: []
      };
    }

    return this.api.post<PageResult<AlertTypeIndicator>>(`${environment.api_url}/alerts/grid`, request)
      .pipe(
        map(data=> {
          return data.records.map(record => {
            if(record.type === AlertType.ValidationReport
              || record.type === AlertType.DebtReports
              || record.type === AlertType.AsicInvoice
              || record.type === AlertType.ChangeOfNameCertificate
              || record.type === AlertType.CompanyStatement
            ) {
              record.indicator = 'green';
            } else {
              record.indicator = 'red';
            }

            return record;
          });
        })
      );
  }

  public getInboundFilterModel(): FilterModel {
    const startDay = new Date();
    const endDay = new Date();
    startDay.setHours(0, 0, 0, 0);
    endDay.setHours(23, 59, 59, 59);

    return  {
      status: {
        filterType: 'set',
        values: [AlertStatus.New.toString()]
      },
      dateCreated: {
        filterType: "date",
        type: "lessThan",
        dateFrom: this.formatDate(endDay)
      },
      isReaded: {
        filterType: 'set',
        values: ['false']
      }
    }
  }

  public getRequiredAttentionFilterModel(): FilterModel {
    return  {
      type: {
        filterType: 'set',
        values: [AlertType.AsicDataIssue.toString(), AlertType.XpmSyncIssue.toString()]
      },
      isReaded: {
        filterType: 'set',
        values: ['true']
      }
    }
  }

  public getPostponedAttentionFilterModel(): FilterModel {
    return  {
      status: {
        filterType: 'set',
        values: [AlertStatus.Postponed.toString()]
      },
    }
  }

  public formatDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'en-US');
  }

  public exportParams() {
    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const alertData = params.node?.data as Alert;

        if (params.column.getColId() === 'dateCreated') {
          return formatDate(alertData.dateCreated ?? '', 'dd MMM yyyy HH:mm', 'en-US');
        } else if(params.column.getColId() === 'type' || params.column.getColId() === 'status' || params.column.getColId() === 'resolvedStatus') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          const options: FilterOption[] = params.column.getColDef()?.filterParams?.filterOptions ?? [];
          return options.find(o => o.value == value)?.label ?? '';
        } else if (params.column.getColId() === 'tags') {
          return (value as Tag[]).map((tag: Tag) => tag.name).join(', ');
        } else if(params.column.getColId() === 'needAttention') {
          return value ? 'Yes' : 'No';
        }

        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName;
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      sheetName: 'Alerts all',
      fileName: 'Alerts all ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
    }
  }

  exportParamsXls() {
    return {
      ...this.exportParams(),
      prependContent: this.getExcelTitleRows(this.numberColumnForExport())
    };
  }

  public getExcelTitleRows: (numberColumnForExport: number) => ExcelRow[] = (numberColumnForExport) =>
    excelTitleForReport(numberColumnForExport, 'Alerts Report');

  public readonly excelStyles: ExcelStyle[] = excelReportTitleStyles;
}
