<div class="button-container" *appHasRole="['Admin', 'Manager', 'Preparer']">
  <div ngbDropdown [placement]="['top-start', 'top-end']" class="dropdown">
    <app-button
      ngbDropdownToggle
      id="add-transaction-dd"
      [text]="'Add Transaction'"
      [iconClass]="'icon ic-xs ic-plus'"
      [iconClassLast]="'icon ic-xs ic-chevron-down'"
    ></app-button>
    <div ngbDropdownMenu aria-labelledby="add-transaction-dd" class="dropdown-menu dropdown-menu-right">
      @for (menuItem of addTransactionMenu; track $index) {
        @if($last) {
          <app-divider [margin]="'4px 0'"></app-divider>
        }
        <div class="dropdown-item">
          <a class="dropdown-button" (click)="menuItem.action()">
            <span>{{menuItem.name}}</span>
          </a>
        </div>
      }
    </div>
  </div>

  <div ngbDropdown [placement]="['top-start', 'top-end']" class="dropdown">
    <app-button
      ngbDropdownToggle
      id="bulk-actions-dd"
      [text]="'Export'"
      [iconClass]="'ic-download-file ic-xs icon'"
    ></app-button>
    <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
      <div class="dropdown-item" (click)="exportSharesList(false, ExportTypeEnum.EXCEL)">
        <a class="dropdown-button">
          <span>Export to xlsx</span>
        </a>
      </div>
      <div class="dropdown-item" (click)="exportSharesList(false, ExportTypeEnum.CSV)">
        <a class="dropdown-button">
          <span>Export to csv</span>
        </a>
      </div>
    </div>
  </div>

  @if(hasIssue) {
    <app-divider [vertical]="true" [margin]="'0 24px'"></app-divider>

    <app-button
      [text]="'Resolve'"
      [btnColor]="'red-orange'"
      [iconClass]="'icon ic-xs ic-wrench'"
      [iconClassLast]="'icon ic-xs ic-chevron-down'"
      (clickBtn)="resolveIssue()"
    ></app-button>
  }
</div>
