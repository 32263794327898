<div class="grid-line-height">
  <div [class.changes-hidden]="!pendingButtonState">
    @if (pendingButtonState && previousNumberOfShares !== numberOfShares) {
      <div class="prop previous">{{ previousNumberOfShares }}</div>
      <div class="prop current">{{ numberOfShares }}</div>
    } @else {
      <div class="prop">{{ previousNumberOfShares }}</div>
    }
  </div>
</div>
