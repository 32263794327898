<app-common-modal-form
    icon="ic-message"
    [header]="'Organisation Exists'"
    [isCustomFooter]="true"
    [hideCloseButton]="true"
>
  <div style="padding-bottom: 18px">
    <app-notification
      [iconClass]="'icon ic-md ic-cancel-circle'"
      [color]="'red'"
      [text]="'Your organisation already has an account with us. Please contact your administrator to gain access to the existing organisation.'"
    ></app-notification>
  </div>
</app-common-modal-form>
