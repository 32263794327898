import { Component, inject } from '@angular/core';
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";
import { NotificationComponent } from '../../../../../components/common/notification/notification.component';

@Component({
  selector: 'already-registered-domain-modal',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    ReactiveFormsModule,
    NotificationComponent
  ],
  templateUrl: './already-registered-domain-modal.component.html'
})
export class AlreadyRegisteredDomainModalComponent {
}
