export class OrganisationBrandModel {
  brandName: string;
  brandDescription: string;
  footerText: string;
  mainColor: string;
  secondaryColor: string;
  insertLogoInAnnualStatementCoverLetter: boolean;
  addCoverPageOnAsicForms: boolean;

  constructor(data: Partial<OrganisationBrandModel> = {}) {
    this.brandName = data.brandName ?? '';
    this.brandDescription = data.brandDescription ?? '';
    this.footerText = data.brandDescription ?? '';
    this.mainColor = data.mainColor ?? '';
    this.secondaryColor = data.secondaryColor ?? '';
    this.footerText = data.footerText ?? '';
    this.insertLogoInAnnualStatementCoverLetter = data.insertLogoInAnnualStatementCoverLetter ?? false;
    this.addCoverPageOnAsicForms = data.addCoverPageOnAsicForms ?? false;
  }
}
