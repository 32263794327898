@if (individual) {
  <div class="individual-profile-container">
    <div class="left">
      <div class="card card-profile">
        <div class="card-body">
          <div class="d-flex flex-column align-items-center justify-content-center mb-4">
            <i class="fad fa-briefcase mb-3" style="font-size: 60px"></i>
            <h3 class="h3-heading mb-1">{{ individual.fullNameWithMiddle }}</h3>
            <p class="text-gray600 mb-0">Individual</p>
          </div>

          <div
            class="d-flex justify-content-between align-items-center mb-3 pb-2"
            style="border-bottom: 1px dashed var(--gray200)"
          >
            <h3 class="h3-heading mb-0">Details</h3>
            <div
              ngbDropdown
              class="dropdown"
              [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
              *appHasRole="['Admin', 'Manager', 'Preparer']"
            >
              <button class="btn btn-primary" id="individual-edit-menu" ngbDropdownToggle [disabled]="modalOpened()">
                Edit
              </button>

              <div ngbDropdownMenu aria-labelledby="individual-edit-menu" class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item">
                  <span class="dropdown-heading">Details</span>
                </div>

                <div class="dropdown-item">
                  <a class="dropdown-button" (click)="address()"><span>Registered address</span>
                    <div class="asic-form"><i class="fad fa-file-invoice"></i> 484A</div>
                  </a>
                </div>

                <div class="dropdown-item">
                  <a class="dropdown-button" (click)="bulkChangeName(individual.relationships)">
                    <span>Change Name</span>
                  </a>
                </div>

                <div class="dropdown-item">
                  <a class="dropdown-button" (click)="bulkChangeAddress(individual.address.normalizedFullAddress)">
                    <span>Change Address</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="mb-1">Date of birth</label>
            <span class="d-block text-gray600">{{ individual.dob | date : "dd/MM/yyyy" }}</span>
          </div>

          <div class="form-group">
            <label class="mb-1">Place of Birth</label>
            <span class="d-block text-gray600">
              <span>{{ individual.birthCountry | country }}</span>
              <span>, </span>
              <span>{{ individual.birthCity }}</span>
            </span>
          </div>

          <div class="form-group">
            <label class="mb-1">Residential address</label>
            <span class="d-block text-gray600">{{ individual.address.normalizedFullAddress | defaultValue }}</span>
          </div>

          <div class="form-group">
            <label class="mb-1">Director ID</label>
            <span class="d-block text-gray600">{{ individual.din | defaultValue }}</span>
          </div>
        </div>
      </div>

      <div>
        <app-entity-profile-tags
          [disableControls]="modalOpened()"
          [entityProfile]="individual"
        ></app-entity-profile-tags>
      </div>
    </div>

    <div class="right d-flex flex-column flex-grow-1">
      <div class="card mb-4">
        <app-page-title header="Companies">
          <div class="d-flex justify-content-between align-items-center gap-24">
            <div class="notes-alerts-buttons">
              <app-tab-button
                iconClass="ic-alert-triangle"
                [isActive]="alertsVisible"
                [isDisabled]="!individual.alerts?.length || modalOpened()"
                (tabClicked)="individual.alerts?.length ? alertsVisible = true : alertsVisible = false"
              >{{ alertsLabel }}
              </app-tab-button>

              <app-tab-button
                iconClass="ic-notes"
                [isActive]="notesVisible"
                [isDisabled]="modalOpened()"
                (tabClicked)="notesVisible = true"
                *appHasRole="['Admin', 'Manager', 'Preparer']"
              >{{ notesLabel }}
              </app-tab-button>
            </div>

            @if (!isSpitMode || isSpliting) {
              <button
                class="btn btn-primary no-animate"
                [appSpinner]="isSpliting"
                [disabled]="modalOpened()"
                (click)="setSplitMode(true)"
                *appHasRole="['Admin', 'Manager', 'Preparer']"
              >
                Split Individuals
              </button>
            } @else {
              <div>
                <button
                  class="btn btn-primary no-animate"
                  [disabled]="modalOpened()"
                  (click)="splitIndidviduals('new')"
                >
                  Split to new
                </button>
                <button
                  class="btn btn-primary no-animate"
                  [disabled]="modalOpened()"
                  (click)="splitIndidviduals('existing')">
                  Assign to existing
                </button>
                <button
                  class="btn btn-primary no-animate"
                  [disabled]="modalOpened()"
                  (click)="setSplitMode(false)">
                  Cancel
                </button>
              </div>
            }
          </div>
        </app-page-title>

        <div class="card-body">
          <table class="table table-middle mb-0">
            <thead>
            <tr>
              @if (isSpitMode == true) {
                <th width="1px">
                  <div class="custom-check">
                    <input id="control" type="checkbox" class="custom-check-input">
                    <label for="control" class="custom-check-padder"></label>
                  </div>
                </th>
              }
              <th><span class="cursor-pointer">Name<i class="fas fa-sort"></i></span></th>
              <th><span class="cursor-pointer">Company<i class="fas fa-sort"></i></span></th>
              <th><span class="cursor-pointer">Address<i class="fas fa-sort"></i></span></th>
              <th><span class="cursor-pointer">Dob<i class="fas fa-sort"></i></span></th>
              <th><span class="cursor-pointer">Din<i class="fas fa-sort"></i></span></th>
              <th width="1px"></th>
            </tr>
            </thead>
            <tbody>
              @for (r of individual.relationships; track r) {
                <tr>
                  @if (isSpitMode == true) {
                    <td>
                      <div class="custom-check">
                        <input id="{{individual.individualId}}" type="checkbox" class="custom-check-input"
                               [(ngModel)]="r.checked">
                      </div>
                    </td>
                  }
                  <td class="text-gray800">
                    <span>{{ r.individualDataOverride?.fullName }}</span><br>
                    <span class="d-block text-gray600">{{ r.type | relationshipType }}</span>
                  </td>
                  <td>
                    <span>{{ r.entity?.name }}</span><br>
                    <span>{{ getCompanytype(r.entity)?.acn }}</span>
                  </td>
                  <td>{{ r.individualDataOverride?.address?.normalizedFullAddress }}</td>
                  <td>{{ r.individualDataOverride?.dob | date : "dd/MM/yyyy" }}</td>
                  <td>{{ r.individualDataOverride?.din }}</td>
                  <td>
                    <a
                      class="company-link"
                      [routerLink]="modalOpened() ? null : ['/company-profile', r.entityId]"
                    >
                      <i class="fad ic-companies ic-md icon"></i>
                    </a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <app-notes
    [individualId]="individual.individualId"
    [opened]="notesVisible"
    (close)="notesVisible = false"
    (notesTotalChanged)="notesTotal = notesTotal + $event"
  ></app-notes>

  <app-alerts-list
    [alerts]="individual.alerts ?? []"
    [entityType]="EntityType.Individual"
    [individual]="individual"
    [opened]="alertsVisible"
    (close)="alertsVisible = false"
  ></app-alerts-list>
}
