<div class="create-edit-note-control" [formGroup]="form">
  <input
    #fileUploadInput
    style="display: none"
    type="file"
    multiple
    [accept]="allowedExtensions"
    [disabled]="isLoading"
    (change)="handleFileUpload($event)"
  >

  <div class="textarea-wrapper">
    <app-textarea
      placeholder="Enter a note"
      formControlName="noteControl"
      [rows]="textareaRows"
      [customErrors]="customTextareaErrors"
      (focusin)="setShowControl(true)"
      (submit)="onSubmitNote()"
      *appHasRole="['Admin', 'Manager', 'Preparer']"
    ></app-textarea>
  </div>

  @if (noteForEdit || showControl || attachmentsName.length) {
    @if (attachmentsName.length) {
      <ul class="attached-files">
        @for (filename of attachmentsName; track filename; let index = $index) {
          <li>
            <app-attached-file-label
              [readonly]="false"
              [disabled]="isLoading"
              [filename]="filename"
              (downloadFile)="onDownloadFile(index)"
              (deleteFile)="deleteFile(index)"
            ></app-attached-file-label>
          </li>
        }
      </ul>
    }

    @if (errorMessageVisible) {
      <div>
        <app-validation-error [control]="form"></app-validation-error>
      </div>
    }

    @if (errorMessage) {
      <app-disclaimer
        icon="ic-circle-cancel-20"
        color="red"
      >{{ errorMessage }}</app-disclaimer>
    }

    <div class="note-controls">
      <div class="confirmation-buttons">
        <app-button
          btnColor="orange"
          text="Save Note"
          [isDisabled]="submitButtonDisabled"
          (clickBtn)="onSubmitNote()"
        ></app-button>

        @if (this.noteForEdit) {
          <app-button
            btnColor="white"
            text="Cancel"
            [isDisabled]="isLoading"
            (clickBtn)="clearForm()"
          ></app-button>
        } @else {
          <app-button
            btnColor="white"
            text="Clear"
            [isDisabled]="isLoading || (!form.controls.noteControl.value.trim() && attachmentsName.length === 0)"
            (clickBtn)="clearForm()"
          ></app-button>
        }
      </div>

      <div class="attach-file-button-wrapper">
        <app-button
          class="upload-file"
          iconClass="icon ic-attachment"
          text="Attach File"
          btnColor="white"
          [isDisabled]="isLoading || attachmentsToUpload.length >= allowedFilesQuantity"
          (clickBtn)="tryHandleFileUpload(fileUploadInput)"
        ></app-button>
      </div>
    </div>
  }
</div>
