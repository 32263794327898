import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModalFormComponent } from "../common-modal-form/common-modal-form.component";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatePickerComponent } from "../../components/common/date-picker/date-picker.component";
import { DatepickerHelper } from "../../../custom-form-validators/date-picker-form-validators";
import { formatDate } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-mark-signed',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    DatePickerComponent,
    FormsModule,
  ],
  templateUrl: './mark-signed.component.html',
  styleUrl: './mark-signed.component.scss'
})
export class MarkSignedComponent implements OnInit {
  @Input() earliestLodgeDate!: Date;
  @Output() confirm = new EventEmitter<string>();

  private activeModal = inject(NgbActiveModal);

  signingDate = new Date();
  formattedSigningDate = '';
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getStructFromDateOrNull(this.earliestLodgeDate)!;
    this.maxDate = DatepickerHelper.getTodayStruct();
  }

  agree(): void {
    this.confirm.emit(this.formattedSigningDate);
    this.activeModal.close();
  }

  close(): void {
    this.activeModal.close();
  }

  onSigningDateChange(signingDate: Date | NgbDateStruct): void {
    if(signingDate) {
      const date = signingDate instanceof Date ? signingDate : DatepickerHelper.getDateFromStruct(signingDate);
      this.formattedSigningDate = formatDate(date, 'YYYY-MM-dd', 'en-US');
    }
  }
}
