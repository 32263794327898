export enum ReminderType {
  Signing,
  Payment
}

export enum ReminderSendingType {
  Email,
  SMS,
  EmailAndSMS
}

export enum ReminderStatus {
  Pending,
  Sent,
  Canceled,
  Failed
}
