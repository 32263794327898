import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { setControlDisabled } from '../../../../functions/set-control-disabled';
import { Address } from '../../../../models/address';
import { Document } from '../../../../models/document';
import { EntityChangeData } from '../../../../models/entityChangeData';
import { EntityData } from '../../../../models/entityData';
import {
  EntitySecurityTransfer,
  SecurityTransactionFrom,
  SecurityTransactionTo
} from '../../../../models/EntitySecurityTransfer';
import { SecurityTransactionType } from '../../../../models/enums/SecurityTransactionType';
import { IndividualData } from '../../../../models/individualData';
import { ShareholderRelationshipDetails } from '../../../../models/relationship';
import { SecurityHolding } from '../../../../models/securityHolding';
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from '../../../../models/securityRegistryRecord';
import { NumbersValidators } from '../../../../validators/numbers.validators';
import { DatePickerComponent } from '../../../components/common/date-picker/date-picker.component';
import { DividerComponent } from '../../../components/common/divider/divider.component';
import { InputNumberComponent } from '../../../components/common/input-number/input-number.component';
import { SelectComponent } from '../../../components/common/select/select.component';
import { ValidationErrorComponent } from '../../../components/common/validation-error/validation-error.component';
import {
  ShareSecurityHoldingsDropdownComponent
} from '../../../components/shares/share-security-holdings-dropdown/share-security-holdings-dropdown.component';
import {
  ShareSecurityTypesDropdownComponent
} from '../../../components/shares/share-security-types-dropdown/share-security-types-dropdown.component';
import { Guid } from '../../../helpers/guid.helper';
import { StepperFormComponent } from '../../stepper-form/stepper-form.component';
import { BaseShareFormComponent } from '../components/base-share-form/base-share-form.component';
import {
  SelectShareholderFormComponent
} from '../components/select-shareholder-form/select-shareholder-form.component';
import { ShareEstimateTableComponent } from '../components/share-estimate-table/share-estimate-table.component';
import {
  ShareholderWithNboSignComponent
} from '../components/shareholder-with-nbo-sign/shareholder-with-nbo-sign.component';
import { ShareholderTypesEnum } from '../share-issue/share-issue.model';
import { CompanySecurityTransfer, CompanySecurityTransferStepsEnum } from './share-transfer.model';
import {
  IndividualDataFormGroupComponent, IndividualDataFormGroupControls
} from "../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import {
  StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";

@Component({
  selector: 'app-share-transfer',
  standalone: true,
  imports: [
    StepperFormComponent,
    ReactiveFormsModule,
    DatePickerComponent,
    ShareEstimateTableComponent,
    ShareholderWithNboSignComponent,
    ShareSecurityHoldingsDropdownComponent,
    DividerComponent,
    InputNumberComponent,
    SelectShareholderFormComponent,
    ShareSecurityTypesDropdownComponent,
    SelectComponent,
    ValidationErrorComponent,
    StepperFormDescriptionComponent
  ],
  templateUrl: './share-transfer.component.html',
  styleUrls: [
    './share-transfer.component.scss',
    '../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
    '../share-cancellation/share-cancellation.component.scss'
  ]
})
export class ShareTransferComponent extends BaseShareFormComponent<CompanySecurityTransferStepsEnum, CompanySecurityTransfer> implements OnInit {
  securityRegistryRecordsHoldings: SecurityHolding[] = [];
  override StepsEnum = CompanySecurityTransferStepsEnum;

  // transfer from
  selectedShareholderShareType: SecurityHolding | null = null;

  // transfer to
  selectedIndividualShareholder: IndividualHolderModel | null = null;
  selectedCompanyShareholder: EntityData | null = null;

  override stepperForm = new FormGroup({
    [CompanySecurityTransferStepsEnum.FormDescription]: new FormGroup({}),
    [CompanySecurityTransferStepsEnum.TransferFrom]: new FormGroup({
      date: new FormControl<Date | null>(null, [Validators.required]),
      securityHoldingId: new FormControl<string | null>(null, [Validators.required]),

      considerationPaid: new FormControl<number | null>(null, [Validators.required]),
      numberIncrease: new FormControl<number | null>(null, [Validators.required, NumbersValidators.min(0), NumbersValidators.isInteger]),
      paidIncrease: new FormControl<number | null>(null, [Validators.required]),
      unpaidIncrease: new FormControl<number | null>(null, [Validators.required]),

      shareholder: ShareholderWithNboSignComponent.defineForm()
    }),
    [CompanySecurityTransferStepsEnum.TransferTo]: new FormGroup({
      securityHoldingId: new FormControl<string | null>(null),
      numberOfSharesTransfered: new FormControl<number | null>(null),
      selectShareholder: SelectShareholderFormComponent.defineForm(),
      consolidateCertificate: new FormControl<boolean | null>(false, [Validators.required]),
      shareCertificateNumber: new FormControl<string | null>(null, [NumbersValidators.isInteger])
    }),
    [CompanySecurityTransferStepsEnum.Estimate]: new FormGroup({}),
  });

  constructor() {
    super();
    this.setupSteps(CompanySecurityTransferStepsEnum);
    this.redirectAfterSubmit = false;
  }

  override setFormValidators(): void {
    this.setCustomFormValidators();
  }

  override setupFormChangeListeners(): void {
    super.setupFormChangeListeners();
    this.listenSecurityRegistryRecordIndexChanges();
    this.listenSecurityHoldingIdChanges();
    this.listenNumberOfSharesChanges();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanySecurityTransfer);
  }

  override setupChange(change: CompanySecurityTransfer = this.formModel) {
    setControlDisabled(this.transferToForm.controls.numberOfSharesTransfered);
    setControlDisabled(this.transferToForm.controls.securityHoldingId);

    if (!this.isEdit && change === this.formModel) {
      return;
    }

    this.setupTransferFromForm(change);
    this.setupTransferToForm(change);
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.steps[newStepIndex].step;
    this.currentStepIndex = newStepIndex;

    if (this.currentStep === CompanySecurityTransferStepsEnum.Estimate && this.shouldLoadEstimate) {
      this.loadEstimates();
    }
  }

  override buildDocument(): Document | null {
    const companySecurityTransfer: CompanySecurityTransfer = this.buildShareFormChange();
    const doc = new Document({
      changes: [],
      entityId: this.companyChangeData?.entityId,
      type: 'c:484',
      documentId: this.companyChangeData?.documentId ?? Guid.EmptyGuid,
    }) as any as Document;
    doc.changes = [companySecurityTransfer];
    return doc;
  }

  override buildShareFormChange(): CompanySecurityTransfer {
    const changeDate = this.transferFromForm.controls.date.value!;
    const companySecurityTransfer = new CompanySecurityTransfer({
      $type: 'CompanySecurityTransfer',
      changeDate,
      description: 'Company share transfer',
      consolidateCertificate: this.transferToForm.controls.consolidateCertificate.value ?? false,
      transferFrom: new SecurityTransactionFrom({
        entityId: this.companyChangeData?.entityId,
        organisationId: this.companyChangeData.organisationId,
        documentId: this.companyChangeData.documentId,
        transactionDate: changeDate,
        transactionType: SecurityTransactionType.TransferFrom,
        securityHoldingId: this.transferFromForm.controls.securityHoldingId.value || null,
        considerationPaid: this.transferFromForm.controls.considerationPaid.value!,
        numberIncrease: -this.transferFromForm.controls.numberIncrease.value!,
        paidIncrease: -this.transferFromForm.controls.paidIncrease.value!,
        unpaidIncrease: -this.transferFromForm.controls.unpaidIncrease.value!,
        securityTypeId: this.selectedShareholderShareType!.securityType.securityTypeId,
        securityType: this.selectedShareholderShareType!.securityType,
        relationshipIds: this.securityRegistryRecords[this.transferFromForm.controls.shareholder.value.securityRegistryRecordIndex!].holders.map(h => h.relationshipId)
      }),
      transferTo: new SecurityTransactionTo({
        entityId: this.companyChangeData?.entityId,
        organisationId: this.companyChangeData.organisationId,
        documentId: this.companyChangeData.documentId,
        transactionDate: changeDate,
        transactionType: SecurityTransactionType.TransferTo,
        securityHoldingId: Guid.EmptyGuid,
        considerationPaid: this.transferFromForm.controls.considerationPaid.value!,
        numberIncrease: this.transferFromForm.controls.numberIncrease.value!,
        paidIncrease: this.transferFromForm.controls.paidIncrease.value!,
        unpaidIncrease: this.transferFromForm.controls.unpaidIncrease.value!,
        securityTypeId: this.selectedShareholderShareType!.securityType.securityTypeId,
        securityType: this.selectedShareholderShareType!.securityType,
        shareCertificateNumber: this.transferToForm.controls.shareCertificateNumber.value || null
      }),
      securityHoldersTo: []
    });

    switch (this.transferToForm.controls.selectShareholder.value.shareholderType) {
      case ShareholderTypesEnum.ExistingShareholder: {
        const selectedHolders = this.transferToSecurityRegistryRecords[this.transferToForm.controls.selectShareholder.value.securityRegistryRecordIndex!]?.holders;
        companySecurityTransfer.securityHoldersTo = this.buildShortBaseHolders(selectedHolders.filter(Boolean));
        break;
      }
      case ShareholderTypesEnum.Individual: {
        const individualData = IndividualDataFormGroupComponent.toIndividualData(this.transferToForm.controls.selectShareholder.controls.newIndividualShareholder as FormGroup<Partial<IndividualDataFormGroupControls>>);
        const newIndividualSecurityHolder = new IndividualHolderModel({
          individualData,
        });
        companySecurityTransfer.securityHoldersTo = [this.buildShortIndividualHolderModel(newIndividualSecurityHolder)];
        break;
      }
      case ShareholderTypesEnum.Corporate: {
        const entityData = new EntityData({
          name: this.transferToForm.controls.selectShareholder.value.name ?? '',
          entityNumber: this.transferToForm.controls.selectShareholder.value.acn ?? '',
          registeredAddress: new Address(this.transferToForm.controls.selectShareholder.value.registeredAddress as Partial<Address>)
        });
        const newCorporateSecurityHolder = new CorporateHolderModel({ entityData });
        companySecurityTransfer.securityHoldersTo = [this.buildShortCorporateHolderModel(newCorporateSecurityHolder)];
        break;
      }
      case ShareholderTypesEnum.JointShareholder: {
        companySecurityTransfer.securityHoldersTo = this.buildShortBaseHolders(this.transferToForm.controls.selectShareholder.value.joint ?? []);
        break;
      }
      default:
        break;
    }

    companySecurityTransfer.transferTo.relationshipIds = companySecurityTransfer.securityHoldersTo.map(h => h.relationshipId);
    return companySecurityTransfer;
  }

  override onShareholdersLoadSuccess(shareholders: SecurityRegistryRecord[]): void {
    this.securityRegistryRecords = shareholders.filter(holder => holder?.holders?.length > 0);
    this.isLoading = false;
    this.cdr.detectChanges();
    this.setupChange();
    this.cdr.detectChanges();
  }

  override onEstimateLoadSuccess(estimatedSecurityRegistryRecords: SecurityRegistryRecord[]): void {
    this.estimatedSecurityRegistryRecords = estimatedSecurityRegistryRecords.filter(record => record?.holders?.length > 0);
  }

  private setupTransferFromForm(change: CompanySecurityTransfer): void {
    // selected security registry record can be only existing one
    const transactionFromSelectedSecurityRegistryRecordIndex = this.securityRegistryRecords
      .findIndex((record) => record?.holders
        .every(holder => change.transferFrom.relationshipIds.includes(holder.relationshipId)));

    if (transactionFromSelectedSecurityRegistryRecordIndex >= 0) {
      const securityHoldingId = this.securityRegistryRecords[transactionFromSelectedSecurityRegistryRecordIndex]?.holdings
        ?.find(holding => holding.securityHoldingId === change.transferFrom.securityHoldingId)
        ?.securityHoldingId ?? null;

      this.transferFromForm.controls.shareholder.controls.securityRegistryRecordIndex.setValue(transactionFromSelectedSecurityRegistryRecordIndex ?? null);
      this.transferFromForm.controls.securityHoldingId.setValue(securityHoldingId);
      this.cdr.detectChanges();
    }

    this.transferFromForm.patchValue({
      ...change,
      ...change.transferFrom,
      date: new Date(change.transferFrom.transactionDate),

      considerationPaid: change.transferFrom.considerationPaid,
      numberIncrease: -change.transferFrom.numberIncrease,
      paidIncrease: -change.transferFrom.paidIncrease,
      unpaidIncrease: -change.transferFrom.unpaidIncrease,
    });
  }

  private setupTransferToForm(change: CompanySecurityTransfer): void {
    const transactionToSelectedSecurityRegistryRecordIndex = this.transferToSecurityRegistryRecords
      .findIndex((record) => record?.holders
        .every(holder => change.transferTo.relationshipIds?.includes(holder.relationshipId)));

    this.transferToForm.patchValue(change.transferTo);
    this.transferToForm.controls.shareCertificateNumber.patchValue(change.transferTo.shareCertificateNumber);
    this.transferToForm.controls.consolidateCertificate.patchValue(change.consolidateCertificate);
    this.transferToForm.controls.selectShareholder.controls.securityRegistryRecordIndex.setValue(transactionToSelectedSecurityRegistryRecordIndex !== -1 ? transactionToSelectedSecurityRegistryRecordIndex : null);

    // set the shareholder type
    if (transactionToSelectedSecurityRegistryRecordIndex >= 0) {
      this.transferToForm.controls.selectShareholder.controls.shareholderType.setValue(ShareholderTypesEnum.ExistingShareholder);
      this.transferToForm.controls.selectShareholder.controls.securityRegistryRecordIndex.setValue(transactionToSelectedSecurityRegistryRecordIndex);
      this.cdr.detectChanges();
    } else if (change.securityHoldersTo.length >= 2) {
      this.transferToForm.controls.selectShareholder.controls.shareholderType.setValue(ShareholderTypesEnum.JointShareholder);
      this.transferToForm.controls.selectShareholder.controls.joint.setValue(new EntitySecurityTransfer(change).securityHoldersTo);
      this.transferToForm.controls.selectShareholder.controls.isBeneficialOwner.setValue(!change.securityHoldersTo[0].details.isBeneficialOwner);
      this.transferToForm.controls.selectShareholder.controls.beneficialOwner.setValue(change.securityHoldersTo[0].details.beneficialOwner);
    } else if (change.securityHoldersTo.length === 1) {
      const holder = change.securityHoldersTo[0];

      if (holder.$type === IndividualHolderModel.$type) {
        this.selectedIndividualShareholder = holder as IndividualHolderModel;
        this.transferToForm.controls.selectShareholder.controls.shareholderType.setValue(ShareholderTypesEnum.Individual);
        this.transferToForm.controls.selectShareholder.controls.newIndividualShareholder.patchValue(this.selectedIndividualShareholder.individualData);
      } else {
        this.selectedCompanyShareholder = (holder as CorporateHolderModel).entityData;
        this.transferToForm.controls.selectShareholder.controls.shareholderType.setValue(ShareholderTypesEnum.Corporate);
        this.transferToForm.controls.selectShareholder.controls.isOverseasCompany.setValue(this.selectedCompanyShareholder.registeredAddress.country !== 'AU');
        this.transferToForm.controls.selectShareholder.patchValue({
          name: this.selectedCompanyShareholder.name,
          acn: this.selectedCompanyShareholder.entityNumber,
          registeredAddress: this.selectedCompanyShareholder.registeredAddress
        });
      }

      this.transferToForm.controls.selectShareholder.controls.isBeneficialOwner.setValue(!holder.details.isBeneficialOwner);
      this.transferToForm.controls.selectShareholder.controls.beneficialOwner.setValue(holder.details.beneficialOwner);
    }

    this.transferToForm.controls.selectShareholder.controls.shareholderType.setValue(this.transferToForm.controls.selectShareholder.value.shareholderType ?? null);
    this.transferToForm.controls.securityHoldingId.setValue(this.transferFromForm.value.securityHoldingId ?? null);
  }

  private listenSecurityRegistryRecordIndexChanges(): void {
    this.transferFromForm.controls.shareholder.controls.securityRegistryRecordIndex.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((recordId) => {
        this.transferToForm.controls.selectShareholder.controls.securityRegistryRecordIndex.setValue(null);

        if (recordId === null) {
          this.securityRegistryRecordsHoldings = [];
          this.transferFromForm.controls.securityHoldingId.setValue(null);
          return;
        }

        this.securityRegistryRecordsHoldings = this.securityRegistryRecords[recordId]?.holdings ?? null;
        this.selectedShareholderShareType = this.securityRegistryRecordsHoldings?.[0] ?? null;
        this.cdr.detectChanges();

        const securityHoldingId = this.securityRegistryRecords[recordId]?.holdings?.[0].securityHoldingId ?? null;
        this.transferFromForm.controls.securityHoldingId.setValue(securityHoldingId);
        this.cdr.detectChanges();
      });
  }

  private listenSecurityHoldingIdChanges(): void {
    this.transferFromForm.controls.securityHoldingId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((securityHoldingId) => {
        this.transferToForm.controls.securityHoldingId.setValue(securityHoldingId);
        this.selectedShareholderShareType = this.securityRegistryRecordsHoldings
          ?.find(holding => holding.securityHoldingId === securityHoldingId) ?? null;
        if (this.selectedShareholderShareType) {
          // cancelled shares >=0 && <= number of shares
          this.setNumberControlValidators(this.transferFromForm.controls.numberIncrease, () => (this.selectedShareholderShareType?.number ?? 0));
          this.transferFromForm.controls.numberIncrease.addValidators([NumbersValidators.isInteger]);
          // total paid >=0 && <= prefilled total paid
          this.setNumberControlValidators(this.transferFromForm.controls.paidIncrease, () => (this.selectedShareholderShareType?.paid ?? 0));
          // total unpaid >=0 && <= prefilled total unpaid
          this.setNumberControlValidators(this.transferFromForm.controls.unpaidIncrease, () => (this.selectedShareholderShareType?.unpaid ?? 0));
        }
        this.transferFromForm.controls.numberIncrease.setValue(this.selectedShareholderShareType?.number ?? 0);
      });
  }

  private listenNumberOfSharesChanges(): void {
    this.transferFromForm.controls.numberIncrease.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((numberOfShares) => {
        this.transferToForm.controls.numberOfSharesTransfered.setValue(numberOfShares);
        if (numberOfShares === null || this.selectedShareholderShareType === null) {
          return;
        }

        this.transferFromForm.controls.considerationPaid.setValue(numberOfShares * this.selectedShareholderShareType.securityType.paidAmount);
        this.transferFromForm.controls.paidIncrease.setValue(numberOfShares * this.selectedShareholderShareType.securityType.paidAmount);
        this.transferFromForm.controls.unpaidIncrease.setValue(numberOfShares * this.selectedShareholderShareType.securityType.unpaidAmount);
      });
  }

  private setCustomFormValidators(): void {
    const paidTotalValidator: ValidatorFn = () => {
      if (this.selectedShareholderShareType === null || !this.transferFromForm.value.paidIncrease || !this.transferFromForm.value.numberIncrease) {
        return null;
      }
      // (Math.abs(prefilled total paid - total paid))/prefilled total paid < 0.05

      const calculatedTotalPaid = this.selectedShareholderShareType.securityType.paidAmount * this.transferFromForm.value.numberIncrease;
      return (Math.abs(calculatedTotalPaid - this.transferFromForm.value.paidIncrease) / calculatedTotalPaid) < 0.05
        ? null
        : { paidChangeIsBiggerThan5Percent: true };
    };

    const unpaidTotalValidator: ValidatorFn = () => {
      if (this.selectedShareholderShareType === null || !this.transferFromForm.value.unpaidIncrease || !this.transferFromForm.value.numberIncrease) {
        return null;
      }
      // (Math.abs(prefilled total unpaid - total unpaid))/prefilled total unpaid < 0.05

      const calculatedTotalPaid = this.selectedShareholderShareType.securityType.unpaidAmount * this.transferFromForm.value.numberIncrease;
      return (Math.abs(calculatedTotalPaid - this.transferFromForm.value.unpaidIncrease) / calculatedTotalPaid) < 0.05
        ? null
        : { unpaidChangeIsBiggerThan5Percent: true };
    };

    // total paid + total unpaid > 0
    const paidUnpaidSumValidator: ValidatorFn = () => {
      const totalPaid = this.transferFromForm.controls.paidIncrease.getRawValue();
      const totalUnpaid = this.transferFromForm.controls.unpaidIncrease.getRawValue();
      if (totalPaid === null || totalUnpaid === null) {
        return null;
      }

      return (totalPaid + totalUnpaid) > 0
        ? null
        : { paidUnpaidSumIsNotPositive: true };
    };

    this.transferFromForm.setValidators([
      paidTotalValidator,
      unpaidTotalValidator,
      paidUnpaidSumValidator
    ]);
  }

  private buildShortBaseHolders(holders: (IndividualHolderModel | CorporateHolderModel)[]): (IndividualHolderModel | CorporateHolderModel)[] {
    return holders.map(holder => {
      if (holder.$type === IndividualHolderModel.$type) {
        return this.buildShortIndividualHolderModel(holder as IndividualHolderModel);
      } else if (holder.$type === CorporateHolderModel.$type) {
        return this.buildShortCorporateHolderModel(holder as CorporateHolderModel);
      }
      return holder;
    });
  }

  private buildShortIndividualHolderModel(individualHolder: IndividualHolderModel): IndividualHolderModel {
    const isBeneficialOwner = this.transferToForm.controls.selectShareholder.value.isBeneficialOwner !== null
      ? !this.transferToForm.controls.selectShareholder.value.isBeneficialOwner
      : individualHolder.details.isBeneficialOwner;
    const individualHolderModel = {
      $type: IndividualHolderModel.$type,
      relationshipId: individualHolder.relationshipId || Guid.generate(),
      details: {
        isBeneficialOwner,
        $type: new ShareholderRelationshipDetails().$type,
        beneficialOwner: this.transferToForm.controls.selectShareholder.value.beneficialOwner ?? individualHolder.details.beneficialOwner ?? '',
      } as unknown as ShareholderRelationshipDetails,
      individualData: {
        firstName: individualHolder.individualData.firstName,
        lastName: individualHolder.individualData.lastName,
        address: individualHolder.individualData.address
      } as IndividualData,
    } as IndividualHolderModel;

    if (individualHolder.individualId) {
      individualHolderModel.individualId = individualHolder.individualId;
    }

    return individualHolderModel;
  }

  private buildShortCorporateHolderModel(corporateHolder: CorporateHolderModel): CorporateHolderModel {
    const isBeneficialOwner = this.transferToForm.controls.selectShareholder.value.isBeneficialOwner !== null
      ? !this.transferToForm.controls.selectShareholder.value.isBeneficialOwner
      : corporateHolder.details.isBeneficialOwner;
    return {
      $type: 'CorporateHolderModel',
      details: {
        isBeneficialOwner,
        $type: new ShareholderRelationshipDetails().$type,
        beneficialOwner: this.transferToForm.controls.selectShareholder.value.beneficialOwner ?? corporateHolder.details.beneficialOwner ?? '',
      } as unknown as ShareholderRelationshipDetails,
      entityData: {
        name: corporateHolder.entityData.name,
        entityNumber: corporateHolder.entityData.entityNumber,
        registeredAddress: corporateHolder.entityData.registeredAddress
      } as EntityData,
      relationshipId: corporateHolder.relationshipId || Guid.generate(),
    } as CorporateHolderModel;
  }

  private setNumberControlValidators(control: FormControl, maxFn: () => number, min = 0): void {
    control.setValidators([Validators.required, NumbersValidators.min(min), NumbersValidators.relativeMax(maxFn.bind(this))]);
    control.updateValueAndValidity();
  }

  get transferFromForm() {
    return this.stepperForm.controls[CompanySecurityTransferStepsEnum.TransferFrom];
  }

  get transferToForm() {
    return this.stepperForm.controls[CompanySecurityTransferStepsEnum.TransferTo];
  }

  get formShareNumberErrorMessageHidden(): boolean {
    return Boolean(this.transferFromForm.controls.numberIncrease?.untouched
      && this.transferFromForm.controls.paidIncrease?.untouched
      && this.transferFromForm.controls.unpaidIncrease?.untouched
      && !this.transferFromForm?.errors);
  }

  get transferToSecurityRegistryRecords(): SecurityRegistryRecord[] {
    return this.securityRegistryRecords
      .filter((_, index) =>
        this.transferFromForm.controls.shareholder.value.securityRegistryRecordIndex !== null
        && this.transferFromForm.controls.shareholder.value.securityRegistryRecordIndex !== index);
  }
}
