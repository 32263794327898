@if(!loading()) {
  @if(!isRowData()) {
    <app-button
      [btnColor]="'white'"
      [iconClass]="'icon ic-table'"
      [isDisabled]="!isRowData()"
    ></app-button>
  } @else {
    <div ngbDropdown #dropdown="ngbDropdown" [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="column-filter">
      <app-button
        ngbDropdownToggle
        [btnColor]="(dropdown.openChange | async) ? 'gray' : 'white'"
        [iconClass]="'icon ic-table'"
      ></app-button>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        @for (column of colDefs(); track $index) {
          @if(!column.suppressColumnsToolPanel) {
            @if (column.headerName && column?.field) {
              <div class="filter-option d-flex align-items-center">
                <i class="icon ic-xs ic-drag-and-drop mr-2"></i>
                <app-checkbox
                  [label]="column.headerName"
                  [value]="!column.hide"
                  [disabled]="disableSelection() && !column.hide"
                  (change)="onCheckboxChange(column)"
                ></app-checkbox>
              </div>
            } @else if(column.headerName && column?.children?.length) {
              <div class="filter-option d-flex align-items-center">
                <i class="icon ic-xs ic-drag-and-drop mr-2"></i>
                <app-checkbox
                  [label]="column.headerName"
                  [value]="!column.hide"
                  [disabled]="disableSelection() && !column.hide"
                  (change)="onGroupCheckboxChange(column)"
                ></app-checkbox>
              </div>

              @for(child of column?.children; track childIndex; let childIndex = $index) {
                <div class="filter-option d-flex ml-5">
                  <app-checkbox
                    [label]="child?.headerName || ''"
                    [value]="!$any(child)?.hide"
                    [disabled]="disableSelection() && !$any(child)?.hide"
                    (change)="onGroupChildCheckboxChange(column, childIndex)"
                  ></app-checkbox>
                </div>
              }
            }
          }
        }
      </div>
    </div>
  }
} @else {
  <app-button
    [btnColor]="'white'"
    [iconClass]="'icon ic-table'"
    [isDisabled]="!!loading()"
  ></app-button>
}
