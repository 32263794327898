import { Component } from '@angular/core';
import {
  CorporateHolderModel,
  HolderModelBase,
  IndividualHolderModel,
  SecurityRegistryRecord
} from "../../../../../../models/securityRegistryRecord";
import { ICellRendererParams } from "ag-grid-community";
import { IContainsHolderData } from "../ag-holder/ag-holder.component";

@Component({
  selector: 'app-ag-holder-number-of-shares',
  standalone: true,
  templateUrl: './ag-holder-number-of-shares.component.html',
  styleUrl: './ag-holder-number-of-shares.component.scss'
})
export class AgHolderNumberOfSharesComponent {
  holderGroups: Record<string, HolderModelBase[]> = {};
  pendingButtonState = false;
  numberOfShares = 0;
  previousNumberOfShares = 0;

  CorporateHolderModel = CorporateHolderModel;
  IndividualHolderModel = IndividualHolderModel;

  agInit(params: ICellRendererParams<SecurityRegistryRecord>): void {
    this.updateCell(params);
  }

  refresh(params: ICellRendererParams<SecurityRegistryRecord>): boolean {
    this.updateCell(params);
    return true;
  }

  updateCell(params: ICellRendererParams<SecurityRegistryRecord>): void {
    this.holderGroups = params.data!.beneficialOwnerGroups;
    const holder = this.holderGroups['']?.[0] as IContainsHolderData | null;
    this.pendingButtonState = holder?.pendingButtonState ?? false;
    this.numberOfShares = params.data?.number ?? 0;
    this.previousNumberOfShares = params.data?.previousNumber ?? 0;
  }
}
