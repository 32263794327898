import { NgTemplateOutlet } from '@angular/common';
import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, of, tap } from 'rxjs';
import { EntityType, getEntityType } from '../../../models/enums/entityType';
import { Tag } from '../../../models/tag';
import { TagsService } from '../../../services/tags.service';
import { ITagsEntity } from '../../settings/components/tags/manage-tags-modal/manage-tags-modal.component';
import { ButtonComponent } from '../common/button/button.component';
import { CheckboxComponent } from '../common/checkbox/checkbox.component';
import { DividerComponent } from '../common/divider/divider.component';
import { LoaderComponent } from '../common/loader/loader.component';
import { SearchInputComponent } from '../common/search-input/search-input.component';
import { TagComponent } from '../common/tag/tag.component';
import { SelectTagsDropdownComponent } from './select-tags-dropdown/select-tags-dropdown.component';
import { HasRoleDirective } from '../../../directives/has-role.directive';

@Component({
  selector: 'app-entity-profile-tags',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgTemplateOutlet,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    LoaderComponent,
    CheckboxComponent,
    DividerComponent,
    ButtonComponent,
    TagComponent,
    SearchInputComponent,
    SelectTagsDropdownComponent,
    HasRoleDirective
  ],
  templateUrl: './entity-profile-tags.component.html',
  styleUrl: './entity-profile-tags.component.scss',
})
export class EntityProfileTagsComponent implements OnInit {
  private toastr = inject(ToastrService);
  private tagsService = inject(TagsService);

  @Input() entityProfile!: ITagsEntity;
  @Input() disableControls = false;

  noTagsAssignedMessage = 'No tags assigned';
  actualTagsSignal = signal<Tag[]>([]);
  form = new FormGroup({
    search: new FormControl<string | null>(null),
    actualTags: new FormArray<FormControl<boolean>>([]),
    suggestedTags: new FormArray<FormControl<boolean>>([]),
  });

  ngOnInit(): void {
    this.actualTagsSignal.set(this.entityProfile.tags);
  }

  addNewTag(): void {
    const { modalRef, instance } = this.tagsService.openCreateEditTagModal();
    instance.isEdit = false;
    modalRef.result.then((newTag: Tag) => {
        this.createOrEditTag(newTag);
      },
      () => {
      });
  }

  assignTag({ tag, assign }: { tag: Tag, assign: boolean }): void {
    if (assign && !this.entityProfile.tags.find(t => t.tagId === tag.tagId)) {
      this.entityProfile.tags = [...this.entityProfile.tags, tag];
    } else {
      this.entityProfile.tags = this.entityProfile.tags.filter(t => t.tagId !== tag.tagId);
    }

    this.actualTagsSignal.set(this.entityProfile.tags);
    this.tagsService.assignTag(tag, this.entityId, assign, this.entityType)
      .pipe(catchError((error) => {
        console.warn('[EntityProfileTagsComponent] assignUnassignTag: ', error);
        this.toastr.error('Failed to load tags.');
        return of();
      }))
      .subscribe();
  }

  private createOrEditTag(tag: Tag): void {
    this.tagsService.createOrUpdateTag(tag, this.entityId, true, this.entityType)
      .pipe(
        tap((tagId) => {
          this.assignTag({ tag: new Tag({ ...tag, tagId }), assign: true });
        }),
        catchError((error) => {
          console.warn('[EntityProfileTagsComponent] createOrUpdateTag: ', error);
          this.toastr.error('Failed to load tags.');
          return of([]);
        })
      )
      .subscribe();
  }

  get entityId(): string {
    return (this.entityProfile?.['entityId'] || this.entityProfile['individualId']) as string;
  }

  get entityType(): EntityType {
    return getEntityType(this.entityProfile);
  }
}
