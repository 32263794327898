<fieldset>
  <div class="autocomplete-container" [style.width]="width">
    @if (!showBulkSearch) {
      <div class="header">
        @if (label) {
          <div class="label">{{ label }}</div>
        }

        @if (link && text) {
          <div>
            <app-link [link]="link" [text]="text"></app-link>
          </div>
        } @else {
          <app-form-switch-button
            [text]="switchBtnText"
            (switchForm)="onClickBtn()"
          ></app-form-switch-button>
        }
      </div>
    }

    <div
      class="input-wrapper"
      [ngClass]="{
        'error': searchFailed || displayValidationError,
        'check': (status === AddressStatus.CHECKED) && displayStatus,
        'warn': status === AddressStatus.WARNING
      }"
    >
      @if (showBulkSearch) {
        <i class="input-icon-left fa fa-search" [ngClass]="{'text-gray600': !value, 'text-gray700': value}"></i>
      }

      <input
        #typeaheadInput
        type="text"
        class="input"
        [ngClass]="{'bulk': showBulkSearch}"
        [value]="value"
        [ngbTypeahead]="search"
        [placeholder]="placeholder"
        [disabled]="isDisabled || disabled"
        (blur)="onBlurSearchControl($event)"
        (click)="autocompleteTrigger$.next()"
        (focus)="onFocusSearchControl($event)"
        (selectItem)="selectOption($event)"
        (keydown)="focusOnActiveOption($event, typeaheadInput)"
      />

      @if (searching || isValidating) {
        <i class="input-icon fad fa-spinner-third fa-spin"></i>
      } @else {
        <i
          class="input-icon icon"
          [ngClass]="{
          'ic-circle-info': status === AddressStatus.WARNING || displayValidationError,
          'ic-circle-check': status === AddressStatus.CHECKED && value,
          'bulk': showBulkSearch
        }">
        </i>
      }

      @if (showBulkSearch && searchTerm) {
        <i class="input-icon icon ic-close" (click)="clear()"></i>
      }
    </div>

    @if (helperText) {
      <div class="helper">{{ helperText }}</div>
    }

    @if (status === AddressStatus.WARNING && !displayValidationError) {
      <div class="helper">Address was not validated and most likely to be rejected</div>
    }

    @if (displayValidationError) {
      <app-validation-error
        [control]="control"
        [customErrors]="customErrors"
      />
    }
  </div>
</fieldset>
