<div class="card document-settings-page settings-page">
  <app-page-title
    header="Document Settings"
    iconClass="ic-settings"
    backButtonPath="settings"
  >
    <app-button
      id="add-tag-dd"
      text="Edit"
      [iconClass]="'icon ic-xs ic-edit'"
      (clickBtn)="openEditDocumentSettingsModal()"
      *appHasRole="['Admin', 'Manager']"
    />
  </app-page-title>

  <div class="document-settings-page-content">
    @if (!isLoading) {
      <div class="documents-part">
        <div class="part-title">Documents</div>
        <div class="grid">
          <div class="form-group">
            <label class="title mb-1">Receive email for e-signed documents</label>
            <span class="property"
                  appCopy>{{ NotifyWhenAllPartiesSignedDocumentLabelRecord[documentSettings.receiveEmailsForDocs] | defaultValue }}</span>
          </div>
        </div>
      </div>

      <div class="annual-statement-part">
        <div class="part-title">Annual Statement</div>

        <div class="grid">
          <div class="form-group">
            <label class="title mb-1">Receive email for e-signed documents</label>
            <span class="property"
                  appCopy>{{ NotifyWhenAllPartiesSignedDocumentLabelRecord[documentSettings.receiveEmailsForDocs] | defaultValue }}</span>
          </div>

          <div class="form-group">
            <label class="title mb-1">Do you want to add Director Signature in Company Statement?</label>
            <span class="property"
                  appCopy>{{ (documentSettings.insertDirectorSignatureInCompanyStatement ? 'Yes' : 'No') | defaultValue }}</span>
          </div>
        </div>
      </div>
    }

    <app-loader [loading]="isLoading"></app-loader>
  </div>
</div>
