import { AnnualChangeData } from "./annualChangeData";
import { DocumentBase } from "./documentEnteties/document-base";
import { AnnualStatementStatus } from "./enums/annualStatementEnums";
import { ReminderRecord } from "./reminderRecord";

export class AnnualStatement extends DocumentBase {
  annualStatementStatus: AnnualStatementStatus;
  isPaid: boolean
  paymentAmount: number;
  override changes: AnnualChangeData[];
  paymentDeadline: string | null;
  reviewDate: string | null;
  reminders: ReminderRecord[];

  constructor(data: Partial<AnnualStatement> = {}) {
    super(data);
    this.annualStatementStatus = data.annualStatementStatus ?? AnnualStatementStatus.ReadyToPrepare;
    this.changes = data.changes ?? [];
    this.isPaid = data.isPaid ?? false;
    this.paymentAmount = data.paymentAmount ?? 0;
    this.paymentDeadline = data.paymentDeadline ?? null;
    this.reviewDate = data.reviewDate ?? null;
    this.reminders = data.reminders ?? [];
  }
}
