import { EntityChangeData } from "./entityChangeData";
import { Company } from "./company";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Document } from "./document";
import { ChangeDictionaryHelper } from "./shared/change-dictionary-helper.model";
import {
  FormCompanyStandardComponent
} from "../app/modals/documents/asic-forms/form-company-standard/form-company-standard.component";
import { PaymentMethod, paymentMethodsNames } from "../app/modals/company-name-change/CompanyChangeName";
import { JurisdictionType } from "./enums/trustEnum";
import { Address } from "./address";
import { Relationship, ShareholderRelationshipDetails } from "./relationship";
import { SecurityHolding } from "./securityHolding";
import { SecurityTransaction } from "./securityTransaction";
import { RelationshipType, RelationshipTypeLabels } from "./enums/relationshipTypeEnum";
import { OfficerAppointmentType } from "./officerAppointmentForm";
import { SecurityTransactionType } from "./enums/SecurityTransactionType";
import { Guid } from "../app/helpers/guid.helper";
import {
  IndividualDataFormGroup,
  IndividualDataFormGroupControls
} from "../app/components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import {
  CompanyNameAcnFormGroupControls
} from "../app/components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import { ShareholderTypesEnum } from "../app/modals/share/share-issue/share-issue.model";
import { AddressFormGroup } from "../app/components/common/address-control/address-control.component";
import { CorporateHolderModel, IndividualHolderModel } from "./securityRegistryRecord";
import { IPDFSection } from "./shared/pdf-section.interface";
import { CountriesService } from "../services/countries.service";
import { CompanyChangeData } from "./enums/companyChangeData";

export class SecurityTransactionBalance extends SecurityTransaction {
  constructor(data: Partial<SecurityTransactionBalance> = {}) {
    super(data);
    this.transactionType = SecurityTransactionType.Balance;
  }
}

export class CompanyRegistration extends EntityChangeData {
  static override $type = 'CompanyRegistration';

  // CompanyStandardStepsEnum.CompanyDetails
  companyName: string | null;
  useAcnAsNewCompanyName: boolean;
  newLegalElementsIfAcnIsUsed: string | null;
  jurisdiction: JurisdictionType | null;
  isProposedNameIdenticalToRegisteredBusinessName: boolean;
  businessNameHolderAbn: string | null;
  manualReviewRequested: boolean;
  manualProcessingReason: string;
  newNameReservedWithForm410: boolean;
  reservationNumber: string | null;
  applicant: string | null;

  haveUltimateHoldingCompany: boolean;
  ultimateHoldingCompanyName: string | null;
  ultimateHoldingCompanyAcnOrAbn: string | null;
  ultimateHoldingCompanyCountry: string | null;

  // CompanyStandardStepsEnum.Address
  registeredAddress: Address;
  principalAddress: Address;
  occupiesTheAddress: boolean;
  occupierNameIfDoesntOccupy: string | null;

  // CompanyStandardStepsEnum.Officeholders
  officers: Relationship[];

  // CompanyStandardStepsEnum.Shareholders
  balances: SecurityTransactionBalance[];
  securityHoldings: SecurityHolding[];

  // General
  paymentMethod: PaymentMethod;

  constructor(data: Partial<CompanyRegistration> = {}) {
    super(data);

    // CompanyStandardStepsEnum.CompanyDetails

    //    name part
    this.companyName = data.companyName ?? '';
    this.useAcnAsNewCompanyName = this.nameIncludesAcnAndLegalElements();
    this.newLegalElementsIfAcnIsUsed = data.newLegalElementsIfAcnIsUsed ?? null;
    this.jurisdiction = data.jurisdiction ?? null;
    this.isProposedNameIdenticalToRegisteredBusinessName = data.isProposedNameIdenticalToRegisteredBusinessName ?? false;
    this.businessNameHolderAbn = data.businessNameHolderAbn ?? null;
    this.manualReviewRequested = data.manualReviewRequested ?? false;
    this.manualProcessingReason = data.manualProcessingReason ?? '';
    this.newNameReservedWithForm410 = data.newNameReservedWithForm410 ?? false;
    this.reservationNumber = data.reservationNumber ?? null;
    this.applicant = data.applicant ?? null;

    //    Ultimate Holding Company part
    this.haveUltimateHoldingCompany = data.haveUltimateHoldingCompany ?? false;
    this.ultimateHoldingCompanyName = data.ultimateHoldingCompanyName ?? '';
    this.ultimateHoldingCompanyAcnOrAbn = data.ultimateHoldingCompanyAcnOrAbn ?? '';
    this.ultimateHoldingCompanyCountry = data.ultimateHoldingCompanyCountry ?? '';

    // CompanyStandardStepsEnum.Address
    this.registeredAddress = data.registeredAddress ? new Address(data.registeredAddress) : new Address();
    this.principalAddress = data.principalAddress ? new Address(data.principalAddress) : new Address();
    this.occupiesTheAddress = data.occupiesTheAddress ?? true;
    this.occupierNameIfDoesntOccupy = data.occupierNameIfDoesntOccupy ?? null;

    // CompanyStandardStepsEnum.Officeholders
    this.officers = data.officers?.map((r) => new Relationship(r)) ?? [];

    // CompanyStandardStepsEnum.Shareholders
    this.balances = data.balances?.map((b) => new SecurityTransactionBalance(b)) ?? [];
    this.securityHoldings = data.securityHoldings?.map((h) => new SecurityHolding(h)) ?? [];

    // General
    this.paymentMethod = data.paymentMethod ?? PaymentMethod.INV;
  }

  override toDictionary(): { key: string; value: string }[] {
    const dict = new ChangeDictionaryHelper();

    // COMPANY DETAILS SECTION
    dict
      .add('Proposed Name of Company', this.companyName)
      .addJurisdiction('Jurisdiction', this.jurisdiction!)
      .addYesNo('Is Existing Business Name', this.isProposedNameIdenticalToRegisteredBusinessName);

    if (this.isProposedNameIdenticalToRegisteredBusinessName) {
      dict
        .addAbn('ABN', this.businessNameHolderAbn);
    }

    dict
      .addYesNo('Would you like ASIC to manually review the company registration?', this.manualReviewRequested)
      .addIfValueIsNotEmpty('Manual Processing Reason', this.manualProcessingReason)
      .addYesNo('Does this company have an Ultimate Holding Company', this.haveUltimateHoldingCompany);

    if (this.haveUltimateHoldingCompany) {
      dict
        .addIfValueIsNotEmpty('Name of the Ultimate Holding Company', this.ultimateHoldingCompanyName)
        .addAcn('ACN', this.ultimateHoldingCompanyAcnOrAbn)
        .addIfValueIsNotEmpty('Place of Incorporation of Ultimate Holding Company', this.ultimateHoldingCompanyCountry ? CountriesService.getCountryName(this.ultimateHoldingCompanyCountry) : '');
    }

    dict
      .addYesNo('Have you reserved the proposed name by lodging form 410', this.newNameReservedWithForm410)
      .addIfValueIsNotEmpty('Reservation Number', this.reservationNumber)
      .add('Payment Method', paymentMethodsNames[this.paymentMethod])
      .addDate('Date of Registration', this.changeDate);

    // ADDRESS SECTION
    dict
      .addDivider()
      .add('Registered Address', this.registeredAddress.normalizedFullAddress)
      .addYesNo('Will this company occupy this address', this.occupiesTheAddress);

    if (!this.occupiesTheAddress) {
      dict.add('Name of the Occupier', this.occupierNameIfDoesntOccupy);
    }

    dict.add('Principal Business Address', this.principalAddress.normalizedFullAddress);

    // OFFICERS SECTIONS
    const officesByIndividuals = this.officers
      .reduce((officesTypes, relationship) => {
        if (relationship.individualId) {
          if (officesTypes[relationship.individualId]) {
            officesTypes[relationship.individualId].push(relationship);
          } else {
            officesTypes[relationship.individualId] = [relationship];
          }
        }

        return officesTypes;
      }, {} as Record<string, Relationship[]>);

    Object.entries(officesByIndividuals).forEach(([individualId, relationships], index) => {
      const officer = relationships[0];
      const positions = officesByIndividuals[individualId]
        ? officesByIndividuals[individualId].map((relationship) => RelationshipTypeLabels[relationship.type])
          .join(', ')
        : '';

      dict
        .addDivider()
        .add('Officer', officer.individualDataOverride?.fullName)
        .add('Former Name/s', officer.individualDataOverride?.formerName?.fullName)
        .addDate('Date of birth', officer.individualDataOverride?.dob)
        .add('Residential Address', officer.individualDataOverride?.address.normalizedFullAddress)
        .add('City of Birth', officer.individualDataOverride?.birthCity)
        .addCountry('Place of Birth', officer.individualDataOverride!.birthCountry!)
        .add('Position', positions)
        .addIfValueIsNotEmpty('Director ID', officer.individualDataOverride?.din);
    });

    // SHAREHOLDERS SECTIONS
    this.securityHoldings.forEach((holding) => {
      const isBeneficialOwner = !holding.relationships?.some((r) => (r?.details as ShareholderRelationshipDetails)?.isBeneficialOwner);
      const beneficialOwner = (holding.relationships?.[0]?.details as ShareholderRelationshipDetails)?.beneficialOwner ?? '';

      dict
        .addDivider();

      holding.relationships?.forEach((relationship) => {
        dict
          .add('Shareholder', relationship.individualDataOverride?.fullName ?? relationship.entityDataOverride?.name ?? '')
          .add('Residential Address', relationship?.individualDataOverride?.address?.normalizedFullAddress ?? relationship.entityDataOverride?.registeredAddress?.normalizedFullAddress ?? '');
      });

      dict
        .add('Share Class', holding.securityType.class)
        .add('Number of Shares', holding.number)
        .addMoney('Total Amount Paid', holding.paid)
        .addMoney('Total Amount Unpaid', holding.unpaid)
        .addNoYes('Shares are owned on behalf', isBeneficialOwner)
        .addIfValueIsNotEmpty('Shares are owned on behalf', beneficialOwner);
    });

    return dict.dictionary;
  }

  override prepareToPdf(): IPDFSection[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .addSection('Pre-registration Consents')
      .add('The applicant lodging this application agrees and declares that he has obtained all the necessary pre-registration consent from the relevant parties. NowInfinity shall not be held responsible for obtaining the same.', 'Yes');

    // COMPANY DETAILS SECTION
    dict
      .addSection('Company Details')
      .add('Proposed Name of Company', this.companyName)
      .addJurisdiction('Jurisdiction', this.jurisdiction!)
      .addYesNo('Is Existing Business Name', this.isProposedNameIdenticalToRegisteredBusinessName);

    if (this.isProposedNameIdenticalToRegisteredBusinessName) {
      dict
        .addAbn('ABN', this.businessNameHolderAbn);
    }

    dict
      .addYesNo('Would you like ASIC to manually review the company registration?', this.manualReviewRequested)
      .addIfValueIsNotEmpty('Manual Processing Reason', this.manualProcessingReason)
      .addYesNo('Does this company have an Ultimate Holding Company', this.haveUltimateHoldingCompany);

    if (this.haveUltimateHoldingCompany) {
      dict
        .addIfValueIsNotEmpty('Name of the Ultimate Holding Company', this.ultimateHoldingCompanyName)
        .addAcn('ACN of the Ultimate Holding Company', this.ultimateHoldingCompanyAcnOrAbn)
        .addIfValueIsNotEmpty('Place of Incorporation of Ultimate Holding Company', this.ultimateHoldingCompanyCountry ? CountriesService.getCountryName(this.ultimateHoldingCompanyCountry) : '');
    }

    dict
      .addYesNo('Have you reserved the proposed name by lodging form 410', this.newNameReservedWithForm410)
      .addIfValueIsNotEmpty('Reservation Number', this.reservationNumber)
      .add('Payment Method', paymentMethodsNames[this.paymentMethod])
      .addDate('Date of Registration', this.changeDate);

    // ADDRESS SECTION
    dict
      .addDivider()
      .addSection('Addresses')
      .add('Registered Address', this.registeredAddress.normalizedFullAddress)
      .addYesNo('Will this company occupy this address', this.occupiesTheAddress);

    if (!this.occupiesTheAddress) {
      dict.add('Name of the Occupier', this.occupierNameIfDoesntOccupy);
    }

    dict.add('Principal Business Address', this.principalAddress.normalizedFullAddress);

    // OFFICERS SECTIONS
    const officesByIndividuals = this.officers
      .reduce((officesTypes, relationship) => {
        if (relationship.individualId) {
          if (officesTypes[relationship.individualId]) {
            officesTypes[relationship.individualId].push(relationship);
          } else {
            officesTypes[relationship.individualId] = [relationship];
          }
        }

        return officesTypes;
      }, {} as Record<string, Relationship[]>);

    Object.entries(officesByIndividuals).forEach(([individualId, relationships], index) => {
      const officer = relationships[0];
      const positions = officesByIndividuals[individualId]
        ? officesByIndividuals[individualId].map((relationship) => RelationshipTypeLabels[relationship.type])
          .join(', ')
        : '';

      const officerSectionHeader = index === 0
        ? `Public Officer/Office Holder ${ index + 1 }`
        : `Office Holder ${ index + 1 }`;
      dict
        .addDivider()
        .addSection(officerSectionHeader)
        .add('Name', officer.individualDataOverride?.fullName)
        .add('Former Name/s', officer.individualDataOverride?.formerName?.fullName)
        .addDate('Date of birth', officer.individualDataOverride?.dob)
        .add('Residential Address', officer.individualDataOverride?.address.normalizedFullAddress)
        .add('City of Birth', officer.individualDataOverride?.birthCity)
        .addCountry('Place of Birth', officer.individualDataOverride!.birthCountry!)
        .add('Position', positions)
        .addIfValueIsNotEmpty('Director ID', officer.individualDataOverride?.din);
    });

    // SHAREHOLDERS SECTIONS
    this.securityHoldings.forEach((holding) => {
      const isBeneficialOwner = !holding.relationships?.some((r) => (r.details as ShareholderRelationshipDetails).isBeneficialOwner);
      const beneficialOwner = (holding.relationships?.[0]?.details as ShareholderRelationshipDetails).beneficialOwner ?? '';

      dict
        .addDivider();

      holding.relationships?.forEach((relationship) => {
        dict
          .add('Shareholder', relationship.individualDataOverride?.fullName ?? relationship.entityDataOverride?.name ?? '')
          .add('Residential Address', relationship?.individualDataOverride?.address?.normalizedFullAddress ?? relationship.entityDataOverride?.registeredAddress?.normalizedFullAddress ?? '');
      });

      dict
        .add('Share Class', holding.securityType.class)
        .add('Number of Shares', holding.number)
        .addMoney('Total Amount Paid', holding.paid)
        .addMoney('Total Amount Unpaid', holding.unpaid)
        .addNoYes('Shares are owned on behalf', isBeneficialOwner)
        .addIfValueIsNotEmpty('Shares are owned on behalf', beneficialOwner);
    });

    return dict.sections;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    return this.fillModalWithCompany(modalRef, actualCompany, true);
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as FormCompanyStandardComponent;
    instance.formModel = this;
    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    return modalRef;
  }

  override prepareToRequest(): CompanyRegistration {
    return {
      ...this,
      ...super.prepareToRequest(),
      jurisdiction: `${ this.jurisdiction }`,
      officers: this.officers.map((officer) => Relationship.prepareToRequest(officer)),
      balances: this.balances.map((balance) => {
        return {
          ...balance.prepareToRequest(),
          entityId: balance.entityId ?? Guid.EmptyGuid,
          securityHoldingId: balance.securityHoldingId ?? Guid.EmptyGuid,
          securityType: {
            ...balance.securityType,
            securityTypeId: balance.securityTypeId,
            entityId: balance.entityId ?? Guid.EmptyGuid,
          }
        };
      }),
      securityHoldings: this.securityHoldings.map((holding) => {
        return {
          ...holding,
          entityId: holding.entityId || Guid.EmptyGuid,
          securityHoldingId: holding.securityHoldingId || Guid.EmptyGuid,
          securityHoldingAsicId: holding.securityHoldingAsicId || null,
          securityType: {
            ...holding.securityType,
            securityTypeId: holding.securityTypeId,
            entityId: holding.entityId || Guid.EmptyGuid,
          }
        };
      })
    };
  }

  private nameIncludesAcnAndLegalElements(): boolean {
    if (!this.companyName || !Boolean(this.newLegalElementsIfAcnIsUsed?.trim())) return false;

    const acnWithoutSpaces = /\d{9}/;
    const acnWithSpaces = /\d{3} \d{3} \d{3}/;
    const includesACN = acnWithoutSpaces.test(this.companyName) || acnWithSpaces.test(this.companyName);

    return Boolean(this.newLegalElementsIfAcnIsUsed?.trim())
      && Boolean(this.newLegalElementsIfAcnIsUsed)
      && this.companyName.includes(this.newLegalElementsIfAcnIsUsed!)
      && includesACN;
  }
}

export enum CompanyStandardStepsEnum {
  FormDescription,
  CompanyDetails,
  Address,
  Officeholders,
  Shareholders,
}


export interface OfficeholderFormGroupControls extends IndividualDataFormGroupControls {
  type: FormControl<RelationshipType | OfficerAppointmentType | null>;
}

export type SecurityHoldingFormGroup = FormGroup<{
  securityTypeId: FormControl<string | null>
  number: FormControl<number | null>;
  paid: FormControl<number | null>;
  unpaid: FormControl<number | null>;
}>

export interface SecurityholderFormGroupControls extends CompanyNameAcnFormGroupControls {
  shareholderType: FormControl<ShareholderTypesEnum | null>;
  isBeneficialOwner: FormControl<boolean | null>;
  beneficialOwner: FormControl<string | null>;

  // selected individual shareholder type
  individualShareholder: IndividualDataFormGroup;

  // selected corporate shareholder type
  //  name: FormControl<string | null>
  //  acn: FormControl<string | null>
  isOverseasCompany: FormControl<boolean | null>;
  registeredAddress: AddressFormGroup;

  // selected joint shareholder type
  joint: FormControl<(IndividualHolderModel | CorporateHolderModel)[] | null>;

  securityTypes: FormArray<SecurityHoldingFormGroup>;
}

export type SecurityholderFormGroup = FormGroup<SecurityholderFormGroupControls>;
export type OfficeholderFormGroup = FormGroup<OfficeholderFormGroupControls>;
