import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from "../button/button.component";
import { MultipleInputComponent } from "../multiple-input/multiple-input.component";
import { TooltipComponent } from "../tooltip/tooltip.component";
import { NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import { NgClass} from "@angular/common";
import { DateFormatPipe } from "../../../../pipes/dateFormatPipe";
import { ReminderRecord } from "../../../../models/reminderRecord";
import { ReminderStatus } from "../../../../models/enums/reminderEnums";
import { SigningStatus } from "../../../../models/enums/annualStatementEnums";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatepickerHelper } from "../../../../custom-form-validators/date-picker-form-validators";

@Component({
  selector: 'app-reminder',
  standalone: true,
  imports: [
    ButtonComponent,
    MultipleInputComponent,
    TooltipComponent,
    NgbTooltip,
    NgClass,
    DateFormatPipe,
    DatePickerComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './reminder.component.html',
  styleUrl: './reminder.component.scss'
})
export class ReminderComponent {
  @Input() title = 'Signing Reminders';
  @Input() sendTooltipText = 'Send Ad-Hoc Signing Reminder';
  @Input() reminderList: ReminderRecord[] = [];
  @Input() signingStatus: SigningStatus | undefined;
  @Output() addReminder = new EventEmitter<string>();
  @Output() removeReminder = new EventEmitter<string>();
  @Output() showReminder = new EventEmitter<boolean>();

  protected readonly ReminderStatus = ReminderStatus;
  protected readonly SigningStatus = SigningStatus;

  minDate = DatepickerHelper.getStructFromDateDayAfter(new Date());
  maxDate = DatepickerHelper.getNextYearStruct();

  form = new FormGroup({
    reminderDate: new FormControl<Date>(DatepickerHelper.getDateWithDaysAfterToday(1)),
  });


  showReminderPopup(): void {
    this.showReminder.emit(true);
  }

  onRemoveReminder(id: string): void {
    this.removeReminder.emit(id);
  }

  onAddReminder(): void {
    const date = this.form.controls.reminderDate.value;

    if(date) {
      this.addReminder.emit(date.toISOString());
    }
  }
}
