<div class="confirm-modal">
  <app-common-modal-form
    [header]="'Set Signing Date'"
    [icon]="''"
    [confirmText]="'Confirm'"
    [closeText]="'Cancel'"
    (close)="close()"
    (confirm)="agree()"
  >
    <div class="message">Only signed documents can be lodged. To proceed, please enter the date of signature:</div>

    <div class="signing-contact">
      <app-date-picker
        label="Signing Date"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [(ngModel)]="signingDate"
        (ngModelChange)="onSigningDateChange($event)"
        [ngModelOptions]="{standalone: true}"
      ></app-date-picker>
    </div>
  </app-common-modal-form>
</div>
