import { Address } from "./address";
import { MeetingLocationType, DocumentationType, MeetingOnlineType, MinutesMeetingPresence } from "./enums/documentConfirmEnums";
import { IndividualData } from "./individualData";
import { Relationship } from "./relationship";
import { DocumentSelectionDto } from "./document-selection";

export class ChangeAuthorisation {
    meetingDate?: Date;
    signingManager: string;
    partnerManagerId?: string;
    authorisingParty: Relationship;
    directorReplacedByAlternative?: Relationship;
    attendees?: MinutesMeetingAttendee[] = [];
    chairperson?: Relationship;
    meetingAddress?: Address;
    documentationType: DocumentationType
    meetingAddressType?: MeetingLocationType;
    meetingOnlineAddress?: MeetingOnlineType;
    documentSelection?: DocumentSelectionDto;

    constructor(data: Partial<ChangeAuthorisation> = {}) {
        this.meetingDate = data.meetingDate ?? undefined;
        this.signingManager = data.signingManager ?? '';
        this.partnerManagerId = data.partnerManagerId ?? undefined;
        this.authorisingParty = data.authorisingParty ?? new Relationship();
        this.directorReplacedByAlternative = data.directorReplacedByAlternative ?? undefined;
        this.attendees = data.attendees ?? undefined;
        this.chairperson = data.chairperson ?? undefined;
        this.meetingAddress = data.meetingAddress ?? undefined;
        this.documentationType = data.documentationType ?? DocumentationType.Default;
        this.meetingAddressType = data.meetingAddressType ?? undefined;
        this.meetingOnlineAddress = data.meetingOnlineAddress ?? undefined;
        this.documentSelection = data.documentSelection ?? undefined;
    }
}

export class MinutesMeetingAttendee {
    individual: IndividualData;
    presence?: MinutesMeetingPresence;

    constructor (data : Partial<MinutesMeetingAttendee> = {}) {
        this.individual = data.individual ?? new IndividualData();
        this.presence = data.presence ?? undefined;
    }
}
