import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { EmailRecord } from "../../../../models/email-templates/emailRecord";
import {
  AgActionIconButtonComponent
} from "../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { TextMessageRecord } from "../../../../models/textMessageRecord";
import { HasRoleDirective } from '../../../../directives/has-role.directive';

type ActionEvent = (data: EmailRecord | TextMessageRecord | undefined) => void;
type ActionsPredicate = (data: EmailRecord | TextMessageRecord | undefined) => boolean;

type CellRendererParams = ICellRendererParams<EmailRecord | TextMessageRecord> & {
  resend: ActionEvent,
  delete: ActionEvent,
  checkShowResend?: ActionsPredicate,
  checkShowDelete?: ActionsPredicate,
  checkDisabled?: ActionsPredicate,
};

@Component({
  selector: 'app-ag-outgoing-emails-actions',
  standalone: true,
  imports: [
    AgActionIconButtonComponent,
    NgbDropdownToggle,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownItem,
    HasRoleDirective
  ],
  templateUrl: 'ag-outgoing-messages-actions.component.html',
  styleUrl: 'ag-outgoing-messages-actions.component.scss'
})
export class AgOutgoingMessagesActionsComponent implements ICellRendererAngularComp {
  @Input() params!: CellRendererParams;
  @Input() showResend = false;
  @Input() showDelete = false;
  @Input() disabled = true;

  agInit(params: CellRendererParams): void {
    this.setup(params);
  }

  refresh(params: CellRendererParams): boolean {
    this.setup(params);
    return true;
  }

  setup(params: CellRendererParams): void {
    this.params = params;
    this.showResend = params.checkShowResend?.(params?.data) ?? false;
    this.showDelete = params.checkShowDelete?.(params?.data) ?? false;
    this.disabled = params.checkDisabled?.(params?.data) ?? false;
  }

  resend(): void {
    this.params.resend(this.params.data);
  }

  delete(): void {
    this.params.delete(this.params.data);
  }
}
