import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { Document } from "../../../../models/document";
import { FilesService} from "../../../../services/files.service";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  Observable,
  of,
  ReplaySubject,
  shareReplay, Subject,
  switchMap,
  tap, throwError,
} from "rxjs";
import { AsyncPipe, CurrencyPipe, DatePipe, LowerCasePipe, NgClass } from "@angular/common";
import { downloadBase64File } from "../../../../functions/download-base64-file";
import { toBase64 } from "../../../../functions/to-base64";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FileCreateDto, FilesResponse } from "../../../../models/files";
import { bytesToMegabytes } from "../../../../functions/bytes-to-megabytes";
import { RouterLink } from "@angular/router";
import { DocumentsService } from "../../../../services/documents.service";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AlertsStatusPipe } from "../../../../pipes/enumsPipes/alertsStatusPipe";
import { DocumentStatusEnum } from "../../../../models/enums/documentStatusEnum";
import { DocumentStatusPipe } from "../../../../pipes/enumsPipes/documentStatusPipe";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { TagComponent } from "../../../components/common/tag/tag.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  AgTagEntityTitleComponent
} from "../../../components/common/grid/components/ag-tag-entity-title/ag-tag-entity-title.component";
import { EntityType } from '../../../../models/enums/entityType';
import { Company } from "../../../../models/company";
import { Trust } from "../../../../models/trust";
import { DocumentSubFolderName } from '../../../../models/enums/documentFolderNameEnum';
import { DocumentStepEnum } from "../../../../models/enums/documentStepEnum";
import { LodgementDeadline } from "../../../../models/documents";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { MarkSignedComponent } from "../../../modals/mark-signed/mark-signed.component";
import { SigningStatus } from "../../../../models/enums/annualStatementEnums";
import { Operation } from "fast-json-patch";
import { ESignService } from '../../../../services/e-sign.service';
import { ConfirmComponent } from "../../../modals/confirm/confirm.component";

@Component({
  selector: 'app-generate-documents-sidebar',
  standalone: true,
  imports: [
    ButtonComponent,
    AsyncPipe,
    NgxSkeletonLoaderModule,
    AlertsStatusPipe,
    DatePipe,
    NgClass,
    DocumentStatusPipe,
    LowerCasePipe,
    DividerComponent,
    RouterLink,
    TagComponent,
    CurrencyPipe,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AgTagEntityTitleComponent
  ],
  templateUrl: './generate-documents-sidebar.component.html',
  styleUrl: './generate-documents-sidebar.component.scss'
})
export class GenerateDocumentsSidebarComponent implements OnInit {
  @Input() loadCompany = false;
  @Input() entityType = EntityType.Company;
  @Input() entity: Company | Trust | null = null;
  @Input() currentStepIndex: number | undefined;
  @Input() lodgement: LodgementDeadline | undefined;
  @Input() confirmLodgeDocumentNowAction$: Subject<boolean> | undefined;
  @Input() set document(value: Document) {
    if (value) {
      this._document = value;
      this.document$.next(this._document);
    }
  }
  @Output() setLastStep = new EventEmitter<boolean>();

  #filesService = inject(FilesService);
  #destroyRef = inject(DestroyRef);
  #documentsService: DocumentsService = inject(DocumentsService);
  #eSignService = inject(ESignService);
  #modalService = inject(NgbModal);
  #toastr = inject(ToastrService);
  #cdr = inject(ChangeDetectorRef);

  DocumentSubFolderName = DocumentSubFolderName;

  readonly changeDocumentStatusOperation: Operation[] = [
    {
      path: '/documentStatus',
      op: 'replace',
      value: DocumentStepEnum.AsicResponse,
    }
  ];

  public lodgeNowLoading = this.#documentsService.lodgeNowLoading;
  public loading = false;
  protected _document: Document | undefined;
  protected signatureDate: string | undefined;
  private readonly document$ = new ReplaySubject<Document>(1);
  private readonly refreshList$ = new BehaviorSubject(false);
  protected readonly DocumentStatusEnum = DocumentStatusEnum;
  protected readonly DocumentStepEnum = DocumentStepEnum;
  protected documentsList$:Observable<FilesResponse> = combineLatest([this.refreshList$, this.document$]).pipe(
    tap(() => { this.loading = true; this.#cdr.detectChanges(); }),
    filter(([_, document]) => !!document),
    switchMap(([_, document]) => this.#filesService.getFiles(document.documentId)),
    tap(() => this.loading = false),
    shareReplay(1),
  );

  constructor() {
    this.#toastr.toastrConfig.positionClass = 'toast-top-right';
  }

  ngOnInit(): void {
    this.confirmLodgeDocumentNowAction$?.pipe(
      filter(confirm => confirm),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(() => {
      this.lodgeDocument();
      this.confirmLodgeDocumentNowAction$?.next(false);
    });
  }

  downloadFile(fileName: string, subFolder: string) {
    this.#filesService
      .downloadFile(this._document!.documentId, fileName, subFolder)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.#toastr.error(`Download ${fileName} error`, "Error");
          return of('ERR');
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((res) => {
        if(res !== 'ERR') {
          downloadBase64File(res, fileName);
        }
      });
  }

  uploadFile(uploadInput: HTMLInputElement) {
    uploadInput.click();
  }

  deleteFile(fileName: string): void {
    const modalRef = this.#modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete file';
    (modalRef.componentInstance as ConfirmComponent).message = `Are you sure you want to delete file ${fileName} ?`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => this.#filesService.deleteFile(this._document!.documentId, fileName).pipe(
      tap(() => {
        this.refreshList$.next(true)
        this.#toastr.success('File deleted!', 'Success');
      }),
      catchError((error: HttpErrorResponse) => {
        this.#toastr.error("Error while deleting file", "Error");
        return throwError(() => error);
      })
    );

    modalRef.closed.pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }

  protected downloadCombinedPDF(subFolder: string): void {
    this.#filesService
      .downloadFolder(this._document!.documentId, true, subFolder)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.#toastr.error("Download 'Generated documents.pdf' error", "Error");
          return of('ERR');
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((res) => {
        if(res !== 'ERR') {
          downloadBase64File(res, `Generated documents.pdf`);
        }
      });
  }

  protected downloadZIP(subFolder: string): void {
    this.#filesService
      .downloadFolder(this._document!.documentId, false, subFolder)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.#toastr.error("Download 'Generated documents.zip' error", "Error");
          return of('ERR');
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((res) => {
        if(res !== 'ERR') {
          downloadBase64File(res, "Generated documents.zip");
        }
      });
  }

  async handleFileUpload(event: Event) {
    const file = (event.target as unknown as { files: File[] }).files[0];
    if (this.isValidFileSize(file.size)) {
      const base64File = await toBase64(file);
      const body: FileCreateDto = {
        fileName: file.name,
        base64FileContent: base64File,
      };
      this.#filesService
        .uploadFile(this._document!.documentId, body, DocumentSubFolderName.Upload)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(() => this.refreshList$.next(true));
    } else {
      alert('File size exceeds 30 MiB')
    }
  }

  isValidFileSize(fileSize: number): boolean {
    return bytesToMegabytes(fileSize) <= 30;
  }

  lodgeDocument(): void {
    const documentSigned = this._document?.documentSigning?.signingStatus === SigningStatus.Signed;
    const documentId = this._document!.documentId;

    if(documentSigned) {
      this.lodgeNowLoading.set(true);
      this.#documentsService.lodgeDocument(documentId).pipe(
        switchMap(() => {
          if(this._document?.documentStatus === DocumentStatusEnum.Completed) {
            return of('');
          }

          return this.#documentsService.patchDocumentOperation(documentId, this.changeDocumentStatusOperation);
        }),
        catchError((error: HttpErrorResponse) => {
          this.lodgeNowLoading.set(false);
          console.error(error);
          this.#toastr.error("Error lodging the documents", "Error");
          return of('ERR');
        }),
        takeUntilDestroyed(this.#destroyRef)
      ).subscribe(res => {
        if(res !== 'ERR') {
          this.setLastStep.emit();
          this.#toastr.success('Lodged the documents!', 'Success');
        }
        this.lodgeNowLoading.set(false);
      });
    } else {
      const modalRef = this.#modalService.open(MarkSignedComponent,{ size: 'lg' });
      (modalRef.componentInstance as MarkSignedComponent).earliestLodgeDate = this._document?.earliestChangeDate ? new Date(this._document?.earliestChangeDate) : new Date();

      (modalRef.componentInstance as MarkSignedComponent).confirm.pipe(
        switchMap((signatureDate) => {
          this.lodgeNowLoading.set(true);
          this.signatureDate = signatureDate;
          return this.#eSignService.markAsSign(documentId, signatureDate);
        }),
        switchMap(() => {
          return this.#documentsService.lodgeDocument(documentId);
        }),
        switchMap(() => {
          if(this._document?.documentStatus === DocumentStatusEnum.Completed) {
            return of('');
          }

          return this.#documentsService.patchDocumentOperation(documentId, this.changeDocumentStatusOperation);
        }),
        catchError((error: HttpErrorResponse) => {
          this.lodgeNowLoading.set(false);
          console.error(error);
          this.#toastr.error("Error lodging the documents", "Error");
          return of('ERR');
        }),
        takeUntilDestroyed(this.#destroyRef)
      ).subscribe(res => {
        if(res !== 'ERR') {
          this.setLastStep.emit();
          this.#toastr.success('Lodged the documents!', 'Success');
        }
        this.lodgeNowLoading.set(false);
      });
    }
  }
}
