<app-common-modal-form
  [header]="'View and Send'"
  [icon]="'icon ic-send'"
  [isCustomFooter]="true"
  (close)="close()"
>
  <div class="body-container d-flex gap-24" [formGroup]="form">
    @if(!sendLoading) {
      <div class="sent-container d-flex flex-column gap-24">
        <div class="sent-container">
          <app-radio
            formControlName="sendBy"
            [label]="'Send by'"
            [options]="sendByOptions"
          ></app-radio>
        </div>

        <app-divider></app-divider>

        <div class="documents-container">
          <div class="sub-title">Documents</div>
          <div class="list">
            @for (document of (documentsList$ | async)?.final; track $index) {
              <div class="name-container">
                <div class="name-wrapper">
                  <div><i class="icon ic-xs ic-file"></i></div>
                  <div class="file-name">{{ document.name }}</div>
                </div>
                <div class="action-wrapper">
                  <div><i class="icon ic-xs ic-download-file" (click)="downloadFile(document.name, 'final')"></i></div>
                </div>
              </div>
            } @empty {
              <div>No files</div>
            }
          </div>
        </div>

        <div class="documents-container">
          <div class="sub-title">Templates</div>
          <div class="list">
            @for (template of (templates$ | async); track $index) {
              <div class="name-container">
                <div class="name-wrapper">
                  <div><i class="icon ic-xs ic-file"></i></div>
                  <div class="file-name">{{ template.code }}</div>
                </div>
              </div>
            } @empty {
              <div>No templates</div>
            }
          </div>
        </div>

        <div class="documents-container">
          <div class="sub-title">Recipients</div>
          <div class="list">
            @for (recipient of annual?.documentSigning?.signatures; track $index) {
              <div class="name-container-2">
                <div class="name-wrapper">
                  <div><i class="icon ic-xs ic-file"></i></div>
                  <div>
                    <span class="file-name bold-500">{{recipient.signatory.fullName}}</span>
                    <span class="file-name">({{recipient.email ? recipient.email : 'Email not found'}})</span>
                  </div>
                </div>
              </div>
            } @empty {
              <div>No recipients</div>
            }
          </div>
        </div>

        <div class="documents-container">
          <div class="sub-title">Partner</div>
          <div class="list">
            <div class="name-container-2">
              <div class="name-wrapper">
                <div class="file-name bold-500">{{partnerManagerName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="skeleton-container">
        <div class="mb-4">
          <ngx-skeleton-loader count="1" appearance="line" />
        </div>
        <div class="mb-4">
          <ngx-skeleton-loader count="8" appearance="line" />
        </div>
        <div class="mb-4">
          <ngx-skeleton-loader count="6" appearance="line" />
        </div>
        <div class="mb-4">
          <ngx-skeleton-loader count="4" appearance="line" />
        </div>
      </div>
    }

    @if(!annualLoading && !sendLoading) {
      <div class="preview-container">
        <app-email-preview
          [sendBy]="form.get('sendBy')?.value"
          [changeAuthorisation]="annual?.changeAuthorisation"
          [paymentDeadline]="annual?.paymentDeadline"
          [reviewDate]="annual?.reviewDate"
          [company]="annual?.company"
          [isDocument]="false"
          [previewMode]="true"
          (changeEmail)="changeEmail($event)"
        ></app-email-preview>
      </div>
    } @else {
      <div class="skeleton-container">
        <div class="mb-4">
          <ngx-skeleton-loader count="1" appearance="line" />
        </div>
        <div class="mb-4">
          <ngx-skeleton-loader count="20" appearance="line" />
        </div>
      </div>
    }
  </div>

  <div class="custom-footer d-flex gap-24">
    <app-button
      [text]="'Send'"
      [iconClass]="'icon ic-xs ic-send'"
      [loading]="sendLoading"
      [isDisabled]="isNotValidForSend || sendLoading"
      (clickBtn)="send()"
    ></app-button>

    <app-button
      [text]="'Customise'"
      [btnColor]="'white'"
      [iconClass]="'icon ic-xs ic-edit'"
      [isDisabled]="sendLoading"
      (clickBtn)="customize()"
    ></app-button>

    <app-divider [vertical]="true"></app-divider>

    <app-button
      [text]="'Cancel'"
      [btnColor]="'white'"
      (clickBtn)="close()"
    ></app-button>
  </div>
</app-common-modal-form>
