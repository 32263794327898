import { Component, inject, Input, OnInit } from '@angular/core';
import { IUserRole, UserPermissionsSelectOptions, UserRecord } from "../../../../../../models/userRecord";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UsersService } from "../../../../../../services/users.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import { InputComponent } from "../../../../../components/common/input/input.component";
import {
  InputPhoneNumberComponent
} from "../../../../../components/common/input-phone-number/input-phone-number.component";
import { SelectComponent } from "../../../../../components/common/select/select.component";
import { ToastrService } from "ngx-toastr";
import { setControlDisabled } from "../../../../../../functions/set-control-disabled";
import { IParsedFullName, parseFullName } from "../../../../../../functions/parse-fullname";

@Component({
  selector: 'app-create-edit-user-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    SelectComponent,
    InputPhoneNumberComponent,
    CommonModalFormComponent,
  ],
  templateUrl: './create-edit-user-modal.component.html',
  styleUrl: './create-edit-user-modal.component.scss'
})
export class CreateEditUserModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private usersService = inject(UsersService);
  private toastr = inject(ToastrService);

  @Input() user: UserRecord | null = null;
  @Input() usersEmails: string[] = [];

  readonly userRolesSelectOptions = UserPermissionsSelectOptions;
  header = '';
  isEdit = false;
  isLoading = false;
  saveButton = '';
  form = new FormGroup({
    fullName: new FormControl<string | null>(null, [Validators.required]),
    phoneNumber: new FormControl<string | null>(null, [Validators.required]),
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    roles: new FormControl<IUserRole | null>('Admin', [Validators.required]),
  });

  ngOnInit() {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog' });
    if (this.user) {
      this.isEdit = true;
      this.form.patchValue({
        ...this.user,
        fullName: this.user.fullName,
        roles: this.user.roles[0] ?? null,
      });
      setControlDisabled(this.form.controls.email);
    }

    this.header = (this.user ? 'Edit' : 'Add') + ' User';
    this.saveButton = this.user ? 'Save' : 'Invite';
  }

  close(): void {
    this.activeModal.dismiss();
  }

  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const names: IParsedFullName = parseFullName(this.form.controls.fullName.value ?? '');
    const payload = new UserRecord({
      ...this.form.value as Partial<UserRecord>,
      email: this.form.controls.email.value!,
      externalId: this.user?.externalId,
      firstName: names.firstName,
      middleName: [names.middleName1, names.middleName2].filter(Boolean).join(' '),
      lastName: names.lastName,
      roles: [this.form.controls.roles.value] as IUserRole[],
    });

    if (this.usersEmails.includes(payload.email.trim())) {
      this.toastr.error('This user already exists.', 'Error');
      return;
    }

    this.form.disable();
    this.isLoading = true;
    const isNewUser = !payload.externalId;

    this.usersService.saveUser(payload)
      .subscribe({
        next: () => {
          this.activeModal.close();
          const message = isNewUser ? `An invite was sent to ${payload.email}` : 'Data saved successfully';
          this.toastr.success(message, 'Success');
        },
        error: (error) => {
          console.warn('[CreateEditUserModalComponent] saveUser: ', error);
          this.isLoading = false;
          this.form.enable();
          setControlDisabled(this.form.controls.email, this.isEdit);
          const message = isNewUser ? `Error inviting the user` : 'Unable to save user';
          this.toastr.error(message, 'Error');
        }
      });
  }

  get userRoleTip(): string {
    switch (this.form.controls.roles.value) {
      case 'Admin':
        return 'Users with the Master (Admin) permission have full access to entity profiles, the capability to prepare and lodge ASIC forms, and the authority to view and add notes for entities. Moreover, they have the privilege to manage the Settings and My Organisation pages, encompassing the addition and removal of users.';
      case'Manager':
        return 'Users with Manager permission have full access to entity profiles, the capability to prepare and lodge ASIC forms, and the authority to view and add notes for entities.';
      case 'Preparer':
        return 'Users with Preparer permission can access entity profiles and prepare ASIC forms, but they cannot complete and lodge forms.';
      case 'User':
        return 'Users with User permission are restricted to accessing only entity profiles and document lists. They are not allowed to prepare and lodge forms.';
      default:
        return '';
    }
  }
}

