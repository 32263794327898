import { booleanAttribute, Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../../../functions/set-control-disabled';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { RelationshipType } from '../../../../../../models/enums/relationshipTypeEnum';
import { Relationship } from '../../../../../../models/relationship';
import { SelectOption } from '../../../../../../models/selectOptions';
import { companyNameValidator } from '../../../../../../validators/compny-name.validator';
import { DatePickerComponent } from '../../../../../components/common/date-picker/date-picker.component';
import { InputComponent } from '../../../../../components/common/input/input.component';
import { SelectComponent } from '../../../../../components/common/select/select.component';
import { BaseStepperFormComponent } from '../../../../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../../../../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../../../../stepper-form/stepper-form.component';
import { CompanyChangePartyName } from './CompanyChangePartyName';
import { ChangesAppliedComponent } from '../../../../stepper-form/pages/changes-applied/changes-applied.component';
import { CustomFormValidators } from "../../../../../../custom-form-validators/custom-form-validators";
import {
  directorFirstNameValidatorMsg
} from "../../../../../../validators/validatorMessages/custom-form-validators-messages";

export enum CompanyChangePartyNameStepsEnum {
  FormDescription = 0,
  CompanyMemberName = 1,
}

export enum CompanyChangePartyNameStepsWithSummaryEnum {
  FormDescription = 0,
  CompanyMemberName = 1,
  Summary = 2,
}

@Component({
  selector: 'app-a2-company-member-name-change',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    InputComponent,
    DatePickerComponent,
    SelectComponent,
    ChangesAppliedComponent,
  ],
  templateUrl: './a2-company-change-party-name.component.html',
  styleUrl: `../../../../stepper-form/base-stepper-component/base-stepper-form.component.scss`
})
export class A2CompanyChangePartyNameComponent extends BaseStepperFormComponent<CompanyChangePartyNameStepsEnum, CompanyChangePartyName> implements OnInit {
  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officersChangeNameOptions: SelectOption[] = [];
  @Input({ transform: booleanAttribute }) set hasSummary(value: boolean) {
    if (value) {
      this.setupSteps(CompanyChangePartyNameStepsWithSummaryEnum);
    }
  }

  protected readonly directorFirstNameValidatorMsg = directorFirstNameValidatorMsg;
  override readonly StepsEnum = CompanyChangePartyNameStepsEnum;
  readonly StepsWithSummaryEnum = CompanyChangePartyNameStepsWithSummaryEnum;
  readonly RelationshipType = RelationshipType;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  selectedOfficerTypeSignal = signal<RelationshipType | null>(null);
  isCorporateSecurityHolderSignal: WritableSignal<boolean> = signal<boolean>(false);

  form = new FormGroup({
    changeDate: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
    selectedOfficerId: new FormControl<string | null>(null, [Validators.required]),

    userData: new FormGroup({
      firstName: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.directorFirstNameValidator]),
      middleName1: new FormControl<string | null>(null, [CustomFormValidators.directorFirstNameValidator]),
      middleName2: new FormControl<string | null>(null, [CustomFormValidators.directorFirstNameValidator]),
      lastName: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.directorFirstNameValidator]),
    }),

    companyName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(7), companyNameValidator()]),
  });

  override stepperForm = new FormGroup({
    [CompanyChangePartyNameStepsEnum.FormDescription]: new FormGroup({}),
    [CompanyChangePartyNameStepsEnum.CompanyMemberName]: this.form
  });

  constructor() {
    super();
    this.setupSteps(CompanyChangePartyNameStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);

    this.listenSelectedOfficeholderIdChanges();
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]): void {
    this.setupChange(changes[0] as CompanyChangePartyName);
  }

  override setupChange(change: CompanyChangePartyName = this.formModel): void {
    const selectedRelationshipIds = change.relationshipIds[0];
    const selectedOfficerId = Object.entries(this.officers)
      .find(([key, relashionships]) => relashionships
        .some(relashionship => relashionship.relationshipId === selectedRelationshipIds)
      )?.[0] ?? null; // it's a key
    this.form.controls.selectedOfficerId.setValue(selectedOfficerId ?? null);
    this.form.controls.changeDate.setValue(change.changeDate);
    this.form.updateValueAndValidity();
    this.form.controls.userData.patchValue(change);
  }

  override buildDocument(): Document | null {
    const fullName = this.form.controls.userData.controls.firstName.value + ' ' + this.form.controls.userData.controls.lastName.value;

    const changes = new CompanyChangePartyName({
      ...this.form.value.userData as Partial<CompanyChangePartyName>,
      description: 'Name change for ' + fullName,
      companyName: this.form.value.companyName ?? '',
      changeDate: this.form.value.changeDate!,
      relationshipIds: this.officers[this.form.value.selectedOfficerId!]?.map(officer => officer.relationshipId)
    });

    try {
      return new Document({
        changes: [changes],
        entityId: this.companyChangeData.entityId,
        type: 'c:484',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private listenSelectedOfficeholderIdChanges(): void {
    this.stepperForm.controls[CompanyChangePartyNameStepsEnum.CompanyMemberName].controls.selectedOfficerId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedOfficerId) => {
        if (!selectedOfficerId) {
          this.selectedOfficerTypeSignal.set(null);
          this.isCorporateSecurityHolderSignal.set(false);
          return;
        }

        const selectedOfficerRelationships = this.officers[selectedOfficerId] ?? [];
        const isSecurityHolder = selectedOfficerRelationships.some((relationship) => relationship.type === RelationshipType.Securityholder);
        const hasRelatedEntity = selectedOfficerRelationships.some(relationship => Boolean(relationship.relatedEntityId));
        const isCorporateSecurityHolder = isSecurityHolder && hasRelatedEntity;

        setControlDisabled(this.form.controls.userData, isCorporateSecurityHolder);
        setControlDisabled(this.form.controls.companyName, !isCorporateSecurityHolder);

        if (isCorporateSecurityHolder) {
          this.selectedOfficerTypeSignal.set(RelationshipType.Securityholder);
        } else {
          this.form.controls.userData.patchValue(selectedOfficerRelationships[0].individualDataOverride!);
          this.selectedOfficerTypeSignal.set(selectedOfficerRelationships[0]?.type || null);
        }

        this.isCorporateSecurityHolderSignal.set(isCorporateSecurityHolder);
      });
  }
}
