<div class="action-container" *appHasRole="['Admin', 'Manager', 'Preparer']">
  <i class="icon ic-notes" (click)="annotation()"></i>
  <i class=" icon ic-download-file" (click)="downloadCertificate()"></i>

  <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown d-inline-flex">
    <i class="icon ic-md ic-three-dots" ngbDropdownToggle></i>

    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      <div ngbDropdownItem class="dropdown-item" (click)="onEdit()">
        <a class="menu-action-button">
          <i class="icon ic-edit mr-1"></i>
          <span>Edit</span>
        </a>
      </div>
      <div ngbDropdownItem class="dropdown-item" (click)="onDelete()">
        <a class="menu-action-button">
          <i class="icon ic-close mr-1"></i>
          <span>Delete</span>
        </a>
      </div>
    </div>
  </div>
</div>
