import { Component, inject, OnInit } from '@angular/core';
import { BaseStepperFormComponent } from "../../../stepper-form/base-stepper-component/base-stepper-form.component";
import {
  CompanyRegistration,
  CompanyStandardStepsEnum,
  OfficeholderFormGroup,
  OfficeholderFormGroupControls,
  SecurityholderFormGroup,
  SecurityHoldingFormGroup,
  SecurityTransactionBalance
} from "../../../../../models/company-registration";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { DatepickerHelper } from "../../../../../custom-form-validators/date-picker-form-validators";
import { Document } from "../../../../../models/document";
import { StepperFormComponent } from "../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { companyNameValidator } from "../../../../../validators/compny-name.validator";
import {
  reservationNumberCustomErrors,
  reservationNumberValidator
} from "../../../../../validators/reservation-number.validator";
import { JurisdictionType, JurisdictionTypeOptions } from "../../../../../models/enums/trustEnum";
import { SelectComponent } from "../../../../components/common/select/select.component";
import { YesNoControlComponent } from "../../../../components/common/yes-no-control-component/yes-no-control.component";
import { InputComponent } from "../../../../components/common/input/input.component";
import { SelectOption } from "../../../../../models/selectOptions";
import { legalElements } from "../../../company-name-change/CompanyChangeName";
import {
  AbnMaskedInputComponent
} from "../../../../components/common/masked-input/specific-masked-inputs/abn-masked-input/abn-masked-input.component";
import { TextareaComponent } from "../../../../components/common/textarea/textarea.component";
import {
  CompanyNameAcnFormGroupComponent,
} from "../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import { AutocompleteComponent } from "../../../../components/common/autocomplete/autocomplete.component";
import { CountriesService } from "../../../../../services/countries.service";
import { RadioComponent } from "../../../../components/common/radio/radio.component";
import { combineLatest, debounceTime, startWith } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { setControlDisabled } from "../../../../../functions/set-control-disabled";
import { CheckboxComponent } from "../../../../components/common/checkbox/checkbox.component";
import {
  AddressAndOccupierFormGroupComponent
} from "../../../../components/reusable-form-groups/address-and-occupier-form-group/address-and-occupier-form-group.component";
import { ValidationErrorComponent } from "../../../../components/common/validation-error/validation-error.component";
import {
  AddressFormGroup,
  AddressFormGroupComponent,
  AddressFormGroupControls
} from "../../../../components/reusable-form-groups/address-form-group/address-form-group.component";
import {
  IndividualDataFormGroupComponent,
  IndividualDataFormGroupControls
} from "../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import { Relationship, ShareholderRelationshipDetails } from "../../../../../models/relationship";
import { positionTypesOptions } from '../../../officer-appointment/officer-appointment.component';
import { ButtonComponent } from "../../../../components/common/button/button.component";
import { RelationshipType, RelationshipTypeLabelsPlural } from "../../../../../models/enums/relationshipTypeEnum";
import { OfficerAppointmentType } from "../../../../../models/officerAppointmentForm";
import { DividerComponent } from "../../../../components/common/divider/divider.component";
import { newShareholderTypesSelectOptions, ShareholderTypesEnum } from "../../../share/share-issue/share-issue.model";
import {
  ShareJointSecurityHolderComponent
} from "../../../share/components/share-joint-security-holder/share-joint-security-holder.component";
import { CorporateHolderModel, IndividualHolderModel } from "../../../../../models/securityRegistryRecord";
import { InputNumberComponent } from "../../../../components/common/input-number/input-number.component";
import {
  ShareSecurityTypesDropdownComponent
} from "../../../../components/shares/share-security-types-dropdown/share-security-types-dropdown.component";
import {
  customErrorsOfShareCancellationNumbers,
  totalNumberOfSharesCustomErrors
} from '../../../share/share-cancellation/company-security-cancellation.model';
import { SecurityType } from "../../../../../models/securityType";
import { SecurityHolding } from "../../../../../models/securityHolding";
import { Guid } from "../../../../helpers/guid.helper";
import { EntityData } from "../../../../../models/entityData";
import { NumbersValidators } from "../../../../../validators/numbers.validators";
import { clearFormArray } from "../../../../../functions/clear-form-array";
import { Address } from "../../../../../models/address";
import { SecurityTransactionType } from "../../../../../models/enums/SecurityTransactionType";
import { AuthService } from "../../../../../services/auth.service";
import { EntityChangeData } from "../../../../../models/entityChangeData";
import { OrganisationService } from "../../../../../services/organisation.service";
import { SettingsService } from "../../../../../services/settings.service";
import { ApplicantType } from '../form6010-voluntary-deregistration/form6010.model';
import {
  directorFullNameValidatorMsg
} from "../../../../../validators/validatorMessages/custom-form-validators-messages";
import { CustomFormValidators } from "../../../../../custom-form-validators/custom-form-validators";
import {
  addressIncludesPoBox,
  poBoxAddressCustomErrors
} from "../484-forms/a1-company-address-change/CompanyChangeAddress.model";
import { abnDeclarationMessage } from "../../../../constants/abn-declaration-message.constant";

@Component({
  selector: 'app-form-company-standard',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ValidationErrorComponent,
    InputComponent,
    RadioComponent,
    CheckboxComponent,
    TextareaComponent,
    SelectComponent,
    AutocompleteComponent,
    YesNoControlComponent,
    ButtonComponent,
    DividerComponent,
    InputNumberComponent,
    AbnMaskedInputComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    CompanyNameAcnFormGroupComponent,
    AddressAndOccupierFormGroupComponent,
    AddressFormGroupComponent,
    IndividualDataFormGroupComponent,
    ShareJointSecurityHolderComponent,
    ShareSecurityTypesDropdownComponent,
  ],
  templateUrl: './form-company-standard.component.html',
  styleUrls: [
    './form-company-standard.component.scss',
    '../../../../modals/stepper-form/base-stepper-component/base-stepper-form.component.scss',
    '../../../../modals/share/share-cancellation/share-cancellation.component.scss',
  ]
})
export class FormCompanyStandardComponent extends BaseStepperFormComponent<CompanyStandardStepsEnum, CompanyRegistration> implements OnInit {
  private countriesService = inject(CountriesService);
  private authService = inject(AuthService);
  private organisationService = inject(OrganisationService);
  private settingsService = inject(SettingsService);

  CountriesService = CountriesService;

  // General
  override readonly StepsEnum = CompanyStandardStepsEnum;
  suggestedNames: string[] = [];

  // CompanyDetails
  readonly OfficerAppointmentType = OfficerAppointmentType;
  readonly abnDeclarationMessage = abnDeclarationMessage;
  readonly ApplicantType = ApplicantType;
  readonly directorFullNameValidatorMsg = directorFullNameValidatorMsg;
  readonly JurisdictionTypeOptions = JurisdictionTypeOptions;
  readonly countries = this.countriesService.getCountries();
  readonly reservationNumberCustomErrors = reservationNumberCustomErrors;
  legalElementsOptions: SelectOption[] = [];
  applicantTypeOptions: SelectOption[] = [
    { label: 'Individual', value: ApplicantType.Director, disabled: false },
    { label: 'Company', value: ApplicantType.Company, disabled: false },
  ];

  // Address
  staticSuggestedAddressesRecord: Record<string, Address | null> = {};
  suggestedAddresses: Address[] = [];

  // Officeholders
  readonly positionTypesOptions = positionTypesOptions.filter(o => o.value !== OfficerAppointmentType.AlternativeDirector);
  selectedOfficeholderTypes: OfficerAppointmentType[][] = [[OfficerAppointmentType.PublicOfficer]];

  // Shareholders
  readonly newShareholderTypesSelectOptions = newShareholderTypesSelectOptions;
  readonly ShareholderTypesEnum = ShareholderTypesEnum;
  readonly individualDataFormLabels: Record<keyof Partial<IndividualDataFormGroupControls>, string> = { fullName: 'Individual Name' } as Record<keyof Partial<IndividualDataFormGroupControls>, string>;
  readonly addressFormLabels: Record<keyof Partial<AddressFormGroupControls>, string> = { normalizedFullAddress: 'Registered address' } as Record<keyof Partial<AddressFormGroupControls>, string>;
  readonly hiddenIndividualControls: (keyof IndividualDataFormGroupControls)[] = ['formerName', 'dob', 'birthCity', 'birthCountry'];
  readonly totalNumberOfSharesCustomErrors = totalNumberOfSharesCustomErrors;
  readonly addressesCustomErrors = {
    ...poBoxAddressCustomErrors,
    foreignAddressForAllDirectors: `It's now allowed to select address of all directors to a foreign address.`
  };
  readonly customErrorsOfShareCancellationNumbers = customErrorsOfShareCancellationNumbers;
  securityTypes: SecurityType[] = [new SecurityType({
    securityTypeId: Guid.generate(),
    entityId: this.companyChangeData.entityId
  })];
  securityTypesRecord: Record<string, SecurityType> = { [this.securityTypes[0].securityTypeId]: this.securityTypes[0] };

  override stepperForm = new FormGroup({
    [CompanyStandardStepsEnum.FormDescription]: new FormGroup({}),
    [CompanyStandardStepsEnum.CompanyDetails]: new FormGroup({
      // Company Name
      companyName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(3), companyNameValidator()]),
      useAcnAsNewCompanyName: new FormControl<boolean | null>(false, [Validators.required]),
      newLegalElementsIfAcnIsUsed: new FormControl<string | null>(null, [Validators.required]),
      jurisdiction: new FormControl<JurisdictionType | null>(null, [Validators.required]),
      isProposedNameIdenticalToRegisteredBusinessName: new FormControl<boolean | null>(false, [Validators.required]),
      abnConfirmation: new FormControl<boolean | null>(false, [Validators.requiredTrue]),
      businessNameHolderAbn: new FormControl<string | null>(null),
      manualReviewRequested: new FormControl<boolean | null>(false, [Validators.required]),
      manualProcessingReason: new FormControl<string | null>(null, [Validators.required]),
      newNameReservedWithForm410: new FormControl<boolean | null>(false, [Validators.required]),
      reservationNumber: new FormControl<string | null>(null, [Validators.required, reservationNumberValidator]),
      applicantType: new FormControl<ApplicantType | null>(null),
      applicantCompanyName: new FormControl<string | null>(null),
      applicantIndividualName: new FormControl<string | null>(null),

      // Ultimate Holding Company
      haveUltimateHoldingCompany: new FormControl<boolean | null>(null, [Validators.required]),
      ultimateHoldingCompany: new FormGroup({
        ...CompanyNameAcnFormGroupComponent.defineFormControls(),
        aboardCompanyName: new FormControl<string | null>(null, [Validators.required]),
        ultimateHoldingCompanyCountry: new FormControl<string | null>(null, Validators.required),
      }),
    }),
    [CompanyStandardStepsEnum.Address]: new FormGroup({
      principalAddressSameAsRegisteredAddress: new FormControl<boolean | null>(false, [Validators.required]),
      registeredAddressAndOccupier: AddressAndOccupierFormGroupComponent.defineForm(),
      principalAddress: AddressFormGroupComponent.defineForm(),
    }),
    [CompanyStandardStepsEnum.Officeholders]: new FormGroup({
      officers: new FormArray<OfficeholderFormGroup>([this.createOfficeholderFormGroup()])
    }),
    [CompanyStandardStepsEnum.Shareholders]: new FormGroup({
      securityHolders: new FormArray<SecurityholderFormGroup>([this.createSecurityholderFormGroup()])
    }),
  });

  constructor() {
    super();
    this.setupSteps(CompanyStandardStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.legalElementsOptions = legalElements['proprietary'];

    this.listenControlsChange();
    this.setupValidators();
    this.setupChange();
    this.fillStaticAddresses();
    this.updateSuggestedFullNames();

    this.companyDetailsForm.controls.applicantType.setValue(ApplicantType.Director);
    if(!this.companyDetailsForm.controls.haveUltimateHoldingCompany.value) {
      this.companyDetailsForm.controls.haveUltimateHoldingCompany.setValue(false);
    }
  }

  override afterSubmit(changes: EntityChangeData[]) {
    const companyRegistrationChange = changes.find((change) => change.$type === CompanyRegistration.$type) as CompanyRegistration;
    this.setupChange(companyRegistrationChange);
  }

  override setupChange(change: CompanyRegistration = this.formModel) {
    this.addressForm.controls.registeredAddressAndOccupier.controls.address.patchValue({ country: 'AU' });
    this.addressForm.controls.principalAddress.patchValue({ country: 'AU' });
    this.companyDetailsForm.controls.abnConfirmation.setValue(this.isEdit);

    if (!this.isEdit && change === this.formModel) return;

    this.setupCompanyDetailsChange(change);
    this.setupAddressChange(change);
    this.setupOfficeholdersChange(change);
    this.setupSecurityholdersChange(change);

    if(!this.companyDetailsForm.controls.haveUltimateHoldingCompany.value) {
      this.companyDetailsForm.controls.haveUltimateHoldingCompany.setValue(false);
    }
  }

  override buildDocument(): Document | null {
    const change = new CompanyRegistration({
      ...this.buildCompanyDetailsPayload(),
      ...this.buildAddressPayload(),
      ...this.buildOfficersPayload(),
      ...this.buildShareholdersPayload(),
      changeDate: DatepickerHelper.getToday(),
    });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData.entityId || null, // TODO: specify about the entity id
        type: 'c:201',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  listenControlsChange(): void {
    this.listenCompanyDetailsControls();
    this.listenAddressControls();
  }

  setupValidators(): void {
    this.addDoNotAllowForeignAddressForAllDirectorsValidator();
    this.addPoBoxAddressValidator(this.addressForm.controls.principalAddress);
    this.addPoBoxAddressValidator(this.addressForm.controls.registeredAddressAndOccupier.controls.address);

    this.positionTypesOptions.forEach((positionOption) => this.addOfficeholderPoBoxAddressValidator(positionOption.value as OfficerAppointmentType));
  }

  // Company Details
  listenCompanyDetailsControls(): void {
    this.listenUseAcnAsNewCompanyNameChange();
    this.listenHasReservationBy410Change();
    this.listenIsProposedNameIdenticalToBusinessNameChange();
    this.listenManualReviewRequestedChange();
    this.listenApplicantTypeChange();
    this.listenCompanyHasUhcChange();
    this.listenJurisdictionChange();
  }

  listenUseAcnAsNewCompanyNameChange(): void {
    this.companyDetailsForm.controls.useAcnAsNewCompanyName.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((useAcnAsNewCompanyName) => {
        setControlDisabled(this.companyDetailsForm.controls.newLegalElementsIfAcnIsUsed, useAcnAsNewCompanyName === false);

        setControlDisabled(this.companyDetailsForm.controls.reservationNumber, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDetailsForm.controls.companyName, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDetailsForm.controls.newNameReservedWithForm410, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDetailsForm.controls.isProposedNameIdenticalToRegisteredBusinessName, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDetailsForm.controls.abnConfirmation, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDetailsForm.controls.businessNameHolderAbn, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDetailsForm.controls.manualReviewRequested, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDetailsForm.controls.manualProcessingReason, useAcnAsNewCompanyName === true);

        this.companyDetailsForm.controls.newNameReservedWithForm410.patchValue(this.companyDetailsForm.value.newNameReservedWithForm410 ?? false);
        this.companyDetailsForm.controls.isProposedNameIdenticalToRegisteredBusinessName.patchValue(this.companyDetailsForm.value.isProposedNameIdenticalToRegisteredBusinessName ?? false);
        this.companyDetailsForm.controls.manualReviewRequested.patchValue(this.companyDetailsForm.value.manualReviewRequested ?? false);
      });
  }

  listenHasReservationBy410Change(): void {
    this.companyDetailsForm.controls.newNameReservedWithForm410.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((hasReservationBy410) => {
        setControlDisabled(this.companyDetailsForm.controls.reservationNumber, hasReservationBy410 !== true);
        setControlDisabled(this.companyDetailsForm.controls.applicantCompanyName, hasReservationBy410 !== true);
        setControlDisabled(this.companyDetailsForm.controls.applicantIndividualName, hasReservationBy410 !== true);
      });
  }

  listenIsProposedNameIdenticalToBusinessNameChange(): void {
    this.companyDetailsForm.controls.isProposedNameIdenticalToRegisteredBusinessName.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((isProposedNameIdenticalToBusinessName) => {
        setControlDisabled(this.companyDetailsForm.controls.abnConfirmation, isProposedNameIdenticalToBusinessName !== true);
        setControlDisabled(this.companyDetailsForm.controls.businessNameHolderAbn, isProposedNameIdenticalToBusinessName !== true);
      });
  }

  listenManualReviewRequestedChange(): void {
    this.companyDetailsForm.controls.manualReviewRequested.valueChanges
      .pipe(startWith(this.companyDetailsForm.value.manualReviewRequested), takeUntilDestroyed(this.destroyRef))
      .subscribe((manualReviewRequested) => {
        setControlDisabled(this.companyDetailsForm.controls.manualProcessingReason, manualReviewRequested !== true);
      });
  }

  listenApplicantTypeChange(): void {
    this.companyDetailsForm.controls.applicantType.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(applicantType => {
      const applicantCompanyName = this.companyDetailsForm.controls.applicantCompanyName;
      const applicantIndividualName = this.companyDetailsForm.controls.applicantIndividualName;

      if (applicantType === ApplicantType.Company) {
        applicantCompanyName.setValidators([Validators.required, Validators.minLength(3), companyNameValidator()]);
        applicantIndividualName.patchValue(null, { emitEvent: false });
        applicantIndividualName.clearValidators();
      } else if (applicantType === ApplicantType.Director) {
        applicantIndividualName.setValidators([Validators.required, CustomFormValidators.directorFullNameValidator]);
        applicantCompanyName.patchValue(null, { emitEvent: false });
        applicantCompanyName.clearValidators();
      }

      applicantCompanyName.updateValueAndValidity();
      applicantIndividualName.updateValueAndValidity();
    });
  }

  listenCompanyHasUhcChange(): void {
    this.companyDetailsForm.controls.haveUltimateHoldingCompany.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((companyHasUhc) => {
        setControlDisabled(this.companyDetailsForm.controls.ultimateHoldingCompany, companyHasUhc !== true);
        setControlDisabled(this.companyDetailsForm.controls.ultimateHoldingCompany.controls.aboardCompanyName, companyHasUhc !== true);
        setControlDisabled(this.companyDetailsForm.controls.ultimateHoldingCompany.controls.name, companyHasUhc !== true);
        setControlDisabled(this.companyDetailsForm.controls.ultimateHoldingCompany.controls.acn, companyHasUhc !== true);
      });
  }

  listenJurisdictionChange(): void {
    this.companyDetailsForm.controls.ultimateHoldingCompany.controls.ultimateHoldingCompanyCountry.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((country) => {
        const isAustraliaSelected = country?.includes('Australia') ?? false;

        setControlDisabled(this.companyDetailsForm.controls.ultimateHoldingCompany.controls.acn, !isAustraliaSelected);
        setControlDisabled(this.companyDetailsForm.controls.ultimateHoldingCompany.controls.name, !isAustraliaSelected);
        setControlDisabled(this.companyDetailsForm.controls.ultimateHoldingCompany.controls.aboardCompanyName, isAustraliaSelected);
      });
  }

  setupCompanyDetailsChange(change: CompanyRegistration): void {
    const countryName = this.countries.find(
      country => country.value === change.ultimateHoldingCompanyCountry
    );

    this.companyDetailsForm.patchValue({
      ...change,
      jurisdiction: change.jurisdiction,
      ultimateHoldingCompany: {
        ...change,
        ultimateHoldingCompanyCountry: (countryName?.value as string) ?? '',
        aboardCompanyName: change.ultimateHoldingCompanyName,
        name: change.ultimateHoldingCompanyName,
        acn: change.ultimateHoldingCompanyAcnOrAbn,
      }
    });
  }

  buildCompanyDetailsPayload(): Partial<CompanyRegistration> {
    const formValue = this.companyDetailsForm.value;
    const countryName = formValue.ultimateHoldingCompany?.ultimateHoldingCompanyCountry ?? '';

    return {
      ...formValue,
      ...formValue.ultimateHoldingCompany,
      applicant: (formValue.applicantIndividualName ?? formValue.applicantCompanyName) ?? null,
      ultimateHoldingCompanyAcnOrAbn: formValue.ultimateHoldingCompany?.acn,
      ultimateHoldingCompanyName: formValue.ultimateHoldingCompany?.name ?? formValue.ultimateHoldingCompany?.aboardCompanyName,
      ultimateHoldingCompanyCountry: countryName
    } as Partial<CompanyRegistration>;
  }

  // Address
  listenAddressControls(): void {
    this.listenPrincipalAddressSameAsRegisteredAddressChange();
    this.listenCompanyAddressesChange();
  }

  listenPrincipalAddressSameAsRegisteredAddressChange(): void {
    this.addressForm.controls.principalAddressSameAsRegisteredAddress.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isSame) => {
        setControlDisabled(this.addressForm.controls.principalAddress, !!isSame);
      });
  }

  listenCompanyAddressesChange(): void {
    this.addressForm.controls.registeredAddressAndOccupier.controls.address.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedAddresses());

    this.addressForm.controls.principalAddress.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedAddresses());
  }

  setupAddressChange(change: CompanyRegistration): void {
    this.addressForm.patchValue({
      principalAddressSameAsRegisteredAddress: change.registeredAddress.isEqual(change.principalAddress),
      principalAddress: { ...change.principalAddress, country: 'AU' },
      registeredAddressAndOccupier: {
        ...change,
        address: { ...change.registeredAddress, country: 'AU' },
      },
    });
  }

  buildAddressPayload(): Partial<CompanyRegistration> {
    const formValue = this.addressForm.value;
    return {
      registeredAddress: formValue.registeredAddressAndOccupier!.address as Address,
      principalAddress: (formValue.principalAddressSameAsRegisteredAddress ? formValue.registeredAddressAndOccupier!.address : formValue!.principalAddress) as Address,
      occupiesTheAddress: formValue.registeredAddressAndOccupier!.occupiesTheAddress!,
      occupierNameIfDoesntOccupy: formValue.registeredAddressAndOccupier?.occupierNameIfDoesntOccupy,
    };
  }

  // Officeholders
  createOfficeholderFormGroup(officeholder: Partial<Relationship> = {}): OfficeholderFormGroup {
    const officeholderType = officeholder.type ?? null;
    const officeholderFormGroup = new FormGroup<OfficeholderFormGroupControls>({
      ...IndividualDataFormGroupComponent.defineFormControls(),
      type: new FormControl<RelationshipType | OfficerAppointmentType | null>(officeholderType, [Validators.required]),
    });

    this.listenOfficeholderChangeFullName(officeholderFormGroup);
    this.listenOfficeholderChangeAddress(officeholderFormGroup);

    officeholderFormGroup.patchValue({
      ...officeholder,
      ...officeholder.individualDataOverride
    });
    return officeholderFormGroup;
  }

  addOfficeholder(officeholder: Partial<Relationship> = {}): void {
    this.officeholdersForm.controls.officers.push(this.createOfficeholderFormGroup(officeholder));
    this.selectedOfficeholderTypes.push([OfficerAppointmentType.PublicOfficer]);
    this.positionTypeValidation();
  }

  removeOfficeholder(index: number): void {
    this.officeholdersForm.controls.officers.removeAt(index);

    this.selectedOfficeholderTypes.splice(index, 1);
    this.positionTypeValidation();
  }

  listenOfficeholderChangeFullName(officeholderFormGroup: OfficeholderFormGroup): void {
    officeholderFormGroup.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedFullNames());
  }

  listenOfficeholderChangeAddress(officeholderFormGroup: OfficeholderFormGroup): void {
    officeholderFormGroup.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedAddresses());
  }

  setupOfficeholdersChange(change: CompanyRegistration): void {
    const relationshipsByTypes = change.officers.reduce((relationships, officer) => {
      const id = officer.individualId ?? officer.relationshipId;

      if (relationships[id]) {
        relationships[id].push(officer);
      } else {
        relationships[id] = [officer];
      }

      return relationships;
    }, {} as Record<string, Relationship[]>);

    const officersFormGroups: OfficeholderFormGroup[] = Object.values(relationshipsByTypes)
      .reduce((forms, relationships) => {
        forms.push(this.createOfficeholderFormGroup(relationships[0]));

        return forms;
      }, [] as OfficeholderFormGroup[]);

    this.officeholdersForm.setControl('officers', new FormArray<OfficeholderFormGroup>(officersFormGroups));
    this.selectedOfficeholderTypes = Object.values(relationshipsByTypes).map(officeholders => {
      return officeholders.map(officeholder => officeholder.type) as unknown as OfficerAppointmentType[];
    });

    this.positionTypeValidation();
  }

  checkSelectedType(type: unknown, index: number): boolean {
    const officeholderType = type as OfficerAppointmentType;
    return this.selectedOfficeholderTypes[index].includes(officeholderType);
  }

  buildOfficersPayload(): Partial<CompanyRegistration> {
    const officers: Relationship[] = this.officeholdersForm.controls.officers.controls.reduce((relationships, officeholderFormGroup, index) => {
      const individualDataOverride = IndividualDataFormGroupComponent.toIndividualData(officeholderFormGroup as unknown as FormGroup<Partial<IndividualDataFormGroupControls>>);
      const officer = new Relationship({
        individualDataOverride,
        type: officeholderFormGroup.controls.type.value as RelationshipType,
        relationshipId: Guid.generate(),
        individualId: Guid.generate(),
      });

      this.selectedOfficeholderTypes[index].forEach(type => {
        relationships.push(new Relationship({
          ...officer,
          type: type as unknown as RelationshipType,
          relationshipId: Guid.generate(),
        }));
      });

      return relationships;
    }, [] as Relationship[]);
    return { officers };
  }

  // Shareholders
  addShareType(shareholderIndex: number, securityHoldingIndex: number, newSecurityHolding: SecurityType): void {
    this.securityTypes.push(newSecurityHolding);
    this.securityTypesRecord[newSecurityHolding.securityTypeId] = newSecurityHolding;
    this.shareholdersForm.controls.securityHolders
      .controls[shareholderIndex]
      .controls.securityTypes.controls[securityHoldingIndex]
      .controls.securityTypeId?.setValue(newSecurityHolding.securityTypeId);
  }

  createSecurityTypeFormGroup(securityHolding: SecurityHolding | null = null): SecurityHoldingFormGroup {
    const securityHoldingFormGroup = new FormGroup({
      securityTypeId: new FormControl<string | null>(securityHolding?.securityType?.securityTypeId ?? null, [Validators.required]),
      number: new FormControl<number | null>(securityHolding?.number ?? null, [Validators.required, NumbersValidators.min(0), NumbersValidators.isInteger]),
      paid: new FormControl<number | null>(securityHolding?.paid ?? null, [Validators.required, NumbersValidators.min(0)]),
      unpaid: new FormControl<number | null>(securityHolding?.unpaid ?? null, [Validators.required, NumbersValidators.min(0)])
    });

    this.setCustomSecurityTypeFormValidators(securityHoldingFormGroup);
    this.listenShareholdingNumberChange(securityHoldingFormGroup);

    return securityHoldingFormGroup;
  }

  createSecurityholderFormGroup(): SecurityholderFormGroup {
    const securityholderFormGroup = new FormGroup({
      shareholderType: new FormControl<ShareholderTypesEnum | null>(null, [Validators.required]),
      isBeneficialOwner: new FormControl<boolean | null>(false, [Validators.required]),
      beneficialOwner: new FormControl<string | null>(null, [Validators.required]),

      individualShareholder: IndividualDataFormGroupComponent.defineForm(),

      ...CompanyNameAcnFormGroupComponent.defineFormControls(),
      isOverseasCompany: new FormControl<boolean | null>(null),
      registeredAddress: AddressFormGroupComponent.defineForm(),

      joint: new FormControl<(IndividualHolderModel | CorporateHolderModel)[] | null>(null, [Validators.required]),

      securityTypes: new FormArray<SecurityHoldingFormGroup>([this.createSecurityTypeFormGroup()])
    });

    this.listenShareholderTypeChanges(securityholderFormGroup);
    this.listenIsBeneficialOwnerChanges(securityholderFormGroup);
    this.listenIndividualShareholderFullNameChanges(securityholderFormGroup);
    this.listenShareholderAddressChanges(securityholderFormGroup);

    return securityholderFormGroup;
  }

  removeShareholdersShareHolding(shareholderIndex: number, shareholdingIndex: number): void {
    this.shareholdersForm.controls.securityHolders.controls[shareholderIndex].controls.securityTypes.removeAt(shareholdingIndex);
  }

  addSecurityHolding(shareholderIndex: number): void {
    this.shareholdersForm.controls.securityHolders.controls[shareholderIndex].controls.securityTypes.push(this.createSecurityTypeFormGroup());
  }

  removeShareholder(shareholderIndex: number): void {
    this.shareholdersForm.controls.securityHolders.removeAt(shareholderIndex);
  }

  addShareholder(): void {
    const form = this.createSecurityholderFormGroup();
    this.shareholdersForm.controls.securityHolders.push(form);
  }

  getSelectedSecurityType(securityTypeFormGroup: SecurityHoldingFormGroup): SecurityType | null {
    return this.securityTypesRecord[securityTypeFormGroup.controls.securityTypeId.value!] ?? null;
  }

  setupSecurityholdersChange(change: CompanyRegistration): void {
    this.disabledUnnecessaryControls();
    clearFormArray(this.shareholdersForm.controls.securityHolders);

    const securityTypes: SecurityType[] = [];
    const securityTypesRecord: Record<string, SecurityType> = {};
    const relationshipsGroups: Record<string, Relationship[]> = {};
    const relationshipsGroupsHoldings: Record<string, SecurityHolding[]> = {};

    change.securityHoldings.forEach((securityHolding) => {
      if (!securityTypesRecord[securityHolding.securityType.securityTypeId]) {
        securityTypesRecord[securityHolding.securityType.securityTypeId] = securityHolding.securityType;
        securityTypes.push(securityHolding.securityType);
      }

      const relationshipGroupKey = securityHolding.relationships!.map((r) => r.relationshipId).join();

      if (relationshipsGroupsHoldings[relationshipGroupKey]) {
        relationshipsGroupsHoldings[relationshipGroupKey].push(securityHolding);
      } else {
        relationshipsGroupsHoldings[relationshipGroupKey] = [securityHolding];
        relationshipsGroups[relationshipGroupKey] = securityHolding.relationships!;
      }
    });

    this.securityTypes = securityTypes;
    this.securityTypesRecord = securityTypesRecord;

    const shareholdersFormArray = new FormArray<SecurityholderFormGroup>([]);
    Object.entries(relationshipsGroups)
      .forEach(([relationshipGroupKey, relationships]) => {
        const shareholdersForm = this.createSecurityholderFormGroup();
        const relationship = relationships[0];
        const shareholderDetails = relationship?.details as ShareholderRelationshipDetails;

        shareholdersForm.controls.isBeneficialOwner.setValue(shareholderDetails?.isBeneficialOwner);
        shareholdersForm.controls.beneficialOwner.setValue(shareholderDetails?.beneficialOwner);
        clearFormArray(shareholdersForm.controls.securityTypes);

        if (relationships.length > 1) {
          shareholdersForm.controls.shareholderType.setValue(ShareholderTypesEnum.JointShareholder);
          const holders: (IndividualHolderModel | CorporateHolderModel)[] = relationshipsGroups[relationshipGroupKey].map((r) => {
            if (r.individualDataOverride) {
              return new IndividualHolderModel({
                individualId: r.individualId!,
                individualData: r.individualDataOverride,
              });
            }

            return new CorporateHolderModel({
              entityId: r.entityId,
              entityData: r.entityDataOverride!
            });
          });
          shareholdersForm.controls.joint.setValue(holders);
        } else {
          const relationship = relationships[0];

          if (relationship?.entityDataOverride) {
            shareholdersForm.controls.shareholderType.setValue(ShareholderTypesEnum.Corporate);
            shareholdersForm.patchValue({
              name: relationship.entityDataOverride.name,
              acn: relationship.entityDataOverride.entityNumber,
              isOverseasCompany: !CountriesService.isAustralianStateOrAustralia(relationship.entityDataOverride.registeredAddress.country),
              registeredAddress: relationship.entityDataOverride.registeredAddress,
            });
          } else {
            shareholdersForm.controls.shareholderType.setValue(ShareholderTypesEnum.Individual);
            shareholdersForm.controls.individualShareholder.patchValue({
              ...relationship?.individualDataOverride,
              address: relationship?.individualDataOverride?.address
            });
          }
        }

        relationshipsGroupsHoldings[relationshipGroupKey].forEach((holding) => {
          const securityTypeFormGroup = this.createSecurityTypeFormGroup(holding);
          shareholdersForm.controls.securityTypes.push(securityTypeFormGroup);
        });

        shareholdersFormArray.push(shareholdersForm);
      });

    this.shareholdersForm.setControl('securityHolders', shareholdersFormArray);
    this.shareholdersForm.updateValueAndValidity();
  }

  buildShareholdersPayload(): Partial<CompanyRegistration> {
    const securityTypesRelationships: Record<string, Relationship[]> = {};
    const securityHoldings = Object.values(this.shareholdersForm.controls.securityHolders.value)
      .map((shareholderFormGroupValue, index) => {
        const relationshipsDetails = new ShareholderRelationshipDetails({
          isBeneficialOwner: shareholderFormGroupValue.isBeneficialOwner!,
          beneficialOwner: shareholderFormGroupValue.beneficialOwner!,
        });
        let relationships: Relationship[] = [];
        const relationshipShareHoldings: SecurityHolding[] = [];

        shareholderFormGroupValue.securityTypes?.forEach((securityHoldingFormValue) => {
          if (!securityTypesRelationships[securityHoldingFormValue.securityTypeId!])
            securityTypesRelationships[securityHoldingFormValue.securityTypeId!] = [];

          switch (shareholderFormGroupValue.shareholderType) {
            case ShareholderTypesEnum.Individual: {
              const individualDataOverride = IndividualDataFormGroupComponent.toIndividualData(this.shareholdersForm.controls.securityHolders.controls[index].controls.individualShareholder as FormGroup<Partial<IndividualDataFormGroupControls>>);
              relationships = [new Relationship({
                individualDataOverride,
                relationshipId: Guid.generate(),
              })];
              break;
            }
            case ShareholderTypesEnum.Corporate: {
              relationships = [new Relationship({
                entityDataOverride: new EntityData({
                  ...shareholderFormGroupValue as Partial<EntityData>,
                  entityNumber: shareholderFormGroupValue.acn!,
                }),
                relationshipId: Guid.generate(),
              })];
              break;
            }
            case ShareholderTypesEnum.JointShareholder: {
              relationships = ShareJointSecurityHolderComponent.toRelationships(shareholderFormGroupValue.joint ?? []);
              break;
            }
          }

          relationships = relationships.map((r) => {
            r.type = RelationshipType.Securityholder;
            r.relationshipId = r.relationshipId ?? Guid.generate();
            r.details = relationshipsDetails;
            return r;
          });

          securityTypesRelationships[securityHoldingFormValue.securityTypeId!] = [...securityTypesRelationships[securityHoldingFormValue.securityTypeId!], ...relationships];

          relationshipShareHoldings.push(new SecurityHolding({
            relationships,
            paid: securityHoldingFormValue.paid!,
            unpaid: securityHoldingFormValue.unpaid!,
            number: securityHoldingFormValue.number!,
            securityTypeId: securityHoldingFormValue.securityTypeId!,
            securityType: this.securityTypesRecord[securityHoldingFormValue.securityTypeId!],
          }));
        });

        return relationshipShareHoldings;
      }).flat();

    const balances: SecurityTransactionBalance[] = securityHoldings.map((holding) => {
      const securityTransactionBalance = new SecurityTransactionBalance({
        entityId: null,
        organisationId: this.authService.currentUserProfile()?.organisationId,
        securityTransactionId: Guid.EmptyGuid,
        transactionDate: DatepickerHelper.getToday(),
        transactionType: SecurityTransactionType.Balance,
        documentId: this.companyChangeData?.documentId ?? null,
        numberIncrease: holding.number,
        paidIncrease: holding.paid,
        unpaidIncrease: holding.unpaid,
        securityTypeId: holding.securityType.securityTypeId,
        securityType: holding.securityType,
        relationshipIds: holding.relationships?.map((r) => r.relationshipId)
      });

      if (!securityTransactionBalance.securityType.securityTypeId || !securityTransactionBalance.securityType.entityId) {
        delete (securityTransactionBalance.securityType as any).entityId;
        delete (securityTransactionBalance.securityType as any).securityTypeId;
      }

      return securityTransactionBalance;
    });

    return { securityHoldings, balances };
  }

  disabledUnnecessaryControls(): void {
    this.hiddenIndividualControls.forEach(key => {
      this.shareholdersForm.controls.securityHolders.controls.forEach((shareholderFormGroup) => {
        setControlDisabled(shareholderFormGroup.controls.individualShareholder.controls[key]);
      });
    });
  }

  listenShareholdingNumberChange(securityTypeFormGroup: SecurityHoldingFormGroup): void {
    securityTypeFormGroup.controls.number.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((numberIncrease) => {
        const selectedSecurityType = this.getSelectedSecurityType(securityTypeFormGroup);

        if (numberIncrease === null || selectedSecurityType === null) {
          return;
        }

        securityTypeFormGroup.controls.paid.patchValue(numberIncrease * selectedSecurityType.paidAmount);
        securityTypeFormGroup.controls.unpaid.patchValue(numberIncrease * selectedSecurityType.unpaidAmount);
      });
  }

  listenShareholderTypeChanges(shareholderForm: SecurityholderFormGroup): void {
    shareholderForm.controls.shareholderType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((shareholderType) => {
        if (shareholderType === null) {
          return;
        }

        // set state of the new Individual Shareholder control
        setControlDisabled(shareholderForm.controls.individualShareholder, shareholderType !== ShareholderTypesEnum.Individual);
        this.hiddenIndividualControls.forEach(key => setControlDisabled(shareholderForm.controls.individualShareholder.controls[key]));

        // set state of the new Corporate Shareholder control
        setControlDisabled(shareholderForm.controls.acn, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(shareholderForm.controls.name, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(shareholderForm.controls.isOverseasCompany, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(shareholderForm.controls.registeredAddress, shareholderType !== ShareholderTypesEnum.Corporate);

        // set state of the new Joint Shareholder control
        setControlDisabled(shareholderForm.controls.joint, shareholderType !== ShareholderTypesEnum.JointShareholder);
      });
  }

  listenIsBeneficialOwnerChanges(shareholderForm: SecurityholderFormGroup): void {
    shareholderForm.controls.isBeneficialOwner.valueChanges
      .pipe(
        startWith(shareholderForm.controls.isBeneficialOwner.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((isSharesOwnedOnBehalf) => {
        setControlDisabled(shareholderForm.controls.beneficialOwner, !isSharesOwnedOnBehalf);
      });
  }

  listenIndividualShareholderFullNameChanges(securityholderFormGroup: SecurityholderFormGroup): void {
    securityholderFormGroup.controls.individualShareholder.controls.fullName.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedFullNames());

    securityholderFormGroup.controls.joint.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedFullNames());
  }

  listenShareholderAddressChanges(securityholderFormGroup: SecurityholderFormGroup): void {
    securityholderFormGroup.controls.registeredAddress.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedAddresses());

    securityholderFormGroup.controls.individualShareholder.controls.address.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedAddresses());

    securityholderFormGroup.controls.joint.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe(() => this.updateSuggestedAddresses());
  }

  setCustomSecurityTypeFormValidators(securityTypeFormGroup: SecurityHoldingFormGroup): void {
    const paidTotalValidator: ValidatorFn = () => {
      const selectedSecurityType = this.getSelectedSecurityType(securityTypeFormGroup);
      if (selectedSecurityType === null || !securityTypeFormGroup.value.paid || !securityTypeFormGroup.value.number) {
        return null;
      }
      // (Math.abs(prefilled total paid - total paid))/prefilled total paid < 0.05

      const calculatedTotalPaid = selectedSecurityType.paidAmount * securityTypeFormGroup.value.number;
      return (Math.abs(calculatedTotalPaid - securityTypeFormGroup.value.paid) / calculatedTotalPaid) < 0.05
        ? null
        : { paidChangeIsBiggerThan5Percent: true };
    };

    const unpaidTotalValidator: ValidatorFn = () => {
      const selectedSecurityType = this.getSelectedSecurityType(securityTypeFormGroup);
      if (selectedSecurityType === null || !securityTypeFormGroup.value.unpaid || !securityTypeFormGroup.value.number) {
        return null;
      }
      // (Math.abs(prefilled total unpaid - total unpaid))/prefilled total unpaid < 0.05

      const calculatedTotalPaid = selectedSecurityType.unpaidAmount * securityTypeFormGroup.value.number;
      return (Math.abs(calculatedTotalPaid - securityTypeFormGroup.value.unpaid) / calculatedTotalPaid) < 0.05
        ? null
        : { unpaidChangeIsBiggerThan5Percent: true };
    };

    // total paid + total unpaid > 0
    const paidUnpaidSumValidator: ValidatorFn = () => {
      const totalPaid = securityTypeFormGroup.controls.paid.getRawValue();
      const totalUnpaid = securityTypeFormGroup.controls.unpaid.getRawValue();
      if (totalPaid === null || totalUnpaid === null) {
        return null;
      }

      return (totalPaid + totalUnpaid) > 0
        ? null
        : { paidUnpaidSumIsNotPositive: true };
    };

    securityTypeFormGroup.setValidators([
      paidTotalValidator,
      unpaidTotalValidator,
      paidUnpaidSumValidator
    ]);
  }

  //  do not allow:
  //  - all directors changing their addresses to a foreign address
  private addDoNotAllowForeignAddressForAllDirectorsValidator(): void {
    this.officeholdersForm.addValidators(((control: AbstractControl) => {
      const form = control['controls']['officers'] as FormArray<OfficeholderFormGroup>;
      const allDirectors = form.controls
        .map((officeholderForm, index) => {

          return {
            country: officeholderForm.controls.address.controls.country.value,
            isDirector: this.selectedOfficeholderTypes[index]?.includes(OfficerAppointmentType.Director)
          }
        })
        .filter(({ isDirector, country}) => isDirector ?? country)

      if (!allDirectors.length)
        return null;

      const allDirectorsHaveOverseasAddress = allDirectors
        .every(({ country}) => !CountriesService.isAustralia(country));

      return allDirectorsHaveOverseasAddress
        ? { foreignAddressForAllDirectors: true }
        : null;
    }) as ValidatorFn);
  }

  private addPoBoxAddressValidator(addressForm: AddressFormGroup): void {
    const companyPoBoxAddressValidator = (control: AbstractControl): ValidationErrors | null => {
      const form = control as AddressFormGroup;
      const address = new Address(form.value as Partial<Address>);

      return addressIncludesPoBox(address.normalizedFullAddress)
        ? { poBoxAddress: true }
        : null;
    };

    addressForm.addValidators([companyPoBoxAddressValidator]);
  }

  private addOfficeholderPoBoxAddressValidator(relationshipType = OfficerAppointmentType.Director): void {
    const officeholderPoBoxAddressValidator = (control: AbstractControl) => {
      const form = control as FormGroup<{ officers: FormArray<OfficeholderFormGroup> }>;
      const formArray = form.controls.officers;

      // skip validator if
      // - address doesn't include "PO Box"
      const currentTypeOfficeholders = formArray.controls
        .filter((officeholderForm) => officeholderForm.controls.type !== null && officeholderForm.controls.type.value === relationshipType);

      const everyProposedAddressDoesntIncludePoBox = currentTypeOfficeholders
        .every((officeholderForm) => !addressIncludesPoBox(officeholderForm.controls.address.controls.normalizedFullAddress.value ?? ''));

      if (!currentTypeOfficeholders.length || everyProposedAddressDoesntIncludePoBox) {
        return null;
      }

      // valid if at least one officeholder of the relationship type
      // has australian and non po box address
      const atLeastOneHasAuNonPoBoxAddress = currentTypeOfficeholders
        .some((officeholderForm) => {
          const address = officeholderForm.controls.address.controls.normalizedFullAddress.value ?? '';
          return !addressIncludesPoBox(address) && CountriesService.isAustralia(officeholderForm.controls.address.controls.country.value ?? '');
        });

      return atLeastOneHasAuNonPoBoxAddress
        ? null
        : { poBoxAddress: RelationshipTypeLabelsPlural[relationshipType] };
    };

    this.officeholdersForm.addValidators([officeholderPoBoxAddressValidator]);
  }

  private fillStaticAddresses(): void {
    combineLatest(this.organisationService.getOrganisation(), this.settingsService.getAgentSettings())
      .subscribe(([organisation, agent]) => {
        if (organisation.address?.normalizedFullAddress)
          this.staticSuggestedAddressesRecord['organisationAddress'] = organisation.address;

        if (agent.postalAddress?.normalizedFullAddress)
          this.staticSuggestedAddressesRecord['agentPostalAddress'] = agent.postalAddress;

        if (agent.streetAddress?.normalizedFullAddress)
          this.staticSuggestedAddressesRecord['agentStreetAddress'] = agent.streetAddress;

        this.updateSuggestedAddresses();
      });
  }

  private updateSuggestedAddresses(): void {
    const addresses: Address[] = [];

    Object.values(this.staticSuggestedAddressesRecord)
      .forEach((address) => {
        if (address?.normalizedFullAddress)
          addresses.push(address);
      });

    if (this.addressForm.controls.principalAddress.valid && this.addressForm.controls.principalAddress.value.normalizedFullAddress)
      addresses.push(new Address(this.addressForm.controls.principalAddress.value as Partial<Address>));

    if (this.addressForm.controls.registeredAddressAndOccupier.controls.address.valid && this.addressForm.controls.registeredAddressAndOccupier.controls.address.value.normalizedFullAddress)
      addresses.push(new Address(this.addressForm.controls.registeredAddressAndOccupier.controls.address.value as Partial<Address>));

    Object.values(this.officeholdersForm.controls.officers.controls)
      .forEach((officerFormValue) => {
        if (officerFormValue.controls.address.valid && officerFormValue.controls.address.controls.normalizedFullAddress)
          addresses.push(new Address(officerFormValue.controls.address.value as Partial<Address>));
      });

    Object.values(this.shareholdersForm.controls.securityHolders.controls)
      .forEach((shareholderFormValue) => {
        if (shareholderFormValue.controls.registeredAddress.valid && shareholderFormValue.controls.registeredAddress.controls.normalizedFullAddress.value)
          addresses.push(new Address(shareholderFormValue.controls.registeredAddress.value as Partial<Address>));
        if (shareholderFormValue.controls.individualShareholder.controls.address.valid && shareholderFormValue.controls.individualShareholder.controls.address.controls.normalizedFullAddress.value)
          addresses.push(new Address(shareholderFormValue.controls.individualShareholder.controls.address.value as Partial<Address>));

        shareholderFormValue.controls.joint.value?.forEach((s) => {
          if ('individualData' in s && s.individualData.address.normalizedFullAddress)
            addresses.push(new Address(s.individualData.address as Partial<Address>));
          if ('registeredAddress' in s && s.registeredAddress)
            addresses.push(new Address(s.registeredAddress as Partial<Address>));
        });
      });

    const mappedAddresses = new Map<string, Address>();
    addresses.forEach((address) => mappedAddresses.set(address.normalizedFullAddress, address));

    this.suggestedAddresses = [...mappedAddresses.values()];
  }

  private updateSuggestedFullNames(): void {
    const nameOfOfficeholders = this.officeholdersForm.value.officers?.map((officeholder) => officeholder.fullName);
    const namesOfShareholders = this.shareholdersForm.value.securityHolders?.map((shareholder) => {
      if (shareholder.individualShareholder?.fullName)
        return [shareholder.individualShareholder.fullName];
      if (shareholder.name)
        return [shareholder.name];
      else
        return shareholder.joint?.map((s) => {
          if (s instanceof IndividualHolderModel)
            return s.individualData.fullName;
          else
            return null;
        });
    }).flat();

    this.suggestedNames = [...new Set([nameOfOfficeholders, namesOfShareholders].flat().filter(Boolean))] as string[];
  }

  onCheckboxChange(selected: boolean, typeValue: unknown, index: number, officeholderFormGroup: OfficeholderFormGroup) {
    if (selected) {
      if (this.selectedOfficeholderTypes.length === index + 1) {
        this.selectedOfficeholderTypes[index].push(typeValue as OfficerAppointmentType);
      } else {
        this.selectedOfficeholderTypes.push([]);
        this.selectedOfficeholderTypes[index].push(typeValue as OfficerAppointmentType);
      }

      this.positionTypeValidation();
    } else {
      const typeIndex = this.selectedOfficeholderTypes[index].findIndex(type => type === typeValue);
      if (typeIndex !== -1) {
        this.selectedOfficeholderTypes[index].splice(typeIndex, 1);
      }

      this.positionTypeValidation();

      if(!this.selectedOfficeholderTypes[index].length) {
        officeholderFormGroup.controls.type.setValue(null);
      }
    }

    this.officeholdersForm.updateValueAndValidity();
  }

  positionTypeValidation(): void {
    let someDirectorSelected = false;
    this.selectedOfficeholderTypes.forEach(officeholderTypes => {
      if(officeholderTypes.some(type => type === OfficerAppointmentType.Director)) {
        someDirectorSelected = true;
      }
    });

    if(someDirectorSelected) {
      this.officeholdersForm.controls.officers.controls.forEach(control => control.controls.type.setValue(OfficerAppointmentType.PublicOfficer));
    } else {
      this.officeholdersForm.controls.officers.controls[0].controls.type.setValue(null);
    }
  }

  get companyDetailsForm() {
    return this.stepperForm.controls[CompanyStandardStepsEnum.CompanyDetails];
  }

  get addressForm() {
    return this.stepperForm.controls[CompanyStandardStepsEnum.Address];
  }

  get officeholdersForm() {
    return this.stepperForm.controls[CompanyStandardStepsEnum.Officeholders];
  }

  get shareholdersForm() {
    return this.stepperForm.controls[CompanyStandardStepsEnum.Shareholders];
  }
}
