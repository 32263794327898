<div class="reminder-container">
  <div class="header">
    <div class="title-container">
      <div><i class="icon ic-md ic-bell"></i></div>
      <div class="title">{{title}}</div>
    </div>
    <div>
      <app-tooltip [customContent]="true">
        <app-button
          container="body"
          [ngbTooltip]="sendTooltipText"
          [placement]="'top'"
          [tooltipClass]="'tooltip-info width-auto'"
          [disableTooltip]="signingStatus !== SigningStatus.Sent"
          [isDisabled]="signingStatus !== SigningStatus.Sent"
          [btnColor]="'white'"
          [iconClass]="'icon ic-sent-mail'"
          (clickBtn)="showReminderPopup()"
        ></app-button>
      </app-tooltip>
    </div>
  </div>

  @if(reminderList.length) {
    <div class="reminder-tags">
      @for(item of reminderList; track index; let index = $index) {
        <div class="tag" [ngClass]="{ send: item.status === ReminderStatus.Sent }">
          <i class="icon ic-checkmark" [hidden]="item.status !== ReminderStatus.Sent"></i>

          <div class="date">{{ item.scheduledOn | dateFormat }}</div>

          @if(item.status !== ReminderStatus.Sent) {
            <i class="icon ic-tiny-lg ic-close" (click)="onRemoveReminder(item.reminderId)"></i>
          }
        </div>
      }
    </div>
  }

  @if(reminderList.length) {
    <div class="add-reminder-container" [formGroup]="form">
      <app-date-picker
        formControlName="reminderDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [shouldShowErrors]="false"
      ></app-date-picker>

      <app-button
        [text]="'Add reminder'"
        [btnColor]="'white'"
        [isDisabled]="form.invalid"
        (clickBtn)="onAddReminder()"
      ></app-button>
    </div>
  }
</div>
