import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { DatepickerHelper } from '../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../functions/set-control-disabled';
import { Document } from '../../../../models/document';
import { EntityChangeData } from '../../../../models/entityChangeData';
import { SecurityTransactionType } from '../../../../models/enums/SecurityTransactionType';
import { SecurityHolding } from '../../../../models/securityHolding';
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from '../../../../models/securityRegistryRecord';
import { SecurityTransactionIncremental } from '../../../../models/securityTransactionIncremental';
import { SelectOption } from '../../../../models/selectOptions';
import { IStep } from '../../../../models/step';
import { NumbersValidators } from '../../../../validators/numbers.validators';
import { BeneficialOwnerComponent } from '../../../components/common/beneficial-owner/beneficial-owner.component';
import { CheckboxComponent } from '../../../components/common/checkbox/checkbox.component';
import { DatePickerComponent } from '../../../components/common/date-picker/date-picker.component';
import { DisclaimerComponent } from '../../../components/common/disclaimer/disclaimer.component';
import { DividerComponent } from '../../../components/common/divider/divider.component';
import { ListGridComponent } from '../../../components/common/grid/components/list-grid/list-grid.component';
import { InputNumberComponent } from '../../../components/common/input-number/input-number.component';
import { InputComponent } from '../../../components/common/input/input.component';
import { LoaderComponent } from '../../../components/common/loader/loader.component';
import { RadioComponent } from '../../../components/common/radio/radio.component';
import {
  SelectGroupsComponent,
  SelectOptionGroup
} from '../../../components/common/select-groups/select-groups.component';
import { SelectComponent } from '../../../components/common/select/select.component';
import { TextareaComponent } from '../../../components/common/textarea/textarea.component';
import { ValidationErrorComponent } from '../../../components/common/validation-error/validation-error.component';
import { SelectShareholderComponent } from '../../../components/shares/select-shareholder/select-shareholder.component';
import {
  ShareSecurityHoldingsDropdownComponent
} from '../../../components/shares/share-security-holdings-dropdown/share-security-holdings-dropdown.component';
import { Guid } from '../../../helpers/guid.helper';
import {
  CompanyForm2560,
  Form2560SectionSectionOptions,
  Form2560SectionsEnum
} from '../../form2560/CompanyForm2560.model';
import {
  BuyBackAttachedDocumentType,
  buyBackDocumentTypeOptions,
  BuyBackType,
  buyBackTypeOptions,
  CompanyForm280
} from '../../form280/form280.model';
import {
  StepperFormDescriptionComponent
} from '../../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../../stepper-form/stepper-form.component';
import { BaseShareFormComponent } from '../components/base-share-form/base-share-form.component';
import { ShareEstimateTableComponent } from '../components/share-estimate-table/share-estimate-table.component';
import { ConsiderationTypesOptions } from '../share-issue/share-issue.model';
import {
  CancellationReason,
  CompanySecurityCancellation,
  customSignErrors,
  reasonsOfCancellationOptionGroups,
  ShareCancellationSteps,
  ShareCancellationStepsEnum
} from './company-security-cancellation.model';

// Share Cancellation
@Component({
  selector: 'app-share-cancellation',
  standalone: true,
  imports: [
    StepperFormComponent,
    ReactiveFormsModule,
    StepperFormDescriptionComponent,
    SelectShareholderComponent,
    BeneficialOwnerComponent,
    DividerComponent,
    ShareSecurityHoldingsDropdownComponent,
    InputNumberComponent,
    DatePickerComponent,
    TextareaComponent,
    InputComponent,
    SelectGroupsComponent,
    SelectComponent,
    RadioComponent,
    CheckboxComponent,
    ListGridComponent,
    ValidationErrorComponent,
    LoaderComponent,
    ShareEstimateTableComponent,
    DisclaimerComponent
  ],
  templateUrl: './share-cancellation.component.html',
  styleUrls: ['./share-cancellation.component.scss', '../../stepper-form/base-stepper-component/base-stepper-form.component.scss']
})
export class ShareCancellationComponent extends BaseShareFormComponent<ShareCancellationStepsEnum, CompanySecurityCancellation> implements OnInit {
  override readonly StepsEnum = ShareCancellationStepsEnum;
  override readonly steps = ShareCancellationSteps;
  override currentStepIndex = 0;
  override currentStep = this.steps[this.currentStepIndex].step;
  readonly CancellationReason = CancellationReason;
  readonly reasonForCancellationOptionGroups: SelectOptionGroup[] = reasonsOfCancellationOptionGroups;
  readonly customSignErrors = customSignErrors;
  readonly considerationTypesOptions = ConsiderationTypesOptions;

  // 280 constants
  readonly buyBackDocumentTypeOptions = buyBackDocumentTypeOptions;
  readonly buyBackTypeOptions = buyBackTypeOptions;

  // 2560 constants
  readonly form2560DocumentsOptions: SelectOption[] = Form2560SectionSectionOptions;

  securityRegistryRecordsHoldings: SecurityHolding[] = [];
  selectedShareholderShareType: SecurityHolding | null = null;
  stepsSignal: WritableSignal<IStep<ShareCancellationStepsEnum>[]> = signal(ShareCancellationSteps);

  override stepperForm = new FormGroup({
    [ShareCancellationStepsEnum.FormDescription]: new FormGroup({}),
    [ShareCancellationStepsEnum.ShareholderAndCancellationDetails]: new FormGroup({
      securityRegistryRecordIndex: new FormControl<number | null>(null, [Validators.required]),
      securityHoldingId: new FormControl<string | null>(null, [Validators.required]),
      considerationPaid: new FormControl<number | null>(0, [Validators.required, NumbersValidators.strictMin(0)]),
      numberOfSharesCancelled: new FormControl<number | null>(0, [Validators.required, NumbersValidators.min(0)]),
      totalPaid: new FormControl<number | null>(1, [Validators.required, NumbersValidators.min(0)]),
      totalUnpaid: new FormControl<number | null>(0, [Validators.required, NumbersValidators.min(0)]),
      issueForCashConsidarationOnly: new FormControl<boolean | null>(true, [Validators.required]),
      nonCashConsiderationDetails: new FormControl<string | null>(null, [Validators.required]),
      consolidateCertificate: new FormControl<boolean | null>(false, [Validators.required]),
      shareCertificateNumber: new FormControl<string | null>(null, [NumbersValidators.isInteger]),
      transactionDate: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
      reason: new FormControl<CancellationReason | null>(null, [Validators.required]),
      reasonDescription: new FormControl<string | null>(null, [Validators.required]),
      section: new FormControl<string | null>(null, [Validators.required]),
    }),
    [ShareCancellationStepsEnum.Form280]: new FormGroup({
      changeDate: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
      buyBackType: new FormControl<BuyBackType | null>(null, [Validators.required]),
      attachedDocumentType: new FormControl<BuyBackAttachedDocumentType | null>(null, [Validators.required]),
    }),
    [ShareCancellationStepsEnum.Form2560]: new FormGroup({
      dateOfProposedMeeting: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
      section: new FormControl<Form2560SectionsEnum | null>(null, [Validators.required]),
      section249hAppliesForUnlistedCompany: new FormControl<boolean>(false),
      isListedCompany: new FormControl<boolean>(false)
    }),
    [ShareCancellationStepsEnum.Estimate]: new FormGroup({}),
  });

  constructor() {
    super();
    this.redirectAfterSubmit = false;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.updateFormSteps();
  }

  override setFormValidators(): void {
    this.setCustomFormValidators();
  }

  override setupFormChangeListeners(): void {
    super.setupFormChangeListeners();
    this.listenShareholderChanges();
    this.listenShareTypeChanges();
    this.listenNumberOfSharesCancelledChange();
    this.listenShareBuyBackTypeChange();
    this.listenIssueForCashConsidarationOnlyChanges();
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.stepsSignal()[newStepIndex].step;
    this.currentStepIndex = newStepIndex;

    if (this.currentStep === ShareCancellationStepsEnum.Estimate && this.shouldLoadEstimate) {
      this.loadEstimates();
    }
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanySecurityCancellation);
  }

  override setupChange(change: CompanySecurityCancellation = this.formModel) {
    this.shareholderDetailsForm.controls.issueForCashConsidarationOnly.setValue(this.shareholderDetailsForm.value.issueForCashConsidarationOnly ?? null);

    if (!this.isEdit && change === this.formModel) {
      return;
    }

    this.shareholderDetailsForm.controls.reason.setValue(change.reason ?? null);

    const selectedSecurityRegistryRecordIndex = this.securityRegistryRecords.findIndex((record) => record?.holdings.some((holder) => holder.securityHoldingId === change.cancellation.securityHoldingId));
    this.shareholderDetailsForm.controls.securityRegistryRecordIndex.patchValue(selectedSecurityRegistryRecordIndex ?? null);

    if (selectedSecurityRegistryRecordIndex >= 0) {
      const selectedSecurityHolding = this.securityRegistryRecords[selectedSecurityRegistryRecordIndex]?.holdings.find(holding => holding.securityHoldingId === change.cancellation.securityHoldingId);

      if (selectedSecurityHolding !== undefined) {
        this.cdr.detectChanges();
        this.shareholderDetailsForm.controls.securityHoldingId.patchValue(selectedSecurityHolding?.securityHoldingId ?? null);

        this.shareholderDetailsForm.patchValue({
          considerationPaid: change.cancellation.considerationPaid,
          totalPaid: -change.cancellation.paidIncrease,
          totalUnpaid: -change.cancellation.unpaidIncrease,
          numberOfSharesCancelled: -change.cancellation.numberIncrease,
          consolidateCertificate: change.consolidateCertificate,
          nonCashConsiderationDetails: change.nonCashConsiderationDetails
        });
      }
    }

    this.shareholderDetailsForm.patchValue({
      ...change,
      transactionDate: change.cancellation.transactionDate,
      shareCertificateNumber: change.cancellation.shareCertificateNumber
    });

    if (change.form280IfShareBuyBackOther) {
      this.form280Form.patchValue(change.form280IfShareBuyBackOther);
    }

    if (change.form2560IfCapitalReduction) {
      this.form2560Form.patchValue(change.form2560IfCapitalReduction);
    }

    this.updateFormSteps();
  }

  override buildDocument(): Document | null {
    const companySecurityCancellation = this.buildShareFormChange();
    companySecurityCancellation.description = 'Company share cancellation';

    try {
      return new Document({
        changes: [companySecurityCancellation],
        entityId: this.companyChangeData?.entityId,
        type: 'c:484',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  override onEstimateLoadSuccess(estimatedSecurityRegistryRecords: SecurityRegistryRecord[]): void {
    this.estimatedSecurityRegistryRecords = estimatedSecurityRegistryRecords;
  }

  override buildShareFormChange(): CompanySecurityCancellation {
    const changeDate = DatepickerHelper.getToday();
    const cancellation = new SecurityTransactionIncremental({
      transactionDate: this.shareholderDetailsForm.value.transactionDate!,
      securityHoldingId: this.shareholderDetailsForm.value.securityHoldingId || null,
      transactionType: SecurityTransactionType.Cancellation,
      considerationPaid: this.shareholderDetailsForm.value.considerationPaid!,
      numberIncrease: -this.shareholderDetailsForm.value.numberOfSharesCancelled!,
      paidIncrease: -this.shareholderDetailsForm.controls.totalPaid.getRawValue()!,
      unpaidIncrease: -this.shareholderDetailsForm.controls.totalUnpaid.getRawValue()!,
      securityTransactionId: Guid.generate(),
      entityId: this.companyChangeData.entityId,
      organisationId: this.companyChangeData.organisationId,
      securityType: this.selectedShareholderShareType!.securityType,
      securityTypeId: this.selectedShareholderShareType!.securityType.securityTypeId,
      relationshipIds: this.securityRegistryRecords[this.shareholderDetailsForm.controls.securityRegistryRecordIndex.value!].holders.map(h => h.relationshipId),
      shareCertificateNumber: this.shareholderDetailsForm.value.shareCertificateNumber ?? null
    });

    cancellation.documentId = this.companyChangeData.documentId ?? null as unknown as string;
    cancellation.changeId = null as unknown as string;
    cancellation.pairTransactionId = null as unknown as string;
    cancellation.shareCertificateQR = null as unknown as number;

    const companySecurityCancellation = new CompanySecurityCancellation({
      cancellation,
      companyName: this.securityRegistryRecords[this.shareholderDetailsForm.value.securityRegistryRecordIndex!]?.name ?? '',
      reason: this.shareholderDetailsForm.value.reason as CancellationReason,
      issueForCashConsidarationOnly: this.shareholderDetailsForm.value.issueForCashConsidarationOnly ?? false,
      nonCashConsiderationDetails: this.shareholderDetailsForm.controls.nonCashConsiderationDetails.value ?? '',
      section: this.shareholderDetailsForm.value.section ?? '',
      reasonDescription: this.shareholderDetailsForm.value.reasonDescription ?? '',
      consolidateCertificate: this.shareholderDetailsForm.controls.consolidateCertificate.value ?? false,
      changeDate
    });

    if (this.shareholderDetailsForm.value.reason === CancellationReason.BuyBackOther) {
      companySecurityCancellation.form280IfShareBuyBackOther = new CompanyForm280({
        ...this.form280Form.value as Partial<CompanyForm280>,
        changeDate
      });
    }

    if (this.shareholderDetailsForm.value.reason === CancellationReason.CapitalReductionMultipleShareholder) {
      companySecurityCancellation.form2560IfCapitalReduction = new CompanyForm2560({
        ...this.form2560Form.value as Partial<CompanyForm2560>,
        dateOfProposedMeeting: this.form2560Form.controls.dateOfProposedMeeting.value!,
        changeDate,
      });
    }

    return companySecurityCancellation;
  }

  override onShareholdersLoadSuccess(shareholders: SecurityRegistryRecord[]) {
    this.securityRegistryRecords = shareholders.filter(holder => holder?.holders && holder?.holders?.length > 0);
    this.setupChange();
  }

  signDataSaved(): void {
    this.shareholderDetailsForm.updateValueAndValidity();
  }

  private updateFormSteps(): void {
    const steps = ShareCancellationSteps.filter((step) => {
      if (step.step === ShareCancellationStepsEnum.Form280) {
        return this.shareholderDetailsForm.value.reason === CancellationReason.BuyBackOther;
      }

      if (step.step === ShareCancellationStepsEnum.Form2560) {
        return this.shareholderDetailsForm.value.reason === CancellationReason.CapitalReductionMultipleShareholder;
      }

      return true;
    });

    this.stepsSignal.set(steps);
  }

  private setCustomFormValidators(): void {
    const nonBeneficialOwnersSignedValidator: ValidatorFn = () => {
      if (this.shareholderDetailsForm.value.securityRegistryRecordIndex === null || this.securityRegistryRecords.length === 0 || !this.selectedShareholderShareType) {
        return null;
      }

      const nonBeneficialOwners = this.securityRegistryRecords[this.shareholderDetailsForm.value.securityRegistryRecordIndex!]?.holders
        .filter(holder => !holder.details.isBeneficialOwner);
      if (!nonBeneficialOwners?.length) {
        return null;
      }

      return nonBeneficialOwners.every(holder => Boolean(holder.details.beneficialOwner))
        ? null
        : { nonBeneficialOwnersNotSigned: true };
    };

    const paidTotalValidator: ValidatorFn = () => {
      if (this.selectedShareholderShareType === null || !this.shareholderDetailsForm.value.totalPaid || !this.shareholderDetailsForm.value.numberOfSharesCancelled) {
        return null;
      }
      // (Math.abs(prefilled total paid - total paid))/prefilled total paid < 0.05

      const calculatedTotalPaid = this.selectedShareholderShareType.securityType.nominalPrice * this.shareholderDetailsForm.value.numberOfSharesCancelled;
      return (Math.abs(calculatedTotalPaid - this.shareholderDetailsForm.value.totalPaid) / calculatedTotalPaid) < 0.05
        ? null
        : { paidChangeIsBiggerThan5Percent: true };
    };

    const unpaidTotalValidator: ValidatorFn = () => {
      if (this.selectedShareholderShareType === null || !this.shareholderDetailsForm.value.totalUnpaid || !this.shareholderDetailsForm.value.numberOfSharesCancelled) {
        return null;
      }
      // (Math.abs(prefilled total unpaid - total unpaid))/prefilled total unpaid < 0.05

      const calculatedTotalPaid = this.selectedShareholderShareType.securityType.unpaidAmount * this.shareholderDetailsForm.value.numberOfSharesCancelled;
      return (Math.abs(calculatedTotalPaid - this.shareholderDetailsForm.value.totalUnpaid) / calculatedTotalPaid) < 0.05
        ? null
        : { unpaidChangeIsBiggerThan5Percent: true };
    };

    // total paid + total unpaid > 0
    const paidUnpaidSumValidator: ValidatorFn = () => {
      const totalPaid = this.shareholderDetailsForm.controls.totalPaid.getRawValue();
      const totalUnpaid = this.shareholderDetailsForm.controls.totalUnpaid.getRawValue();
      if (totalPaid === null || totalUnpaid === null) {
        return null;
      }

      return (totalPaid + totalUnpaid) > 0
        ? null
        : { paidUnpaidSumIsNotPositive: true };
    };

    this.stepperForm.setValidators([nonBeneficialOwnersSignedValidator]);
    this.shareholderDetailsForm.setValidators([
      paidTotalValidator,
      unpaidTotalValidator,
      paidUnpaidSumValidator
    ]);
  }

  private listenShareholderChanges(): void {
    this.shareholderDetailsForm.controls.securityRegistryRecordIndex.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((recordId) => {
        if (recordId === null) {
          this.securityRegistryRecordsHoldings = [];
          this.shareholderDetailsForm.controls.securityHoldingId.patchValue(null);
          return;
        }

        this.securityRegistryRecordsHoldings = this.securityRegistryRecords[recordId]?.holdings;
        this.selectedShareholderShareType = this.securityRegistryRecordsHoldings[0] ?? null;
        this.cdr.detectChanges();
        this.shareholderDetailsForm.controls.securityHoldingId.patchValue(this.securityRegistryRecords[recordId]?.holdings[0].securityHoldingId ?? null);
      });
  }

  private listenShareTypeChanges(): void {
    this.shareholderDetailsForm.controls.securityHoldingId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((securityHoldingId) => {
        this.selectedShareholderShareType = this.securityRegistryRecordsHoldings.find(holding => holding.securityHoldingId === securityHoldingId) ?? null;
        if (this.selectedShareholderShareType) {
          // cancelled shares >=0 && <= number of shares
          this.setNumberControlValidators(this.shareholderDetailsForm.controls.numberOfSharesCancelled, () => (this.selectedShareholderShareType?.number ?? 0));
          this.shareholderDetailsForm.controls.numberOfSharesCancelled.addValidators([NumbersValidators.isInteger]);
          // total paid >=0 && <= prefilled total paid
          this.setNumberControlValidators(this.shareholderDetailsForm.controls.totalPaid, () => (this.selectedShareholderShareType?.paid ?? 0));
          // total unpaid >=0 && <= prefilled total unpaid
          this.setNumberControlValidators(this.shareholderDetailsForm.controls.totalUnpaid, () => (this.selectedShareholderShareType?.unpaid ?? 0));
        }

        this.shareholderDetailsForm.controls.numberOfSharesCancelled.patchValue(this.selectedShareholderShareType?.number ?? 0);
      });
  }

  private listenNumberOfSharesCancelledChange(): void {
    this.shareholderDetailsForm.controls.numberOfSharesCancelled.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((numberOfSharesCancelled) => {
        if (numberOfSharesCancelled === null || this.selectedShareholderShareType === null) {
          return;
        }

        this.shareholderDetailsForm.controls.considerationPaid.patchValue(numberOfSharesCancelled * this.selectedShareholderShareType.securityType.nominalPrice);
        this.shareholderDetailsForm.controls.totalPaid.patchValue(numberOfSharesCancelled * this.selectedShareholderShareType.securityType.nominalPrice);
        this.shareholderDetailsForm.controls.totalUnpaid.patchValue(numberOfSharesCancelled * this.selectedShareholderShareType.securityType.unpaidAmount);

        setControlDisabled(this.shareholderDetailsForm.controls.totalPaid, numberOfSharesCancelled >= this.selectedShareholderShareType.number);
        setControlDisabled(this.shareholderDetailsForm.controls.totalUnpaid, numberOfSharesCancelled >= this.selectedShareholderShareType.number);
      });
  }

  private listenShareBuyBackTypeChange(): void {
    this.shareholderDetailsForm.controls.reason.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((reason) => {
        setControlDisabled(this.shareholderDetailsForm.controls.reasonDescription, reason !== CancellationReason.Other);
        setControlDisabled(this.shareholderDetailsForm.controls.section, reason !== CancellationReason.Other);

        setControlDisabled(this.form280Form, reason !== CancellationReason.BuyBackOther);

        setControlDisabled(this.form2560Form, reason !== CancellationReason.CapitalReductionMultipleShareholder);

        this.updateFormSteps();
      });
  }

  private listenIssueForCashConsidarationOnlyChanges(): void {
    this.shareholderDetailsForm.controls.issueForCashConsidarationOnly.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((issueForCashConsidarationOnly) => {
        setControlDisabled(this.shareholderDetailsForm.controls.nonCashConsiderationDetails, issueForCashConsidarationOnly !== false);
      });
  }

  private setNumberControlValidators(control: FormControl, maxFn: () => number, min = 0): void {
    control.setValidators([Validators.required, NumbersValidators.min(min), NumbersValidators.relativeMax(maxFn.bind(this))]);
    control.updateValueAndValidity();
  }

  get beneficialOwnersSignsErrorMessageHidden(): boolean {
    return !this.stepperForm.errors?.['nonBeneficialOwnersNotSigned']
      || (this.shareholderDetailsForm.controls.securityRegistryRecordIndex.touched
        && this.shareholderDetailsForm.value.securityRegistryRecordIndex === null);
  }

  get formShareNumberErrorMessageHidden(): boolean {
    return Boolean(this.shareholderDetailsForm.controls.numberOfSharesCancelled?.untouched
      && this.shareholderDetailsForm.controls.totalPaid?.untouched
      && this.shareholderDetailsForm.controls.totalUnpaid?.untouched
      && !this.shareholderDetailsForm?.errors);
  }

  get shareholderDetailsForm() {
    return this.stepperForm.controls[ShareCancellationStepsEnum.ShareholderAndCancellationDetails];
  }

  get form280Form() {
    return this.stepperForm.controls[ShareCancellationStepsEnum.Form280];
  }

  get form2560Form() {
    return this.stepperForm.controls[ShareCancellationStepsEnum.Form2560];
  }

  get boSignsVisible(): boolean {
    return !!this.shareholderDetailsForm.value.securityRegistryRecordIndex || this.shareholderDetailsForm.controls.securityRegistryRecordIndex.value === 0
  }

  get selectedSecurityRegistryRecordsHolders(): (IndividualHolderModel | CorporateHolderModel)[] {
    return this.securityRegistryRecords[this.shareholderDetailsForm.value.securityRegistryRecordIndex!]?.holders ?? [];
  }

  get certificatePartHidden(): boolean {
    return !this.selectedShareholderShareType
      || !this.shareholderDetailsForm.controls.numberOfSharesCancelled.value
      || this.shareholderDetailsForm.controls.numberOfSharesCancelled.value >= this.selectedShareholderShareType.number;
  }
}
