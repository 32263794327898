import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { map, switchMap } from 'rxjs';
import { UserInvitationRecord } from '../models/userInvitationRecord';

@Injectable({
    providedIn: 'root'
  })
  export class UserInvitationsService {
    constructor(
      private api: HttpClient
    ) { }
  
    public getUserInvitations() {
      return this.api.get<UserInvitationRecord[]>(`${environment.api_url}/invites`)
        .pipe(map(u => u.map(u => new UserInvitationRecord(u))));
    }
  
    deleteInvitation(invitationId: string) {
      return this.api.delete(`${environment.api_url}/invites/${invitationId}`);
    }
  }