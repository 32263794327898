<div class="ag-shareholder-name-cell d-flex align-items-center gap-12">
  <i class="icon ic-sm" [ngClass]="iconClass"></i>

  <div>
    <ul class="list-unstyled mb-0">
      @for (holder of individualShareholders; track holder.relationshipId) {
        <li class="shareholder-name individual-shareholder-name">
          @if (displayPendingChanges
          && (holder.previousIndividualData?.fullName
            && holder.previousIndividualData?.fullName !== holder.individualData.fullName)) {
            <div class="red">{{ holder.previousIndividualData?.fullName }}</div>
            <div class="green">{{ holder.name }}</div>
          } @else {
            {{ holder.name }}
          }
        </li>
      }
    </ul>

    <ul class="list-unstyled mb-0">
      @for (holder of corporateShareholders; track holder.relationshipId) {
        <li class="shareholder-name">{{ holder.name }}</li>
      }
    </ul>
  </div>
</div>
