import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

export const roleGuard = (allowedRoles: string[], redirectUri: string) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const isAuthorized = authService.hasRole(allowedRoles);

  return isAuthorized ? true : router.parseUrl(redirectUri);
};
