<div class="card settings-page">
  <app-page-title
    header="Manage Tags"
    iconClass="ic-tags"
    backButtonPath="settings"
  >
    <app-search-input
      placeholder="Search"
      [searchDelayTime]="0"
      [formControl]="searchControl"
    ></app-search-input>

    <app-button
      id="add-tag-dd"
      text="Add Tags"
      [iconClass]="'icon ic-xs ic-plus'"
      (clickBtn)="addTag()"
      *appHasRole="['Admin', 'Manager', 'Preparer']"
    ></app-button>
  </app-page-title>

  <div class="card-body">
    @if (!isLoading) {
      <app-list-grid
        [rowData]="filteredTagList()"
        [colDefs]="colDefs"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="pageSizeService.getPageSize"
        [paginationPageSizeSelector]="true"
        [suppressCellFocus]="true"
      ></app-list-grid>
    }

    <app-loader [loading]="isLoading"></app-loader>
  </div>
</div>
