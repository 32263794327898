import { Component, inject, OnInit } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { SearchInputComponent } from "../../../components/common/search-input/search-input.component";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { LoaderComponent } from "../../../components/common/loader/loader.component";
import { ColDef } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  AddDocumentTemplateModalComponent
} from "./components/add-cover-letter-modal/add-document-template-modal.component";
import { PageSizeService } from "../../../components/common/grid/services/page-size.service";
import { CoverLetter } from "../../../../models/cover-letter";
import { CoverLettersService } from "../../../../services/cover-letters.service";
import { AgCoverLetterActionsComponent } from "./components/ag-cover-letter-actions/ag-cover-letter-actions.component";
import { AgCoverLetterFileComponent } from "./components/ag-cover-letter-file/ag-cover-letter-file.component";
import { downloadBase64File } from "../../../../functions/download-base64-file";
import { finalize } from "rxjs";
import {
  AgEntityTagsComponent
} from "../../../components/common/grid/components/ag-company-tag/ag-entity-tags.component";
import { HasRoleDirective } from '../../../../directives/has-role.directive';

@Component({
  selector: 'app-document-templates-page',
  standalone: true,
  providers: [CoverLettersService, PageSizeService],
  imports: [
    ButtonComponent,
    PageTitleComponent,
    SearchInputComponent,
    ListGridComponent,
    LoaderComponent,
    HasRoleDirective
  ],
  templateUrl: './document-templates-page.component.html',
  styleUrls: ['./document-templates-page.component.scss', '../../settings-styles.scss']
})
export class DocumentTemplatesPageComponent implements OnInit {
  public pageSizeService = inject(PageSizeService);
  private modalService = inject(NgbModal);
  private coverLettersService = inject(CoverLettersService);

  coverLetters: CoverLetter[] = [];
  isLoading = false;
  addDocumentTemplateModalOpened = false;

  ngOnInit(): void {
    this.loadCoverLetters();
  }

  createEditDocumentTemplate(editedCoverLetter: CoverLetter | null = null): void {
    if (this.addDocumentTemplateModalOpened) return;
    this.addDocumentTemplateModalOpened = true;
    const modalRef = this.modalService.open(AddDocumentTemplateModalComponent, {
      windowClass: 'add-document-template-modal',
      size: 'lg',
    });
    const instance = modalRef.componentInstance as AddDocumentTemplateModalComponent;
    instance.editedCoverLetter = editedCoverLetter;

    modalRef.result.then(
      () => {
        this.loadCoverLetters();
        this.addDocumentTemplateModalOpened = false;
      },
      () => {
        this.addDocumentTemplateModalOpened = false;
      });
  }

  downloadTemplate(coverLetter: CoverLetter): void {
    this.coverLettersService.downloadCoverLetterTemplate(coverLetter.id, coverLetter.fileName)
      .subscribe({
        next: (file) => downloadBase64File(file, coverLetter.fileName)
      });
  }

  downloadPreview(coverLetter: CoverLetter): void {
    this.coverLettersService.testCoverLetter(coverLetter.id, coverLetter.fileName)
      .subscribe({
        next: (file) => downloadBase64File(file, "test-cover-letter.pdf")
      });
  }

  downloadDefaultTemplate(): void {
    this.coverLettersService.downloadDefaultTemplate()
      .subscribe((file) => downloadBase64File(file, 'default-template.docx'));
  }

  private loadCoverLetters(): void {
    this.isLoading = true;

    this.coverLettersService.loadCoverLetters()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((coverLetters) => {
        this.coverLetters = coverLetters;
      });
  }

  colDefs: ColDef[] = [
    {
      headerName: 'Template Name',
      flex: 2,
      field: 'title',
      sort: 'asc',
      cellRenderer: AgCoverLetterFileComponent,
      cellClass: 'cursor-text'
    },
    {
      minWidth: 200,
      headerName: 'Tags',
      field: 'tags',
      flex: 1,
      cellRenderer: AgEntityTagsComponent,
    },
    {
      width: 90,
      minWidth: 90,
      headerName: '',
      cellRenderer: AgCoverLetterActionsComponent,
      cellRendererParams: {
        downloadTemplate: this.downloadTemplate.bind(this),
        downloadPreview: this.downloadPreview.bind(this),
        edit: this.createEditDocumentTemplate.bind(this),
      },
      suppressHeaderMenuButton: true,
    },
  ];
}
