import { Component, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  DatePickerFormValidators,
  DatepickerHelper
} from '../../../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../../../functions/set-control-disabled';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import {
  CompanyChangeHoldingCompanyActionEnum
} from '../../../../../../models/enums/CompanyChangeHoldingCompanyActionEnum';
import { SelectOption } from '../../../../../../models/selectOptions';
import { CountriesService } from '../../../../../../services/countries.service';
import { BaseStepperFormComponent } from '../../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { allChangeDescriptionOptions } from './all-change-description-options.constant';
import { ChangeHoldingCompanyStepsEnum } from './change-holding-company-steps.enum';
import { CompanyChangeHoldingCompany } from './company-change-holding-company.model';
import {
  CompanyNameAcnFormGroupComponent
} from "../../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import { SelectComponent } from "../../../../../components/common/select/select.component";
import { InputComponent } from "../../../../../components/common/input/input.component";
import { StepperFormComponent } from "../../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { DatePickerComponent } from "../../../../../components/common/date-picker/date-picker.component";
import { AutocompleteComponent } from "../../../../../components/common/autocomplete/autocomplete.component";

@Component({
  selector: 'app-a3-holding-company-change',
  standalone: true,
  templateUrl: './a3-holding-company-change.component.html',
  styleUrl: '../../../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
  imports: [
    ReactiveFormsModule,
    InputComponent,
    SelectComponent,
    DatePickerComponent,
    AutocompleteComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    CompanyNameAcnFormGroupComponent
  ]
})
export class A3HoldingCompanyChangeComponent extends BaseStepperFormComponent<ChangeHoldingCompanyStepsEnum, CompanyChangeHoldingCompany> implements OnInit {
  countriesService = inject(CountriesService);

  override readonly StepsEnum = ChangeHoldingCompanyStepsEnum;

  changeDescriptionOptions: SelectOption[] = [];
  countries: SelectOption[] = this.countriesService.getCountries();
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  errorMessage = '';

  form = new FormGroup({
    ...CompanyNameAcnFormGroupComponent.defineFormControls(),
    aboardCompanyName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(3)]),
    action: new FormControl<CompanyChangeHoldingCompanyActionEnum | string | null>(null, Validators.required),
    holdingCompanyPlaceOfIncorporation: new FormControl<string | null>(null, Validators.required),
    changeDate: new FormControl<Date | null>(null, [Validators.required, DatePickerFormValidators.dateFormatValidator]),
  });

  override stepperForm = new FormGroup({
    [ChangeHoldingCompanyStepsEnum.FormDescription]: new FormGroup({}),
    [ChangeHoldingCompanyStepsEnum.ChangeHoldingCompany]: this.form
  });

  isAustraliaSelected = signal(false);

  constructor() {
    super();
    this.setupSteps(ChangeHoldingCompanyStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.setDefaultFormState();

    this.listenFormChanges();
    this.listenHoldingCompanyPlaceOfIncorporation();
    this.listenChangeDescription();

    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanyChangeHoldingCompany);
  }

  override setupChange(change: CompanyChangeHoldingCompany = this.formModel) {
    this.form.patchValue({
      aboardCompanyName: change.holdingCompanyName,
      name: change.holdingCompanyName,
      acn: change.holdingCompanyAcn,
      holdingCompanyPlaceOfIncorporation: change.holdingCompanyPlaceOfIncorporation,
    });

    this.form.controls.aboardCompanyName.setValue(change.holdingCompanyName ?? null);
    this.form.controls.holdingCompanyPlaceOfIncorporation.setValue(change.holdingCompanyPlaceOfIncorporation ?? null);

    if (!this.isEdit && change === this.formModel)
      return;

    this.setupActionOptions();
    this.updateControlsWithChangeAction(change.action);

    this.form.patchValue({
      ...change,
      name: change.holdingCompanyName,
      acn: change.holdingCompanyAcn,
      aboardCompanyName: change.holdingCompanyName,
      changeDate: DatepickerHelper.toDate(change.changeDate)
    });
  }

  override buildDocument(): Document | null {
    const formValue = this.form.value;
    const ultimateHoldingCompanyChanges = new CompanyChangeHoldingCompany({
      ...formValue as Partial<CompanyChangeHoldingCompany>,
      changeDate: formValue.changeDate!,
      holdingCompanyName: formValue.name ?? formValue.aboardCompanyName ?? '',
      holdingCompanyAcn: formValue.acn!,
    });

    try {
      return new Document({
        changes: [ultimateHoldingCompanyChanges],
        entityId: this.companyChangeData.entityId,
        type: 'c:484',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private setDefaultFormState(): void {
    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());

    this.setupActionOptions();
  }

  private setupActionOptions(): void {
    const setOtherOptions = () => {
      this.changeDescriptionOptions = allChangeDescriptionOptions
        .filter(option => option.value !== CompanyChangeHoldingCompanyActionEnum.AP);
    };

    const setAppointOptions = () => {
      this.changeDescriptionOptions = allChangeDescriptionOptions
        .filter(option => option.value === CompanyChangeHoldingCompanyActionEnum.AP);
    };

    if (this.isEdit) {
      if (this.formModel.action === CompanyChangeHoldingCompanyActionEnum.AP) {
        setAppointOptions();
      } else {
        setOtherOptions();
      }
    } else {
      if (this.formModel.holdingCompanyName) {
        setOtherOptions();
      } else {
        setAppointOptions();
      }
    }
  }

  private listenFormChanges(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.errorMessage = '';
      });
  }

  private listenHoldingCompanyPlaceOfIncorporation(): void {
    this.form.controls.holdingCompanyPlaceOfIncorporation.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((country) => {
        const isAustraliaSelected = country?.includes('Australia') ?? false;

        if (isAustraliaSelected) {
          this.form.controls.aboardCompanyName?.setValue(this.form.controls.name?.getRawValue() ?? null);
        } else {
          this.form.controls.name?.setValue(this.form.controls.aboardCompanyName?.getRawValue() ?? null);
        }

        setControlDisabled(this.form.controls.acn, !isAustraliaSelected);
        setControlDisabled(this.form.controls.name, !isAustraliaSelected);
        setControlDisabled(this.form.controls.aboardCompanyName, isAustraliaSelected);
        this.isAustraliaSelected.set(isAustraliaSelected);
      });
  }

  private listenChangeDescription(): void {
    this.form.controls.action.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.updateControlsWithChangeAction(value as CompanyChangeHoldingCompanyActionEnum);
      });
  }

  private updateControlsWithChangeAction(action: CompanyChangeHoldingCompanyActionEnum | null): void {
    switch (action) {
      case CompanyChangeHoldingCompanyActionEnum.AP:
        // Appoint UHC - use default settings of form
        break;

      case CompanyChangeHoldingCompanyActionEnum.NO:
        // Cease UHC - disable everything, except date of change and change type
        setControlDisabled(this.form.controls.aboardCompanyName);
        setControlDisabled(this.form.controls.holdingCompanyPlaceOfIncorporation);
        this.form.updateValueAndValidity();

        break;

      default:
        // Change UHC name
        this.form.controls.holdingCompanyPlaceOfIncorporation?.enable();
        this.form.controls.aboardCompanyName?.enable();
    }
  }

  get isOverseasCompany(): boolean {
    return Boolean(this.form.value.holdingCompanyPlaceOfIncorporation && this.form.value.holdingCompanyPlaceOfIncorporation !== 'AU');
  }

  get hideDataPart(): boolean {
    return this.form.controls.action.getRawValue() === null || this.form.controls.action.getRawValue() === CompanyChangeHoldingCompanyActionEnum.NO;
  }

  get hideAustralianCompanyNameAcn(): boolean {
    return this.form.controls.action.getRawValue() === null
      || !(this.form.controls.action.getRawValue() !== CompanyChangeHoldingCompanyActionEnum.NO
        && this.isAustraliaSelected());
  }

  get hideAboardCompanyName(): boolean {
    return this.form.controls.action.getRawValue() === null
      || !(this.form.controls.action.getRawValue() !== CompanyChangeHoldingCompanyActionEnum.NO
        && !this.isAustraliaSelected());
  }
}
