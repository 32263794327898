<div class="action-container" *appHasRole="['Admin', 'Manager', 'Preparer']">
  <app-ag-action-icon-button
    iconClass="ic-eye"
    (action)="openDocument()"
  ></app-ag-action-icon-button>

  <div
    ngbDropdown
    container="body"
    [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
    class="dropdown d-inline-flex"
  >
    <app-ag-action-icon-button ngbDropdownToggle iconClass="ic-three-dots"></app-ag-action-icon-button>

    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      @if (params.data?.asicStatus === AsicStatus.Lodged) {
        <div ngbDropdownItem class="dropdown-item" (click)="openForm492()">
          <a class="menu-action-button">
            <span>Request for Correction</span>
          </a>
        </div>

        <div ngbDropdownItem class="dropdown-item" (click)="openForm902()">
          <a class="menu-action-button">
            <span>Notification for Supplementary Information</span>
          </a>
        </div>
      }

      @if (documentDeleteAvailable) {
        <div ngbDropdownItem class="dropdown-item" (click)="deleteDocument()">
          <a class="menu-action-button">
            <span>Delete Document</span>
          </a>
        </div>
      } @else {
        <div class="no-action">No Action</div>
      }
    </div>
  </div>
</div>
