<div class="card settings-page branding-page">
  <app-page-title
    header="Branding"
    iconClass="ic-upload-file"
    backButtonPath="settings"
  >
    @if (currentPageMode == BrandingPageModes.Preview || currentPageMode == BrandingPageModes.NoLogo) {
      <app-button
        id="edit-branding-btn"
        text="Edit Branding"
        iconClass="icon ic-xs ic-edit"
        [isDisabled]="isLoading"
        (clickBtn)="startEditBranding()"
        *appHasRole="['Admin', 'Manager']"
      />
    } @else if (currentPageMode == BrandingPageModes.Edit) {
      <app-button
        text="Save"
        [isDisabled]="isLoading"
        (clickBtn)="saveChanges()"
      />
      <app-button
        text="Cancel"
        btnColor="white"
        [isDisabled]="isLoading"
        (clickBtn)="cancelEditing()"
      />
    }
  </app-page-title>

  <div class="branding-page-content">
    @if (currentPageMode == BrandingPageModes.Preview || currentPageMode == BrandingPageModes.Edit) {
      <div class="preview-variant-control">
        <app-radio
          [formControl]="previewVariantControl"
          [options]="previewVariants"
        />
      </div>
    }

    @if (currentPageMode == BrandingPageModes.Loading) {
      <app-loader [loading]="currentPageMode == BrandingPageModes.Loading && isLoading"></app-loader>
    } @else if (currentPageMode == BrandingPageModes.NoLogo) {
      <div class="no-logos">
        <i class="icon ic-md ic-info-circle"></i>
        <span>No logo is available at the moment. Please upload a logo.</span>
      </div>
    } @else if (currentPageMode == BrandingPageModes.Preview) {
      <div class="view-content">
        <div class="template-wrapper">
          <ng-container *ngTemplateOutlet="previewTemplate; context: { logo: logoImg, footerText: organisationBrandModel.footerText }"></ng-container>
        </div>

        <div class="description">
          <div class="form-group mb-0">
            <label class="title mb-1">Brand Name</label>
            <span class="property" appCopy>{{ organisationBrandModel.brandName | defaultValue }}</span>
          </div>

          <div class="form-group mb-0">
            <label class="title mb-1">Cover Page Footer Text</label>
            <span class="property" appCopy>{{ organisationBrandModel.footerText | defaultValue }}</span>
          </div>

          <div class="form-group mb-0">
            <label class="title mb-1">Brand Color</label>

            <div class="property color-template-wrapper">
              <span class="color-template preview" [style.background]="organisationBrandModel.mainColor"></span>
              <span appCopy>{{ organisationBrandModel.mainColor | defaultValue }}</span>
            </div>
          </div>

          <div class="form-group mb-0">
            <label class="title mb-1">Insert Logo in the Annual Statement Cover Letter</label>
            <span class="property" appCopy>{{ 'No' | defaultValue }}</span>
          </div>

          <div class="form-group mb-0">
            <label class="title mb-1">Add a Cover Page on ASIC forms</label>
            <span class="property" appCopy>{{ 'No' | defaultValue }}</span>
          </div>
        </div>
      </div>
    } @else if (currentPageMode == BrandingPageModes.Edit) {
      <form class="view-content" (ngSubmit)="saveChanges($event)">
        <button type="submit" hidden [disabled]="isLoading"></button>

        <div class="preview-container">
          <div class="template-wrapper">
            <ng-container *ngTemplateOutlet="previewTemplate; context: { logo: url, footerText: editModeFooterText }"></ng-container>
          </div>
        </div>

        <div class="description" [formGroup]="form">
          <div class="form-group mb-0">
            <app-input
              label="Brand Name"
              formControlName="brandName"
            />
          </div>

          <div class="form-group mb-0">
            <app-select
              label="Cover Page Footer Text"
              formControlName="coverPageFooterTextVariant"
              [options]="coverPageFooterTextOptions"
              [valuePrimitive]="true"
            />
          </div>

          @if (form.controls.coverPageFooterTextVariant.value === CoverPageFooterTextVariant.CustomFooterText) {
            <div class="form-group mb-0">
              <app-input
                label="Custom Footer Text"
                formControlName="footerText"
              />
            </div>
          }

          <div class="form-group mb-0 file-control-palette">
            <div class="file-control">
              <div class="header">Brand Logo</div>

              <input
                #fileUploadInput
                style="display: none"
                type="file"
                [multiple]="false"
                [accept]="acceptedFormats"
                [disabled]="isLoading"
                (change)="handleFileInputEvent($event)"
              >

              @if (url) {
                <div class="file-label">
                  <div class="icon-text">
                    <i class="icon ic-sm ic-file"></i>
                    <span>logo.png</span>
                  </div>

                  <app-ag-action-icon-button
                    iconClass="ic-sm ic-trash"
                    [disabled]="isLoading"
                    (action)="clearFiles()"
                  ></app-ag-action-icon-button>
                </div>

              } @else {
                <app-button
                  iconClass="icon ic-md ic-upload-file"
                  btnColor="white"
                  text="Upload New Logo"
                  [isDisabled]="isLoading"
                  (clickBtn)="fileUploadInput.click()"
                />
              }
            </div>

            <div class="palette">
              <app-color-picker
                label="Brand Color"
                formControlName="mainColor"
                [fullWidth]="true"
                [hideRandom]="true"
                [colors]="brandColors"
              />
            </div>
          </div>

          <div>
            <app-yes-no-control
              label="Insert Logo in the Annual Statement Cover Letter"
              formControlName="insertLogoInAnnualStatementCoverLetter"
            />
          </div>

          <div>
            <app-yes-no-control
              label="Add a Cover Page on ASIC forms"
              formControlName="addCoverPageOnAsicForms"
            />
          </div>

          @if (errorMessage) {
            <div class="error-message">
              <app-disclaimer color="red">{{ errorMessage }}</app-disclaimer>
            </div>
          }
        </div>
      </form>
    }
  </div>
</div>

<ng-template #previewTemplate let-logo="logo" let-footerText="footerText">
  @if (this.previewVariantControl.value === 1) {
    <app-shadow-dom-viewer [customStyle]="customStyle" [htmlContent]="htmlContent()"/>
  } @else {
    <div class="template">
      <div class="logo-wrapper">
        @if (logo) {
          <img
            class="logo-image"
            alt="Organisation Logo"
            [src]="logo"
          >
        }
      </div>

      <p style="text-align: end">DOCUMENT LOGO PREVIEW</p>
      <p style="text-align: end; flex-grow: 1">LOGO TEST</p>

      @if (footerText) {
        <div class="footer-text">{{ footerText }}</div>
      }
    </div>
  }
</ng-template>
