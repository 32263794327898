import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DividerComponent } from "../common/divider/divider.component";
import { NgClass } from "@angular/common";
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from '../../../models/securityRegistryRecord';
import { ButtonComponent } from "../common/button/button.component";
import { SpanWithOpenIconComponent } from "../common/span-with-open-icon/span-with-open-icon.component";
import {
  EntityNameNumberRecordComponent
} from "../common/entity-name-number-record/entity-name-number-record.component";
import { AsPipe } from "../../../pipes/asPipe";
import { ACNPipe } from "../../../pipes/acnPipe";
import { AgYesNoStatusComponent } from "../common/grid/components/ag-yes-no-status/ag-yes-no-status.component";
import { ColDef, ValueGetterParams } from 'ag-grid-enterprise';
import { SecurityService } from '../../../services/security.service';
import { ToastrService } from 'ngx-toastr';
import { ListGridComponent } from "../common/grid/components/list-grid/list-grid.component";
import { BeneficialOwnerComponent } from "../common/beneficial-owner/beneficial-owner.component";
import { AuthSignatoryComponent } from "../common/auth-signatory/auth-signatory.component";
import { DefaulValuePipe } from "../../../pipes/enumsPipes/defaultValuePipe";
import {
  AgHolderNumberOfSharesComponent
} from "../common/grid/components/ag-holder-number-of-shares/ag-holder-number-of-shares.component";

@Component({
  selector: 'app-securityholder-profile',
  standalone: true,
  templateUrl: './securityholder-profile.component.html',
  styleUrl: './securityholder-profile.component.scss',
  imports: [
    ButtonComponent,
    DividerComponent,
    NgClass,
    SpanWithOpenIconComponent,
    EntityNameNumberRecordComponent,
    ACNPipe,
    AsPipe,
    AgYesNoStatusComponent,
    BeneficialOwnerComponent,
    AuthSignatoryComponent,
    ListGridComponent,
    DefaulValuePipe
  ]
})
export class SecurityholderProfileComponent {
  @Input() securityholder!: SecurityRegistryRecord;

  @Output() onDataSaved = new EventEmitter();
  @Output() profileViewChange = new EventEmitter<boolean>();

  securityService = inject(SecurityService);
  toastr = inject(ToastrService);

  CorporateHolderModel = CorporateHolderModel;
  IndividualHolderModel = IndividualHolderModel;

  colDefs: ColDef[] = [
    {
      headerName: 'Security Type',
      valueGetter: (params: ValueGetterParams<SecurityRegistryRecord>) => params.data!.holdings[0].securityType.identifier,
      flex: 2,
      sort: 'asc',
      suppressHeaderMenuButton: true,
      minWidth: 100
    },
    {
      headerName: '# of Shares',
      field: 'number',
      flex: 1,
      cellRenderer: AgHolderNumberOfSharesComponent,
      suppressHeaderMenuButton: true,
      minWidth: 100
    },
    {
      headerName: 'Paid Amount',
      valueGetter: (params: ValueGetterParams<SecurityRegistryRecord>) => params.data!.holdings[0].paid,
      flex: 1,
      suppressHeaderMenuButton: true,
      minWidth: 100
    },
    {
      headerName: 'Unpaid Amount',
      valueGetter: (params: ValueGetterParams<SecurityRegistryRecord>) => params.data!.holdings[0].unpaid,
      flex: 1,
      suppressHeaderMenuButton: true,
      minWidth: 100
    },
    {
      headerName: 'Fully Paid',
      field: 'fullyPaid',
      flex: 1,
      cellRenderer: AgYesNoStatusComponent,
      suppressHeaderMenuButton: true,
      minWidth: 50
    },
  ];
}
