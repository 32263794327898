<div class="card">
  <app-page-title
    header="Outgoing SMS Messages"
    iconClass="ic-outgoing-emails"
  >
    @if (checkedTextMessageRecords.length) {
      <app-button
        [text]="'Export selected messages'"
        [iconClass]="'icon ic-xs ic-download-file'"
        [isDisabled]="!totalRows || modalOpened()"
        (clickBtn)="exportTextMessagesList(false, ExportTypeEnum.EXCEL)"
      ></app-button>
    } @else {
      <app-button
        style="width: 100px"
        [text]="'Export'"
        [iconClass]="'icon ic-xs ic-download-file'"
        [isDisabled]="!totalRows || modalOpened()"
        (clickBtn)="exportSelectedTextMessagesToXls()"
      ></app-button>
    }
  </app-page-title>

  <app-grid
    pageTitle="Outgoing SMS Messages"
    storageKey="outgoing-sms-messages"
    [maxBlocksInCache]="1"
    [excelStyles]="excelStyles"
    [defaultExcelExportParams]="excelExportParams"
    [colDefs]="$any(colDefs)"
    [rowData]="[]"
    [totalRows]="totalRows"
    [gridOptions]="gridOptions"
    [suppressRowClickSelection]="true"
    [rowModelType]="serverRowModelType"
    [serverSideDatasource]="dataSource"
    [getRowId]="getRowId"
    [pagination]="true"
    [paginationPageSize]="defaultPageSize"
    [cacheBlockSize]="cacheBlockSize"
    (gridReady)="onGridReady($event)"
    (itemSelect)="selectTextMessageRecord($event)"
    (search)="onSearch($event)"
  ></app-grid>
</div>

<app-outgoing-text-message-details
  [isOuterLoading]="isDetailsLoading"
  [opened]="!!$selectedTextMessageRecord()"
  [textMessageRecord]="$selectedTextMessageRecord()"
  [currentEmailMessageIndex]="currentTextMessageRecordIndex"
  [totalRows]="totalRows"
  [currentEmailMessageItemNumber]="currentTextMessageItemNumber"
  (close)="onCloseDetails()"
  (getNextEmailMessage)="nextTextMessage()"
  (getPreviousEmailMessage)="previousTextMessage()"
  (refreshData)="reloadGrid()"
/>
