import { NgClass, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import { diff } from 'deep-object-diff';
import { catchError, of, tap } from 'rxjs';
import { Entity } from '../../../../../../models/entity';
import { EntityType, getEntityType } from '../../../../../../models/enums/entityType';
import { FilterOption, GridFilterModel } from '../../../../../../models/gridFilter';
import { Tag } from '../../../../../../models/tag';
import { CompaniesService } from '../../../../../../services/companies.service';
import { GridService } from '../../../../../../services/grid.service';
import { TagsService } from '../../../../../../services/tags.service';
import { hexToRgb } from '../../../../../helpers/hex-to-rgb.helper';
import {
  SelectTagsDropdownComponent
} from '../../../../company-profile-tags/select-tags-dropdown/select-tags-dropdown.component';
import { ButtonComponent } from '../../../button/button.component';
import { TagComponent } from '../../../tag/tag.component';
import { Company } from "../../../../../../models/company";
import { Trust } from "../../../../../../models/trust";
import { Individual } from "../../../../../../models/individual";
import { ColumnWithExportName } from "../../../../../../models/columnWithExportName";
import { TrustsService } from "../../../../../../services/trustsService";
import { IndividualService } from "../../../../../../services/individual.service";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { HasRoleDirective } from '../../../../../../directives/has-role.directive';

type CellRendererParams = ICellRendererParams<Company | Trust | Individual> & {
  allowEdit?: boolean;
  wrappable?: boolean;
  useValueForTags?: boolean;
  handleAddNewTag?: (() => void)
}

@Component({
  selector: 'app-ag-entity-tags',
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    TagComponent,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    SelectTagsDropdownComponent,
    HasRoleDirective
  ],
  templateUrl: './ag-entity-tags.component.html',
  styleUrl: './ag-entity-tags.component.scss'
})
export class AgEntityTagsComponent implements ICellRendererAngularComp {
  companiesService = inject(CompaniesService);
  trustsService = inject(TrustsService);
  individualService = inject(IndividualService);
  gridService = inject(GridService);
  tagsService = inject(TagsService);

  protected readonly hexToRgb = hexToRgb;
  entity!: Entity | Individual | Company | Trust;
  systemTags: Tag[] = [];
  tags: Tag[] = [];
  wrappable = false;
  allowEdit = false;
  useValueForTags = false
  handleAddNewTag?: (() => void);

  agInit(params: CellRendererParams): void {
    this.setParams(params);
    this.handleAddNewTag = params.handleAddNewTag;
  }

  refresh(params: CellRendererParams): boolean {
    this.setParams(params);
    return true;
  }

  private setParams(params: CellRendererParams): void {
    this.entity = params.data as Entity & Individual & Company & Trust;
    this.allowEdit = params.allowEdit ?? false;
    this.wrappable = params.wrappable ?? false;
    this.useValueForTags = params.useValueForTags ?? false;
    this.systemTags = (params.data?.systemTags ?? []).map(tag => new Tag(tag)) || [];
    this.tags = ((this.useValueForTags ? params.value : params.data?.tags) as Tag[] ?? [])?.map((tag: Tag) => new Tag(tag));
    this.tags?.sort((a, b) => a.name.localeCompare(b.name));
  }

  selectTag(tagName: string, isSystemTag: boolean): void {
    let colDefs: ColumnWithExportName[] = [];
    if(Object.keys(this.entity).includes('acn')) {
      colDefs = this.companiesService.getColDefs();
    } else if(Object.keys(this.entity).includes('abn')) {
      colDefs = this.trustsService.getColDefs();
    } else if(Object.keys(this.entity).includes('individualId')) {
      colDefs = this.individualService.getColDefs();
    }

    const index = isSystemTag
      ? colDefs.findIndex(c => c.field === 'systemTags')
      : colDefs.findIndex(c => c.field === 'tags');
    const filterOptions = (colDefs[index]?.filterParams as { filterOptions?: FilterOption[] })?.filterOptions ?? [];

    if (filterOptions.length) {
      filterOptions.forEach(option => {
        if (option.value === tagName && !option.active) {
          option.active = true;
        }
      });

      const modelValues = filterOptions.filter(option => option.active).map(item => item.value);
      colDefs[index].filterTagShowed = !!modelValues?.length;


      const filterModel: GridFilterModel = this.gridService.getGridFilterModelState();
      if (modelValues?.length) {
        const difference = isSystemTag
          ? diff(filterModel['systemTags']?.values as string[], modelValues)
          : diff(filterModel['tags']?.values as string[], modelValues);

        if (typeof difference === 'object' && Object.keys(difference)?.length) {
          if(isSystemTag) {
            filterModel['systemTags'] = { filterType: 'set', values: modelValues };
          } else {
            filterModel['tags'] = { filterType: 'set', values: modelValues };
          }
        } else {
          return;
        }
      }

      if(Object.keys(this.entity).includes('acn')) {
        this.companiesService.setColDefs(colDefs);
        this.companiesService.gridApi.setFilterModel(filterModel);
      } else if(Object.keys(this.entity).includes('abn')) {
        this.trustsService.setColDefs(colDefs);
        this.trustsService.gridApi.setFilterModel(filterModel);
      } else if(Object.keys(this.entity).includes('individualId')) {
        this.individualService.setColDefs(colDefs);
        this.individualService.gridApi.setFilterModel(filterModel);
      }
    }
  }

  assignTag({ tag, assign }: { tag: Tag, assign: boolean }): void {
    if (assign && !this.tags.find(t => t.tagId === tag.tagId)) {
      this.tags = [...this.tags, tag];
      this.entity.tags = [...this.entity.tags, tag];
    } else {
      this.tags = this.tags.filter(t => t.tagId !== tag.tagId);
      this.entity.tags = this.entity.tags.filter(t => t.tagId !== tag.tagId);
    }

    this.tagsService.assignTag(tag, this.entityId, assign, this.entityType)
      .pipe(catchError((error) => {
        console.warn('[AgCompanyTagComponent] assignTag: ', error);
        return of();
      }))
      .subscribe();
  }

  addNewTag(): void {
    const { modalRef, instance } = this.tagsService.openCreateEditTagModal();
    instance.isEdit = false;
    modalRef.result.then((tag: Tag) => {
      this.tagsService.createOrUpdateTag(tag, this.entityId, true, this.entityType)
        .pipe(
          tap((tagId) => {
            this.assignTag({ tag: new Tag({ ...tag, tagId }), assign: true });
          }),
          catchError((error) => {
            console.warn('[AgCompanyTagComponent] createOrUpdateTag: ', error);
            return of([]);
          }),
        )
        .subscribe({ next: () => this.handleAddNewTag?.() });
      },
      () => {
      });
  }

  get entityId(): string {
    return (this.entity as { entityId?: string })?.entityId ?? (this.entity as { individualId?: string })?.individualId ?? '';
  }

  get entityType(): EntityType {
    return getEntityType(this.entity);
  }
}
