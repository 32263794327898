<div class="select-container">
  <div class="header">
    <div class="label">{{ label }}</div>
  </div>

  <div
    #suburbDropdown="ngbDropdown"
    ngbDropdown
    class="dropdown"
    (openChange)="onDropdownChange($event)"
  >
    <div
      class="input-wrapper"
      [ngClass]="{'opened': suburbDropdown.isOpen()}"
    >
      <input
        ngbDropdownToggle
        tabindex="0"
        class="input"
        type="text"
        [formControl]="searchControl"
        [placeholder]="placeholder"
        (keydown)="suburbDropdown.open()"
      >
      <span ngbDropdownToggle class="icon ic-select-icon input-icon"></span>
    </div>
    <div ngbDropdownMenu class="dropdown-menu">
      @for (option of filteredOptions; track option; let index = $index) {
        <button
          ngbDropdownItem
          type="button"
          class="dropdown-button"
          [class.selected]="option.value === value?.value"
          (click)="selectOption(option, index)"
        >
          <span class="label">{{ option.label }}</span>

          <span class="icon ic-circle-check"></span>
        </button>
      }

      @if (searchControl.value?.length && !filteredOptions.length) {
        <button
          ngbDropdownItem
          type="button"
          class="dropdown-button selected"
          (click)="selectOption({ label: searchControl.value ?? '', value: -1 }, -1)"
        >
          {{ searchControl.value }}
        </button>
      } @else if (!searchControl.value?.length && !filteredOptions.length) {
        <div>Start typing</div>
      }
    </div>
  </div>

  @if (displayValidationError) {
    <app-validation-error></app-validation-error>
  }
</div>
