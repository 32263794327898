import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import {
  AgActionIconButtonComponent
} from "../../../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { CoverLetter } from "../../../../../../models/cover-letter";
import { HasRoleDirective } from '../../../../../../directives/has-role.directive';

type ActionEvent = (data: CoverLetter | undefined) => void;
type CellRendererParams = ICellRendererParams<CoverLetter> & {
  edit: ActionEvent,
  downloadTemplate: ActionEvent,
  downloadPreview: ActionEvent,
};

@Component({
  selector: 'app-ag-cover-letter-actions',
  standalone: true,
  imports: [
    AgActionIconButtonComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    HasRoleDirective
  ],
  templateUrl: './ag-cover-letter-actions.component.html',
  styleUrl: './ag-cover-letter-actions.component.scss'
})
export class AgCoverLetterActionsComponent implements ICellRendererAngularComp {
  params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  edit(): void {
    this.params.edit(this.params.data);
  }

  downloadTemplate(): void {
    this.params.downloadTemplate(this.params.data);
  }

  downloadPreview(): void {
    this.params.downloadPreview(this.params.data);
  }
}
