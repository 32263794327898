<div class="address-and-occupier" [formGroup]="form">
  <app-address-form-group
    [label]="label"
    [useInternationalAddresses]="useInternationalAddresses"
    [expandedAddressForm]="expandedAddressForm"
    [formGroup]="form.controls.address"
    [suggestedAddresses]="suggestedAddresses"
    [careOfAllowed]="careOfAllowed"
  ></app-address-form-group>

  <ng-content></ng-content>

  <div class="occupier-name">
    <app-date-picker
      [hidden]="changeDateHidden"
      label="Date of Change"
      formControlName="changeDate"
      [minDate]="minDateOfAddressChange"
      [maxDate]="maxDateOfAddressChange"
      [customErrors]="customDatepickerErrors"
    ></app-date-picker>

    <div [hidden]="occupierPartHidden">
      <app-yes-no-control
        formControlName="occupiesTheAddress"
        label="Does the company occupy the premises at the new registered office?"
      ></app-yes-no-control>
    </div>

    <div [hidden]="occupierPartHidden || occupierNameHidden" class="occupier-name">
      <app-input
        label="Name of Occupier"
        formControlName="occupierNameIfDoesntOccupy"
      ></app-input>

      <app-yes-no-control
        formControlName="companyHaveOccupiersConsent"
        label="Does the company have occupier's consent?"
        [customErrors]="{ valueMismatch: 'Consent from the occupier is necessary to proceed.' }"
      ></app-yes-no-control>
    </div>
  </div>
</div>
