import { Component } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DocumentRecord } from '../../../../../../models/documentRecord';
import { AgActionIconButtonComponent } from "../ag-action-icon-button/ag-action-icon-button.component";
import { AsicStatus } from "../../../../../../models/enums/asicStatus";
import { DocumentStatusEnum } from "../../../../../../models/enums/documentStatusEnum";
import { HasRoleDirective } from '../../../../../../directives/has-role.directive';

type ActionEvent = (data: DocumentRecord | undefined) => void;

type CellRendererParams = ICellRendererParams<DocumentRecord> & {
  openDocument: ActionEvent,
  openForm492: ActionEvent,
  openForm902: ActionEvent,
  deleteDocument: ActionEvent,
};

@Component({
  selector: 'app-ag-company-document-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AgActionIconButtonComponent,
    HasRoleDirective
  ],
  templateUrl: './ag-company-document-actions.component.html',
  styleUrl: '../ag-company-actions/ag-company-actions.component.scss'
})
export class AgCompanyDocumentActionsComponent implements ICellRendererAngularComp {
  protected params!: CellRendererParams;
  readonly AsicStatus = AsicStatus;

  agInit(params: CellRendererParams): void {
    this.params = params;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  openDocument(): void {
    this.params.openDocument(this.params.data);
  }

  openForm492(): void {
    this.params.openForm492(this.params.data);
  }

  openForm902(): void {
    this.params.openForm902(this.params.data);
  }

  deleteDocument(): void {
    this.params.deleteDocument(this.params.data);
  }

  get documentDeleteAvailable(): boolean {
    return this.params.data?.status === DocumentStatusEnum.AuthorisationPending || this.params.data?.status === DocumentStatusEnum.SignaturePending;
  }
}
