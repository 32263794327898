<div class="action-container">
  <app-ag-action-icon-button
    iconClass="ic-alert-triangle"
    [numberOfAlerts]="numberOfAlerts"
    [disabled]="!numberOfAlerts"
    (action)="numberOfAlerts && onOpenAlerts()"
  ></app-ag-action-icon-button>

  <app-ag-action-icon-button
    iconClass="ic-notes"
    (action)="onOpenNotes()"
    *appHasRole="['Admin', 'Manager', 'Preparer']"
  ></app-ag-action-icon-button>

  <app-ag-action-icon-button
    iconClass="ic-eye"
    (action)="onOpenProfile()"
    *appHasRole="['Admin', 'Manager', 'Preparer']"
  ></app-ag-action-icon-button>
</div>
