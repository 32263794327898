<div class="holder d-flex align-items-center">
  <div class="d-flex align-items-center pr-3">
    <i class="icon" [ngClass]="{
      'ic-accounts': isJoint,
      'ic-my-account': !isJoint && holderGroups[this.keys[0]][0].$type === IndividualHolderModel.$type,
      'ic-companies': !isJoint && holderGroups[this.keys[0]][0].$type === CorporateHolderModel.$type
      }"></i>
  </div>
  <div class="w-100 flex-text-ellipsis">
    @for (groupEntry of holderGroups | keyvalue; track groupEntry) {
    <div class="border-bottom-dashed prop">

      @for (holder of groupEntry.value; track holder) {
        @if (holder.$type === CorporateHolderModel.$type) {
          <app-span-with-open-icon
            class="cell-padding pl-0 grid-line-height"
            [route]="(holder | as : CorporateHolderModel).entityId ? ['company-profile', (holder | as : CorporateHolderModel).entityId!] : []"
          >
            <app-entity-name-number-record
              [name]="holder.name"
              [numberFormatted]="(holder | as : CorporateHolderModel).entityData.entityNumber | acn"
            ></app-entity-name-number-record>
          </app-span-with-open-icon>
        }
        @if (holder.$type === IndividualHolderModel.$type) {
          <div class="cell-padding pl-0">
            <app-span-with-open-icon
              class="cell-padding pl-0 grid-line-height"
              [route]="(holder | as : IndividualHolderModel).individualId ? ['individual', (holder | as : IndividualHolderModel).individualId!] : []"
            >
              <div [class.changes-hidden]="!pendingButtonState">
                @if (pendingButtonState && previousOfficeholderName !== officeholderName) {
                  <div class="prop previous">{{ previousOfficeholderName }}</div>
                  <div class="prop current">{{ officeholderName | defaultValue }}</div>
                } @else {
                  <div class="prop">{{ previousOfficeholderName | defaultValue }}</div>
                }
              </div>
            </app-span-with-open-icon>
          </div>
        }
      }
    </div>
    }
  </div>
</div>
