import { Component, inject, OnInit } from '@angular/core';
import CompanyNameAcnComponent from "../../../components/common/company-name-acn/company-name-acn.component";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from "@angular/forms";
import { StepperFormComponent } from "../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatepickerHelper } from "../../../../custom-form-validators/date-picker-form-validators";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { CompanyForm361 } from "../../documents/asic-forms/form361-registered-agent-ceasing/CompanyForm361";
import { Document } from "../../../../models/document";
import { BaseBulkStepperFormComponent } from "../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component";
import { BulkCompanyCeaseRegisteredAgent } from "./BulkCompanyCeaseRegisteredAgent";
import { DocumentationType } from "../../../../models/enums/documentConfirmEnums";
import { SelectOption } from "../../../../models/selectOptions";
import { ColDef, RowClassRules } from "ag-grid-community";
import {
  AgEntityNameAcnAbnComponent
} from "../../../components/common/grid/components/ag-entity-name-acn-abn/ag-entity-name-acn-abn.component";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { Company } from "../../../../models/company";
import { catchError, firstValueFrom, forkJoin, of } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Relationship } from "../../../../models/relationship";
import { OfficeHoldersByCompany } from "../../../../models/bulkChangeData";
import { CompaniesService } from "../../../../services/companies.service";
import { ChangeAuthorisation } from "../../../../models/changeAuthorisation";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import { OrganisationService } from "../../../../services/organisation.service";
import { UserBrief } from "../../../../models/uiProfile";
import {
  AgSelectChangeNameComponent
} from "../../../components/common/grid/components/ag-select-change-name/ag-select-change-name.component";
import { BulkAddressChangeStepsEnum } from "../../../../models/enums/bulkAddressChangeStepsEnum";
import { NgClass } from "@angular/common";
import { UserRecord } from '../../../../models/userRecord';
import { UsersService } from '../../../../services/users.service';

enum BulkCeaseRegisteredAgentStepsEnum {
  FormDescription = 0,
  RegisteredAgentCeasing = 1,
  Summary = 2,
}

interface BulkCeaseRegisteredAgentControls {
  changeDate: FormControl<Date | null>;
  companyName: FormControl<string | null>;
  companyAcn: FormControl<string | null>;
  changeDateError: FormControl<boolean | null>
}

interface AppliedChanges {
  name: string;
  acn: string;
  entityId: string;
  fullName: string;
  selectedOption: SelectOption;
  options: SelectOption[];
  error: string;
}

export type BulkCeaseRegisteredAgentFormGroup = FormGroup<BulkCeaseRegisteredAgentControls>

@Component({
  selector: 'app-bulk-cease-registered-agent',
  standalone: true,
  imports: [
    CompanyNameAcnComponent,
    DatePickerComponent,
    FormsModule,
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    RadioComponent,
    ListGridComponent,
    NgClass
  ],
  templateUrl: './bulk-cease-registered-agent.component.html',
  styleUrls: ['./bulk-cease-registered-agent.component.scss', '../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component.scss']
})
export class BulkCeaseRegisteredAgentComponent extends BaseBulkStepperFormComponent<BulkCeaseRegisteredAgentStepsEnum, BulkCompanyCeaseRegisteredAgent> implements OnInit {
  companiesService = inject(CompaniesService);
  organisationService = inject(OrganisationService);
  usersService = inject(UsersService);

  override readonly StepsEnum = BulkCeaseRegisteredAgentStepsEnum;
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  companiesList: Partial<Company>[] = [];

  public rowClassRules: RowClassRules = {
    'error-row-bg': (params: { data: { error: string }}) => !!params.data.error,
  };

  documentTypeOptions: SelectOption[] = [
    { label: 'Minutes', value: DocumentationType.Minutes, disabled: true },
    { label: 'Resolution', value: DocumentationType.Resolution, disabled: true },
    { label: 'ASIC Form only', value: DocumentationType.FormOnly, disabled: false },
  ];

  colDefs: ColDef[] = [
    {
      headerName: 'Entity Name',
      field: 'name',
      sort: 'asc',
      flex: 1,
      cellRenderer: AgEntityNameAcnAbnComponent,
      menuTabs: []
    },
    {
      headerName: 'ASIC Contact',
      field: 'fullName',
      flex: 1.2,
      cellRenderer: AgSelectChangeNameComponent,
      cellRendererParams: {
        selectName: this.selectName.bind(this),
      },
      menuTabs: []
    },
  ];
  private readonly colDefsWithErrorLength = 3;

  rowHasError = false;
  rows: AppliedChanges[] = [];
  officeHoldersByCompany: OfficeHoldersByCompany[] = [];
  currentProfileUser: UserBrief | undefined;
  users: UserRecord[] = [];
  changeAuthorisationByCompany: { companyId: string, changeAuthorisation: ChangeAuthorisation }[] = [];

  form: BulkCeaseRegisteredAgentFormGroup = new UntypedFormGroup({
    changeDate: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
    documentationType: new FormControl<DocumentationType>(DocumentationType.FormOnly, [Validators.required]),
    changeDateError: new FormControl<boolean | null>(null, [Validators.required])
  }) as BulkCeaseRegisteredAgentFormGroup;

  override stepperForm = new FormGroup({
    [BulkCeaseRegisteredAgentStepsEnum.FormDescription]: new FormGroup({}),
    [BulkCeaseRegisteredAgentStepsEnum.RegisteredAgentCeasing]: this.form,
    [BulkCeaseRegisteredAgentStepsEnum.Summary]: this.form,
  });

  constructor() {
    super();

    this.setupSteps(BulkCeaseRegisteredAgentStepsEnum);
    this.redirectAfterSubmit = true;
  }

  async ngOnInit(): Promise<void> {
    this.minDate = DatepickerHelper.getDefaultMinDateStruct();
    this.maxDate = DatepickerHelper.getTodayStruct();
    this.currentProfileUser = this.organisationService.getCurrentUser();
    this.users = await firstValueFrom(this.usersService.getAssignableUsersCached());

    this.setupChange();
    this.listenChangeDate();
  }

  override afterSubmit(changes: EntityChangeData[]): void {
    this.setupChange(changes[0] as BulkCompanyCeaseRegisteredAgent);
  }

  override setupChange(change: BulkCompanyCeaseRegisteredAgent = this.formModel): void {
    if (!this.isEdit && change === this.formModel) return;

    this.form.controls.changeDate.setValue(change.changeDate);
    this.companiesList = change.companiesList;

    const options = this.users?.map(user => ({ label: user.fullName, value: user.id }));
    this.companiesList.forEach(c => {
      this.rows.push({
        name: c.name ?? '',
        acn: c.acn ?? '',
        entityId: c.entityId ?? '',
        fullName: this.currentProfileUser?.fullName ?? '',
        selectedOption: { label: this.currentProfileUser?.fullName ?? '', value: this.currentProfileUser?.id },
        options,
        error: '',
      });
    });

    this.getOfficeholders();
  }

  override buildDocument(): Document[] | null {
    const change = new CompanyForm361({ changeDate: this.form.controls.changeDate.value! });
    const documentationType = DocumentationType.FormOnly;

    try {
      return this.changeAuthorisationByCompany.map(c => {
        delete c.changeAuthorisation.authorisingParty.entity;

        return new Document({
          changes: [change],
          changeAuthorisation: c.changeAuthorisation,
          entityId: c.companyId,
          type: 'c:361',
          documentId: this.companyChangeData?.documentId,
          documentationType
        });
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private getOfficeholders(): void {
    const getOfficeHolders = this.companiesList
      .map(company => {
        return this.companiesService.officeHolders(company.entityId ?? '')
      });

    forkJoin(getOfficeHolders)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          console.error('Error getting office holders:', error);
          return of([] as { isSigningContact: boolean, relationship: Relationship }[][]);
        })
      )
      .subscribe((officeHolders) => {
        if(officeHolders.length) {
          this.officeHoldersByCompany = officeHolders
            .filter(item => item.length > 0 && item[0].relationship.entityId)
            .map((item) => ({ companyId: item[0].relationship.entityId, officeHolders: item }));

          this.changeAuthorisationByCompany = this.officeHoldersByCompany.map((officeHolder, index) => {
            const changeAuthorisation = new ChangeAuthorisation();

            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);
            const signingContact = officeHolder.officeHolders.find(item => item.isSigningContact)?.relationship;
            const directors = relationshipOfficeHolders.filter(o => o.type === RelationshipType.Director);
            const authOfficeHolder = directors[0];

            changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
            changeAuthorisation.signingManager = this.currentProfileUser?.id ?? '';
            changeAuthorisation.authorisingParty.entityId = relationshipOfficeHolders[0].entityId;
            changeAuthorisation.documentationType = DocumentationType.FormOnly;

            if(changeAuthorisation.authorisingParty.individualDataOverride?.address?.careOf === null) {
              changeAuthorisation.authorisingParty.individualDataOverride.address.careOf = '';
            }

            const changeDate = this.form.get('changeDate')?.value as unknown as Date;
            if(relationshipOfficeHolders[0].entity?.dateOfEstablishment) {
              this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].entity?.dateOfEstablishment)
                ? 'Date of change cannot be earlier or same as the company registration date'
                : '';
            }

            return { companyId: relationshipOfficeHolders[0].entityId, changeAuthorisation };
          });

          this.rows = [...this.rows];
          this.addErrorToRows();
        }
      });
  }

  selectName(data: { entityId: string, selectedId: string }): void {
    this.changeAuthorisationByCompany.forEach(c => {
      if(c.companyId === data.entityId) {
        c.changeAuthorisation.signingManager = data.selectedId;
      }
    });
  }

  onStepChange(stepIndex: BulkAddressChangeStepsEnum): void {
    if(stepIndex === BulkAddressChangeStepsEnum.Summary) {
      if(this.form.get('changeDateError')?.value) {
        this.form.get('changeDateError')?.patchValue(null);
      }
    }
  }

  private addErrorToRows(): void {
    this.rowHasError = this.rows.some(row => row.error);

    if (this.rowHasError && !this.colDefs.some(c => c.field === 'error')) {
      console.log('err');
      this.colDefs.push({
        headerName: 'Error',
        field: 'error',
        flex: 1.2,
        cellRenderer: (params: { value: string }) => {
          const error = params.value;
          if (error) {
            return `<div style="color: #CE5252; line-height: 2px; padding-top: 8px;">${error.slice(0, Math.floor(error.length / 2))}</div>
                    <div style="color: #CE5252;">${error.slice(Math.floor(error.length / 2), error.length)}</div>`;
          } else {
            return `<div>${error}</div>`;
          }
        },
        menuTabs: []
      });
    } else if(!this.rowHasError && this.colDefs.length === this.colDefsWithErrorLength) {
      this.colDefs.pop();
    }

    this.colDefs = [...this.colDefs];

    if(this.rows.some(row => row.error)) {
      this.form.get('changeDateError')?.setValue(true);
    } else {
      this.form.get('changeDateError')?.setValue(false);
    }
  }

  private listenChangeDate(): void {
    this.form.controls.changeDate.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(changeDate => {
        if(changeDate) {
          this.officeHoldersByCompany.map((officeHolder, index) => {
            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);

            if(relationshipOfficeHolders[0].entity?.dateOfEstablishment) {
              this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].entity?.dateOfEstablishment)
                ? 'Date of change cannot be earlier or same as the company registration date'
                : '';
            }
          });

          this.rows = [...this.rows];
          this.addErrorToRows();
        }
      });
  }
}
