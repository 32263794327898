import { Component, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { setControlDisabled } from '../../../../functions/set-control-disabled';
import { Address } from '../../../../models/address';
import { Document } from '../../../../models/document';
import { EntityChangeData } from '../../../../models/entityChangeData';
import { EntityData } from '../../../../models/entityData';
import { SecurityTransactionType } from '../../../../models/enums/SecurityTransactionType';
import { IndividualData } from '../../../../models/individualData';
import { ShareholderRelationshipDetails } from '../../../../models/relationship';
import { SecurityHolding } from '../../../../models/securityHolding';
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from '../../../../models/securityRegistryRecord';
import { SecurityTransactionIncremental } from '../../../../models/securityTransactionIncremental';
import { SecurityType } from '../../../../models/securityType';
import { acnValidator } from '../../../../validators/acn.validator';
import { companyNameValidator } from '../../../../validators/compny-name.validator';
import { NumbersValidators } from '../../../../validators/numbers.validators';
import { BeneficialOwnerComponent } from '../../../components/common/beneficial-owner/beneficial-owner.component';
import { CheckboxComponent } from '../../../components/common/checkbox/checkbox.component';
import { DatePickerComponent } from '../../../components/common/date-picker/date-picker.component';
import { DisclaimerComponent } from '../../../components/common/disclaimer/disclaimer.component';
import { InputNumberComponent } from '../../../components/common/input-number/input-number.component';
import { InputComponent } from '../../../components/common/input/input.component';
import { RadioComponent } from '../../../components/common/radio/radio.component';
import { SelectGroupsComponent } from '../../../components/common/select-groups/select-groups.component';
import { SelectComponent } from '../../../components/common/select/select.component';
import { TextareaComponent } from '../../../components/common/textarea/textarea.component';
import { ValidationErrorComponent } from '../../../components/common/validation-error/validation-error.component';
import { YesNoControlComponent } from '../../../components/common/yes-no-control-component/yes-no-control.component';
import { SelectShareholderComponent } from '../../../components/shares/select-shareholder/select-shareholder.component';
import {
  ShareSecurityHoldingsDropdownComponent
} from '../../../components/shares/share-security-holdings-dropdown/share-security-holdings-dropdown.component';
import {
  ShareSecurityTypesDropdownComponent
} from '../../../components/shares/share-security-types-dropdown/share-security-types-dropdown.component';
import { Guid } from '../../../helpers/guid.helper';
import { StepperFormComponent } from '../../stepper-form/stepper-form.component';
import { BaseShareFormComponent } from '../components/base-share-form/base-share-form.component';
import { ShareEstimateTableComponent } from '../components/share-estimate-table/share-estimate-table.component';
import {
  ShareJointSecurityHolderComponent
} from '../components/share-joint-security-holder/share-joint-security-holder.component';
import {
  CompanySecurityIssue,
  ConsiderationTypesOptions,
  ShareholderTypesEnum,
  shareholderTypesOptionGroups,
  ShareIssueStepsEnum
} from './share-issue.model';
import {
  AddressFormGroupComponent,
  AddressFormGroupControls
} from "../../../components/reusable-form-groups/address-form-group/address-form-group.component";
import {
  IndividualDataFormGroupComponent, IndividualDataFormGroupControls
} from "../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import {
  CompanyNameAcnFormGroupComponent
} from "../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import {
  StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";

@Component({
  selector: 'app-share-issue',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    InputComponent,
    InputNumberComponent,
    CheckboxComponent,
    RadioComponent,
    YesNoControlComponent,
    SelectGroupsComponent,
    TextareaComponent,
    DisclaimerComponent,
    ValidationErrorComponent,
    SelectShareholderComponent,
    StepperFormComponent,
    ShareEstimateTableComponent,
    DatePickerComponent,
    ShareJointSecurityHolderComponent,
    BeneficialOwnerComponent,
    ShareSecurityHoldingsDropdownComponent,
    ShareSecurityTypesDropdownComponent,
    AddressFormGroupComponent,
    IndividualDataFormGroupComponent,
    CompanyNameAcnFormGroupComponent,
    StepperFormDescriptionComponent
  ],
  templateUrl: './share-issue.component.html',
  styleUrls: [
    '../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
    '../share-cancellation/share-cancellation.component.scss'
  ]
})
export class ShareIssueComponent extends BaseShareFormComponent<ShareIssueStepsEnum, CompanySecurityIssue> implements OnInit {
  @Input() companySecurityStructures: SecurityHolding[] = [];

  readonly shareholderTypesOptionGroups = shareholderTypesOptionGroups;
  readonly ShareholderTypesEnum = ShareholderTypesEnum;
  readonly individualDataFormLabels: Record<keyof Partial<IndividualDataFormGroupControls>, string> = { fullName: 'Individual Name' } as Record<keyof Partial<IndividualDataFormGroupControls>, string>;
  readonly addressFormLabels: Record<keyof Partial<AddressFormGroupControls>, string> = { normalizedFullAddress: 'Registered address' } as Record<keyof Partial<AddressFormGroupControls>, string>;
  readonly hiddenIndividualControls: (keyof IndividualDataFormGroupControls)[] = ['formerName', 'dob', 'birthCity', 'birthCountry'];

  readonly considerationTypesOptions = ConsiderationTypesOptions;
  override readonly StepsEnum = ShareIssueStepsEnum;

  selectedShareholderShareType: SecurityType | null = null;
  selectedIndividualShareholder: IndividualHolderModel | null = null;
  selectedCompanyShareholder: EntityData | null = null;

  securityTypes: SecurityType[] = [];

  override stepperForm = new FormGroup({
    [ShareIssueStepsEnum.FormDescription]: new FormGroup({}),
    [ShareIssueStepsEnum.Shareholders]: new FormGroup({
      date: new FormControl<Date | null>(null, [Validators.required]),
      shareholderType: new FormControl<ShareholderTypesEnum | null>(null, [Validators.required]),
      securityRegistryRecordIndex: new FormControl<number | null>(null, [Validators.required]),
      securityTypeId: new FormControl<string | null>(null, [Validators.required]),
      numberIncrease: new FormControl<number | null>(null, [Validators.required, NumbersValidators.min(0), NumbersValidators.isInteger]),
      paidIncrease: new FormControl<number | null>(null, [Validators.required, NumbersValidators.min(0)]),
      unpaidIncrease: new FormControl<number | null>(null, [Validators.required, NumbersValidators.min(0)]),
      isBeneficialOwner: new FormControl<boolean | null>(false, [Validators.required]),
      beneficialOwner: new FormControl<string | null>(null, [Validators.required]),

      newIndividualShareholder: IndividualDataFormGroupComponent.defineForm(),

      isOverseasCompany: new FormControl<boolean>(false),
      registeredAddress: AddressFormGroupComponent.defineForm(),

      ...CompanyNameAcnFormGroupComponent.defineFormControls(), // name and acn controls

      joint: new FormControl<(IndividualHolderModel | CorporateHolderModel)[] | null>(null, [Validators.required])
    }),
    [ShareIssueStepsEnum.ShareIssuesAndCertificateDetails]: new FormGroup({
      issueForCashConsidarationOnly: new FormControl<boolean | null>(true, [Validators.required]),
      nonCashConsiderationDetails: new FormControl<string | null>(null, [Validators.required]),
      sharesWereWrittenUnderContract: new FormControl<boolean | null>(false, [Validators.required]),
      shareCertificateNumber: new FormControl<string | null>(null, [NumbersValidators.isInteger]),
      consolidateCertificate: new FormControl<boolean | null>(false, [Validators.required]),
    }),
    [ShareIssueStepsEnum.Estimate]: new FormGroup({}),
  });

  constructor() {
    super();
    this.setupSteps(ShareIssueStepsEnum);
    this.redirectAfterSubmit = false;
  }

  override setFormValidators(): void {
    this.setCustomFormValidators();
  }

  override setupFormChangeListeners(): void {
    super.setupFormChangeListeners();

    this.listenShareholderTypeChanges();
    this.listenNumberIncreaseChange();
    this.listenShareTypeChanges();
    this.listenIsSharesOwnedOnBehalfChanges();
    this.listenIsOverseasCompanyChange();
    this.listenIssueForCashConsidarationOnlyChanges();
    this.listenShareholderDetailsFormChange();
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.steps[newStepIndex].step;
    this.currentStepIndex = newStepIndex;

    if (this.currentStep === ShareIssueStepsEnum.Estimate && this.shouldLoadEstimate) {
      this.loadEstimates();
    }
  }

  override afterSubmit(changes: EntityChangeData[]) {
    const change = changes[0] as CompanySecurityIssue;
    change.issue.securityType.securityTypeId = this.shareholdersForm.value.securityTypeId ?? '';
    this.setupChange(change);
  }

  override setupChange(change: CompanySecurityIssue = this.formModel) {
    this.disabledUnnecessaryControls();
    this.issuesCertificateForm.controls.issueForCashConsidarationOnly.setValue(this.issuesCertificateForm.value.issueForCashConsidarationOnly ?? null);

    if (!this.isEdit && change === this.formModel) {
      return;
    }

    // check if the form needs a user-created security type.
    const usersSecurityType = this.securityTypes.find((securityType) => securityType.securityTypeId === change.issue.securityTypeId);
    if (change.issue.securityTypeId && !usersSecurityType) {
      this.securityTypes.push(change.issue.securityType);
    }

    this.shareholdersForm.patchValue(change as any);
    this.shareholdersForm.patchValue({
      ...change.issue,
      date: new Date(change.issue.transactionDate)
    });

    this.issuesCertificateForm.patchValue(change as any);
    this.issuesCertificateForm.patchValue(change.issue);

    const selectedSecurityRegistryRecordIndex = this.securityRegistryRecords
      .findIndex((record) => record?.holders
        .every(holder => change.issue.relationshipIds?.includes(holder.relationshipId)));

    // set the shareholder type
    if (selectedSecurityRegistryRecordIndex >= 0) {
      this.shareholdersForm.controls.shareholderType.patchValue(ShareholderTypesEnum.ExistingShareholder);
      this.shareholdersForm.controls.securityRegistryRecordIndex.patchValue(selectedSecurityRegistryRecordIndex);
      this.cdr.detectChanges();
    } else if (change.securityHolders.length >= 2) {
      this.shareholdersForm.controls.shareholderType.patchValue(ShareholderTypesEnum.JointShareholder);
      this.shareholdersForm.controls.joint.setValue(change.securityHolders);
      this.shareholdersForm.controls.isBeneficialOwner.patchValue(!change.securityHolders[0].details.isBeneficialOwner);
      this.shareholdersForm.controls.beneficialOwner.patchValue(change.securityHolders[0].details.beneficialOwner);
    } else if (change.securityHolders.length === 1) {
      const holder = change.securityHolders[0];

      if (holder.$type === IndividualHolderModel.$type) {
        this.selectedIndividualShareholder = holder as IndividualHolderModel;
        this.shareholdersForm.controls.shareholderType.patchValue(ShareholderTypesEnum.Individual);
        this.shareholdersForm.controls.newIndividualShareholder.patchValue(this.selectedIndividualShareholder.individualData);
      } else {
        this.selectedCompanyShareholder = (holder as CorporateHolderModel).entityData;
        this.shareholdersForm.controls.shareholderType.patchValue(ShareholderTypesEnum.Corporate);
        this.shareholdersForm.controls.isOverseasCompany.patchValue(this.selectedCompanyShareholder.registeredAddress.country !== 'AU');
        this.shareholdersForm.patchValue({
          name: this.selectedCompanyShareholder.name,
          acn: this.selectedCompanyShareholder.entityNumber,
          registeredAddress: this.selectedCompanyShareholder.registeredAddress
        });
      }

      this.shareholdersForm.controls.isBeneficialOwner.patchValue(!holder.details.isBeneficialOwner);
      this.shareholdersForm.controls.beneficialOwner.patchValue(holder.details.beneficialOwner);
    }

    this.cdr.detectChanges();
    this.issuesCertificateForm.controls.issueForCashConsidarationOnly.setValue(this.issuesCertificateForm.value.issueForCashConsidarationOnly ?? null);
    this.issuesCertificateForm.controls.sharesWereWrittenUnderContract.setValue(change.sharesWereWrittenUnderContract ?? null);
    this.shareholdersForm.controls.securityTypeId.setValue(change.issue.securityType?.securityTypeId ?? null);
    this.shareholdersForm.patchValue({
      numberIncrease: change.issue.numberIncrease,
      paidIncrease: change.issue.paidIncrease,
      unpaidIncrease: change.issue.unpaidIncrease,
    });
  }

  override buildDocument(): Document | null {
    const companySecurityIssue = this.buildShareFormChange();

    // Document created that way because of FE models difficulties
    return {
      changes: [companySecurityIssue],
      entityId: this.companyChangeData?.entityId,
      type: 'c:484',
      documentId: this.companyChangeData?.documentId ?? Guid.EmptyGuid,
    } as any as Document;
  }

  override onShareholdersLoadSuccess(shareholders: SecurityRegistryRecord[]): void {
    this.securityRegistryRecords = shareholders.filter(holder => holder?.holders.length > 0);
    this.isLoading = false;

    const allSecurityTypes = shareholders.flatMap(shareholder => shareholder.holdings.map(holding => holding.securityType));
    this.securityTypes = [...new Map<string, SecurityType>(allSecurityTypes.map(type => [type.securityTypeId, type])).values()]
      .filter(type => type.entityId && type.entityId !== Guid.EmptyGuid);
    this.cdr.detectChanges();
    this.setupChange();
  }

  override onEstimateLoadSuccess(estimatedSecurityRegistryRecords: SecurityRegistryRecord[]): void {
    this.estimatedSecurityRegistryRecords = estimatedSecurityRegistryRecords.filter(record => record?.holders.length > 0);
    this.shouldLoadEstimate = false;
  }

  override buildShareFormChange(): CompanySecurityIssue {
    const securityType = structuredClone(this.securityTypes.find(type => type.securityTypeId === this.shareholdersForm.value.securityTypeId)!);
    const companySecurityIssue = new CompanySecurityIssue({
      $type: 'CompanySecurityIssue',
      changeDate: this.shareholdersForm.value.date!,
      issueForCashConsidarationOnly: this.issuesCertificateForm.value.issueForCashConsidarationOnly!,
      nonCashConsiderationDetails: this.issuesCertificateForm.value.nonCashConsiderationDetails!,
      consolidateCertificate: this.issuesCertificateForm.value.consolidateCertificate!,
      sharesWereWrittenUnderContract: this.issuesCertificateForm.controls.sharesWereWrittenUnderContract.value!,
      issue: new SecurityTransactionIncremental({
        securityHoldingId: this.formModel.issue.securityHoldingId ?? Guid.EmptyGuid,
        entityId: this.companyChangeData?.entityId,
        organisationId: this.companyChangeData?.organisationId,
        documentId: this.companyChangeData?.documentId ?? null,
        transactionDate: this.shareholdersForm.value.date!,
        transactionType: SecurityTransactionType.Issue,
        numberIncrease: this.shareholdersForm.controls.numberIncrease.value!,
        paidIncrease: this.shareholdersForm.controls.paidIncrease.value!,
        unpaidIncrease: this.shareholdersForm.controls.unpaidIncrease.value!,
        shareCertificateNumber: this.issuesCertificateForm.value.shareCertificateNumber,
        securityTypeId: securityType.securityTypeId,
        securityType,
      })
    });

    if (!companySecurityIssue.issue.securityType.securityTypeId || !companySecurityIssue.issue.securityType.entityId) {
      delete (companySecurityIssue.issue.securityType as any).entityId;
      delete (companySecurityIssue.issue.securityType as any).securityTypeId;
    }

    switch (this.shareholdersForm.value.shareholderType) {
      case ShareholderTypesEnum.ExistingShareholder: {
        const selectedHolders = this.securityRegistryRecords[this.shareholdersForm.value.securityRegistryRecordIndex!]?.holders;
        companySecurityIssue.securityHolders = this.buildShortBaseHolders(selectedHolders.filter(Boolean));
        break;
      }
      case ShareholderTypesEnum.Individual: {
        const individualData = IndividualDataFormGroupComponent.toIndividualData(this.shareholdersForm.controls.newIndividualShareholder as FormGroup<Partial<IndividualDataFormGroupControls>>);
        const newIndividualSecurityHolder = new IndividualHolderModel({
          individualData,
        });
        companySecurityIssue.securityHolders = [this.buildShortIndividualHolderModel(newIndividualSecurityHolder)];
        break;
      }
      case ShareholderTypesEnum.Corporate: {
        const entityData = new EntityData({
          name: this.shareholdersForm.value.name ?? '',
          entityNumber: this.shareholdersForm.value.acn ?? '',
          registeredAddress: new Address(this.shareholdersForm.value.registeredAddress as Partial<Address>)
        });
        const newCorporateSecurityHolder = new CorporateHolderModel({ entityData });
        companySecurityIssue.securityHolders = [this.buildShortCorporateHolderModel(newCorporateSecurityHolder)];
        break;
      }
      case ShareholderTypesEnum.JointShareholder: {
        companySecurityIssue.securityHolders = this.buildShortBaseHolders(this.shareholdersForm.value.joint ?? []);
        break;
      }
      default:
        break;
    }
    companySecurityIssue.issue.relationshipIds = companySecurityIssue.securityHolders.map(holder => holder.relationshipId);
    companySecurityIssue.issue.documentId = this.companyChangeData.documentId ?? null as unknown as string;
    return companySecurityIssue;
  }

  addShareType(newSecurityHolding: SecurityType): void {
    this.securityTypes.push(newSecurityHolding);
    this.shareholdersForm.controls.securityTypeId.setValue(newSecurityHolding.securityTypeId);
  }

  private listenShareholderTypeChanges(): void {
    this.shareholdersForm.controls.shareholderType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((shareholderType) => {
        if (shareholderType === null) {
          return;
        }

        // set state of the existing security holder
        setControlDisabled(this.shareholdersForm.controls.securityRegistryRecordIndex, shareholderType !== ShareholderTypesEnum.ExistingShareholder);
        setControlDisabled(this.shareholdersForm.controls.isBeneficialOwner, shareholderType === null || shareholderType === ShareholderTypesEnum.ExistingShareholder);
        setControlDisabled(this.shareholdersForm.controls.beneficialOwner, shareholderType === null || shareholderType === ShareholderTypesEnum.ExistingShareholder);

        // set state of the new Individual Shareholder control
        setControlDisabled(this.shareholdersForm.controls.newIndividualShareholder, shareholderType !== ShareholderTypesEnum.Individual);
        this.hiddenIndividualControls.forEach(key => setControlDisabled(this.shareholdersForm.controls.newIndividualShareholder.controls[key]));

        // set state of the new Corporate Shareholder control
        setControlDisabled(this.shareholdersForm.controls.acn, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(this.shareholdersForm.controls.name, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(this.shareholdersForm.controls.registeredAddress, shareholderType !== ShareholderTypesEnum.Corporate);

        // set state of the new Joint Shareholder control
        setControlDisabled(this.shareholdersForm.controls.joint, shareholderType !== ShareholderTypesEnum.JointShareholder);

        if (shareholderType !== ShareholderTypesEnum.ExistingShareholder) {
          this.shareholdersForm.controls.isBeneficialOwner.patchValue(this.shareholdersForm.controls.isBeneficialOwner.value ?? null);
        }
      });
  }

  private listenNumberIncreaseChange(): void {
    this.shareholdersForm.controls.numberIncrease.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((numberIncrease) => {
        if (numberIncrease === null || this.selectedShareholderShareType === null) {
          return;
        }

        this.shareholdersForm.controls.paidIncrease.patchValue(numberIncrease * this.selectedShareholderShareType.paidAmount);
        this.shareholdersForm.controls.unpaidIncrease.patchValue(numberIncrease * this.selectedShareholderShareType.unpaidAmount);
      });
  }

  private listenShareTypeChanges(): void {
    this.shareholdersForm.controls.securityTypeId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((securityTypeId) => {
        this.selectedShareholderShareType = this.securityTypes.find(type => type.securityTypeId === securityTypeId) ?? null;
        this.shareholdersForm.controls.numberIncrease.patchValue(this.shareholdersForm.value.numberIncrease ?? null);
      });
  }

  private listenIsSharesOwnedOnBehalfChanges(): void {
    this.shareholdersForm.controls.isBeneficialOwner.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isSharesOwnedOnBehalf) => {
        setControlDisabled(this.shareholdersForm.controls.beneficialOwner, !isSharesOwnedOnBehalf);
      });
  }

  private listenIsOverseasCompanyChange(): void {
    this.shareholdersForm.controls.isOverseasCompany.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isOverseasCompany) => {
        if (isOverseasCompany) {
          this.shareholdersForm.controls.acn.clearValidators();
          this.shareholdersForm.controls.name.setValidators([Validators.required, Validators.minLength(7)]);
        } else {
          this.shareholdersForm.controls.acn.setValidators([Validators.required, acnValidator()]);
          this.shareholdersForm.controls.name.setValidators([Validators.required, Validators.minLength(7), companyNameValidator()]);
        }

        this.cdr.detectChanges();
        this.shareholdersForm.updateValueAndValidity();
        this.shareholdersForm.controls.acn.updateValueAndValidity();
        this.shareholdersForm.controls.acn.patchValue(this.shareholdersForm.value.acn ?? '');
      });
  }

  private listenIssueForCashConsidarationOnlyChanges(): void {
    this.issuesCertificateForm.controls.issueForCashConsidarationOnly.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((issueForCashConsidarationOnly) => {
        setControlDisabled(this.issuesCertificateForm.controls.nonCashConsiderationDetails, issueForCashConsidarationOnly !== false);
      });
  }

  private listenShareholderDetailsFormChange(): void {
    this.shareholdersForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.shouldLoadEstimate = true);
  }

  private buildShortBaseHolders(holders: (IndividualHolderModel | CorporateHolderModel)[]): (IndividualHolderModel | CorporateHolderModel)[] {
    return holders.map(holder => {
      if (holder.$type === IndividualHolderModel.$type) {
        return this.buildShortIndividualHolderModel(holder as IndividualHolderModel);
      } else if (holder.$type === CorporateHolderModel.$type) {
        return this.buildShortCorporateHolderModel(holder as CorporateHolderModel);
      }
      return holder;
    });
  }

  private buildShortIndividualHolderModel(individualHolder: IndividualHolderModel): IndividualHolderModel {
    const isBeneficialOwner = this.shareholdersForm.value.isBeneficialOwner !== null
      ? !this.shareholdersForm.value.isBeneficialOwner
      : individualHolder.details.isBeneficialOwner;
    const individualHolderModel = {
      $type: IndividualHolderModel.$type,
      relationshipId: individualHolder.relationshipId || Guid.generate(),
      details: {
        isBeneficialOwner,
        $type: new ShareholderRelationshipDetails().$type,
        beneficialOwner: this.shareholdersForm.value.beneficialOwner ?? individualHolder.details.beneficialOwner ?? '',
      } as unknown as ShareholderRelationshipDetails,
      individualData: {
        firstName: individualHolder.individualData.firstName,
        middleName1: individualHolder.individualData.middleName1,
        middleName2: individualHolder.individualData.middleName2,
        lastName: individualHolder.individualData.lastName,
        address: individualHolder.individualData.address
      } as IndividualData
    } as IndividualHolderModel;

    if (individualHolder.individualId) {
      individualHolderModel.individualId = individualHolder.individualId;
    }

    return individualHolderModel;
  }

  private buildShortCorporateHolderModel(corporateHolder: CorporateHolderModel): CorporateHolderModel {
    const isBeneficialOwner = this.shareholdersForm.value.isBeneficialOwner !== null
      ? !this.shareholdersForm.value.isBeneficialOwner
      : corporateHolder.details.isBeneficialOwner;
    const corporateHolderModel = {
      $type: 'CorporateHolderModel',
      relationshipId: corporateHolder.relationshipId || Guid.generate(),
      details: {
        isBeneficialOwner,
        $type: new ShareholderRelationshipDetails().$type,
        beneficialOwner: this.shareholdersForm.value.beneficialOwner ?? corporateHolder.details.beneficialOwner ?? '',
      } as unknown as ShareholderRelationshipDetails,
      entityData: ({
        name: corporateHolder.entityData.name,
        entityNumber: corporateHolder.entityData.entityNumber,
        registeredAddress: corporateHolder.entityData.registeredAddress
      } as EntityData),
    } as CorporateHolderModel;

    return corporateHolderModel;
  }

  private setCustomFormValidators(): void {
    const paidTotalValidator: ValidatorFn = () => {
      if (this.selectedShareholderShareType === null || !this.shareholdersForm.value.paidIncrease || !this.shareholdersForm.value.numberIncrease) {
        return null;
      }
      // (Math.abs(prefilled total paid - total paid))/prefilled total paid < 0.05

      const calculatedTotalPaid = this.selectedShareholderShareType.paidAmount * this.shareholdersForm.value.numberIncrease;
      return (Math.abs(calculatedTotalPaid - this.shareholdersForm.value.paidIncrease) / calculatedTotalPaid) < 0.05
        ? null
        : { paidChangeIsBiggerThan5Percent: true };
    };

    const unpaidTotalValidator: ValidatorFn = () => {
      if (this.selectedShareholderShareType === null || !this.shareholdersForm.value.unpaidIncrease || !this.shareholdersForm.value.numberIncrease) {
        return null;
      }
      // (Math.abs(prefilled total unpaid - total unpaid))/prefilled total unpaid < 0.05

      const calculatedTotalPaid = this.selectedShareholderShareType.unpaidAmount * this.shareholdersForm.value.numberIncrease;
      return (Math.abs(calculatedTotalPaid - this.shareholdersForm.value.unpaidIncrease) / calculatedTotalPaid) < 0.05
        ? null
        : { unpaidChangeIsBiggerThan5Percent: true };
    };

    // total paid + total unpaid > 0
    const paidUnpaidSumValidator: ValidatorFn = () => {
      const totalPaid = this.shareholdersForm.controls.paidIncrease.getRawValue();
      const totalUnpaid = this.shareholdersForm.controls.unpaidIncrease.getRawValue();
      if (totalPaid === null || totalUnpaid === null) {
        return null;
      }

      return (totalPaid + totalUnpaid) > 0
        ? null
        : { paidUnpaidSumIsNotPositive: true };
    };

    this.shareholdersForm.setValidators([
      paidTotalValidator,
      unpaidTotalValidator,
      paidUnpaidSumValidator
    ]);
  }

  private disabledUnnecessaryControls(): void {
    this.hiddenIndividualControls.forEach(key => {
      setControlDisabled(this.shareholdersForm.controls.newIndividualShareholder.controls[key])
    })
  }

  get shareholdersForm() {
    return this.stepperForm.controls[ShareIssueStepsEnum.Shareholders];
  }

  get issuesCertificateForm() {
    return this.stepperForm.controls[ShareIssueStepsEnum.ShareIssuesAndCertificateDetails];
  }

  get formShareNumberErrorMessageHidden(): boolean {
    return Boolean(this.shareholdersForm.controls.numberIncrease?.untouched
      && this.shareholdersForm.controls.paidIncrease?.untouched
      && this.shareholdersForm.controls.unpaidIncrease?.untouched
      && !this.shareholdersForm?.errors);
  }
}
