import { AnnualStatementStatus, AnnualStep } from "../../models/enums/annualStatementEnums";

export function annualStatusToStep(documentStatus: AnnualStatementStatus | null | undefined): number {
  const documentStatusToStepMap = {
    [AnnualStatementStatus.ReadyToPrepare]: AnnualStep.Authorization,
    [AnnualStatementStatus.ReadyToSend]: AnnualStep.SignDocuments,
    [AnnualStatementStatus.Sent]: AnnualStep.SignDocuments,
    [AnnualStatementStatus.Signed]: AnnualStep.SignDocuments,
    [AnnualStatementStatus.Ignored]: AnnualStep.SignDocuments,
    [AnnualStatementStatus.Archived]: AnnualStep.SignDocuments,
    [AnnualStatementStatus.Finalized]: AnnualStep.SignDocuments,
  };

  if(documentStatus !== null && documentStatus !== undefined) {
    return documentStatusToStepMap[documentStatus] as number;
  }

  return 0;
}
