<app-common-modal-form
  header="Ad-hoc Signing Reminders"
  icon="icon ic-bell"
  [isCustomFooter]="true"
  (close)="close()"
>
  <div class="body-container d-flex gap-24" [formGroup]="form">
    <div class="sent-container d-flex flex-column gap-24">
      <div class="d-flex flex-column gap-24">
        @if(isAnnualReminder) {
          <div class="sent-container">
            <app-radio
              formControlName="sendBy"
              label="Send by"
              [options]="sendByOptions"
            ></app-radio>
          </div>
        }

        <div class="document-container">
          <div class="debt-container">
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold">Last signing reminder was sent on:</div>
              <div class="font-weight-bold">{{currentDate | date: 'dd/MM/yyyy'}}</div>
            </div>
          </div>
        </div>
      </div>

      @if(form.get('sendBy')?.value === SendOption.Email) {
        <app-multiple-input-email
          formControlName="recipientEmails"
          label="Recipient"
          placeholder="Enter email"
          [customErrors]="{ minArrayLength: 'Field is required' }"
        ></app-multiple-input-email>
      } @else {
        <app-select
          label="Recipient"
          placeholder="Select recipient"
          formControlName="recipientPhones"
          [options]="recipientPhoneList"
        ></app-select>
      }
    </div>

    <div class="preview-container">
      @if(!loading) {
        @if(form.get('sendBy')?.value === SendOption.Email) {
          <div class="title">Subject: {{ template.subjectTemplate }}</div>
          <div class="preview-dashboard" [class.annual-height]="isAnnualReminder">
            <app-shadow-dom-viewer [htmlContent]="htmlContent()"/>
          </div>
        } @else {
          <div class="title">SMS Preview</div>
          <div class="sms-preview-dashboard">
            <app-shadow-dom-viewer [htmlContent]="smsHtmlContent()"/>
          </div>
        }
      } @else {
        <ngx-skeleton-loader count="2" appearance="line" />
        <div class="d-flex gap-24">
          <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
          <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
        </div>
        <ngx-skeleton-loader count="2" appearance="line" />
        <div class="d-flex gap-24">
          <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
          <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
        </div>
        <ngx-skeleton-loader count="2" appearance="line" />
      }

    </div>
  </div>

  <div class="custom-footer d-flex gap-24">
    <app-button
      text="Send Reminder"
      iconClass="icon ic-xs ic-bell"
      [isDisabled]="form.invalid"
      (clickBtn)="sendReminder()"
    ></app-button>

    <app-button
      text="Cancel"
      btnColor="white"
      (clickBtn)="close()"
    ></app-button>
  </div>
</app-common-modal-form>
