import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { CompanyAddressChangeStepsEnum } from '../../../../../../models/enums/companyAddressChangeStepsEnum';
import {
  RelationshipType,
  RelationshipTypeLabelsPlural
} from '../../../../../../models/enums/relationshipTypeEnum';
import { OfficerAppointmentForm } from '../../../../../../models/officerAppointmentForm';
import { Relationship } from '../../../../../../models/relationship';
import { SelectOption } from '../../../../../../models/selectOptions';
import { autocompleteServiceToken } from '../../../../../../services/autocomplete.service';
import { AuxiliaryService } from '../../../../../../services/auxiliary.service';
import { BaseStepperFormComponent } from '../../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { addressIncludesPoBox, CompanyChangeAddress, customForm484A1Errors } from './CompanyChangeAddress.model';
import { DatepickerHelper } from '../../../../../../custom-form-validators/date-picker-form-validators';
import {
  AddressAndOccupierFormGroupComponent,
  AddressAndOccupierFormGroupControls
} from "../../../../../components/reusable-form-groups/address-and-occupier-form-group/address-and-occupier-form-group.component";
import { ValidationErrorComponent } from "../../../../../components/common/validation-error/validation-error.component";
import { CheckboxComponent } from "../../../../../components/common/checkbox/checkbox.component";
import {
  StepperFormDescriptionComponent
} from "../../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { StepperFormComponent } from "../../../../stepper-form/stepper-form.component";
import { setControlDisabled } from "../../../../../../functions/set-control-disabled";
import { Address } from "../../../../../../models/address";
import { CountriesService } from "../../../../../../services/countries.service";

export interface A1CompanyAddressChangeFormGroupControls extends AddressAndOccupierFormGroupControls {
  applyToRegistered: FormControl<boolean | null>;
  applyToPrincipal: FormControl<boolean | null>;
  relationshipIds: FormArray<FormControl<boolean>>;
}

export type A1CompanyAddressChangeFormGroup = FormGroup<A1CompanyAddressChangeFormGroupControls>;

@Component({
  selector: 'app-a1-company-address-change',
  standalone: true,
  templateUrl: './a1-company-address-change.component.html',
  styleUrls: [
    './a1-company-address-change.component.scss',
    '../../../../stepper-form/base-stepper-component/base-stepper-form.component.scss'
  ],
  imports: [
    ReactiveFormsModule,
    CheckboxComponent,
    ValidationErrorComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    AddressAndOccupierFormGroupComponent,
  ],
  providers: [{ provide: autocompleteServiceToken, useClass: AuxiliaryService }]
})
export class A1CompanyAddressChangeComponent extends BaseStepperFormComponent<CompanyAddressChangeStepsEnum, CompanyChangeAddress> implements OnInit {
  @Input() changeOfficerModel: OfficerAppointmentForm = new OfficerAppointmentForm();
  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officersSelectOptions: SelectOption[] = [];
  @Input() shareholdersOfficeholdersIndividualIds: string[] = [];

  readonly customErrors = customForm484A1Errors;
  override readonly StepsEnum = CompanyAddressChangeStepsEnum;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  expandedAddressForm = false;

  form: A1CompanyAddressChangeFormGroup = new FormGroup<A1CompanyAddressChangeFormGroupControls>({
    ...AddressAndOccupierFormGroupComponent.defineFormControls(),
    applyToRegistered: new FormControl<boolean | null>(null),
    applyToPrincipal: new FormControl<boolean | null>(null),
    relationshipIds: new FormArray<FormControl<boolean>>([]),
  });

  override stepperForm = new FormGroup({
    [CompanyAddressChangeStepsEnum.FormDescription]: new FormGroup({}),
    [CompanyAddressChangeStepsEnum.NewAddress]: this.form
  });

  constructor() {
    super();
    this.setupSteps(CompanyAddressChangeStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.setupDefaultState();
    this.setupChange();
  }

  override buildDocument(): Document | null {
    const relationshipIds = this.form.value.relationshipIds
      ?.reduce((acc, option, index) => {
        if (option) {
          acc.push(this.officers[this.officersSelectOptions[index].value as string].map(officer => officer.relationshipId));
        }

        return acc;
      }, [] as string[][])
      ?.flat();

    const companyChangeAddress = new CompanyChangeAddress({
      ...this.form.value as Partial<CompanyChangeAddress>,
      relationshipIds
    });

    try {
      return new Document({
        changes: [companyChangeAddress],
        entityId: this.companyChangeData?.entityId,
        type: 'c:484',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanyChangeAddress);
  }

  override setupChange(change: CompanyChangeAddress = this.formModel): void {
    if (!this.isEdit && change === this.formModel)
      return;

    this.form.patchValue({
      ...change,
      relationshipIds: []
    });
  }

  checkValidity(): void {
    this.form.updateValueAndValidity();
  }

  private setupDefaultState(): void {
    setControlDisabled(this.form.controls.occupierNameIfDoesntOccupy);
    setControlDisabled(this.form.controls.companyHaveOccupiersConsent);

    this.officersSelectOptions.forEach(() => {
      this.form.controls.relationshipIds.push(new FormControl<boolean>(false, { nonNullable: true }));
    });

    this.setupValidators();

    if (this.formModel.relationshipIds.length) {
      this.formModel.relationshipIds.forEach((relationshipId) => {
        const selectedOfficerIndex = this.officersSelectOptions
          .findIndex((officer) => this.officers[officer.value as string]
            .some(relationship => relationship.relationshipId === relationshipId));

        this.form.controls.relationshipIds.at(selectedOfficerIndex)?.setValue(true, { emitEvent: false });
      });
    }
  }

  private setupValidators(): void {
    this.addSelectAtLeastOneOptionValidator();
    this.addDoNotAllowForeignAddressAsCompanyAddressesValidator();
    this.addDoNotAllowForeignAddressForAllDirectorsValidator();
    this.addCareOfResidentialAddressValidator();
    this.addCompanyPoBoxAddressValidator();
    this.addOfficeholdersPoBoxAddressValidator(RelationshipType.Director);
    this.addOfficeholdersPoBoxAddressValidator(RelationshipType.AlternativeDirector);
    this.addOfficeholdersPoBoxAddressValidator(RelationshipType.Secretary);
    this.addShareholderOfficeholderPoBoxAddressValidator();
  }

  private addSelectAtLeastOneOptionValidator(): void {
    this.form.addValidators(((form: A1CompanyAddressChangeFormGroup) => {
      // set validator that checks if one of controls' value equal to true
      const applyToRegistered = form.value.applyToRegistered;
      const applyToPrincipal = form.value.applyToPrincipal;
      const atLeastOneOfficerSelected = form.value.relationshipIds?.some((value) => value);

      if (!applyToRegistered && !applyToPrincipal && !atLeastOneOfficerSelected) {
        return { required: 'true' };
      }

      return null;
    }) as ValidatorFn);
  }

  //  do not allow:
  //  - using a foreign address as a registered address
  //  - using a foreign address as a principal address
  private addDoNotAllowForeignAddressAsCompanyAddressesValidator(): void {
    this.form.addValidators(((form: A1CompanyAddressChangeFormGroup) => {
      const applyToRegistered = !!form.value.applyToRegistered;
      const applyToPrincipal = !!form.value.applyToPrincipal;

      if (form.value.address?.country && (applyToRegistered || applyToPrincipal)) {
        const isAustraliaSelected = form.value.address?.country === 'AU' || form.value.address?.country === 'Australia';

        if (!isAustraliaSelected) {
          return { foreignAddressForCompany: 'true' };
        }
      }

      return null;
    }) as ValidatorFn);
  }

  //  do not allow:
  //  - all directors changing their addresses to a foreign address
  private addDoNotAllowForeignAddressForAllDirectorsValidator(): void {
    this.form.addValidators(((form: A1CompanyAddressChangeFormGroup) => {

      const allDirectorsSelected = form.value.relationshipIds?.reduce((acc, isSelected, index) => {
        const isDirector = this.officers[this.officersSelectOptions[index].value as string]
          ?.some((relationship) => relationship.type === RelationshipType.Director);

        acc.push({
          isDirector,
          isSelected
        });
        return acc;
      }, [] as { isDirector: boolean, isSelected: boolean }[])
        .filter((director) => director.isDirector)
        .every(director => director.isSelected);

      const isAustraliaSelected = form.value.address?.country === 'AU' || form.value.address?.country === 'Australia';

      if (form.value.address?.country && allDirectorsSelected && !isAustraliaSelected) {
        return { foreignAddressForAllDirectors: true };
      }

      return null;
    }) as ValidatorFn);
  }

  // do not allow:
  // - Care Of for residential addresses
  private addCareOfResidentialAddressValidator(): void {
    this.form.addValidators(((form: A1CompanyAddressChangeFormGroup) => {
      const directorsSelected = !!form.value.relationshipIds?.reduce((acc, isSelected, index) => {
        const isDirector = this.officers[this.officersSelectOptions[index].value as string]
          ?.some((relationship) => relationship.type === RelationshipType.Director);
        acc.push({
          isDirector,
          isSelected
        });
        return acc;
      }, [] as { isDirector: boolean, isSelected: boolean }[])
      .some((director) => director.isDirector && director.isSelected);

      const principalAddressSelected = !!form.value.applyToPrincipal;
      const isCareOfEntered = !!form.value.address?.careOf?.length;

      if (directorsSelected || principalAddressSelected) {
        return isCareOfEntered ? { careOfResidential: true } : null;
      }

      return null;
    }) as ValidatorFn);
  }

  // do not allow:
  // - set any company address with PO Box in it
  private addCompanyPoBoxAddressValidator(): void {
    const companyPoBoxAddressValidator = (control: AbstractControl): ValidationErrors | null => {
      const form = control as A1CompanyAddressChangeFormGroup;
      const address = new Address(form.value.address as Partial<Address>);
      const anyCompanyAddressSelected = Boolean(form.controls.applyToRegistered.value || form.controls.applyToPrincipal.value);

      return anyCompanyAddressSelected && addressIncludesPoBox(address.normalizedFullAddress)
        ? { poBoxAddress: true }
        : null;
    };
    this.form.addValidators([companyPoBoxAddressValidator]);
  }

  // do not allow:
  // - set PO Box address for [officeholders with relationshipType] at once
  private addOfficeholdersPoBoxAddressValidator(relationshipType = RelationshipType.Director): void {
    const noOfficersOfSelectedType = !Object.values(this.officers)
      .flat()
      .some((officer) => officer.type === relationshipType);

    if (noOfficersOfSelectedType) return;

    const officeholderPoBoxAddressValidator = (control: AbstractControl) => {
      const form = control as A1CompanyAddressChangeFormGroup;

      // skip validator if
      // - no officeholders selected
      // - address doesn't include "PO Box"
      const areOfficeholdersSelected = (form.controls.relationshipIds.value).some((officeholderSelected) => officeholderSelected);
      const proposedAddressIncludesPoBox = addressIncludesPoBox(form.controls.address.controls.normalizedFullAddress.value ?? '') || addressIncludesPoBox(form.controls.address.controls.street.value ?? '');

      if (!(areOfficeholdersSelected && proposedAddressIncludesPoBox)) {
        return null;
      }

      // get all UNSELECTED [relationshipType] relationships
      const unselectedDirectors = form.controls.relationshipIds.value
        ?.reduce((acc, isSelected, index) => {
          const officer: Relationship | undefined = this.officers[this.officersSelectOptions[index].value as string]
            .find((officer) => officer.type === relationshipType);

          if (!isSelected && officer) {
            acc.push(officer);
          }

          return acc;
        }, [] as (Relationship | undefined)[])
        .filter(Boolean) as Relationship[];

      const unselectedOfficersWithAuNonPoBoxAddresses = unselectedDirectors
        .filter((director) => CountriesService.isAustralia(director.individualDataOverride?.address.country ?? '') && !addressIncludesPoBox(director.individualDataOverride?.address.normalizedFullAddress ?? ''));

      return unselectedOfficersWithAuNonPoBoxAddresses.length
        ? null
        : { poBoxAddress: RelationshipTypeLabelsPlural[relationshipType] };
    };
    this.form.addValidators([officeholderPoBoxAddressValidator]);
  }

  private addShareholderOfficeholderPoBoxAddressValidator(): void {
    const shareholderOfficeholderPoBoxAddressValidator = (control: AbstractControl): ValidationErrors | null => {
      const form = control as A1CompanyAddressChangeFormGroup;
      return addressIncludesPoBox(form.controls.address.controls.normalizedFullAddress.value ?? '')
      && this.selectedOfficeholdersIds(form.controls.relationshipIds)
        .some((id) => this.shareholdersOfficeholdersIndividualIds.includes(id))
        ? { poBoxAddress: true }
        : null;
    };

    this.form.addValidators([shareholderOfficeholderPoBoxAddressValidator]);
  }

  private selectedOfficeholdersIds(relationshipIdsFormArray: FormArray<FormControl<boolean>>): string[] {
    return relationshipIdsFormArray.value
      .reduce((ids, selected, index) => {
        const id = this.officersSelectOptions[index].value;
        if (selected && id) ids.push(this.officersSelectOptions[index].value as string);
        return ids;
      }, [] as string[]);
  }

  get occupierPartHidden(): boolean {
    return this.form.controls.applyToRegistered.value !== true;
  }

  get isCheckboxErrorHidden(): boolean {
    return !(
      (this.form.controls.applyToRegistered.touched || this.form.controls.applyToPrincipal.touched || this.form.controls.relationshipIds.touched)
      && this.form.errors);
  }

  get isOfficeholderWasSelected(): boolean {
    return this.form.controls.relationshipIds.controls.some((control) => control.value);
  }
}

