import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { GridComponent } from "../../components/common/grid/components/grid/grid.component";
import { PageTitleComponent } from "../../components/common/page-title/page-title.component";
import {
  CellClickedEvent,
  ColDef,
  CsvExportParams,
  ExcelExportParams, ExcelRow,
  ExcelStyle,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  ValueFormatterParams
} from "ag-grid-community";
import { EmailTemplatesService } from "../../../services/email-templates.service";
import { formatDate } from "@angular/common";
import { OrganisationService } from "../../../services/organisation.service";
import { ToastrService } from "ngx-toastr";
import {
  AgOutgoingEmailMessageStatusComponent
} from "../components/ag-outgoing-message-status/ag-outgoing-email-message-status.component";
import { AgEmailSubjectComponent } from "../components/ag-email-subject/ag-email-subject.component";
import {
  AgEmailRecipientsDropdownComponent
} from "../components/ag-email-recipients-dropdown/ag-email-recipients-dropdown.component";
import { EmailRecord } from "../../../models/email-templates/emailRecord";
import { dateTimeComparator } from "../../components/common/grid/functions/date-time-comparator";
import { CellDataType } from "../../../models/enums/agGridEnums";
import { convertEnumToFilterOption } from "../../helpers/enum-to-filter-option.helper";
import { uniqueArrayToFilterOptionHelper } from "../../helpers/unique-array-to-filter-option.helper";
import { IServerSideGetRowsRequest } from "ag-grid-community/dist/lib/interfaces/iServerSideDatasource";
import { GridOptions } from "ag-grid-enterprise";
import { FilterOption } from "../../../models/gridFilter";
import { ColumnWithExportName } from "../../../models/columnWithExportName";
import { ButtonComponent } from "../../components/common/button/button.component";
import { ExportTypeEnum } from "../../../models/enums/exportTypeEnum";
import { ExportCompanyListComponent } from "../../modals/export-company-list/export-company-list.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ModalFormsService } from "../../../services/modal-forms.service";
import {
  AgOutgoingMessagesActionsComponent
} from "../components/ag-ougoing-emails-actions/ag-outgoing-messages-actions.component";
import { EmailStatus } from "../../../models/enums/emailStatus";
import {
  OutgoingEmailMessageDetailsComponent
} from "../components/outgoing-email-message-details/outgoing-email-message-details.component";
import { finalize } from "rxjs";
import { HasRoleDirective } from '../../../directives/has-role.directive';
import {excelReportTitleStyles, excelTitleForReport} from "../../helpers/excel-title-for-report";

@Component({
  selector: 'app-outgoing-email-messages',
  standalone: true,
  imports: [
    PageTitleComponent,
    ButtonComponent,
    GridComponent,
    OutgoingEmailMessageDetailsComponent,
    HasRoleDirective
  ],
  templateUrl: './outgoing-email-messages.component.html',
  styleUrl: './outgoing-email-messages.component.scss',
  providers: [EmailTemplatesService],
})
export class OutgoingEmailMessagesComponent implements OnInit {
  private modalFormsService = inject(ModalFormsService);
  private emailTemplatesService = inject(EmailTemplatesService);
  private organisationService = inject(OrganisationService);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);

  readonly defaultPageSize = 20;
  readonly cacheBlockSize = 20;
  readonly excelExportParams = this.exportParams() as ExcelExportParams;
  readonly excelStyles: ExcelStyle[] = excelReportTitleStyles;
  readonly profileUserEmailsList: string[] = this.organisationService.getCachedProfileUsers().map(u => u?.email);
  readonly serverRowModelType: 'serverSide' | 'infinite' | 'clientSide' = 'serverSide';
  readonly emailMessagesStatusFilterOptions = convertEnumToFilterOption(EmailStatus);
  readonly sendersFilterOptions = uniqueArrayToFilterOptionHelper(this.profileUserEmailsList);
  readonly ExportTypeEnum = ExportTypeEnum;

  gridApi!: GridApi;
  colDefs: ColDef[] = [];
  totalRows = 0;
  currentEmailRecordIndex = -1;
  currentEmailMessageItemNumber = 0;
  gridOptions: GridOptions = {
    overlayNoRowsTemplate: '<span class="no-rows-overlay">No data available</span>',
  };
  checkedEmailRecords: EmailRecord[] = [];
  dataSource: IServerSideDatasource = this.getDataSource('');
  emailRows: EmailRecord[] = [];
  lastParamsRequest: IServerSideGetRowsRequest | null = null;
  isDetailsLoading = false;
  $selectedEmailRecord = signal<EmailRecord | null>(null);
  numberColumnForExport = 4;

  ngOnInit(): void {
    this.setGridConfig();
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  selectEmailRecord(emailRecords: EmailRecord[]): void {
    this.checkedEmailRecords = emailRecords;
  }

  onSearch(searchText = ''): void {
    this.dataSource = this.getDataSource(searchText);
  }

  getDataSource(searchText = ''): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const request = params.request;
        this.lastParamsRequest = params.request;
        this.emailTemplatesService.getOutgoingEmailMessagesList(request, searchText)
          .pipe(finalize(() => this.isDetailsLoading = false))
          .subscribe({
            next: result => {
              this.emailRows = result.records;
              this.totalRows = result.total;
              params.success({
                rowData: result.records,
                rowCount: result.total,
              });

              if (this.currentEmailRecordIndex >= 0) {
                this.updateSelectedEmail();
              }
            },
            error: (err) => {
              this.toastr.error('Something happened while loading outgoing email messages', 'Error');
              console.error(err);
              params.fail();
            }
          });
      }
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams<EmailRecord>) => params.data.emailMessageId;

  openEmailRecordPreview(data: { record: EmailRecord, index: number }): void {
    this.currentEmailRecordIndex = data.index;
    this.updateSelectedEmail();
  }

  previousEmailMessage(): void {
    if (this.currentEmailRecordIndex > 0) {
      const pageSize = this.gridApi.paginationGetPageSize();
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const lastElementPrevPageIndex = pageSize * currentPage - 1;

      this.currentEmailRecordIndex--;
      if (this.currentEmailRecordIndex === lastElementPrevPageIndex) {
        this.gridApi.paginationGoToPreviousPage();
        this.isDetailsLoading = true;
      }
      this.updateSelectedEmail();
    }
  }

  nextEmailMessage(): void {
    if (this.currentEmailRecordIndex < this.totalRows) {
      const pageSize = this.gridApi.paginationGetPageSize();
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const firstElementNextPageIndex = (pageSize * currentPage + pageSize);

      this.currentEmailRecordIndex++;
      if (this.currentEmailRecordIndex === firstElementNextPageIndex) {
        this.gridApi.paginationGoToNextPage();
        this.isDetailsLoading = true;
      }
      this.updateSelectedEmail();
    }
  }

  reloadGrid(): void {
    this.gridApi.refreshServerSide();
  }

  exportSelectedEmailMessagesToXls(): void {
    this.exportEmailMessagesList(true, ExportTypeEnum.EXCEL);
  }

  exportEmailMessagesList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const componentInstance = this.modalFormsService.openModal(ExportCompanyListComponent, { modalDialogClass: 'export-company-list' }).componentInstance as ExportCompanyListComponent;

    componentInstance.title = 'Export Outgoing Email Messages';
    componentInstance.subTitle = 'email messages selected';
    componentInstance.colDefs = this.colDefs;
    componentInstance.numberOfCompanies = isBulkExport ? this.checkedEmailRecords.length : this.totalRows;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((columnForExport: string[]) => {
      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? {
          columnKeys: columnForExport,
          onlySelected: true
        } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsExcel(params);
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? {
          columnKeys: columnForExport,
          onlySelected: true
        } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsCsv(params);
      }
    });
  }

  private updateSelectedEmail(): void {
    const pageSize = this.gridApi.paginationGetPageSize();
    const currentPage = this.gridApi.paginationGetCurrentPage();

    this.currentEmailMessageItemNumber = this.currentEmailRecordIndex + 1;

    let currentIndex = 0;
    if (this.currentEmailRecordIndex < pageSize) {
      currentIndex = this.currentEmailRecordIndex;
    } else {
      const countRows = (pageSize * currentPage);
      if (countRows) {
        currentIndex = this.currentEmailRecordIndex % (pageSize * currentPage);
      } else {
        currentIndex = 0;
      }
    }

    if (!this.isDetailsLoading)
      this.$selectedEmailRecord.set(this.emailRows[currentIndex]);
  }

  onCloseDetails(): void {
    this.currentEmailRecordIndex = -1;
    this.$selectedEmailRecord.set(null);
    this.reloadGrid();
  }

  private setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Date',
        field: 'createdOn',
        sort: 'desc',
        width: 200,
        minWidth: 200,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateTimeComparator,
        },
        cellDataType: CellDataType.DATE,
        valueFormatter: (params: ValueFormatterParams<EmailRecord>) => {
          return formatDate(params.data?.createdOn ?? '', 'dd MMM yyyy HH:mm', 'en-US');
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        onCellClicked: this.cellClickHandler.bind(this),
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Subject',
        field: 'subject',
        cellRenderer: AgEmailSubjectComponent,
        flex: 1,
        onCellClicked: this.cellClickHandler.bind(this),
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Recipients',
        field: 'to',
        cellRenderer: AgEmailRecipientsDropdownComponent,
        flex: 2,
        onCellClicked: this.cellClickHandler.bind(this),
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: AgOutgoingEmailMessageStatusComponent,
        width: 105,
        minWidth: 105,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.emailMessagesStatusFilterOptions,
          values: Object.values(EmailStatus).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Sender',
        field: 'from',
        flex: 1,
        hide: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.sendersFilterOptions,
          values: this.profileUserEmailsList,
        },
        onCellClicked: this.cellClickHandler.bind(this),
        menuTabs: ['generalMenuTab'],
        cellClass: 'hover-underline'
      },
      {
        width: 60,
        minWidth: 60,
        cellRenderer: AgOutgoingMessagesActionsComponent,
        cellRendererParams: {
          resend: this.resendEmailMessage.bind(this),
          delete: this.deleteEmailMessage.bind(this),
          checkShowResend: (data: EmailRecord | undefined) => data?.status === EmailStatus.Failed,
          checkShowDelete: (data: EmailRecord | undefined) => data?.status !== EmailStatus.Created,
          checkDisabled: (data: EmailRecord | undefined) => data?.status === EmailStatus.Created,
        }
      }
    ];
  }

  private cellClickHandler(params: CellClickedEvent<EmailRecord>) {
    this.openEmailRecordPreview({
      record: params.data as EmailRecord,
      index: params.rowIndex as number
    });
  }

  private resendEmailMessage(emailRecord: EmailRecord): void {
    this.emailTemplatesService.resendOutgoingEmailMessage(emailRecord.emailMessageId)
      .subscribe({
        next: () => this.reloadGrid(),
        error: () => this.toastr.error('Something happened while sending email', 'Error')
      });
  }

  private deleteEmailMessage(emailRecord: EmailRecord): void {
    if (confirm('Deleting the email will not cancel pending e-signing envelopes or recall sent emails. To cancel an e-signing request, you must void the envelope. Do you want to continue?')) {
      this.emailTemplatesService.deleteOutgoingEmailMessage(emailRecord.emailMessageId)
        .subscribe({
          next: () => this.reloadGrid(),
          error: () => this.toastr.error('Something went wrong with deleting!', 'Error'),
        });
    }
  }

  private exportParams() {
    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const emailMessageData = params.node?.data as EmailRecord;

        if (params.column.getColId() === 'createdOn') {
          return formatDate(emailMessageData.createdOn ?? '', 'dd MMM yyyy HH:mm', 'en-US');
        } else if (params.column.getColId() === 'type' || params.column.getColId() === 'status' || params.column.getColId() === 'resolvedStatus') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          const options: FilterOption[] = params.column.getColDef()?.filterParams?.filterOptions ?? [];
          return options.find(o => o.value == value)?.label ?? '';
        }

        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName;
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      prependContent: this.getExcelTitleRows(3),
      sheetName: 'Outgoing Email Messages',
      fileName: 'Outgoing Email Messages ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
    };
  }

  private getExcelTitleRows(numberColumnForExport: number): ExcelRow[] {
    return excelTitleForReport(numberColumnForExport, 'Outgoing Email Messages');
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
