<div class="card">
  <div class="card-header pl-0">
    <div class="d-flex align-items-center justify-content-between">
      <app-page-title
        backButtonText="Back to Company profile"
        iconClass="ic-shares"
        [header]="pageTitle"
        [backButtonPath]="companyProfileLink"
      />

      <div class="button-container" id="button-container-id">
        @if(shareAlertsNumber) {
          <app-button
            class="issue-button"
            [btnColor]="'white'"
            [text]="'Share Alerts | ' + shareAlertsNumber"
            [iconClass]="'icon ic-alert-triangle'"
            [iconClassLast]="'icon ic-xs ic-chevron-down'"
            [isDisabled]="modalOpened()"
          ></app-button>

          <app-divider [vertical]="true" [margin]="'0 8px'"></app-divider>
        }

        <div
          ngbDropdown
          [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
          *appHasRole="['Admin', 'Manager', 'Preparer']"
          class="dropdown"
        >
          <button
            ngbDropdownToggle
            id="add-transaction-dd"
            class="btn btn-primary d-flex align-items-center gap-2"
            [disabled]="modalOpened()"
          >
            <i class="icon ic-xs ic-plus"></i>
            <span style="flex-grow: 1">Add Transaction</span>
            <i class="icon ic-xs ic-chevron-down"></i>
          </button>

          <div ngbDropdownMenu aria-labelledby="add-transaction-dd" class="dropdown-menu dropdown-menu-right">
            @for (menuItem of addTransactionMenu; track $index) {
              @if($last) {
                <app-divider [margin]="'4px 0'"></app-divider>
              }
              <div class="dropdown-item">
                <a class="dropdown-button" (click)="menuItem.action()">
                  <span>{{menuItem.name}}</span>
                </a>
              </div>
            }
          </div>
        </div>

        <div
          ngbDropdown
          [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
          *appHasRole="['Admin', 'Manager', 'Preparer']"
          class="dropdown"
        >
          <button
            ngbDropdownToggle
            id="company-actions-dd-3"
            class="btn btn-primary d-flex align-items-center gap-2"
            [disabled]="modalOpened()"
          >
            <span>Form Actions</span>
            <i class="icon ic-xs ic-select-icon"></i>
          </button>

          <div ngbDropdownMenu aria-labelledby="company-actions-dd-3" class="dropdown-menu dropdown-menu-right">
            @for(item of sharesFormMenu; track $index) {
              <div ngbDropdownItem class="dropdown-item">
                <a class="dropdown-button p-2" (click)="item.action()">
                  <i class="ic-form ic-md icon"></i>
                  <div class="document">
                    <div class="title">{{ item.title }}</div>
                    <div class="asic-form">{{ item.formName }}</div>
                  </div>
                </a>
              </div>
            }
          </div>
        </div>

        <div *appHasRole="['Admin', 'Manager', 'Preparer']">
          @if (checkedShares.length) {
            <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
              <button
                ngbDropdownToggle
                id="bulk-actions-dd"
                style="width: 128px"
                class="btn btn-primary d-flex align-items-center gap-2"
                [disabled]="modalOpened()"
              >
                <span>Actions</span>
                <i class="icon ic-xs ic-select-icon"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item" (click)="exportSelectedSharesToXls()">
                  <a class="dropdown-button">
                    <span>Export to xlsx</span>
                  </a>
                </div>
                <div class="dropdown-item" (click)="exportSelectedSharesToCsv()">
                  <a class="dropdown-button">
                    <span>Export to csv</span>
                  </a>
                </div>
              </div>
            </div>
          } @else {
            <app-button
              style="width: 180px"
              [text]="'Export'"
              [iconClass]="'icon ic-xs ic-download-file'"
              [isDisabled]="!sharesList.length || modalOpened()"
              (clickBtn)="exportSharesList(false, ExportTypeEnum.EXCEL)"
            ></app-button>
          }
        </div>
      </div>
    </div>
  </div>

  @if(groupedShareRows.length) {
    <app-grid
      class="shares-grid"
      storageKey="shares"
      [defaultExcelExportParams]="excelExportParams"
      [defaultCsvExportParams]="csvExportParams"
      [excelStyles]="excelStyles"
      [rowData]="groupedShareRows"
      [colDefs]="$any(groupedColDefs())"
      [pageTitle]="pageTitle"
      [getContextMenuItems]="$any(getContextMenuItems)"
      [groupDisplayType]="groupDisplayType"
      [groupByOptions]="shareGroupByOptions"
      [detailRowAutoHeight]="detailRowAutoHeight"
      [masterDetail]="true"
      [detailCellRenderer]="detailCellRenderer"
      [detailCellRendererParams]="detailCellRendererParams"
      [loading]="loading"
      (itemSelect)="selectShares($event)"
      (selectGroupByOption)="selectGroupByOption($event)"
      (clearGroupByOption)="clearGroupByOption()"
      (gridReady)="onGridReady($event)"
    ></app-grid>
  } @else {
    <app-grid
      class="shares-grid"
      storageKey="shares"
      [defaultExcelExportParams]="excelExportParams"
      [defaultCsvExportParams]="csvExportParams"
      [excelStyles]="excelStyles"
      [rowData]="sharesList"
      [rowClassRules]="rowClassRules"
      [colDefs]="$any(colDefs())"
      [pageTitle]="pageTitle"
      [getContextMenuItems]="$any(getContextMenuItems)"
      [groupByOptions]="shareGroupByOptions"
      [loading]="loading"
      (itemSelect)="selectShares($event)"
      (selectGroupByOption)="selectGroupByOption($event)"
      (clearGroupByOption)="clearGroupByOption()"
      (gridReady)="onGridReady($event)"
    ></app-grid>

    <app-divider [margin]="'0 0 24px 0'"></app-divider>

    <div class="chart-container">
      @if(shareBreakdownLabels.length && shareBreakdownSeries.length) {
        <app-donut-chart
          [name]="shareBreakdownTitle"
          [series]="shareBreakdownSeries"
          [labels]="shareBreakdownLabels"
        ></app-donut-chart>
      }

      @if(shareTypeLabels.length && shareTypeSeries.length) {
        <app-donut-chart
          [name]="shareTypeTitle"
          [series]="shareTypeSeries"
          [labels]="shareTypeLabels"
        ></app-donut-chart>
      }
    </div>
  }
</div>
