import { Component } from '@angular/core';
import { DefaulValuePipe } from "../../../../../../pipes/enumsPipes/defaultValuePipe";
import { ICellRendererParams } from "ag-grid-community";
import { HighlightOfficeholder } from "../../../../../../models/highlightOfficeholder";

@Component({
  selector: 'app-ag-officeholder-highlight-name',
  standalone: true,
  imports: [
    DefaulValuePipe
  ],
  templateUrl: './ag-officeholder-highlight-name.component.html',
  styleUrl: './ag-officeholder-highlight-name.component.scss'
})
export class AgOfficeholderHighlightNameComponent {
  pendingButtonState = false;
  officeholderName = '';
  previousOfficeholderName = '';
  previousAppointmentDateDate: string | undefined;
  previousCessationDate: string | undefined;
  appointmentDate: string | undefined;
  cessationDate: string | undefined;

  agInit(params: ICellRendererParams<HighlightOfficeholder>): void {
    this.pendingButtonState = params.data?.pendingButtonState ?? false;
    this.officeholderName = params.data?.individualDataOverride?.fullName ?? '';
    this.appointmentDate = params.data?.start as unknown as string;
    this.cessationDate = params.data?.end as unknown as string;
    this.previousOfficeholderName = params.data?.previousIndividualDataOverride?.fullName ?? '';
    this.previousAppointmentDateDate = params.data?.previousStart as unknown as string;
    this.previousCessationDate = params.data?.previousEnd as unknown as string;
  }

  refresh(params: ICellRendererParams<HighlightOfficeholder>): boolean {
    this.pendingButtonState = params.data?.pendingButtonState ?? false;
    this.officeholderName = params.data?.individualDataOverride?.fullName ?? '';
    this.appointmentDate = params.data?.start as unknown as string;
    this.cessationDate = params.data?.end as unknown as string;
    this.previousOfficeholderName = params.data?.previousIndividualDataOverride?.fullName ?? '';
    this.previousAppointmentDateDate = params.data?.previousStart as unknown as string;
    this.previousCessationDate = params.data?.previousEnd as unknown as string;
    return true;
  }
}
