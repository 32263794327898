import { Component, DestroyRef, inject, Input } from '@angular/core';
import { ReusableFormGroupComponent } from "../reusable-form-group/reusable-form-group.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { AddressControlComponent, AddressFormGroup } from "../../common/address-control/address-control.component";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatepickerHelper } from "../../../../custom-form-validators/date-picker-form-validators";
import { AddressFormGroupComponent } from "../address-form-group/address-form-group.component";
import { CountriesService } from "../../../../services/countries.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { IndividualData } from "../../../../models/individualData";
import { AutocompleteComponent } from "../../common/autocomplete/autocomplete.component";
import { DatePickerComponent } from "../../common/date-picker/date-picker.component";
import { InputComponent } from "../../common/input/input.component";
import { Address } from "../../../../models/address";
import { InputWithSuggestionsComponent } from "../../common/input-with-suggestions/input-with-suggestions.component";
import { startWith } from "rxjs";
import { parseFullName } from "../../../../functions/parse-fullname";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { directorFullNameValidatorMsg } from "../../../../validators/validatorMessages/custom-form-validators-messages";
import { ParsedName } from "../../../../models/parsedName";

export interface IndividualDataFormGroupControls {
  fullName: FormControl<string | null>;
  formerName: FormControl<ParsedName | null>;
  formerFullName: FormControl<string | null>;
  dob: FormControl<Date | null>;
  birthCity: FormControl<string | null>;
  birthCountry: FormControl<string | null>;
  address: AddressFormGroup;
}

export type IndividualDataFormGroup = FormGroup<IndividualDataFormGroupControls>;

@Component({
  selector: 'app-individual-data-form-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    AddressControlComponent,
    AutocompleteComponent,
    DatePickerComponent,
    AddressFormGroupComponent,
    InputWithSuggestionsComponent,
  ],
  templateUrl: './individual-data-form-group.component.html',
})
export class IndividualDataFormGroupComponent extends ReusableFormGroupComponent<IndividualDataFormGroup> {
  destroyRef = inject(DestroyRef);
  countriesService = inject(CountriesService);

  @Input() useInternationalAddresses = false;
  @Input() suggestedAddresses: Address[] = [];
  @Input() fullNameSuggestions: string[] = [];
  @Input() minDate: NgbDateStruct = DatepickerHelper.getDefaultMinDateStruct();
  @Input() maxDate: NgbDateStruct = DatepickerHelper.getMatureDateStruct();
  @Input() individualsName = 'Officeholders';

  @Input('individualDataFormLabels') set labels(labels: Record<keyof Partial<IndividualDataFormGroupControls>, string>) {
    this.individualDataFormLabels = Object.assign(this.individualDataFormLabels, labels);
  }

  @Input('hiddenControls') set hideControls(controlsList: (keyof IndividualDataFormGroupControls)[]) {
    controlsList.forEach(controlName => {
      this.hiddenControls[controlName] = true;
    });
  }

  individualDataFormLabels: Record<keyof IndividualDataFormGroupControls, string> = {
    fullName: 'Full Name',
    formerName: 'Former Name (if any)',
    formerFullName: 'Former Name (if any)',
    dob: 'Date of Birth',
    birthCity: 'City of Birth',
    birthCountry: 'Country of Birth',
    address: 'Address',
  };
  hiddenControls: Record<string | (keyof IndividualDataFormGroupControls), boolean> = {};
  countryOptions = this.countriesService.getCountriesAndDivisions();
  expandedAddressForm = false;
  protected readonly directorFullNameValidatorMsg = directorFullNameValidatorMsg;

  override ngOnInit() {
    super.ngOnInit();
    this.listenBirthCountryChanges();
  }

  private listenBirthCountryChanges(): void {
    this.form.controls.birthCountry.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), startWith(this.form.controls.birthCountry.value))
      .subscribe((birthCountry) => {
        if (birthCountry) {
          if (CountriesService.isAustralianStateOrAustralia(birthCountry)) {
            this.form.controls.birthCity.setValidators([Validators.required]);
          } else {
            this.form.controls.birthCity.clearValidators();
          }

          this.form.controls.birthCity.patchValue(this.form.controls.birthCity.getRawValue() ?? null);
          this.form.updateValueAndValidity();
        }
      });
  }

  get dateOfBirthCustomErrors() {
    return { tooBigDate: `${ this.individualsName } must be at least 18 years of age.` };
  }

  static override defineForm(): IndividualDataFormGroup {
    return new FormGroup(IndividualDataFormGroupComponent.defineFormControls());
  }

  static override defineFormControls(): IndividualDataFormGroupControls {
    return {
      fullName: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.directorFullNameValidator]),
      formerName: new FormControl<ParsedName | null>(null),
      formerFullName: new FormControl<string | null>(null, [CustomFormValidators.directorFullNameValidator]),
      dob: new FormControl<Date | null>(null),
      birthCity: new FormControl<string | null>(null, [Validators.required]),
      birthCountry: new FormControl<string | null>(null, [Validators.required]),
      address: AddressFormGroupComponent.defineForm()
    };
  }

  static toIndividualData(form: FormGroup<Partial<IndividualDataFormGroupControls>>, individualData: Partial<IndividualData> = {}): IndividualData {
    const formerName = parseFullName(form?.controls?.formerFullName?.value ?? '') as ParsedName;
    delete form?.controls?.formerFullName;

    return new IndividualData({
      ...individualData,
      ...form.value as Partial<IndividualData>,
      ...parseFullName(form?.controls?.fullName?.value ?? ''),
      formerName,
    });
  }
}
