import { AfterViewInit, Component, ElementRef, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModalFormComponent } from "../../common-modal-form/common-modal-form.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputComponent } from "../../../components/common/input/input.component";
import { SelectComponent } from "../../../components/common/select/select.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { emailValidatorMsg } from "../../../../validators/validatorMessages/custom-form-validators-messages";

@Component({
  selector: 'app-add-carbon-copy',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    FormsModule,
    InputComponent,
    ReactiveFormsModule,
    SelectComponent
  ],
  templateUrl: './add-carbon-copy.component.html',
  styleUrl: './add-carbon-copy.component.scss'
})
export class AddCarbonCopyComponent implements OnInit, AfterViewInit {
  @ViewChild('emailInput', { static: false }) emailInput!: ElementRef<InputComponent>
  @Output() confirm = new EventEmitter<string>();

  private fb: FormBuilder = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);

  form!: FormGroup
  protected readonly emailValidatorMsg = emailValidatorMsg;

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, CustomFormValidators.emailValidator]],
    });
  }

  ngAfterViewInit(): void {
    if (this.emailInput) {
      const el = (this.emailInput.nativeElement as unknown as HTMLElement).querySelector('.input');
      if(el) {
        (el as HTMLElement).focus();
      }
    }
  }

  add(): void {
    const email = this.form.get('email')?.value as string;
    this.confirm.emit(email);
    this.activeModal.close();
  }

  close(): void {
    this.activeModal.close();
  }
}
