import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from 'ag-grid-community';
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from "../../../../../models/securityRegistryRecord";
import { NgClass } from "@angular/common";

export interface IShareholderNameCellRendererParams extends ICellRendererParams<SecurityRegistryRecord> {
  displayPendingChanges: boolean | undefined;
}

@Component({
  selector: 'app-ag-shareholder-name',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './ag-shareholder-name.component.html',
  styleUrl: './ag-shareholder-name.component.scss'
})
export class AgShareholderNameComponent implements ICellRendererAngularComp {
  @Input() displayPendingChanges = false;

  individualShareholders: IndividualHolderModel[] = [];
  corporateShareholders: CorporateHolderModel[] = [];
  iconClass = '';

  agInit(params: IShareholderNameCellRendererParams): void {
    this.updateCell(params);
  }

  refresh(params: IShareholderNameCellRendererParams): boolean {
    return this.updateCell(params);
  }

  updateCell(params: IShareholderNameCellRendererParams): boolean {
    this.displayPendingChanges = params.displayPendingChanges ?? false;

    this.individualShareholders = [];
    this.corporateShareholders = [];

    params.data?.holders.forEach((holder) => {
      if (holder.$type === IndividualHolderModel.$type) {
        this.individualShareholders.push(holder as IndividualHolderModel);
      } else if (holder.$type === CorporateHolderModel.$type) {
        this.corporateShareholders.push(holder as CorporateHolderModel);
      }
    });

    if (params.data?.holders && params.data.holders.length > 1) {
      this.iconClass = 'ic-accounts';
    } else if (this.individualShareholders.length) {
      this.iconClass = 'ic-my-account';
    } else if (this.corporateShareholders.length) {
      this.iconClass = 'ic-companies';
    } else {
      this.iconClass = '';
    }

    return true;
  }
}
