import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Note } from '../../../../../models/note';
import { DateFormatPipe } from '../../../../../pipes/dateFormatPipe';
import {
  AgActionIconButtonComponent
} from '../../../common/grid/components/ag-action-icon-button/ag-action-icon-button.component';
import { AttachedFileLabelComponent } from '../attached-file-label/attached-file-label.component';
import { AuthService } from '../../../../../services/auth.service';
import { HasRoleDirective } from '../../../../../directives/has-role.directive';

@Component({
  selector: 'app-note-list-element',
  standalone: true,
  imports: [
    AttachedFileLabelComponent,
    DateFormatPipe,
    DatePipe,
    AgActionIconButtonComponent,
    HasRoleDirective
  ],
  templateUrl: './note-list-element.component.html',
  styleUrl: './note-list-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteListElementComponent {
  @Input() note!: Note;
  @Input() disabled = false;

  @Output() editNote = new EventEmitter<void>();
  @Output() deleteNote = new EventEmitter<void>();
  @Output() downloadFile = new EventEmitter<string>();
  @Output() deleteFile = new EventEmitter<string>();

  readonly authService = inject(AuthService);
  
  onEditNote(): void {
    this.editNote.emit();
  }

  onDeleteNote(): void {
    this.deleteNote.emit();
  }

  onDownloadFile(filename: string): void {
    if(this.canEdit) {
      this.downloadFile.emit(filename);
    }
  }

  onDeleteFile(filename: string): void {
    if(this.canEdit) {
      this.deleteFile.emit(filename);
    }
  }

  get canEdit(): boolean {
    const allowedRoles = ['Admin', 'Manager', 'Preparer'];
    return this.authService.hasRole(allowedRoles);
  }
}
